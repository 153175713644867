import React, { useState, useEffect } from 'react';
import {
    Box, Grid, Button, IconButton, Typography, TextField, Select, Divider, Autocomplete, 
    MenuItem, Accordion, AccordionSummary, AccordionDetails, FormGroup, Checkbox 
} from '@mui/material';
import { getData, postData } from "../../../ServerRequest";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function BatchCollection(props) {
    const searchParams = new URLSearchParams(window.location.search);
    const collectionId = searchParams.get('collection_id');
    const [loading, setLoading] = useState(true);
    const [collectionsData, setCollectionsData] = useState([]);
    const [batchAction, setBatchAction] = useState('');
    const [batchCollectionName, setBatchCollectionName] = useState('');
    const [batchPromotionalName, setBatchPromotionalName] = useState('');
    const [batchCreatedBy, setBatchCreatedBy] = useState(null);
    const [batchUpdatedBy, setBatchUpdatedBy] = useState(null);
    const [batchStatus, setBatchStatus] = useState([]);
    const [batchMarketingKeywords, setBatchMarketingKeywords] = useState('');
    const [selectedCollections, setSelectedCollections] = useState([]);

    const handleBatchSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (batchAction === "Merge") {
            // Call mergeCollections to create the merged collection
            const mergedCollection = mergeCollections(
                props.selectedRows,
                selectedCollections,
                batchCollectionName,
                batchPromotionalName,
                batchMarketingKeywords
            );
    
            if (!mergedCollection) {
                setLoading(false);
                return; // Stop if validation fails
            }

            const reqData = {
                'collection_id': collectionId ? collectionId : '', 
                'batch_action': batchAction ? batchAction : '',
                'merged_collection': mergedCollection ? mergedCollection : '', 
                'batch_collection_name': batchCollectionName ? batchCollectionName : '',
                'batch_promotional_name': batchPromotionalName ? batchPromotionalName : '',
                'batch_marketing_keywords': batchMarketingKeywords ? batchMarketingKeywords : '',
                'batch_created_by': batchCreatedBy ? batchCreatedBy : '',
                'batch_updated_by': batchUpdatedBy ? batchUpdatedBy : '',
                'batch_status': batchStatus ? batchStatus : 1,
            };

            try {
                console.log(reqData);
                const resData = await postData('/api/product/product-collection', reqData);
                console.log(resData);
                if (resData['data']['code'] === '200') {
                    setLoading(false);
                    alert('Batch updated successfully');
                    window.location.href = '/admin/products/collectionMaster/collection';
                } else {
                    alert('Batch update failed');
                    console.error('Failed to submit form:', resData.statusText);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error submitting form:', error);
                setLoading(false);
            }
        
        } else if (batchAction === "Delete") {
            // Call deleteCollections to prepare the delete payload
            const deletePayload = deleteCollections(props.selectedRows, selectedCollections);
    
            if (!deletePayload) {
                setLoading(false);
                return; // Stop if validation fails
            }
    
            try {
                console.log(deletePayload);
                const resData = await postData('/api/product/product-collection', deletePayload);
                console.log(resData);
                if (resData['data']['code'] === '200') {
                    setLoading(false);
                    alert('Collections deleted successfully');
                    //window.location.href = '/admin/products/collectionMaster/collection';
                } else {
                    alert('Failed to delete collections');
                    console.error('Failed to submit delete request:', resData.statusText);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error submitting delete request:', error);
                setLoading(false);
            }

        } else if (batchAction === "Archived") {
            // Call archiveCollections to prepare the archive payload
            const archivePayload = archiveCollections(props.selectedRows, selectedCollections);
    
            if (!archivePayload) {
                setLoading(false);
                return; // Stop if validation fails
            }
    
            try {
                console.log(archivePayload);
                const resData = await postData('/api/product/product-collection', archivePayload);
                console.log(resData);
                if (resData['data']['code'] === '200') {
                    setLoading(false);
                    alert('Collections archived successfully');
                    window.location.href = '/admin/products/collectionMaster/collection';
                } else {
                    alert('Failed to archive collections');
                    console.error('Failed to submit archive request:', resData.statusText);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error submitting archive request:', error);
                setLoading(false);
            }    
        } else {    
            alert("Please select a valid batch action.");
            setLoading(false);
        }    
    };

    useEffect(() => {
        async function fetchData() {
            try {                
                let resData = await getData('/api/product/product-collection');
                let collections_data = resData['data']['collection_data']
                if (collectionId) {
                    console.log(collectionId);
                    const filteredCollections = collections_data.filter(
                        (collection) => collection.id === collectionId
                    );
                    setCollectionsData(filteredCollections);
                } else {
                    setCollectionsData(collections_data);
                }

            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        }

        fetchData();

    }, []);

    const handleBatchActionChange = (event) => {
        setBatchAction(event.target.value);
    };
    
    const mergeCollections = (selectedRows, selectedCollections, batchCollectionName, batchPromotionalName, batchMarketingKeywords) => {
        // Validate inputs
        if (!batchCollectionName || !batchPromotionalName || !batchMarketingKeywords) {
            alert("Please provide a name, promotional name, and marketing keywords for the merged collection.");
            return null;
        }
    
        // Extract tags from selectedRows
        const rowTags = selectedRows.flatMap((row) => row.tags || []); // Extract tags from selectedRows
    
        // Extract tags from selectedCollections
        const collectionTags = selectedCollections.flatMap((item) => item.tags || []); // Extract tags from selectedCollections
    
        // Combine all tags
        const combinedTags = [...rowTags, ...collectionTags];
    
        // Remove duplicates
        const uniqueTags = [...new Set(combinedTags)];
    
        // Create the merged collection object
        const mergedCollection = {
            'name': batchCollectionName, // New collection name
            'promotionalName': batchPromotionalName, // New promotional name
            'marketingKeywords': batchMarketingKeywords, // New marketing keywords
            'tags': uniqueTags, // Merged tags
        };
    
        console.log("Merged Collection:", mergedCollection);
        return mergedCollection;
    };

    const deleteCollections = (selectedRows, selectedCollections) => {
        // Validate inputs
        if (selectedRows.length === 0 && selectedCollections.length === 0) {
            alert("Please select at least one collection to delete.");
            return null;
        }
    
        // Combine selected rows and collections
        const collectionsToDelete = [
            ...selectedRows.map((row) => row.id), // Assuming `id` is the unique identifier for rows
            ...selectedCollections.map((collection) => collection.id), // Assuming `id` is the unique identifier for collections
        ];
    
        // Create the delete request payload
        const deletePayload = {
            collection_id: collectionId,
            collections: collectionsToDelete,
        };
    
        console.log("Delete Payload:", deletePayload);

        // Notify the parent component to update the state
        if (props.onDeleteRows) {
            props.onDeleteRows(collectionsToDelete); // Pass the IDs to the parent component
        }

        return deletePayload;
    };

    const archiveCollections = (selectedRows, selectedCollections) => {
        // Validate inputs
        if (selectedRows.length === 0 && selectedCollections.length === 0) {
            alert("Please select at least one collection to archive.");
            return null;
        }
    
        // Combine selected rows and collections
        const collectionsToArchive = [
            ...selectedRows.map((row) => row.id), // Assuming `id` is the unique identifier for rows
            ...selectedCollections.map((collection) => collection.id), // Assuming `id` is the unique identifier for collections
        ];
    
        // Create the archive request payload
        const archivePayload = {
            collections: collectionsToArchive,
            status: "archived", // Indicating the new status
        };
    
        console.log("Archive Payload:", archivePayload);
        return archivePayload;
    };

    return (
        <Box sx={{ width: '100%', mt: 2, mb: 5 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%' }}>
                    <Accordion selectedRows={props.selectedRows} sx={{ backgroundColor: '#F8F9FA', mt: 2, borderRadius: 0, boxShadow: 0 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ p: { xs: 2, sm: 4 }, pt: 0, pb: 0, gap: { xs: 1, sm: 2 }, height: { xs: 48, sm: 58 }, alignItems: 'center', justifyContent: 'space-between', display: "flex" }}>
                            <Typography variant="body1" sx={{ fontFamily: "Inter", fontWeight: "700", fontSize: 16 }}>
                                Create batch actions of Collection
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 4, pt: 2, alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column' }}>
                            <Grid container spacing={2} sx={{ mb: 3, alignItems: 'center', flexDirection: { xs: 'column', md: 'row' } }}>
                                {/* Label and Search Box */}
                                <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', gap: 3, width: '100%' }}>
                                    <Typography variant="body1" sx={{ fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>
                                        Similar collections
                                    </Typography>
                                    <Autocomplete
                                        multiple
                                        id="tags-outlined"
                                        options={collectionsData}
                                        getOptionLabel={(option) => option.collection}
                                        filterSelectedOptions    
                                        value={selectedCollections} // Bind the state to the Autocomplete
                                        onChange={(event, newValue) => setSelectedCollections(newValue)} // Update state on selection                  
                                        renderTags={(value) => value.map((option) => option.collection).join(', ')}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                placeholder="Search by item, name, tag, product code"
                                            />
                                        )}
                                        sx={{ flex: 1, "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px", backgroundColor: "#fff" } }}
                                    />
                                </Grid>
                                {/* Filters pushed to the right */}
                                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: { xs: 'flex-start', md: 'flex-end' }, gap: 2, width: '100%' }}>
                                    <Select size="small" sx={{ width: "150px", height: "35px", borderRadius: "0px", backgroundColor: '#fff' }}>
                                        <MenuItem value="Tag1">Tag1</MenuItem>
                                        <MenuItem value="Tag2">Tag2</MenuItem>
                                        <MenuItem value="Tag3">Tag3</MenuItem>
                                    </Select>
                                    <Select size="small" sx={{ width: "150px", height: "35px", borderRadius: "0px", backgroundColor: '#fff' }}>
                                        <MenuItem value="TagA">TagA</MenuItem>
                                        <MenuItem value="TagB">TagB</MenuItem>
                                        <MenuItem value="TagC">TagC</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                            <Typography variant="body1" sx={{ fontFamily: "Inter", fontWeight: 700, fontSize: 14, mb: 1 }}>
                                Collection list | selected {props.selectedRows.length + selectedCollections.length}
                            </Typography>
                            <Divider />
                            <Grid container spacing={2} sx={{ mt: 2, mb: 5, flexDirection: { xs: 'column', md: 'row' } }}>
                                <Grid item xs={12} md={3}>
                                    <FormGroup column sx={{ display: 'flex-row' }}>
                                        {props.selectedRows.map((collection, index) => (
                                            <Box key={collection.id || index} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                                <Checkbox
                                                    checked={true} // Initially checked since it's a selected row
                                                    onChange={() => props.onToggleRow(collection)} // Call a handler to toggle selection
                                                    sx={{ padding: 0, marginRight: 1 }}
                                                />
                                                <Typography variant="body2" sx={{ fontFamily: "Inter", fontWeight: 700, fontSize: 14, mt: 1 }}>
                                                    {collection.collection || `Collection ${index + 1}`} &nbsp;&nbsp;
                                                </Typography>
                                            </Box>                                  
                                        ))}
                                        {selectedCollections.map((collection, index) => (
                                            <Box key={collection.collection} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                                <Checkbox
                                                    checked={true} // Always checked since it's selected
                                                    onChange={() => {
                                                        // Remove the collection from the selected list on uncheck
                                                        setSelectedCollections((prev) =>
                                                            prev.filter((item) => item.collection !== collection.collection)
                                                        );
                                                    }}
                                                    sx={{ padding: 0, marginRight: 1 }}
                                                />
                                                <Typography variant="body2" sx={{ fontFamily: "Inter", fontWeight: 700, fontSize: 14, mt: 1 }}>
                                                    {collection.collection} &nbsp;&nbsp;
                                                </Typography>
                                            </Box>
                                        ))}
                                    </FormGroup>
                                </Grid>
                                {/* Batch Action */}
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}> Batch Action </Typography>
                                    <Select 
                                        size="small" 
                                        value={batchAction} 
                                        onChange={handleBatchActionChange} 
                                        sx={{ width: { xs: "100%", md: "193px" }, height: "35px", borderRadius: "0px", backgroundColor: '#fff' }}   
                                    >
                                        <MenuItem value="Merge">Merge</MenuItem>
                                        <MenuItem value="Delete">Delete</MenuItem>
                                        <MenuItem value="Archived">Archived</MenuItem>
                                        <MenuItem value="Remove assigned">Remove assigned</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>  
                            <Divider sx={{ mb: 3 }} />  

                            {/* New Content */}
                            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: { xs: 2, md: 16 }, mb: 5, width: '100%' }}>
                                <Box sx={{ flex: 1 }}>
                                    <Typography sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}> 
                                        Name of the collection 
                                    </Typography>
                                    <TextField 
                                        sx={{ width: "100%", "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px", backgroundColor: '#fff' } }} 
                                        variant="outlined" 
                                        value={batchCollectionName}
                                        onChange={(e) => setBatchCollectionName(e.target.value)}
                                    />
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    <Typography sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}> 
                                        Create Promotional Name 
                                    </Typography>
                                    <TextField 
                                        sx={{ width: "100%", "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px", backgroundColor: '#fff' } }} 
                                        variant="outlined" 
                                        value={batchPromotionalName}
                                        onChange={(e) => setBatchPromotionalName(e.target.value)}
                                    />
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    <Typography sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}> 
                                        Add marketing keywords 
                                    </Typography>
                                    <TextField 
                                        sx={{ width: "100%", "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px", backgroundColor: '#fff' } }} 
                                        variant="outlined" 
                                        helperText="Summer, Essentials, light, breezy..."
                                        value={batchMarketingKeywords}
                                        onChange={(e) => setBatchMarketingKeywords(e.target.value)} 
                                    />
                                </Box>
                            </Box>

                            {/* Save Button */}
                            <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' }, width: '100%' }}>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        width: "307px",
                                        height: "44px",
                                        backgroundColor: "#565E6D",
                                        color: "#fff",
                                        "&:hover": { backgroundColor: "#546e7a" },
                                        borderRadius: "0px",
                                    }}
                                    onClick={handleBatchSubmit}
                                >
                                    Save batch
                                </Button>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Grid>
        </Box>
    );
};


