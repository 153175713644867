import { React, useState } from 'react';
import ReactDOM from "react-dom";
import MUIDataTable from "mui-datatables";
import { Grid, Container, Typography, Button, Box, TableCell } from '@mui/material';
import { Add } from '@mui/icons-material'; 

const columns = [
    {
        name: "orderId",
        label: "Order ID",
        options: {
            filter: true,
            sort: true,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
    {
        name: "customerName",
        label: "Customer Name",
        options: {
            filter: true,
            sort: false,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
    {
        name: "orderDate",
        label: "Order Date",
        options: {
            filter: true,
            sort: false,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
    {
        name: "orderStatus",
        label: "Status",
        options: {
            filter: true,
            sort: false,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
            customBodyRender: (value) => {
                let color;
                switch (value) {
                    case "Delivered":
                        color = "green";
                        break;
                    case "Processing":
                        color = "orange";
                        break;
                    case "Cancelled":
                        color = "red";
                        break;
                    default:
                        color = "gray";
                }
                return <span style={{ color }}>{value}</span>;
            }
        }
    },
];

const data = [
    { orderId: "ORD001", customerName: "Alice Smith", orderDate: "2023-09-01", orderStatus: "Delivered" },
    { orderId: "ORD002", customerName: "Bob Johnson", orderDate: "2023-09-02", orderStatus: "Processing" },
    { orderId: "ORD003", customerName: "Charlie Lee", orderDate: "2023-09-03", orderStatus: "Cancelled" },
    { orderId: "ORD004", customerName: "David Wright", orderDate: "2023-09-04", orderStatus: "Delivered" },
];

function OrdersList() {
    const options = {
        filterType: 'checkbox',
    };

    return (
        <Box sx={{ width: '95%', paddingLeft: 3, mb: 5 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%' }}>
                    <MUIDataTable
                        title={"Orders List"}
                        data={data}
                        columns={columns}
                        options={{
                            ...options,
                            responsive: 'standard',
                            tableBodyHeight: 'calc(100vh - 64px - 48px)', 
                            tableBodyMaxHeight: 'calc(100vh - 64px - 48px)', 
                        }}
                    />
                </Box>
            </Grid>
        </Box>
    );
}

export default OrdersList;
