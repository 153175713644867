import React, { useState, useEffect } from 'react';
import {
    Box, Grid, Button, IconButton, Typography, TextField, Select,
    MenuItem, Accordion, AccordionSummary, AccordionDetails, FormGroup, FormControlLabel, Checkbox
} from '@mui/material';
import { Autocomplete } from '@mui/material';
import { postFormData, getData } from '../../ServerRequest';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

const UploadProductImage = () => {
    const [productCode, setProductCode] = useState('');
    const [images, setImages] = useState({});
    const [productCodeList, setProductCodeList] = useState([]);

    const [productDetails, setProductList] = useState({
        productName: '',
        description: '',
        price: ''
    });
    const [productsData, setProductsData] = useState([]);
    const [productStatus, setProductStatus] = useState('added');
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedTab, setSelectedTab] = useState(0);

    const [pagination, setPagination] = useState({ current_page: 1, per_page: 10, total: 0 });
    const [loading, setLoading] = useState(true);
    const [productCount, setProductCount] = useState(0);
    const [counts, setCounts] = useState({
        addedProductCount: 0,
        liveProductCount: 0,
        archivedProductCount: 0,
        deletedProductCount: 0,
    });
    const fetchProducts = async (page = 1) => {
        setLoading(true);
        try {
            const resData = await getData(`/api/product/all?ps_product_status=${productStatus}&page=${page}&per_page=${pagination.per_page}`);
            console.log("API Response:", resData); // Debug response
            const { products = [], pagination: pag = { total_product: 0, total: 0, num_pages: 1, current_page: 1, per_page: 10 } } = resData.data;

            setProductsData(products);
            setProductCodeList(products.map((product) => ({ id: product.id, name: product.ps_product_prodname, code: product.ps_product_productcode })));
        } catch (error) {
            console.error("Error fetching products:", error);
            setPagination({ total: 0, num_pages: 1, current_page: 1, per_page: 10 });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [productStatus]);


    const handleProductCodeChange = (event, newValue) => {
        setProductCode(newValue ? newValue.code : '');
    };

    const handleImageChange = (e) => {
        const { name, files } = e.target;
        setImages((prevImages) => ({
            ...prevImages,
            [name]: files[0],
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        Object.keys(images).forEach((key, index) => {
            formData.append('images', images[key], `${productCode}_${index + 1}`);
        });
        formData.append('product_id', productCode);
        try {
            const response = await postFormData('/api/product/upload-fabric-image', formData);
            if (response.data.code === '200') {

                alert("Images uploaded successfully");
                setLoading(false);
                window.location.reload();
            }
            else {
                alert("Error uploading images");
                setLoading(false);
                window.location.reload();
            }
        } catch (error) {
            alert("Error uploading images");
            setLoading(false);
            window.location.reload();
            console.error('Error uploading images:', error);
        }
    };

    return (
        <Box sx={{ width: '95%', paddingLeft: 3, mb: 5 }}>
            {loading? <Typography>Loading...</Typography>:<>
            <Typography variant="h4" sx={{ marginBottom: 2 }}>Upload Product Images</Typography>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="productCode">Product Code:</label>
                    <Autocomplete
                        required={true}
                        id="productCode"
                        options={productCodeList}
                        getOptionLabel={(option) => option.name || ''}
                        value={productCodeList.find((product) => product.code === productCode) || null}
                        onChange={handleProductCodeChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label=""
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />
                </div>
                {['image1', 'image2', 'image3', 'image4'].map((imageName, index) => (
                    <div key={index}>
                        <label htmlFor={imageName}>{`Image ${index + 1}:`}</label>
                        <Grid item xs={12} sm={6} md={3} lg={3}>

                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    mt: 1,
                                    width: 120,
                                }}
                            >
                                <input type="file" id={imageName} name={imageName} onChange={handleImageChange} />
                                {images[imageName] && (
                                    <div>
                                        <img
                                            src={URL.createObjectURL(images[imageName])}
                                            alt={`Preview ${imageName}`}
                                            style={{ width: '100px', height: '100px', objectFit: 'cover', marginTop: '10px' }}
                                        />
                                    </div>
                                )}
                            </Box>
                        </Grid>
                        <br />
                        <br />

                    </div>
                ))}
                <Button type="submit" variant="contained" color="primary">
                    Upload Images
                </Button>
            </form>
            </>}
        </Box>
    );
};

export default UploadProductImage;