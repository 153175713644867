import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Divider, Select, MenuItem, Grid, Checkbox, Tabs, Tab, TextField, IconButton } from '@mui/material';
import { getData, postData } from "../../../ServerRequest"; 
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LandscapeIcon from '@mui/icons-material/PhotoCamera';
import DetailDropdowns from './DetailDropdowns';

export default function ProductDetail() { 
    const searchParams = new URLSearchParams(window.location.search);
    const productId = searchParams.get('product_id');
    const [loading, setLoading] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null);
    const [editingImageUrl, setEditingImageUrl] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [images, setImages] = useState([]);
    const [formData, setFormData] = useState({ 
        ps_product_ID: '',
        ps_product_productcode: '',
        ps_product_cat: '',
        ps_product_productdesc: '',
        ps_product_productimg: '',
        ps_product_prodwho: '',
        ps_product_prodname: '',
        ps_product_desc: '',
        ps_product_sellrate1: '',
        ps_product_sellrate2: '',
        ps_product_sellrate3: '',
        ps_product_subcat: '',
        ps_product_mill: '',
        ps_product_count: '',
        ps_product_composition: '',
        ps_product_size: '',
        ps_product_construction: '',
        ps_product_width: '',
        ps_product_style: '',
        ps_product_quality: '',
        ps_product_type: '',
        ps_product_pattern: '',
        ps_product_shade: '',
        ps_product_color1: '',
        ps_product_color2: '',
        ps_product_color3: '',
        ps_product_color4: '',
        ps_product_color5: '',
        ps_product_purchaserate1: '',
        ps_product_purchaserate2: '',
        ps_product_purchaserate3: '',
        ps_product_createdttime: '',
        ps_product_modifydttime: '',
        ps_product_QQH: '',
        ps_product_SO: '',
        ps_product_IQ: '',
        ps_product_DC: '',
        ps_product_DCvalue: '',
        ps_product_DCqty: '',
        ps_product_rcptqty: '',
        ps_product_issueqty: '',
        ps_product_lastsellrate: '',
        ps_product_COMM: '',
        ps_product_netqty: '',
        ps_product_dim1: '',
        ps_product_dim2: '',
        ps_product_dim3: '',
        ps_product_dim4: '',
        ps_product_dim5: '',
        ps_product_instock: '',
        ps_product_likes: '',
        ps_product_tags: '',
        ps_product_tags2: '',
        ps_product_excelpath: '',
        ps_product_isimgdone: '',
        ps_product_tags3: '',
    });
    const [fabricImages, setFabricImages] = useState(["fabric1.jpg", "fabric2.jpg", "fabric3.jpg", "fabric4.jpg", "fabric5.jpg", "fabric6.jpg"]);
    const [garmentImages, setGarmentImages] = useState(["garment1.jpg", "garment2.jpg", "garment3.jpg", "garment4.jpg", "garment5.jpg", "garment6.jpg"]);
    const [imageSizing, setImageSizing] = useState(["size1.jpg", "size2.jpg", "size3.jpg"]);
    const handleProductSubmit = async (event) => {
        console.log(formData);
        setLoading(true);
        event.preventDefault();
        let productReqData = {
            'ps_product_ID': formData.ps_product_ID ? formData.ps_product_ID : '',
            'ps_product_productcode': formData.ps_product_productcode,
            'ps_product_cat': formData.ps_product_cat,
            'ps_product_productdesc': formData.ps_product_productdesc,
            'ps_product_productimg': formData.ps_product_productimg,
            'ps_product_prodwho': formData.ps_product_prodwho,
            'ps_product_prodname': formData.ps_product_prodname,
            'ps_product_desc': formData.ps_product_desc,
            'ps_product_sellrate1': formData.ps_product_sellrate1,
            'ps_product_sellrate2': formData.ps_product_sellrate2,
            'ps_product_sellrate3': formData.ps_product_sellrate3,
            'ps_product_subcat': formData.ps_product_subcat,
            'ps_product_mill': formData.ps_product_mill,
            'ps_product_count': formData.ps_product_count,
            'ps_product_composition': formData.ps_product_composition,
            'ps_product_size': formData.ps_product_size,
            'ps_product_construction': formData.ps_product_construction,
            'ps_product_width': formData.ps_product_width,
            'ps_product_style': formData.ps_product_style,
            'ps_product_quality': formData.ps_product_quality,
            'ps_product_type': formData.ps_product_type,
            'ps_product_pattern': formData.ps_product_pattern,
            'ps_product_shade': formData.ps_product_shade,
            'ps_product_color1': formData.ps_product_color1,
            'ps_product_color2': formData.ps_product_color2,
            'ps_product_color3': formData.ps_product_color3,
            'ps_product_color4': formData.ps_product_color4,
            'ps_product_color5': formData.ps_product_color5,
            'ps_product_purchaserate1': formData.ps_product_purchaserate1,
            'ps_product_purchaserate2': formData.ps_product_purchaserate2,
            'ps_product_purchaserate3': formData.ps_product_purchaserate3,
            'ps_product_createdttime': formData.ps_product_createdttime,
            'ps_product_modifydttime': formData.ps_product_modifydttime,
            'ps_product_QQH': formData.ps_product_QQH,
            'ps_product_SO': formData.ps_product_SO,
            'ps_product_IQ': formData.ps_product_IQ,
            'ps_product_DC': formData.ps_product_DC,
            'ps_product_DCvalue': formData.ps_product_DCvalue,
            'ps_product_DCqty': formData.ps_product_DCqty,
            'ps_product_rcptqty': formData.ps_product_rcptqty,
            'ps_product_issueqty': formData.ps_product_issueqty,
            'ps_product_lastsellrate': formData.ps_product_lastsellrate,
            'ps_product_COMM': formData.ps_product_COMM,
            'ps_product_netqty': formData.ps_product_netqty,
            'ps_product_dim1': formData.ps_product_dim1,
            'ps_product_dim2': formData.ps_product_dim2,
            'ps_product_dim3': formData.ps_product_dim3,
            'ps_product_dim4': formData.ps_product_dim4,
            'ps_product_dim5': formData.ps_product_dim5,
            'ps_product_instock': formData.ps_product_instock,
            'ps_product_likes': formData.ps_product_likes,
            'ps_product_tags': formData.ps_product_tags,
            'ps_product_tags2': formData.ps_product_tags2,
            'ps_product_excelpath': formData.ps_product_excelpath,
            'ps_product_isimgdone': formData.ps_product_isimgdone,
            'ps_product_tags3': formData.ps_product_tags3,
        };

        const convertToBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        };

        if (formData.ps_product_productimg && formData.ps_product_productimg instanceof Blob) {
            productReqData.ps_product_productimg = await convertToBase64(formData.ps_product_productimg);
        } else if (isEditing && editingImageUrl) {
            productReqData.ps_product_productimg = editingImageUrl;
        }


        console.log(productReqData);
    
        try {
            let productResData = await postData('/api/product/all', productReqData);
            if (productResData['data']['code'] === '200') {
                alert('Product saved successfully.');
                if (isEditing) {
                    setImages(images.map(image => image.id === selectedImage.id ? formData : image));
                } else {
                    const newimage = { ...formData, id: images.length + 1 };
                    setImages([...images, newimage]);
                }            
                setLoading(false);
            } else {
                alert('Error saving product. Please try again.');
            }
        } catch (error) {
            console.error('Error saving product:', error);
        }
    };   

    useEffect(() => {
        async function fetchformData() {
            let resData = await getData('/api/product/get-product?product_id=' + productId);
            console.log(resData);
            let product_data = resData['data']['product']
            if (product_data) {
                console.log(product_data);
                const imageUrls = product_data.ps_product_productimg?product_data.ps_product_productimg.split(','):[];
                console.log(imageUrls);
                
                const savedfabricImages = imageUrls
                    .filter(url => /_1_(small|medium|large|xlarge)\.jpeg$/.test(url))
                    .map(url => `https://ambikaproduct.s3.ap-south-1.amazonaws.com/${url}`);
                // const savedgarmentImages = imageUrls
                //     .filter(url => /_2_(small|medium|large|xlarge)\.jpeg$/.test(url))
                //     .map(url => `https://ambikaproduct.s3.ap-south-1.amazonaws.com/${url}`);
                const savedsizingImages = imageUrls
                    .filter(url => /_\d_\d_(small|medium|large|xlarge)\.jpeg$/.test(url))
                    .map(url => `https://ambikaproduct.s3.ap-south-1.amazonaws.com/${url}`);

                setFabricImages(savedfabricImages);
                // setGarmentImages(savedgarmentImages);
                setImageSizing(savedsizingImages);
                if (product_data) {
                    setFormData(product_data);
                    console.log(formData)
                }
            } else {
                console.error("Error fetching product data:", resData.message);
            }
            setLoading(false);
        }
        fetchformData();
    }, []);

    const handleToggleEdit = () => {
        setIsEditing((prev) => !prev);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const newImage = {
                    id: Date.now(), 
                    ps_product_productimg: reader.result, 
                };
                setImages((prevImages) => [...prevImages, newImage]); 
            };
            reader.readAsDataURL(file);
        }
    };

    const [isChecked, setIsChecked] = useState(false);

    const toggleCheck = () => {
        setIsChecked((prev) => !prev);
    };

    const [isStocked, setIsStocked] = useState(false);

    const handleStockToggle = () => {
        setIsStocked((prevState) => !prevState);
    };

    const TabPanel = ({ value, index, children }) => {
        return (
          <Box
            role="tabpanel"
            hidden={value !== index}
            sx={{ mt: 4, mb: 4 }}
          >
            {value === index && <Typography>{children}</Typography>}
          </Box>
        );
      };
      
    
    const [selectedTab, setSelectedTab] = useState(0);
     
    const handleTabChange = (event, newValue) => {
      setSelectedTab(newValue);
    };
    
    const [selected, setSelected] = useState({});

    const handleToggle = (label) => {
        setSelected((prev) => ({
            ...prev,
            [label]: !prev[label], // Toggle state
        }));
    };

    const handleDateChange = (field, value) => {
        setFormData((prev) => ({
          ...prev,
          [field]: value,
          ps_product_dateUpdated: new Date().toISOString().split("T")[0], // YYYY-MM-DD format
        }));
    };

    return (
        <Box sx={{ width: '95%', paddingLeft: 3 }}>
            <Box sx={{ border: "0px solid #ccc", borderRadius: "0px", padding: 2, maxWidth: "1200px", margin: "0 auto" }}>
                <Typography variant="body1" sx={{ mb: 2, fontWeight: 700, fontSize: '16px', fontFamily: "Inter, sans-serif", color: "#171A1F" }}>
                    General information
                </Typography>

                {/* 1ST Part */}
                <Grid container sx={{ backgroundColor: "#F8F9FA", border: '1px solid #F3F4F6', padding: 2, borderRadius: "0px", maxWidth: "1210px" }}>
                    {/* Product Image with Placeholder */}
                    <Grid item xs={12} sm={4}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "240px",
                                border: "2px solid #ccc",
                                borderRadius: "0px",
                                backgroundColor: "white",
                                overflow: "hidden",
                            }}
                            style={{ marginRight: 25, marginLeft: 10, marginTop: 10 }}
                        >
                            {fabricImages[0] ? (
                            <img
                                src={fabricImages[0]}
                                alt="Product Img"
                                style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
                            />
                            ) : (
                            <LandscapeIcon fontSize="large" color='#BCC1CA' />
                            )}
                        </Box>
                    </Grid>

                    {/* Input Fields */}
                    <Grid item xs={12} sm={8}>
                        <Grid container spacing={2}>
                            {/* Header Inputs */}
                            <Grid item xs={12} sx={{ display: "flex", gap: 2 }}>
                                <Box sx={{ display: "flex", alignItems: "center", flex: 1, mb: 2 }}>
                                    <label htmlFor="productCode" style={{ width: "120px", fontWeight: 400, fontSize: '16px', fontFamily: "Inter, sans-serif" }}>
                                        Product code
                                    </label>
                                    <input
                                        id="productCode"
                                        type="text"
                                        style={{
                                            flex: 1,
                                            padding: "4px",
                                            border: "1px solid #F8F9FA",
                                            borderRadius: "0px",
                                            backgroundColor: "#F8F9FA",
                                            fontFamily: "Inter, sans-serif",
                                            fontWeight: 700,
                                            fontSize: '16px',
                                        }}
                                        value={formData.ps_product_productcode || ""}
                                        onChange={(e) => handleDateChange("ps_product_productcode", e.target.value)}
                                    />
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center", flex: 1, mb: 2 }}>
                                    <label htmlFor="dateUpdated" style={{ width: "120px", fontWeight: 400, fontSize: '16px', fontFamily: "Inter, sans-serif" }}>
                                        Date updated :
                                    </label>
                                    <input
                                        id="dateUpdated"
                                        type="text"
                                        style={{
                                            flex: 1,
                                            padding: "4px",
                                            border: "1px solid #F8F9FA",
                                            borderRadius: "0px",
                                            backgroundColor: "#F8F9FA",
                                            fontFamily: "Inter, sans-serif",
                                            fontWeight: 400,
                                            fontSize: '16px',
                                        }}
                                        value={formData.ps_product_dateUpdated || ""}
                                        onChange={(e) => handleDateChange("ps_product_dateUpdated", e.target.value)}
                                    />
                                </Box>
                            </Grid>

                            {/* Left Column */}
                            <Grid item xs={12} sm={6}>
                            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                                <label htmlFor="count" style={{ width: "120px", fontWeight: 400, fontSize: '16px', fontFamily: "Inter, sans-serif" }}>
                                    Count</label>
                                <input
                                    id="count"
                                    type="text"
                                    style={{
                                        flex: 1,
                                        padding: "4px",
                                        border: "1px solid #F8F9FA",
                                        borderRadius: "0px",
                                        backgroundColor: "#F8F9FA",
                                        fontFamily: "Inter, sans-serif",
                                        fontWeight: 400,
                                        fontSize: '16px',
                                    }}
                                    value={formData.ps_product_count || ""}
                                    onChange={(e) => handleDateChange("ps_product_count", e.target.value)}
                                />
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                                <label htmlFor="construction" style={{ width: "120px", fontWeight: 400, fontSize: '16px', fontFamily: "Inter, sans-serif" }}>
                                    Construction
                                </label>
                                <input
                                    id="construction"
                                    type="text"
                                    style={{
                                        flex: 1,
                                        padding: "4px",
                                        border: "1px solid #F8F9FA",
                                        borderRadius: "0px",
                                        backgroundColor: "#F8F9FA",
                                        fontFamily: "Inter, sans-serif",
                                        fontWeight: 400,
                                        fontSize: '16px',
                                    }}
                                    value={formData.ps_product_construction || ""}
                                    onChange={(e) => handleDateChange("ps_product_construction", e.target.value)}
                                />
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                                <label htmlFor="gsm" style={{ width: "120px", fontWeight: 400, fontSize: '16px', fontFamily: "Inter, sans-serif" }}>
                                    GSM</label>
  
                              <input
                                    id="gsm"
                                    type="text"
                                    style={{
                                        flex: 1,
                                        padding: "4px",
                                        border: "1px solid #F8F9FA",
                                        borderRadius: "0px",
                                        backgroundColor: "#F8F9FA",
                                        fontFamily: "Inter, sans-serif",
                                        fontWeight: 400,
                                        fontSize: '16px',
                                    }}
                                    value={formData.ps_product_gsm || ""}
                                    onChange={(e) => handleDateChange("ps_product_gsm", e.target.value)}

                                />
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                                <label htmlFor="width" style={{ width: "120px", fontWeight: 400, fontSize: '16px', fontFamily: "Inter, sans-serif" }}>
                                    Width</label>
                                <input
                                    id="width"
                                    type="text"
                                    style={{
                                        flex: 1,
                                        padding: "4px",
                                        border: "1px solid #F8F9FA",
                                        borderRadius: "0px",
                                        backgroundColor: "#F8F9FA",
                                        fontFamily: "Inter, sans-serif",
                                        fontWeight: 400,
                                        fontSize: '16px',
                                    }}
                                    value={formData.ps_product_width || ""}
                                    onChange={(e) => handleDateChange("ps_product_width", e.target.value)}
                                />
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                                <label htmlFor="updatedBy" style={{ width: "120px", fontWeight: 400, fontSize: '16px', fontFamily: "Inter, sans-serif" }}>
                                    Updated by :</
                                label>
                                <input
                                    id="updatedBy"
                                    type="text"
                                    style={{
                                        flex: 1,
                                        padding: "4px",
                                        border: "1px solid #F8F9FA",
                                        borderRadius: "0px",
                                        backgroundColor: "#F8F9FA",
                                        fontFamily: "Inter, sans-serif",
                                        fontWeight: 400,
                                        fontSize: '16px',
                                    }}
                                    value={formData.ps_product_updatedBy || ""}
                                    onChange={(e) => handleDateChange("ps_product_updatedBy", e.target.value)}
                                />
                            </Box>
                            </Grid>

                            {/* Right Column */}
                            <Grid item xs={12} sm={6}>
                            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                                <label htmlFor="mill" style={{ width: "120px", fontWeight: 400, fontSize: '16px', fontFamily: "Inter, sans-serif" }}>
                                    Mill</label>
 
                               <input
                                    id="mill"
                                    type="text"
                                    style={{
                                        flex: 1,
                                        padding: "4px",
                                        border: "1px solid #F8F9FA",
                                        borderRadius: "0px",
                                        backgroundColor: "#F8F9FA",
                                        fontFamily: "Inter, sans-serif",
                                        fontWeight: 400,
                                        fontSize: '16px',
                                    }}
                                    value={formData.ps_product_mill || ""}
                                    onChange={(e) => handleDateChange("ps_product_mill", e.target.value)}
                                />
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                                <label htmlFor="inventory" style={{ width: "120px", fontWeight: 400, fontSize: '16px', fontFamily: "Inter, sans-serif" }}>
                                    Inventory
                                </label>
                                <input
                                    id="inventory"
                                    type="text"
                                    style={{
                                        flex: 1,
                                        padding: "4px",
                                        border: "1px solid #F8F9FA",
                                        borderRadius: "0px",
                                        backgroundColor: "#F8F9FA",
                                        fontFamily: "Inter, sans-serif",
                                        fontWeight: 400,
                                        fontSize: '16px',
                                    }}
                                    value={formData.ps_product_inventory || ""}
                                    onChange={(e) => handleDateChange("ps_product_inventory", e.target.value)}
                                />
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                                <label htmlFor="fabricPattern" style={{ width: "120px", fontWeight: 400, fontSize: '16px', fontFamily: "Inter, sans-serif" }}>
                                    Fabric pattern
                                </label>
                                <input
                                    id="fabricPattern"
                                    type="text"
                                    style={{
                                        flex: 1,
                                        padding: "4px",
                                        border: "1px solid #F8F9FA",
                                        borderRadius: "0px",
                                        backgroundColor: "#F8F9FA",
                                        fontFamily: "Inter, sans-serif",
                                        fontWeight: 400,
                                        fontSize: '16px',
                                    }}
                                    value={formData.ps_product_pattern || ""}
                                    onChange={(e) => handleDateChange("ps_product_pattern", e.target.value)}
                                />
                            </Box>
                            <Button sx={{ mt: 5, fontFamily: "Inter, sans-serif", borderRadius: "0px", backgroundColor: "#565E6D", color: "#ffffff", "&:hover": { backgroundColor: "#546e7a" } }}>
                                Request modification
                            </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* 2ND Part */}
                <Box sx={{ mt: 5, mb: 5, width: "100%" }}>
                    {/* Tabs Section */}
                    <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                        variant="fullWidth"
                        textColor="inherit"
                        TabIndicatorProps={{
                        style: { display: "none" }, 
                        }}
                        sx={{    
                            minHeight: '35px',    
                            backgroundColor: "#F3F4F6",
                            '& .MuiTabs-flexContainer': {
                                height: '35px',
                            },
                            "& .MuiTab-root": {
                                minHeight: '35px',
                                textAlign: "start",
                                color: "black",
                                fontWeight: "bold",
                                padding: "2px 6px", 
                                borderRight: "3px solid #696969", 
                                "&:last-of-type": { borderRight: "none" },
                                "&.Mui-selected": { backgroundColor: "#DEE1E6" },
                        },
                        }}
                    >
                        <Tab label="Fabric Images" />
                        <Tab label="Garment Images" />
                        <Tab label="Image Sizing" />
                    </Tabs>

                    {/* Tab Content */}
                    <TabPanel value={selectedTab} index={0}>
                        <Box display="flex" gap={2}>
                        {/* Fabric Images */}
                        {fabricImages.map((src, index) => (
                            <Box
                            key={index}
                            sx={{
                                width: 93,
                                height: 63,
                                backgroundColor: "#F3F4F6", 
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "0px solid #ccc",
                                borderRadius: "0px",
                                position: "relative",
                            }}
                            >
                            <img
                                src={src}
                                alt={`Fabric ${index + 1}`}
                                width="100%"
                                height="100%"
                                style={{ display: "block" }}                            
                            />
                            <Box
                                sx={{
                                position: "absolute",
                                bottom: 8,
                                right: 8, 
                                }}
                            >
                                <IconButton>
                                    <VisibilityOffOutlinedIcon fontSize="small" />                            
                                </IconButton>
                            </Box>
                            </Box>
                        ))}
                        </Box>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        <Box display="flex" gap={2}>
                        {/* Garment Images */}
                        {garmentImages.map((src, index) => (
                            <Box
                            key={index}
                            sx={{
                                width: 93,
                                height: 63,
                                backgroundColor: "#F3F4F6", 
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "0px solid #ccc",
                                borderRadius: "0px",
                                position: "relative",
                            }}
                            >
                            <img
                                src={src}
                                alt={`Garment ${index + 1}`}
                                width="100%"
                                height="100%"
                                style={{ display: "block" }}
                            />
                            <Box
                                sx={{
                                position: "absolute",
                                bottom: 8, 
                                right: 8, 
                                }}
                            >                                
                                <IconButton>
                                    <VisibilityOffOutlinedIcon fontSize="small" />                            
                                </IconButton>
                            </Box>
                            </Box>
                        ))}
                        </Box>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={2}>
                        <Box display="flex" gap={2}>
                        {/* Image Sizing */}
                        {imageSizing.map((src, index) => (
                            <Box
                            key={index}
                            sx={{
                                width: 93,
                                height: 63,
                                backgroundColor: "#F3F4F6", 
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "0px solid #ccc",
                                borderRadius: "0px",
                                position: "relative",
                            }}
                            >
                            <img
                                src={src}
                                alt={`Size ${index + 1}`}
                                width="100%"
                                height="100%"
                                style={{ display: "block" }}
                            />
                            </Box>
                        ))}
                        </Box>
                    </TabPanel>

                    {/* Load Custom Presets Button */}
                    <Button
                        fullWidth
                        sx={{
                        padding: 1,
                        borderRadius: "0px",
                        backgroundColor: "#DEE1E6", 
                        color: "black",
                        fontWeight: "bold",
                        "&:hover": {
                            backgroundColor: "#b1b4b6", 
                        },
                        }}
                    >
                        Load Custom Presets
                    </Button>
                </Box>
                        
                {/* 3RD Part */}
                <DetailDropdowns />

                {/* 4TH Part */}
                <Box
                sx={{
                    mt: 5,
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#F3F4F6",
                    padding: 3,
                    position: "sticky", 
                    top: 0, 
                    zIndex: 1000, 
                }}
                >
                    <Typography variant="h7" sx={{ fontWeight: 700, fontSize: '16px', fontFamily: "Inter, sans-serif", color: "#323842" }}>
                        Product name
                        <IconButton sx={{ mb: 1, ml: 4 }} onClick={handleToggleEdit}>
                            <EditOutlinedIcon />
                        </IconButton>
                    </Typography>
                    <TextField
                        variant="outlined"
                        placeholder="Enter product name"
                        fullWidth
                        sx={{
                        mb: 3,
                        backgroundColor: "white",
                        width: "85%",
                        "& .MuiOutlinedInput-root": {
                            height: "36px",
                            borderRadius: "0px",
                        },
                        }}
                        value={formData.ps_product_prodname || ""}
                        onChange={(e) => handleDateChange("ps_product_prodname", e.target.value)}
                        disabled={!isEditing}
                    />
                    <Typography variant="h7" sx={{ fontWeight: 700, fontSize: '16px', fontFamily: "Inter, sans-serif", color: "#323842" }}>
                        Description
                        <IconButton sx={{ mb: 1, ml: 4 }} onClick={handleToggleEdit}>
                            <EditOutlinedIcon />
                        </IconButton>
                    </Typography>
                    <Box
                        sx={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "space-between",
                        width: "100%",
                        }}
                    >
                        <TextField
                        variant="outlined"
                        placeholder="Enter product description"
                        multiline
                        minRows={4}
                        sx={{
                            backgroundColor: "white",
                            flex: 1, 
                            "& .MuiOutlinedInput-root": {
                            borderRadius: "0px",
                            },
                        }}
                        value={formData.ps_product_productdesc || ""}
                        onChange={(e) => handleDateChange("ps_product_productdesc", e.target.value)}
                        disabled={!isEditing}
                        />

                        {/* Save Changes Button */}
                        <div style={{ textAlign: "center", marginTop: "20px" }}>
                            <Button
                                sx={{
                                    ml: 5, 
                                    backgroundColor: "#565E6D",
                                    color: "#fff",
                                        "&:hover": { backgroundColor: "#546e7a" },
                                    borderRadius: "0px",
                                    }}
                            >
                                Save Changes
                            </Button>
                        </div>
                    </Box>
                </Box>

            </Box>                                                  
            {/* <Box sx={{ p: 3, backgroundColor: '#eceff1', boxShadow: 3 }}>                             
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>    
                    <TextField fullWidth label= "CODE" value={formData.ps_product_productcode} onChange={handleInputChange} disabled variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "CATEGORY" value={formData.ps_product_cat} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "PRODUCT DESCRIPTION" value={formData.ps_product_productdesc} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "TAGS 3" value={formData.ps_product_tags3} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "WHO" value={formData.ps_product_prodwho} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "PRODUCT NAME" value={formData.ps_product_prodname} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "DESCRIPTION" value={formData.ps_product_desc} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "SELL RATE 1" value={formData.ps_product_sellrate1} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "SELL RATE 2" value={formData.ps_product_sellrate2} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "SELL RATE 3" value={formData.ps_product_sellrate3} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "SUB CATEGORY" value={formData.ps_product_subcat} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "MILL" value={formData.ps_product_mill} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "COUNT" value={formData.ps_product_count} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "COMPOSITION" value={formData.ps_product_composition} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "SIZE" value={formData.ps_product_size} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "CONSTRUCTION" value={formData.ps_product_construction} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "WIDTH" value={formData.ps_product_width} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "STYLE" value={formData.ps_product_style} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box> 
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "QUALITY" value={formData.ps_product_quality} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "TYPE" value={formData.ps_product_type} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "PATTERN" value={formData.ps_product_pattern} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "SHADE" value={formData.ps_product_shade} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "COLOR 1" value={formData.ps_product_color1} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "COLOR 2" value={formData.ps_product_color2} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "COLOR 3" value={formData.ps_product_color3} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "COLOR 4" value={formData.ps_product_color4} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "COLOR 5" value={formData.ps_product_color5} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "PURCHASE RATE 1" value={formData.ps_product_purchaserate1} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "PURCHASE RATE 2" value={formData.ps_product_purchaserate2} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "PURCHASE RATE 3" value={formData.ps_product_purchaserate3} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "CREATED TIME" value={formData.ps_product_createdttime} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "MODIFIED TIME" value={formData.ps_product_modifydttime} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "QQH" value={formData.ps_product_QQH} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box> 
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "SO" value={formData.ps_product_SO} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "IQ" value={formData.ps_product_IQ} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "DC" value={formData.ps_product_DC} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "DC VALUE" value={formData.ps_product_DCvalue} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "DC QUANTITY" value={formData.ps_product_DCqty} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "RCPT QUANTITY" value={formData.ps_product_rcptqty} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "ISSUE QUANTITY" value={formData.ps_product_issueqty} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "LAST SELL RATE" value={formData.ps_product_lastsellrate} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "COMM" value={formData.ps_product_COMM} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "NET QUANTITY" value={formData.ps_product_netqty} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "DIM 1" value={formData.ps_product_dim1} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "DIM 2" value={formData.ps_product_dim2} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "DIM 3" value={formData.ps_product_dim3} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "DIM 4" value={formData.ps_product_dim4} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "DIM 5" value={formData.ps_product_dim5} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "IN STOCK" value={formData.ps_product_instock} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "LIKES" value={formData.ps_product_likes} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "TAGS" value={formData.ps_product_tags} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box> 
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "TAGS 2" value={formData.ps_product_tags2} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "EXCEL PATH" value={formData.ps_product_excelpath} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <FormControl fullWidth> 
                        <InputLabel shrink={true}>IS IMG DONE</InputLabel>
                        <Select fullWidth label= "IS IMG DONE" value={formData.ps_product_isimgdone} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ mt: 2, backgroundColor: 'white' }} >
                            <MenuItem value={0}>True</MenuItem>     
                            <MenuItem value={1}>False</MenuItem>    
                        </Select>
                    </FormControl>                         
                </Box>  
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "IMAGE" value={formData.ps_product_productimg} onChange={handleInputChange} disabled variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                                                
                    <IconButton component="label" sx={{ color: 'black', mt: 1 }}>
                        <FileUploadOutlinedIcon />
                        <input hidden accept="image/*" type="file" onChange={(e) => handleImageUpload(e)} />
                    </IconButton>
                </Box>  
                <Box sx={{ backgroundColor: 'white', padding: 4, borderRadius: 1 }}>
                    <Box margin="normal" sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: 1 }}>
                    {images.length > 0 ? (
                        images.map((image, index) => (                           
                            <Box key={image.id}sx={{ textAlign: 'center' }}>                              
                                <img src={image.ps_product_productimg} alt="Uploaded image" style={{ width: '140px', height: '60px', marginBottom: '10px', marginTop: '10px' }} />                                                         
                            </Box>                           
                        ))
                    ) : (
                        <PhotoCameraIcon fontSize="large" />
                    )}
                    </Box>
                </Box>                                         
            </Box> */}           

        </Box>
    );  
}
