import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import { Grid, Button, IconButton, Typography, Modal, TextField, Box, TableCell, Container } from '@mui/material';
import MUIDataTable from "mui-datatables";
import { getData, postData } from "../../ServerRequest";
import AddIcon from '@mui/icons-material/Add';

export default function TagCategory() {
    const [modalOpen, setModalOpen] = useState(false); 
    const [categoryName, setCategoryName] = useState('');
    const [categoryData, setCategoryData] = useState([]);
    const [loading, setLoading] = useState(true); 

    useEffect(() => {
        async function fetchTagsCategoryData() {
            let resData = await getData('/api/product/product-tag-category');
            console.log(resData);
            let category_data = resData['data']['categories']
            if (category_data) {
                console.log(category_data);
                if (category_data) {
                    setCategoryData(category_data);
                }
            } else {
                console.error("Error fetching tags data:", resData.message);
            }
            setLoading(false);
        }

        fetchTagsCategoryData();
    }, []);

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleCategoryNameChange = (event) => {
        setCategoryName(event.target.value);
    };

    const handleFormSubmit = () => {
        console.log("Category Name:", categoryName);
        // Handle the form submission logic here (e.g., API call)
        setModalOpen(false); // Close modal after submission
    };

    const columns = [
        {
            name: "ps_tag_cat_ID",
            label: "ID",
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            }
        },
        {
            name: "ps_tag_cat_category",
            label: "Category",
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            }
        },
    ];

    const options = {
        elevation: 0,
        filterType: 'checkbox',
        responsive: 'standard',
        tableBodyHeight: 'calc(100vh - 64px - 48px)', 
        tableBodyMaxHeight: 'calc(100vh - 64px - 48px)',
        customToolbar: () => (
                <IconButton onClick={() => setModalOpen(true)} sx={{ background: '#f8f9fa', border: '3px solid #b0bec5', color: 'black', fontSize: '0.850rem', '&:hover': { background: '#eceff1' }, mb:0 }}>
                    <AddIcon /> 
                </IconButton>
            ),
            textLabels: {
                body: {
                    noMatch: loading ? "Loading..." : "Sorry, no matching records found"
                }
            }
    };

    return (
        <Box sx={{ width: '95%', paddingLeft: 3, mb: 5 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%' }}>
                    <Box sx={{ border: '2px solid #F4F3F6' }}>
                        <MUIDataTable
                            title={
                                <Typography variant="body1" sx={{ fontFamily: "Inter", fontWeight: 700, fontSize: 16 }}>
                                    Category
                                </Typography>
                            }
                            data={loading ? [] : categoryData}
                            columns={columns}
                            options={options}
                        />
                    </Box>    
                </Box>
            </Grid>
            
            {/* Modal for creating a new tag category */}
            <Modal open={modalOpen} onClose={handleModalClose} aria-labelledby="create-tag-category" aria-describedby="create-a-new-tag-category">
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 6, borderRadius: 2 }}>
                    <Typography id="create-tag-category" variant="h6" component="h2" sx={{ mb: 3 }}>
                        Create Tag Category
                    </Typography>
                    <TextField label="Category Name" fullWidth variant="outlined" value={categoryName} onChange={handleCategoryNameChange} sx={{ mb: 3 }} />
                    <Button variant="contained" sx={{ backgroundColor: '#417690', '&:hover': { backgroundColor: '#5A8EA8' } }} style={{ color: '#fff' }} onClick={handleFormSubmit}>
                        Submit
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
};
