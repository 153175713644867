import React from 'react';
import { Typography, Box, Container, TextField, Button, Select } from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';

const buttonStyle = { background: '#f8f9fa', border: '3px solid #b0bec5', color: 'black', fontSize: '0.850rem', '&:hover': { background: '#eceff1' } };

export default function DownloadPdf() {
    return (
        <Box sx={{ width: '95%', paddingLeft: 3, mb: 5 }}>            
            <Box sx={{ padding: 2, pl: 4, boxShadow: 3 }}>
                <Typography variant="h6">
                    PDF Filter
                </Typography>                    
            </Box>    
            <Box sx={{ boxShadow: 3, backgroundColor: '#ECEFF1', p: 4 }}>                        
                                    
            </Box>           
        </Box>
    );
};
