import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from '../assets/images/logo.png';
import { AppBar, Toolbar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { postData } from '../ServerRequest';


const theme = createTheme({
    palette: {
        background: {
            default: 'ghostwhite',
        },
    },
});

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Ambikacc
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);

    const handleSubmit = async(event) => {
        event.preventDefault();
        setLoading(true);
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
        const reqData = {
            username: data.get('email'),
            password: data.get('password'),
        };
        try{
            const resData = await postData('/appadmin/login', reqData);
            console.log(resData);
            if (resData['data']['code'] === 200 ) {
                window.location.href = '/admin/dashboard/Dashboard';
                //setLoading(false);

            }
            else {
                setLoading(false);
                alert("Invalid credentials");
            }
        }
        catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error);
        }
        // if (data.get('email') === 'admin@gmail.com' && data.get('password') === 'admin') {
        //     window.location.href = '/admin/dashboard/Dashboard';
        // }
    };

    return (
        
        <ThemeProvider theme={defaultTheme}>
            {loading ? <p>Loading....</p> : <>
            <AppBar position="fixed"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: '#ECEFF1'
                }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="logo">
                    <img src={logo} alt="Logo" style={{ width: '100px', height: 'auto' }} />
                    </IconButton>
                    {/* ... */}
                </Toolbar>
            </AppBar>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar sx={{ m: 1, bgcolor: '#ECEFF1' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, backgroundColor: 'rgba(0, 0, 0, 0.88)', 
                                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.70)' } }}>
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            {/* <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid> */}
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
            </>
        }
        </ThemeProvider>

    );
}