import React, { useState, useEffect, useMemo } from 'react';
import MUIDataTable from 'mui-datatables';
import { Box, Grid, Button, Typography, TextField, IconButton, Link, Select, TableCell, Tabs, Tab } from '@mui/material';
import { getData, postData } from "../../ServerRequest";
import LandscapeIcon from '@mui/icons-material/Landscape';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: (theme.vars ?? theme).palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));

export default function ProductDetails() {
    const [productsData, setProductsData] = useState([]);
    const [addedProducts, setAddedProductsData] = useState([]);
    const [liveProducts, setLiveProductsData] = useState([]);
    const [archivedProducts, setArchivedProductsData] = useState([]);
    const [deletedProducts, setDeletedProductsData] = useState([]);
    const [productStatus, setProductStatus] = useState('added');
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedTab, setSelectedTab] = useState(0);
    const [pagination, setPagination] = useState({ current_page: 1, per_page: 10, total: 0 });
    const [loading, setLoading] = useState(true);
    const [counts, setCounts] = useState({
        addedProductCount: 0,
        liveProductCount: 0,
        archivedProductCount: 0,
        deletedProductCount: 0,
    });

    const fetchProducts = async (page = 1) => {
        setLoading(true);
        try {
            const resData = await getData(`/api/product/all?ps_product_status=${productStatus}&page=${page}&per_page=${pagination.per_page}`);
            console.log("API Response:", resData); // Debug response
            const { products = [], pagination: pag = { total_product: 0, total: 0, num_pages: 1, current_page: 1, per_page: 10 } } = resData.data;
            
            setProductsData(resData['data']['added_products']);
            setPagination(pag);
            setCounts({
                liveProductCount: resData['data']['live_products_count'],
                archivedProductCount: resData['data']['archived_products_count'],
                deletedProductCount: resData['data']['deleted_products_count'],
                addedProductCount: resData['data']['added_products_count'],
            });

            setAddedProductsData(resData['data']['added_products']);
            setDeletedProductsData(resData['data']['deleted_products']);
            setArchivedProductsData(resData['data']['archived_products']);
            setLiveProductsData(resData['data']['live_products']);


        } catch (error) {
            console.error("Error fetching products:", error);
            setPagination({ total: 0, num_pages: 1, current_page: 1, per_page: 10 });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        setProductStatus(
            newValue === 0 ? 'added' : 
            newValue === 1 ? 'live' : 
            newValue === 2 ? 'archived' : 
            'deleted'
        );
        setProductsData(newValue === 0 ? addedProducts :newValue === 1 ? liveProducts : newValue === 2 ? archivedProducts : deletedProducts);
    };

    const addedColumns = [
        {
            name: 'ps_product_productcode',
            label: 'Product Code',
            options: {
                sort: true,
                // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                //                     <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                //                         {columnMeta.label}
                //                     </TableCell>
                //                 ),
                setCellProps: () => ({
                    style: { textAlign: 'center', fontFamily: 'inter', fontWeight: 600, textAlign: 'center'  } // Center content
                }),
                customBodyRender: (value) => (
                    <Link style={{ color: "black", fontWeight: "bold", textDecoration: 'none', textAlign: 'center' }}>
                        {value}
                    </Link>
                ),
            }
        },
        {
            name: 'ps_product_netqty',
            label: 'Inventory in Mtr',
            options: {
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' } // Center content
                }),
            }
        },
        {
            name: 'ps_product_createdttime',
            label: 'Date Created',
            options: {
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' } // Center content
                }),
                customBodyRender: (value) => value ? value.split('T')[0] : ''
            }
        },
        {
            name: 'updated_on',
            label: 'Added/Updated On',
            options: {
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' } // Center content
                }),
                customBodyRender: (value) => value ? value.split('T')[0] : ''
            }
        },
        {
            name: 'ps_product_prodwho',
            label: 'Created By',
            options: {
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' } // Center content
                }),
            }
        },
        {
            name: "ps_product_productcode",
            label: "Action",
            options: {
                sort: true,
                
                setCellProps: () => ({
                    style: { textAlign: 'center' } // Center content
                }),
                customBodyRender: (value) => (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Link style={{ color: "black", fontWeight: "bold", textDecoration: 'none' }} href={'/admin/products/productDetail/productDetail?product_id=' + value}>
                            <IconButton>
                                <EditOutlinedIcon style={{ color: 'black', width: '20px', height: '20px' }} />
                            </IconButton>
                        </Link>
                    </div>
                ),
            }
        },
    ];

    const liveColumns = [
        {
            name: 'ps_product_productimg',
            label: 'Image',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div style={{
                        width: 80,
                        height: 53,
                        backgroundColor: '#F3F4F6',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '0px',
                        border: "1px solid #F3F4F6",
                        overflow: 'hidden',
                        textAlign: 'center',
                        margin: 'auto', 
                    }}>
                        {value ? (
                            <img
                                src={value}
                                alt="img"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                        ) : (
                            <LandscapeIcon style={{ color: '#BCC1CA', fontSize: 30 }} />
                        )}
                    </div>
                ),
            }
        },
        {
            name: 'ps_product_productcode',
            label: 'Product Code',
            options: {
                sort: true,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },
        {
            name: 'ps_product_netqty',
            label: 'Qty',
            options: {
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' } // Center content
                }),
            }
        },
        {
            name: 'updated_on',
            label: 'Date Updated',
            options: {
                sort: true,
                // customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                //     <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                //         {columnMeta.label}
                //     </TableCell>
                // ),
                setCellProps: () => ({
                    style: { textAlign: 'center' } // Center content
                }),
                customBodyRender: (value) => value ? value.split('T')[0] : ''
            }
        },
        // {
        //     name: 'updated_on',
        //     label: 'Updated on',
        //     options: {
        //         sort: true,
        //         customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
        //             <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
        //                 {columnMeta.label}
        //             </TableCell>
        //         ),
        //         setCellProps: () => ({
        //             style: { textAlign: 'center' } // Center content
        //         }),
        //         customBodyRender: (value) => value ? value.split('T')[0] : ''
        //     }
        // },
        {
            name: 'ps_product_updatedtime',
            label: 'Updated By',
            options: {
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' } // Center content
                }),
                customBodyRender: (value, tableMeta) => {
                    if (Array.isArray(value)) {
                        return (
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', justifyContent: 'center' }}>
                                {value.map((product) => (
                                    <div
                                        key={product.id}
                                        style={{
                                            padding: '5px 10px',
                                            backgroundColor: '#F3F4F6',
                                            borderRadius: '15px',
                                            fontSize: '12px',
                                            color: '#323842',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {product.updatedby_name}
                                    </div>
                                ))}
                            </div>
                        );
                    }
                    return value;
                },
            }
        },
        {
            name: "ps_product_productcode",
            label: "Action",
            options: {
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' } // Center content
                }),
                customBodyRender: (value,tableMeta) => (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Link style={{ color: "black", fontWeight: "bold", textDecoration: 'none' }} href={'/admin/products/productDetail/productDetail?product_id=' + value}>
                                <IconButton>
                                    <EditOutlinedIcon style={{ color: 'black', width: '20px', height: '20px' }} />
                                </IconButton>
                            </Link>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '15px' }}>
                            <IconButton
                                style={{ color: 'black' }}
                                sx="center"
                                onClick={async () => {
                                    const rowIndex = tableMeta.rowIndex; // Get the index of the row to archive
                                    const rowToArchive = liveProducts[rowIndex]; // Get the row data from liveTags
                                    const updatedRow = { ...rowToArchive, collection_status: 'archived' };
                                    //await handleTableSubmit(updatedRow, 'archive');
                                }}
                            >
                                <ArchiveOutlinedIcon style={{ color: 'black', width: '20px', height: '20px' }} />
                            </IconButton>
                        </div> 
                        <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '15px' }}>
                            <IconButton
                                style={{ color: 'black' }}
                                sx="center"
                                onClick={async () => {
                                    const rowIndex = tableMeta.rowIndex; // Get the index of the row to delete
                                    const rowToDelete = liveProducts[rowIndex]; // Get the row data from liveTags     
                                    const updatedRow = { ...rowToDelete, collection_status: 'deleted' };
                                    //await handleTableSubmit(updatedRow, 'delete');
                                }}
                            >
                                <DeleteOutlinedIcon style={{ color: 'black', width: '20px', height: '20px' }} />
                            </IconButton>
                        </div>                
                    </div>                   
                ),
            }
        },
    ];

    const archivedColumns = [
        // Define columns specific to the "Archived" tab
        {
            name: 'ps_product_productimg',
            label: 'Image',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div style={{
                        width: 80,
                        height: 53,
                        backgroundColor: '#F3F4F6',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '0px',
                        border: "1px solid #F3F4F6",
                        overflow: 'hidden',
                        textAlign: 'center',
                        margin: 'auto', 
                    }}>
                        {value ? (
                            <img
                                src={value}
                                alt="img"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                        ) : (
                            <LandscapeIcon style={{ color: '#BCC1CA', fontSize: 30 }} />
                        )}
                    </div>
                ),
            }
        },
        {
            name: 'ps_product_producttitle',
            label: 'Product Title',
            options: {
                sort: true,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },
        {
            name: 'ps_product_netqty',
            label: 'No of Items',
            options: {
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' } // Center content
                }),
            }
        },
        {
            name: 'ps_product_updatedtime',
            label: 'Date Updated',
            options: {
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' } // Center content
                }),
                customBodyRender: (value) => value ? value.split('T')[0] : ''
            }
        },
        {
            name: 'updated_on',
            label: 'Collection added',
            options: {
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' } // Center content
                }),
                customBodyRender: (value) => value ? value.split('T')[0] : ''
            }
        },
        {
            name: 'ps_product_updatedtime',
            label: 'Updated By',
            options: {
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' } // Center content
                }),
                customBodyRender: (value, tableMeta) => {
                    if (Array.isArray(value)) {
                        return (
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', justifyContent: 'center' }}>
                                {value.map((product) => (
                                    <div
                                        key={product.id}
                                        style={{
                                            padding: '5px 10px',
                                            backgroundColor: '#F3F4F6',
                                            borderRadius: '15px',
                                            fontSize: '12px',
                                            color: '#323842',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {product.updatedby_name}
                                    </div>
                                ))}
                            </div>
                        );
                    }
                    return value;
                },
            }
        },
        {
            name: 'action',
            label: 'Action',
            options: {
                sort: true,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
                customBodyRender: (value, tableMeta) => (
                    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '15px' }}>
                        <IconButton
                            style={{ color: 'black' }}
                            sx="center"
                            onClick={async () => {
                                const rowIndex = tableMeta.rowIndex; // Get the index of the row to retrieve
                                const rowToRetrieve = archivedProducts[rowIndex]; // Get the row data from archivedTags
                                const updatedRow = { ...rowToRetrieve, collection_status: 'live' };
                                //await handleTableSubmit(updatedRow, 'retrieve');
                            }}
                        >
                            <UnarchiveOutlinedIcon style={{ color: 'black', width: '20px', height: '20px' }} />
                        </IconButton>
                    </div>
                ),                          
            }
        },
        // Add more columns as needed
    ];

    const deletedColumns = [
        // Define columns specific to the "Deleted" tab
        {
            name: 'ps_product_productimg',
            label: 'Image',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div style={{
                        width: 80,
                        height: 53,
                        backgroundColor: '#F3F4F6',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '0px',
                        border: "1px solid #F3F4F6",
                        overflow: 'hidden',
                        textAlign: 'center',
                        margin: 'auto', 
                    }}>
                        {value ? (
                            <img
                                src={value}
                                alt="img"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                        ) : (
                            <LandscapeIcon style={{ color: '#BCC1CA', fontSize: 30 }} />
                        )}
                    </div>
                ),
            }
        },
        {
            name: 'ps_product_producttitle',
            label: 'Product Title',
            options: {
                sort: true,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },
        {
            name: 'ps_product_netqty',
            label: 'No of Items',
            options: {
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' } // Center content
                }),
            }
        },
        {
            name: 'ps_product_updatedtime',
            label: 'Date Updated',
            options: {
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' } // Center content
                }),
                customBodyRender: (value) => value ? value.split('T')[0] : ''
            }
        },
        {
            name: 'added_on',
            label: 'Collection added',
            options: {
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' } // Center content
                }),
                customBodyRender: (value) => value ? value.split('T')[0] : ''
            }
        },
        {
            name: 'ps_product_updatedtime',
            label: 'Updated By',
            options: {
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' } // Center content
                }),
                customBodyRender: (value, tableMeta) => {
                    if (Array.isArray(value)) {
                        return (
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', justifyContent: 'center' }}>
                                {value.map((product) => (
                                    <div
                                        key={product.id}
                                        style={{
                                            padding: '5px 10px',
                                            backgroundColor: '#F3F4F6',
                                            borderRadius: '15px',
                                            fontSize: '12px',
                                            color: '#323842',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {product.updatedby_name}
                                    </div>
                                ))}
                            </div>
                        );
                    }
                    return value;
                },
            }
        },
        {
            name: 'ps_product_deleted_date',
            label: 'Deleted Date',
            options: {
                sort: true,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
                customBodyRender: (value) => value ? value.split('T')[0] : ''
            }
        },
        // Add more columns as needed
    ];

    const columns = useMemo(() => {
        switch (selectedTab) {
            case 0: return addedColumns;
            case 1: return liveColumns;
            case 2: return archivedColumns;
            case 3: return deletedColumns;
            default: return addedColumns;
        }
    }, [selectedTab]);

    const options = {
        filterType: 'checkbox',
        responsive: 'standard',
        print: false,        // Disable print
        download: false,     // Disable download
        columns: false,      // Disable column visibility
        viewColumns: false,  // Disable view columns
        filter: false,
        search: false,
        sort: true,
        // serverSide: false, // Enable server-side pagination
        // count: pagination?.total_product || 0, // Set the total count of items
        // page: pagination?.current_page - 1, // MUI uses zero-based indexing for pages
        // rowsPerPage: pagination?.per_page,
        // onChangePage: (currentPage) => {
        //     fetchProducts(currentPage + 1); // Convert zero-based index to one-based index
        // },
        // onChangeRowsPerPage: (rowsPerPage) => {
        //     setPagination((prev) => ({ ...prev, per_page: rowsPerPage }));
        //     fetchProducts(1); // Reset to the first page when per_page changes
        // },
        textLabels: {
            body: {
                noMatch: loading ? "Loading..." : "Sorry, no matching records found"
            }
        }
    };

    // Filtered data based on searchQuery
    const filteredData = useMemo(() => {
        if (!Array.isArray(productsData)) return [];
        if (!searchQuery) return productsData;
        
        return productsData
            //.filter(row => row) // Exclude null or undefined rows
            .filter(row =>
                Object.values(row || {}).some(value =>
                    typeof value === "object"
                        ? Object.values(value || {}).some(subValue =>
                            subValue?.toString().toLowerCase().includes(searchQuery.toLowerCase())
                        )
                        : value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
                )
            );
    }, [productsData, searchQuery]);

    useEffect(() => {
        console.log("Fetching products for status:", productStatus);
        fetchProducts();
    }, []);
    
    useEffect(() => {
        console.log("Products data updated:", productsData);
    }, []);     

    return (
        <Box sx={{ width: '95%', paddingLeft: 3, mb: 5 }}>
        <Grid container sx={{ flex: 1, height: '100%' }}>
            {/* Product Update Panel Section */}

            <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 0, mb: 2 }}>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Product update panel</Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={2} lg={2}>
                    <TextField
                        sx={{ "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px" } }}
                        size="small"
                        variant="outlined"
                        placeholder="Product, collection by name"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <IconButton position="start" sx={{ ml: "-10px", mr: 1, color: "black" }}>
                                    <SearchIcon />
                                </IconButton>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>          
                    {/* Weekly Data Visualization Legend */}
                    <Grid container sx={{ display: 'flex', gap: 2, backgroundColor: "#DEE1E6", padding: 0.5, pl: 2, pr: 2 }}>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TrendingDownIcon color="black" />
                                <Typography variant="body2">Decline in sale</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TrendingUpIcon color="black" />
                                <Typography variant="body2">Increase in sale</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <PauseCircleOutlineIcon color="black" />
                                <Typography variant="body2">Stagnant item</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', mt: 2, mb: 2 }}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box sx={{ height: '35px' }}>
                        <Tabs
                            value={selectedTab}
                            onChange={handleTabChange}
                            variant="fullWidth"
                            sx={{
                                minHeight: '35px',
                                border: '1px solid #F3F4F6',
                                borderRadius: '0px',
                                '& .MuiTabs-flexContainer': {
                                    height: '35px',
                                },
                                '& .MuiTab-root': {
                                    minHeight: '35px',
                                    width: '144px',
                                    borderRight: '1px solid #F3F4F6',
                                    textTransform: 'none',
                                    color: '#333',
                                },
                                '& .MuiTab-root:last-of-type': {
                                    borderRight: 'none',
                                },
                                '& .Mui-selected': {
                                    color: '#000 !important',
                                },
                                '& .MuiTabs-indicator': {
                                    height: '3px !important',
                                    backgroundColor: '#9095A0 !important',
                                },
                            }}
                        >
                            <Tab label={`Added (${counts.addedProductCount})`} />
                            <Tab label={`Live (${counts.liveProductCount})`} />
                            <Tab label={`Archived (${counts.archivedProductCount})`} />
                            <Tab label={`Deleted (${counts.deletedProductCount})`} />
                        </Tabs>
                    </Box>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, ml: 'auto' }}>
                        <Select
                            sx={{ width: "250px", height: "35px", borderRadius: "0px" }}
                            size="small"
                            variant="outlined"
                        />
                        <Select
                            sx={{ width: "250px", height: "35px", borderRadius: "0px" }}
                            size="small"
                            variant="outlined"
                            placeholder="Collection Name"
                        />
                    </Box>
                </Grid> */}
            </Grid>
            <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', mt: 1, mb: 4 }} >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    {/* Data Table */}
                    <Box sx={{ border: '1px solid #F4F3F6' }}>
                        <MUIDataTable
                            title={''}
                            data={loading ? [] : filteredData}
                            columns={columns}
                            options={{ ...options, elevation: 0 }}
                        />
                    </Box>

                    {selectedTab === 0 && ( 
                        <div style={{ textAlign: "end", marginTop: "25px", marginBottom: "40px" }}>
                            <Button
                                sx={{
                                    backgroundColor: "#565E6D",
                                    color: "#fff",
                                    "&:hover": { backgroundColor: "#546e7a" },
                                    borderRadius: "0px",
                                }}
                            >
                                Send update request
                            </Button>
                        </div>
                    )}
                </Grid>
            </Grid>

        </Grid>
        </Box>
    );
};
