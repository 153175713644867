import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, TextField } from '@mui/material';
import { getData, postData } from "../../../ServerRequest"; 
import LinkIcon from '@mui/icons-material/Link';
import SearchCollection from './SearchCollection';
import ManualCollection from './ManualCollection';
import Layout from '../../../components/layout/Layout';
import CloseIcon from '@mui/icons-material/Close';

export default function CreateCollection() { 
    const searchParams = new URLSearchParams(window.location.search);
    const collectionId = searchParams.get('collection_id');
    const [loading, setLoading] = useState(true);   
    const [mainCollectionId, setMainCollectionId] = useState(null);
    const [searchCollectionTagIds, setSearchCollectionTagIds] = useState([]);        
    const [searchCollection, setSearchCollection] = useState([]);   
    const [searchNestedCollectionTagIds, setSearchNestedCollectionTagIds] = useState([]);
    const [searchNestedCollection, setSearchNestedCollection] = useState([]); 
    //manual collection
    const [manualCollection, setManualCollection] = useState([]);        
    const [manualNestedCollection, setManualNestedCollection] = useState([]);   
    //popup visibility
    const [showDefineCollection, setShowDefineCollection] = useState(false);
    const [showCollectionSection, setShowCollectionSection] = useState(false);
    // nested within nested collections
    const [addSearchNestedCollections, setAddSearchNestedCollections] = useState([]);   
    const [addManualNestedCollections, setAddManualNestedCollections] = useState([]);   
    // form fields for all collections
    const [collectionName, setCollectionName] = useState('');
    const [promotionalName, setPromotionalName] = useState('');
    const [marketingKeywords, setMarketingKeywords] = useState('');
    const [isCollectionSaved, setIsCollectionSaved] = useState(false);

    const [nestedCollections, setNestedCollections] = useState([]);


    useEffect(() => {
        if (manualCollection.length > 0 || manualNestedCollection.length > 0) {
            setShowCollectionSection(true);
        }
    }, [manualCollection, manualNestedCollection]);

    const handleCollectionSubmit = async (event) => {        
        setLoading(true);
        event.preventDefault();
        console.log(searchCollection);
        

        // Validate required fields
        if (!collectionName || !promotionalName || !marketingKeywords) {
            alert('Please fill in all required fields before submitting.');
            setLoading(false);
            return;
        }

        let reqData = {
            'collection_name': collectionName ? collectionName : '',
            'promotional_name': promotionalName ? promotionalName : '',
            'marketing_keyword': marketingKeywords ? marketingKeywords : '',
            'search_collection': searchCollectionTagIds ? searchCollectionTagIds : [] ,
            'search_nested_collection': searchNestedCollection ? searchNestedCollection : '',
            'manual_collection': manualCollection ? manualCollection : '', 
            'manual_nested_collection': manualNestedCollection ? manualNestedCollection : '', 
            'is_nested': searchNestedCollection.length > 0 || manualNestedCollection.length > 0 ? true : false,
            'nested_collection': nestedCollections ? nestedCollections : '', 
        };

        console.log(reqData);
    
        try {
            let resData = await postData('/api/product/product-collection', reqData);
            if (resData['data']['code'] === '200') {
                console.log('Collection saved successfully:', resData);
                alert('Collection saved successfully.');      
                if (resData['data']['collection_id']) {
                    setMainCollectionId(resData['data']['collection_id']);
                    
                     // Fetch the full collection details
                    const collectionDetails = await getData(`/api/product/product-collection/${resData['data']['collection_id']}`);
                    console.log('Full collection details:', collectionDetails);
                }
                // Reset form fields
                setCollectionName('');
                setPromotionalName('');
                setMarketingKeywords('');
                setSearchCollection([]);
                setSearchNestedCollection([]);
                setManualCollection([]);
                setManualNestedCollection([]);
                setAddSearchNestedCollections([]);
                setShowDefineCollection(false);
                setIsCollectionSaved(true);
                //window.location.href = '/admin/products/collectionMaster/createCollection';
            } else {
                alert('Error saving collection. Please try again.');
                //console.error('Failed to submit form:', resData.statusText);
            }
        } catch (error) {
            console.error('Error saving collection:', error);
        } finally {
            setLoading(false);
        }
    }; 
    
    const handleShowCollectionSection = () => setShowCollectionSection(true);

    const handleCloseCollectionSection = () => setShowCollectionSection(false);
    
    return (
        <Box sx={{ width: { xs: '100%', md: '95%' }, paddingLeft: { xs: 1, md: 3 }, mb: 5 }}>
            <Typography variant="body1" sx={{ fontFamily: "Inter", fontWeight: 700, fontSize: 16 }}>
                Create a new collection 
            </Typography>            
            <Box sx={{ display: "flex", flexDirection: "column", gap: "0px", marginTop: 5 }}>
                {showCollectionSection && (
                <Box sx={{ border: "1px solid #DEE1E6", position: 'sticky', top: '70px', zIndex: 1000, backgroundColor: 'rgba(255, 255, 255, 0.8)', backdropFilter: 'blur(25px)' }}>
                    {/* Created Collection Display */}
                    <Box sx={{ p: 3, backgroundColor: "#DEE1E6" }}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 3, flexWrap: "nowrap" }}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: { xs: "20px", md: "40px" }, mb: 3, flexWrap: "wrap" }}>
                                <Typography variant="body1" sx={{ fontFamily: "Inter", fontWeight: 700, fontSize: 16 }}>
                                    Selected Tags
                                </Typography>
                                <Typography 
                                    variant="body2" 
                                    sx={{ fontFamily: "Inter", fontWeight: 400, fontSize: 14, fontStyle: "italic", textTransform: "uppercase" }}
                                >
                                    TOTAL {searchCollection.length + searchNestedCollection.length + manualCollection.length + manualNestedCollection.length} ITEMS 
                                    / {searchCollection.nos} Never out of stock
                                </Typography>
                            </Box>
                           
                            {/* Close Button */}
                            <Button
                                variant="outlined"
                                sx={{
                                    mb: 2,
                                    width: "30px", // Circular button
                                    height: "30px",
                                    minWidth: "30px",
                                    padding: 0,
                                    borderRadius: "50%", // Make it circular
                                    color: "#565E6D",
                                    border: "1px solid #565E6D",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "14px",
                                    "&:hover": { backgroundColor: "#f5f5f5" },
                                }}
                                onClick={handleCloseCollectionSection}
                            >
                                <CloseIcon sx={{ fontSize: "16px" }} />
                            </Button>
                        </Box>    
                        

                        {/* Collection Display */}
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
                            {/* Search Collection display */}
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", alignItems: "center", overflowX: "auto" }}>
                                {searchCollection.map((tag) => (
                                    <Button
                                        key={tag.id}                                    
                                        sx={{
                                            borderRadius: "20px",
                                            fontSize: "11px",
                                            padding: "4px 10px",
                                            minWidth: "70px",
                                            whiteSpace: "nowrap",
                                            display: "flex",
                                            border: "1px solid #565E6D",
                                            backgroundColor: "#fff",
                                            color: "#565E6D",
                                        }}
                                    >
                                        {tag.product_tag ? tag.product_tag.trim() : ""}
                                    </Button>
                                ))}
                            </Box>
                            
                            {/* HyperLink Icon */}
                            {searchNestedCollection.length > 0 && <LinkIcon sx={{ mr: 2, ml: 2 }} />}

                            {/* Search Nested Collection Display */}    
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", alignItems: "center", overflowX: "auto" }}>                              
                                {searchNestedCollection.map((tag) => (
                                    <Button
                                        key={tag.id}                                    
                                        sx={{
                                            borderRadius: "20px",
                                            fontSize: "11px",
                                            padding: "4px 10px",
                                            minWidth: "70px",
                                            whiteSpace: "nowrap",
                                            display: "flex",
                                            border: "1px solid #565E6D",
                                            backgroundColor: "#fff",
                                            color: "#565E6D",
                                        }}
                                    >
                                        {tag.product_tag ? tag.product_tag.trim() : ""}
                                    </Button>
                                ))}

                                {/* HyperLink Icon for new nested collections */}
                                {addSearchNestedCollections.length > 0 && <LinkIcon sx={{ mr: 2, ml: 2 }} />}

                                {/* New Nested Collection Display */}
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", alignItems: "center", overflowX: "auto" }}>
                                    {addSearchNestedCollections.map((tag) => (
                                        <Button
                                            key={tag.id}
                                            sx={{
                                                borderRadius: "20px",
                                                fontSize: "11px",
                                                padding: "4px 10px",
                                                minWidth: "70px",
                                                whiteSpace: "nowrap",
                                                display: "flex",
                                                border: "1px solid #565E6D",
                                                backgroundColor: "#fff",
                                                color: "#565E6D",
                                            }}
                                        >
                                            {tag.product_tag ? tag.product_tag.trim() : ""}
                                        </Button>
                                    ))}
                                </Box>
                            </Box>
                        </Box>                        

                        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, mt : 2 }}>
                            {/* Manual Collection Display */}
                            <Box  sx={{ display: "flex", flexWrap: "wrap", gap: "8px", alignItems: "center", overflowX: "auto" }}>
                                {manualCollection.map((tag) => (
                                    <Button
                                        key={tag.id}                                    
                                        sx={{
                                            borderRadius: "20px",
                                            fontSize: "11px",
                                            padding: "4px 10px",
                                            minWidth: "70px",
                                            whiteSpace: "nowrap",
                                            display: "flex",
                                            border: "1px solid #565E6D",
                                            backgroundColor: "#fff",
                                            color: "#565E6D",
                                        }}
                                    >
                                        {tag.product_tag ? tag.product_tag.trim() : ""}
                                    </Button>
                                ))}
                            </Box>

                            {/* HyperLink Icon */}
                            {manualNestedCollection.length > 0 && 
                                <LinkIcon sx={{ mr: 2, ml: 2 }} />
                            }

                            {/* Manual Nested Collection display */}    
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", alignItems: "center", overflowX: "auto" }}>
                                {manualNestedCollection.map((tag) => (
                                    <Button
                                        key={tag.id}                                    
                                        sx={{
                                            borderRadius: "20px",
                                            fontSize: "11px",
                                            padding: "4px 10px",
                                            minWidth: "70px",
                                            whiteSpace: "nowrap",
                                            display: "flex",
                                            border: "1px solid #565E6D",
                                            backgroundColor: "#fff",
                                            color: "#565E6D",
                                        }}
                                    >
                                        {tag.product_tag ? tag.product_tag.trim() : ""}
                                    </Button>
                                ))}
                            </Box>

                            {/* HyperLink Icon for new nested collections */}
                            {addManualNestedCollections.length > 0 && <LinkIcon sx={{ mr: 2, ml: 2 }} />}

                            {/* New Nested Collection Display */}
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", alignItems: "center", overflowX: "auto" }}>
                                {addManualNestedCollections.map((tag) => (
                                    <Button
                                        key={tag.id}
                                        sx={{
                                            borderRadius: "20px",
                                            fontSize: "11px",
                                            padding: "4px 10px",
                                            minWidth: "70px",
                                            whiteSpace: "nowrap",
                                            display: "flex",
                                            border: "1px solid #565E6D",
                                            backgroundColor: "#fff",
                                            color: "#565E6D",
                                        }}
                                    >
                                        {tag.product_tag ? tag.product_tag.trim() : ""}
                                    </Button>
                                ))}
                            </Box>
                        </Box>
                    </Box>

                    {showDefineCollection && (
                    <Box sx={{ p: 1, pt: 2, pb: 0 }}>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: { xs: 2, md: 16 }, mb: 5 }}>
                            <Box sx={{ flex: 1 }}>
                                <Typography sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}> 
                                    Name of the collection 
                                </Typography>
                                <TextField 
                                    sx={{ width: "100%", "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px" } }} 
                                    variant="outlined" 
                                    value={collectionName}
                                    onChange={(e) => setCollectionName(e.target.value)}
                                />
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <Typography sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}> 
                                    Create Promotional Name 
                                </Typography>
                                <TextField 
                                    sx={{ width: "100%", "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px" } }} 
                                    variant="outlined" 
                                    value={promotionalName}
                                    onChange={(e) => setPromotionalName(e.target.value)}
                                />
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <Typography sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}> 
                                    Add marketing keywords 
                                </Typography>
                                <TextField 
                                    sx={{ width: "100%", "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px" } }} 
                                    variant="outlined" helperText="Summer, Essentials, light, breezy..."
                                    value={marketingKeywords}
                                    onChange={(e) => setMarketingKeywords(e.target.value)} 
                                />
                            </Box>
                        </Box>
                        {/* Save Changes Button */}
                        <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
                              <Button
                                sx={{
                                    width: { xs: "100%", md: "307px" },
                                    height: "44px",
                                    backgroundColor: "#565E6D",
                                    color: "#fff",
                                    "&:hover": { backgroundColor: "#546e7a" },
                                    borderRadius: "0px",
                                    }}
                                onClick={handleCollectionSubmit}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                    )}
                </Box>
                )}

                {/* Create collection from search */}            
                <SearchCollection 
                    setSearchCollection={(validTags) => {
                        console.log("Selected Tags:", validTags); // Debugging: Log the selected tags
                        setSearchCollection(
                            validTags.map(tag => ({
                                id: tag.id,
                                product_tag: tag.product_tag,
                            }))
                        );
                    }}   
                    setSearchCollectionTagIds={setSearchCollectionTagIds}              
                    setSearchNestedCollection={(validTags) => 
                        setSearchNestedCollection(
                            validTags.map(tag => ({
                                id: tag.id,
                                product_tag: tag.product_tag,
                            }))
                        )
                    }
                    setSearchNestedCollectionTagIds={setSearchNestedCollectionTagIds}
                    setShowDefineCollection={setShowDefineCollection}
                    handleShowCollectionSection={handleShowCollectionSection} 
                    mainCollectionId={mainCollectionId} 
                    isCollectionSaved={isCollectionSaved}              
                />
                {/* Create collection from manual tags */}
                <ManualCollection 
                    setManualCollection={setManualCollection}            
                    setSearchCollectionTagIds={setSearchCollectionTagIds}     
                    setManualNestedCollection={setManualNestedCollection}
                    setShowDefineCollection={setShowDefineCollection} 
                    handleShowCollectionSection={handleShowCollectionSection} 
                    mainCollectionId={mainCollectionId} 
                    isCollectionSaved={isCollectionSaved} 
                />           
            </Box>
        </Box>
    );  
}

