import React, { useEffect, useState, useRef } from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Box, Typography, Button, Divider, Select, MenuItem, Checkbox, Slider,
  FormControlLabel, RadioGroup, FormControl, Grid, Radio, Switch, TextField, IconButton, Chip
} from '@mui/material';
import { getData, postData, postFormData } from "../../../ServerRequest";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CancelIcon from "@mui/icons-material/Cancel";
import AddFabricTag from './AddFabricTag';

export default function DetailDropdowns() {
  const searchParams = new URLSearchParams(window.location.search);
  const productId = searchParams.get('product_id');
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState('');
  const [open, setOpen] = useState(false);
  const [editingImageUrl, setEditingImageUrl] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [images, setImages] = useState([]);
  const [ps_product_ID, setPsProductID] = useState('');
  const [ps_product_productcode, setPsProductProductcode] = useState('');
  const [ps_product_cat, setPsProductCat] = useState('');
  const [ps_product_productdesc, setPsProductProductdesc] = useState('');
  const [ps_product_productimg, setPsProductProductimg] = useState('');
  const [ps_product_prodwho, setPsProductProdwho] = useState('');
  const [ps_product_prodname, setPsProductProdname] = useState('');
  const [ps_product_desc, setPsProductDesc] = useState('');
  const [ps_product_sellrate1, setPsProductSellrate1] = useState('');
  const [ps_product_sellrate2, setPsProductSellrate2] = useState('');
  const [ps_product_sellrate3, setPsProductSellrate3] = useState('');
  const [ps_product_subcat, setPsProductSubcat] = useState('');
  const [ps_product_mill, setPsProductMill] = useState('');
  const [ps_product_count, setPsProductCount] = useState('');
  const [ps_product_composition, setPsProductComposition] = useState('');
  const [ps_product_size, setPsProductSize] = useState('');
  const [ps_product_construction, setPsProductConstruction] = useState('');
  const [ps_product_width, setPsProductWidth] = useState('');
  const [ps_product_style, setPsProductStyle] = useState('');
  const [ps_product_quality, setPsProductQuality] = useState('');
  const [ps_product_type, setPsProductType] = useState('');
  const [ps_product_pattern, setPsProductPattern] = useState('');
  const [ps_product_shade, setPsProductShade] = useState('');
  const [ps_product_color1, setPsProductColor1] = useState('');
  const [ps_product_color2, setPsProductColor2] = useState('');
  const [ps_product_color3, setPsProductColor3] = useState('');
  const [ps_product_color4, setPsProductColor4] = useState('');
  const [ps_product_color5, setPsProductColor5] = useState('');
  const [ps_product_purchaserate1, setPsProductPurchaserate1] = useState('');
  const [ps_product_purchaserate2, setPsProductPurchaserate2] = useState('');
  const [ps_product_purchaserate3, setPsProductPurchaserate3] = useState('');
  const [ps_product_createdttime, setPsProductCreatedttime] = useState('');
  const [ps_product_modifydttime, setPsProductModifydttime] = useState('');
  const [ps_product_QQH, setPsProductQQH] = useState('');
  const [ps_product_SO, setPsProductSO] = useState('');
  const [ps_product_IQ, setPsProductIQ] = useState('');
  const [ps_product_DC, setPsProductDC] = useState('');
  const [ps_product_DCvalue, setPsProductDCvalue] = useState('');
  const [ps_product_DCqty, setPsProductDCqty] = useState('');
  const [ps_product_rcptqty, setPsProductRcptqty] = useState('');
  const [ps_product_issueqty, setPsProductIssueqty] = useState('');
  const [ps_product_lastsellrate, setPsProductLastsellrate] = useState('');
  const [ps_product_COMM, setPsProductCOMM] = useState('');
  const [ps_product_netqty, setPsProductNetqty] = useState('');
  const [ps_product_dim1, setPsProductDim1] = useState('');
  const [ps_product_dim2, setPsProductDim2] = useState('');
  const [ps_product_dim3, setPsProductDim3] = useState('');
  const [ps_product_dim4, setPsProductDim4] = useState('');
  const [ps_product_dim5, setPsProductDim5] = useState('');
  const [ps_product_instock, setPsProductInstock] = useState('');
  const [ps_product_likes, setPsProductLikes] = useState('');
  const [ps_product_tags, setPsProductTags] = useState('');
  const [ps_product_tags2, setPsProductTags2] = useState('');
  const [ps_product_excelpath, setPsProductExcelpath] = useState('');
  const [ps_product_isimgdone, setPsProductIsimgdone] = useState('');
  const [ps_product_tags3, setPsProductTags3] = useState('');

  // Additional model variables
  const [product_code, setProductCode] = useState('');
  const [productDetailId, setProductDetailId] = useState(0);
  const [is_nos, setIsNos] = useState(false);
  const [denim, setDenim] = useState(false);
  const [minimum_order, setMinimumOrder] = useState('');
  const [tag_ids, setTagIds] = useState([]);
  const [fabric_specification, setFabricSpecification] = useState('');
  const [delivery_return_policy, setDeliveryReturnPolicy] = useState({
    policy_type: "manual", 
    shipping_details: '',
    return_policy: ''
  });
  const [product_description, setProductDescription] = useState('');
  const [product_title, setProductTitle] = useState('');
  const [febricWeave, setFebricWeave] = useState('');
  const [patternSize, setPatternSize] = useState("medium");

  const [excludedTerritories, setExcludedTerritories] = useState([]);
  const [includePincodes, setIncludePincodes] = useState([]);
  const [excludePincodes, setExcludePincodes] = useState([]);
  const [inputValues, setInputValues] = useState({ excluded: "", include: "", exclude: "" });
  const [isSpecChecked, setIsSpecChecked] = useState(false);
  const [fabricSpec, setFabricSpec] = useState("");
  const [editedSpec, setEditedSpec] = useState(fabricSpec);

  const [manufacturingDetails, setManufacturingDetails] = useState("");
  const [editedManufacturing, setEditedManufacturing] = useState(manufacturingDetails);

  const [isTestChecked, setIsTestChecked] = useState(false);
  const [reports, setReports] = useState([
    { id: 1, name: "Report 1", file: null },
    { id: 2, name: "Report 2", file: null },
    { id: 3, name: "Report 3", file: null },
  ]);

  // Meta tags & description
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [titleTag, setTitleTag] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [weaveList, setWeaveList] = useState([]);
  const [isStocked, setIsStocked] = useState(false);
  const [fabricWeave, setFabricWeave] = useState('');
  const [minOrderQty, setMinOrderQty] = useState("10");
  const [isCotton, setIsCotton] = useState(false);

  const [savedFabricSpec, setSavedFabricSpec] = useState('');
  const [savedManufacturingDetails, setSavedManufacturingDetails] = useState('');
  const [savedTitleTag, setSavedTitleTag] = useState('');
  const [savedMetaDescription, setSavedMetaDescription] = useState('');


  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    let productReqData = {
      'id': productDetailId,
      'product_id': ps_product_ID,
      'product_code': ps_product_productcode,
      'fabric_weave': fabricWeave,
      'is_nos': is_nos,
      'denim': denim,
      'minimum_order': minOrderQty,
      'tag_ids': tag_ids,
      'fabric_specification': fabric_specification,
      'manufacturing_details': manufacturingDetails,
      'delivery_return_policy': delivery_return_policy,
      'product_description': product_description,
      'product_title': product_title,
    };

    try {
      console.log(productReqData);
      const res = await postData('/api/product/save-fabric-details', productReqData);
      console.log(res);
      if (res['data']['code'] === '200') {
        setLoading(false);
        alert('Product updated successfully');
        window.location.reload();
      } else {
        alert('Product update failed');
        console.error('Failed to submit form:', res.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setLoading(false);
    }
  };

  const handleFabricDetailsSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    let fabricDetailsData = {
      'id': productDetailId,
      'product_id': productId,
      'product_code': product_code,
      'fabric_weave': fabricWeave,
      'is_nos': is_nos,
      'denim': denim,
      'minimum_order': minOrderQty,
      'tag_ids': tag_ids,
      'fabric_specification': fabric_specification,
      'manufacturing_details': manufacturingDetails,
      'delivery_return_policy': delivery_return_policy,
      'product_description': product_description,
      'product_title': product_title,
    };

    try {
      console.log(fabricDetailsData);
      const res = await postData('/api/product/save-fabric-details?product_id=' + productId, fabricDetailsData);
      console.log(res);
      if (res['data']['code'] === '200') {
        setLoading(false);
        alert('Fabric details updated successfully');
      } else {
        alert('Fabric details update failed');
        console.error('Failed to submit form:', res.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setLoading(false);
    }
  };

  const handleFabricSpecSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    let fabricSpecData = {
      'id': productDetailId,
      'product_code': productId,
      'fabric_specification': editedSpec,
    };

    try {
      console.log(fabricSpecData);
      const res = await postData('/api/product/save-fabric-specification?product_id=' + productId, fabricSpecData);
      console.log(res);
      if (res['data']['code'] === '200') {
        setSavedFabricSpec(editedSpec);
        setLoading(false);
        alert('Fabric specification updated successfully');
      } else {
        alert('Fabric specification update failed');
        console.error('Failed to submit form:', res.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setLoading(false);
    }
  };

  const handleManufacturingDetailsSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    let manufacturingDetailsData = {
      'id': productDetailId,
      'product_code': productId,
      'manufacturing_details': manufacturingDetails,
    };

    try {
      console.log(manufacturingDetailsData);
      const res = await postData('/api/product/save-manufacturing-details?product_id=' + productId, manufacturingDetailsData);
      console.log(res);
      if (res['data']['code'] === '200') {
        setSavedManufacturingDetails(manufacturingDetails);
        setLoading(false);
        alert('Manufacturing details updated successfully');
        //window.location.reload();
      } else {
        alert('Manufacturing details update failed');
        console.error('Failed to submit form:', res.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setLoading(false);
    }
  };

  const handleTestReportSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
  
    try {
      const reportsToSubmit = reports.filter((report) => report.file);
  
      if (reportsToSubmit.length === 0) {
        alert("No files to update.");
        setLoading(false);
        return;
      }
  
      // Create a FormData object
      const formData = new FormData();
      await formData.append('product_id', productId);
      reportsToSubmit.forEach((report, index) => {
        formData.append('reports', report.file, `${productId}_${index + 1}`);
      });
      // console.log(Object.fromEntries(formData.entries()));
      // Send the FormData object using postFormData
      const res = await postFormData('/api/product/save-test-report', formData);
      console.log("API response:", res);
  
      if (res.data.code !== '200') {
        throw new Error("Failed to update report: " + res.statusText);
      }
  
      setLoading(false);
      alert("Test reports updated successfully");
  
      // Update the reports state to mark them as saved
      setReports((prev) =>
        prev.map((report) => ({
          ...report,
          isNewUpload: false,
        }))
      );
    } catch (error) {
      console.error("Error submitting reports:", error);
      setLoading(false);
      alert("Test report update failed");
    }
  };

  const handleDeliveryPolicySubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
  
    let deliveryPolicyData = {
      'id': productDetailId,
      'product_id': productId,
      'delivery_return_policy': {
        'policy_type': delivery_return_policy.policy_type,
        'shipping_details': delivery_return_policy.shipping_details,
        'return_policy': delivery_return_policy.return_policy
      }
    };
  
    try {
      const res = await postData('/api/product/save-delivery-policy?product_id=' + productId, deliveryPolicyData);
      if (res.data.code === '200') {
        setLoading(false);
        alert('Policy updated successfully');
        // Update local state with new data including policy_type
        setDeliveryReturnPolicy(res.data.updated_policy);
      } else {
        alert('Policy update failed');
        console.error('Failed to submit form:', res.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setLoading(false);
    }
  };

  const handleDeliveryDetailsSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const deliveryDetailsData = {
      'id': productId,
      'excluded_territories': excludedTerritories,
      'include_pincodes': includePincodes,
      'exclude_pincodes': excludePincodes
    };

    try {
      console.log(deliveryDetailsData);
      const res = await postData('/api/product/save-delivery-details?product_id=' + productId, deliveryDetailsData);
      console.log(res);
      if (res['data']['code'] === '200') {
        setLoading(false);
        alert('Delivery details updated successfully');
      } else {
        alert('Delivery details update failed');
        console.error('Failed to submit form:', res.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setLoading(false);
    }
  };

  const handleMetaTagsSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    let metaTagsData = {
      'id': productId,
      'title_tag': titleTag,
      'meta_description': metaDescription,
    };

    try {
      console.log(metaTagsData);
      const res = await postData('/api/product/save-meta-tags?product_id=' + productId, metaTagsData);
      console.log(res);
      if (res['data']['code'] === '200') {
        setSavedTitleTag(titleTag);
        setSavedMetaDescription(metaDescription);
        setLoading(false);
        alert('Meta tags & description updated successfully');
      } else {
        alert('Meta tags & description update failed');
        console.error('Failed to submit form:', res.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    async function fetchformData() {
      let resData = await getData('/api/product/get-product?product_id=' + productId);
      console.log(resData);
      let product_data = resData['data']['product'];
      let product_details_data = resData['data']['acc_product_details'];
      if (product_data) {
        console.log(product_data);
        console.log(product_details_data);

        if (product_details_data && product_details_data.fabric_specification) {
          setSavedFabricSpec(product_details_data.fabric_specification);
          setEditedSpec(product_details_data.fabric_specification);
        } else if (product_data.fabric_specification) {
          setSavedFabricSpec(product_data.fabric_specification);
          setEditedSpec(product_data.fabric_specification);
        }

        if (product_details_data && product_details_data.manufacturing_details) {
          setSavedManufacturingDetails(product_details_data.manufacturing_details);
        setManufacturingDetails(product_details_data.manufacturing_details);
        } else if (product_data.manufacturing_details) {
          setSavedManufacturingDetails(product_data.manufacturing_details);
          setManufacturingDetails(product_data.manufacturing_details);
        }

        /* if (product_data && product_data.reports) {
          const reportsFromDB = product_data.reports.map((report) => ({
            ...report,
            isNewUpload: false, 
          }));
          setReports(reportsFromDB);
        } */

        const deliveryPolicy = product_data.delivery_return_policy || {};
        setDeliveryReturnPolicy({
          policy_type: deliveryPolicy.policy_type || "manual",
          shipping_details: deliveryPolicy.shipping_details || '',
          return_policy: deliveryPolicy.return_policy || ''
        });

        if (product_data.delivery_details) {
          setExcludedTerritories(product_data.delivery_details.excluded_territories || []);
          setIncludePincodes(product_data.delivery_details.include_pincodes || []);
          setExcludePincodes(product_data.delivery_details.exclude_pincodes || []);
        }

        if (product_details_data) {
          setSavedTitleTag(product_details_data.product_title || '');
          setSavedMetaDescription(product_details_data.product_description || '');
          setTitleTag(product_details_data.product_title || '');
          setMetaDescription(product_details_data.product_description || '');
        }

        setPsProductID(product_data.ps_product_ID);
        setPsProductProductcode(product_data.ps_product_productcode);
        setPsProductCat(product_data.ps_product_cat);
        setPsProductProductdesc(product_data.ps_product_productdesc);
        setPsProductProductimg(product_data.ps_product_productimg);
        setPsProductProdwho(product_data.ps_product_prodwho);
        setPsProductProdname(product_data.ps_product_prodname);
        // setPsProductDesc(product_data.ps_product_desc);
        setPsProductSellrate1(product_data.ps_product_sellrate1);
        setPsProductSellrate2(product_data.ps_product_sellrate2);
        setPsProductSellrate3(product_data.ps_product_sellrate3);
        setPsProductSubcat(product_data.ps_product_subcat);
        // setPsProductMill(product_details_data? product_details_data.manufacturing_details:product_data.ps_product_mill);
        setPsProductCount(product_data.ps_product_count);
        setPsProductComposition(product_data.ps_product_composition);
        setPsProductSize(product_data.ps_product_size);
        setPsProductConstruction(product_data.ps_product_construction);
        setPsProductWidth(product_data.ps_product_width);
        setPsProductStyle(product_data.ps_product_style);
        setPsProductQuality(product_data.ps_product_quality);
        setPsProductType(product_data.ps_product_type);
        setPsProductPattern(product_data.ps_product_pattern);
        setPsProductShade(product_data.ps_product_shade);
        setPsProductColor1(product_data.ps_product_color1);
        setPsProductColor2(product_data.ps_product_color2);
        setPsProductColor3(product_data.ps_product_color3);
        setPsProductColor4(product_data.ps_product_color4);
        setPsProductColor5(product_data.ps_product_color5);
        setPsProductPurchaserate1(product_data.ps_product_purchaserate1);
        setPsProductPurchaserate2(product_data.ps_product_purchaserate2);
        setPsProductPurchaserate3(product_data.ps_product_purchaserate3);
        setPsProductCreatedttime(product_data.ps_product_createdttime);
        setPsProductModifydttime(product_data.ps_product_modifydttime);
        setPsProductQQH(product_data.ps_product_QQH);
        setPsProductSO(product_data.ps_product_SO);
        setPsProductIQ(product_data.ps_product_IQ);
        setPsProductDC(product_data.ps_product_DC);
        setPsProductDCvalue(product_data.ps_product_DCvalue);
        setPsProductDCqty(product_data.ps_product_DCqty);
        setPsProductRcptqty(product_data.ps_product_rcptqty);
        setPsProductIssueqty(product_data.ps_product_issueqty);
        setPsProductLastsellrate(product_data.ps_product_lastsellrate);
        setPsProductCOMM(product_data.ps_product_COMM);
        setPsProductNetqty(product_data.ps_product_netqty);
        setPsProductDim1(product_data.ps_product_dim1);
        setPsProductDim2(product_data.ps_product_dim2);
        setPsProductDim3(product_data.ps_product_dim3);
        setPsProductDim4(product_data.ps_product_dim4);
        setPsProductDim5(product_data.ps_product_dim5);
        setPsProductInstock(product_data.ps_product_instock);
        setPsProductLikes(product_data.ps_product_likes);
        setPsProductTags(product_data.ps_product_tags);
        setPsProductTags2(product_data.ps_product_tags2);
        setPsProductExcelpath(product_data.ps_product_excelpath);
        setPsProductIsimgdone(product_data.ps_product_isimgdone);
        setPsProductTags3(product_data.ps_product_tags3);
        setProductCode(product_data.product_code);
        setIsNos(product_details_data?product_details_data.is_nos:product_data.is_nos);
        setDenim(product_details_data?product_details_data.denim:product_data.denim);
        setMinimumOrder(product_data.minimum_order);
        setTagIds(product_details_data?product_details_data.tag_ids:product_data.tag_ids);
        setFabricSpecification(product_data.fabric_specification);
        // setDeliveryReturnPolicy(product_data.delivery_return_policy);
        setProductDescription(product_data.product_description);
        setProductTitle(product_data.product_title);
        setFebricWeave(product_details_data?product_details_data.fabric_weave:product_data.febricWeave);
        setProductDetailId(product_details_data?product_details_data.id:'');
        setPatternSize(product_data.patternSize);
        setIsSpecChecked(product_data.isSpecChecked);
        setFabricSpec(product_data.fabricSpec);
        setEditedSpec(product_data.editedSpec);
        setManufacturingDetails(product_details_data?product_details_data.manufacturing_details?product_details_data.manufacturing_details:product_data.ps_product_mill:product_data.ps_product_mill);
        setEditedManufacturing(product_data.editedManufacturing);
        setIsTestChecked(product_data.isTestChecked);
        setReports(product_data?product_data.reports?product_data.reports:reports:reports);
        setIsChecked(product_data.isChecked);
        setIsStocked(product_data.isStocked);
        setFabricWeave(product_details_data?product_details_data.fabric_weave:product_data.fabricWeave);
        setMinOrderQty(product_details_data?product_details_data.minimum_order:product_data.minOrderQty);
        setIsCotton(product_data.isCotton);
        setTitleTag(product_details_data?product_details_data.tag_ids:product_data.titleTag);
        setMetaDescription(product_details_data?product_details_data.product_description:product_data.metaDescription);

      } else {
        console.error("Error fetching product data:", resData.message);
      }
      let weaveListData = await getData('/api/product/get-weaves');
      console.log(weaveListData['data']['weave_tags']);
      
      if (weaveListData['data']['code'] === 200) {
        setWeaveList(weaveListData['data']['weave_tags']);
      } else {
        console.error("Error fetching weave list:", weaveListData.message);
      }
      setLoading(false);
    }
    fetchformData();
  }, [productId]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newImage = {
          id: Date.now(),
          ps_product_productimg: reader.result,
        };
        setImages((prevImages) => [...prevImages, newImage]);
      };
      reader.readAsDataURL(file);
    }
  };

  const [isChecked, setIsChecked] = useState(false);

  const toggleCheck = () => {
    setIsChecked((prev) => !prev);
  };

  const handleDeliveryInputChange = (e, field) => {
    setInputValues({ ...inputValues, [field]: e.target.value });
  };

  const [territoryInputs, setTerritoryInputs] = useState([
    { id: 1, value: '', pincodeRange: '' },
    { id: 2, value: '', pincodeRange: '' },
    { id: 3, value: '', pincodeRange: '' }
  ]);
  
  // Fetch pincode range for a single territory
  const fetchPincodeRange = async (territoryName) => {
    try {
      const response = await fetch(`https://api.postalpincode.in/postoffice/${territoryName}`);
      const data = await response.json();
      
      if (data[0]?.Status === "Success" && data[0]?.PostOffice?.length > 0) {
        const pincodes = data[0].PostOffice.map(office => office.Pincode).sort();
        return pincodes.length > 0 ? `${pincodes[0]} to ${pincodes[pincodes.length-1]}` : '';
      }
      return '';
    } catch (error) {
      console.error("Error fetching pincodes:", error);
      return '';
    }
  };

  const handleTerritoryInputChange = async (id, value) => {
    const updatedInputs = territoryInputs.map(input => 
      input.id === id ? { ...input, value, pincodeRange: '' } : input
    );
    
    setTerritoryInputs(updatedInputs);
    
    setTimeout(async () => {
      if (value.length > 2) {
        const range = await fetchPincodeRange(value);
        setTerritoryInputs(prev => 
          prev.map(input => 
            input.id === id ? { ...input, pincodeRange: range } : input
          )
        );
      }
    }, 500);
  };

  const handleAddTerritories = () => {
    const newTerritories = territoryInputs
      .filter(input => input.value.trim() && input.pincodeRange)
      .map(input => input.value.trim());
  
    setExcludedTerritories(prev => [...new Set([...prev, ...newTerritories])]);
    setTerritoryInputs([
      { id: 1, value: '', pincodeRange: '' },
      { id: 2, value: '', pincodeRange: '' },
      { id: 3, value: '', pincodeRange: '' }
    ]);
  };

  const handleAddTag = async (field) => {
    const value = inputValues[field].trim();
    if (!value) return;
  
    try {
      let processedValue = value;
      
      if (field === "excluded") {
        // Fetch pincode details for excluded territories
        processedValue = await fetchPincodeRange(value);
      } else {
        // Validate numeric pincode for include/exclude
        if (!/^\d+$/.test(value)) {
          alert("Please enter a valid numeric pincode");
          return;
        }
      }
  
      const updateState = {
        excluded: () => setExcludedTerritories([...excludedTerritories, processedValue]),
        include: () => setIncludePincodes([...includePincodes, processedValue]),
        exclude: () => setExcludePincodes([...excludePincodes, processedValue]),
      };
  
      updateState[field]();
      setInputValues({ ...inputValues, [field]: "" });
    } catch (error) {
      console.error("Error adding tag:", error);
    }
  };

  // Remove tags
  const handleRemoveTag = (field, index) => {
    if (field === "excluded") setExcludedTerritories(excludedTerritories.filter((_, i) => i !== index));
    else if (field === "include") setIncludePincodes(includePincodes.filter((_, i) => i !== index));
    else if (field === "exclude") setExcludePincodes(excludePincodes.filter((_, i) => i !== index));
  };

  // Fabric Testing Report function 
const handleTestFileChange = (index, target) => {
  if (!target || !target.files || !target.files[0]) {
    console.error("Invalid file input");
    return;
  }
  const file = target.files[0];
  if (file && file.type === "application/pdf" && file.size <= 5 * 1024 * 1024) {
    setReports((prev) => {
      const updatedReports = [...prev];
      updatedReports[index] = {
        ...updatedReports[index],
        file: file,
        fileName: file.name,
        isNewUpload: true,
      };
      return updatedReports;
    });
  } else {
    alert("Only PDFs up to 5MB are allowed.");
  }
};

  const handleTestDownload = (index) => {
    const file = reports[index].file;
    if (file) {
      const url = URL.createObjectURL(file);
      const a = document.createElement("a");
      a.href = url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } else {
      alert("No file uploaded to download.");
    }
  };

  const handleTestNameChange = (index, event) => {
    const name = event.target.value;
    setReports((prev) => {
      const updatedReports = [...prev];
      updatedReports[index] = {
        ...updatedReports[index],
        name: name,
      };
      return updatedReports;
    });
  };

  const addNewTestReport = () => {
    setReports((prev) => [...prev, { id: prev.length + 1, name: "", file: null }]);
  };


  const handleStockToggle = () => {
    setIsStocked((prev) => {
      const newValue = !prev;
      setIsNos(newValue ? 1 : 0);
      return newValue;
    });
  };

  return (
    <Box sx={{ padding: 0, width: "100%", margin: "0 auto" }}>
      { /* Dropdowns */}
      <Accordion sx={{ backgroundColor: "#F3F4F6", boxShadow: 0 }}>
        <AccordionSummary
          expandIcon={
            <div onClick={toggleCheck}>
              <ExpandMoreIcon />
              {isChecked ? (
                <RadioButtonCheckedIcon sx={{ ml: 2 }} />
              ) : (
                <RadioButtonUncheckedIcon sx={{ ml: 2 }} />
              )}
            </div>
          }
          sx={{ display: "flex", alignItems: "center", gap: 2, height: 58 }}
        >
          <Typography
            variant="h7"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              fontWeight: "bold",
            }}
          >
            Important Fabric Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: 30,
              padding: 30,
              paddingTop: 0,
              paddingBottom: 5,
            }}
          >
            {/* Column 1 */}
            <div
              style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: 15,
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Fabric Weave: {fabricWeave}
              </Typography>
              <Select
              value={fabricWeave}
              onChange={(e) => setFabricWeave(e.target.value)}
              sx={{ width: 200, height: 30, borderRadius: "0px", backgroundColor: 'white' }}
              >
              {weaveList.map((weave) => (
                <MenuItem key={weave.id} value={weave.product_tag} selected={weave.product_tag === fabricWeave}>
                {weave.product_tag}
                </MenuItem>
              ))}
              </Select>
              <Button
              startIcon={<AddCircleOutlineIcon />}
              sx={{
                mt: 4.5,
                width: 200,
                height: 28,
                border: "1px solid #ccc",
                borderRadius: "0px",
                backgroundColor: "white",
                color: "black",
                fontWeight: "bold",
                "&:hover": { backgroundColor: "#b1b4b6" },
              }}
              >
              Create New
              </Button>
            </div>

            {/* Column 2 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: 15,
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Never Out of Stock
              </Typography>
              <Box display="flex" alignItems="center" gap={1} sx={{ ml: 2 }}>
                <Typography>Yes</Typography>
                <Switch
                  checked={is_nos}
                  onChange={handleStockToggle}
                  sx={{
                    width: 40,
                    height: 24,
                    padding: 0,
                    '& .MuiSwitch-switchBase': {
                      '&.Mui-checked': {
                        transform: 'translateX(10px)',
                        '& + .MuiSwitch-track': {
                          backgroundColor: 'black',
                          opacity: 1,
                        },
                      },
                      '&:not(.Mui-checked)': {
                        transform: 'translateX(-7px)',
                      },
                    },
                    '& .MuiSwitch-thumb': {
                      backgroundColor: 'white',
                      width: 18,
                      height: 18,
                      marginTop: "-6px",
                    },
                    '& .MuiSwitch-track': {
                      backgroundColor: 'black',
                      borderRadius: 20,
                    },
                  }}
                />
                <Typography>No</Typography>
              </Box>
              <div
                style={{ display: "flex", flexDirection: "column", gap: 8 }}
              >
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Minimum Order Quantity
                </Typography>
                <Select
                  value={minOrderQty}
                  onChange={(e) => setMinOrderQty(e.target.value)}
                  sx={{ width: 200, height: 34, borderRadius: "0px", backgroundColor: 'white' }}
                >
                  <MenuItem value="10" selected={10 === minOrderQty}>10</MenuItem>
                  <MenuItem value="20" selected={20 === minOrderQty}>20</MenuItem>
                  <MenuItem value="50" selected={50 === minOrderQty}>50</MenuItem>
                </Select>
              </div>
            </div>

            {/* Column 3 */}
            {/* <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: 15,
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Is NOS
              </Typography>
              <Switch
                checked={is_nos}
                onChange={(e) => setIsNos(e.target.checked)}
                sx={{
                  width: 40,
                  height: 24,
                  padding: 0,
                  '& .MuiSwitch-switchBase': {
                    '&.Mui-checked': {
                      transform: 'translateX(10px)',
                      '& + .MuiSwitch-track': {
                        backgroundColor: 'black',
                        opacity: 1,
                      },
                    },
                    '&:not(.Mui-checked)': {
                      transform: 'translateX(-7px)',
                    },
                  },
                  '& .MuiSwitch-thumb': {
                    backgroundColor: 'white',
                    width: 18,
                    height: 18,
                    marginTop: "-6px",
                  },
                  '& .MuiSwitch-track': {
                    backgroundColor: 'black',
                    borderRadius: 20,
                  },
                }}
              />
            </div> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: 15,
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={denim}
                    onChange={(e) => setDenim(e.target.checked)}
                    sx={{
                      color: "#565E6D",
                      '&.Mui-checked': {
                        color: "#565E6D",
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: 22,

                      }
                    }}
                  />
                }
                label="Denim"
              />
              {/* Save Changes Button */}
              <div style={{ textAlign: "center", marginTop: "58px" }}>
                <Button
                  sx={{ backgroundColor: "#565E6D", color: "#fff", "&:hover": { backgroundColor: "#546e7a" }, borderRadius: "0px" }}
                  onClick={handleSubmit}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      {/* Add Fabric Tag Section */}
      <AddFabricTag />

      {/* Fabric specification */}
      <Accordion sx={{ mt: 2, backgroundColor: "#F3F4F6", boxShadow: 0 }}>
        <AccordionSummary
          expandIcon={
            <div onClick={toggleCheck}>
              <ExpandMoreIcon />
              {isChecked ? (
                <RadioButtonCheckedIcon sx={{ ml: 2 }} />
              ) : (
                <RadioButtonUncheckedIcon sx={{ ml: 2 }} />
              )}
            </div>
          }
          sx={{ display: "flex", alignItems: "center", gap: 2, height: 58 }}
        >
          <Typography
            variant="h7"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              fontWeight: "bold",
            }}
          >
            Fabric Specification
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ marginTop: "5px", padding: "10px", paddingTop: "0px" }}>
            <Typography variant="h7" sx={{ fontWeight: "bold" }}>Fabric specifications</Typography>
            <TextField
              placeholder="Auto generated"
              multiline
              fullWidth
              rows={8}
              variant="outlined"
              sx={{ marginTop: "10px", "& .MuiOutlinedInput-root": { borderRadius: "0px", backgroundColor: "white" } }}
              value={editedSpec}
              onChange={(event) => setEditedSpec(event.target.value)}
            />

            {/* Save Changes Button */}
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                sx={{ backgroundColor: "#565E6D", color: "#fff", "&:hover": { backgroundColor: "#546e7a" }, borderRadius: "0px" }}
                onClick={handleFabricSpecSubmit}
              >
                Save Changes
              </Button>
            </div>

            {/* Display Saved Fabric Specification */}
            {savedFabricSpec && (
              <div style={{ marginTop: "20px" }}>
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>Saved Fabric Specification:</Typography>
                <Typography variant="body1" sx={{ marginTop: "10px", whiteSpace: "pre-wrap" }}>
                  {savedFabricSpec}
                </Typography>
              </div>
            )}
          </div>
        </AccordionDetails>
      </Accordion>

      {/* Manufacturing details */}
      <Accordion sx={{ mt: 2, backgroundColor: "#F3F4F6", boxShadow: 0 }}>
        <AccordionSummary
          expandIcon={
            <div onClick={toggleCheck}>
              <ExpandMoreIcon />
              {isChecked ? (
                <RadioButtonCheckedIcon sx={{ ml: 2 }} />
              ) : (
                <RadioButtonUncheckedIcon sx={{ ml: 2 }} />
              )}
            </div>
          }
          sx={{ display: "flex", alignItems: "center", gap: 2, height: 58 }}
        >
          <Typography
            variant="h7"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              fontWeight: "bold",
            }}
          >
            Manufacturing details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ marginTop: "5px", padding: "10px", paddingTop: "0px" }}>
            <Typography variant="h7" sx={{ fontWeight: "bold" }}>Manufacturing details</Typography>
            <TextField
              placeholder="manual"
              multiline
              fullWidth
              rows={8}
              variant="outlined"
              sx={{ marginTop: "10px", "& .MuiOutlinedInput-root": { borderRadius: "0px", backgroundColor: "white" } }}
              value={manufacturingDetails}
              onChange={(event) => setManufacturingDetails(event.target.value)}
            />

            {/* Save Changes Button */}
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                sx={{
                  backgroundColor: "#565E6D",
                  color: "#fff",
                  "&:hover": { backgroundColor: "#546e7a" },
                  borderRadius: "0px",
                }}
                onClick={handleManufacturingDetailsSubmit}
              >
                Save Changes
              </Button>
            </div>

            {/* Display Saved Manufacturing Details */}
            {savedManufacturingDetails && (
              <div style={{ marginTop: "20px" }}>
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>Saved Manufacturing Details:</Typography>
                <Typography variant="body1" sx={{ marginTop: "10px", whiteSpace: "pre-wrap" }}>
                  {savedManufacturingDetails}
                </Typography>
              </div>
            )}
          </div>
        </AccordionDetails>
      </Accordion>

      {/* Fabric Testing Report Section */}
      <Accordion sx={{ mt: 2, backgroundColor: "#F3F4F6", boxShadow: 0 }}>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ display: "flex", alignItems: "center", gap: 2, height: 58 }}
        >
        <Typography variant="h7" sx={{ fontWeight: "bold" }}>
          Fabric Testing Report
        </Typography>
        </AccordionSummary>
        <AccordionDetails>
        {reports.map((report, index) => (
          <div key={index} style={{ marginBottom: "10px" }}>
          <Typography variant="body2" fontWeight="bold">
            Fabric testing report {index + 1}
          </Typography>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <TextField
            variant="outlined"
            placeholder="Name of the report"
            sx={{
              width: "350px",
              "& .MuiOutlinedInput-root": {
              height: "35px",
              borderRadius: "0px",
              backgroundColor: "white",
              },
            }}
            value={report.name}
            onChange={(e) => handleTestNameChange(index, e)}
            />
            <Button component="label" style={{ color: "black" }}>
            <FileUploadOutlinedIcon sx={{ mr: 1 }} /> Upload
            <input
              onChange={(e) => handleTestFileChange(index, e.target)}
              type="file"
              hidden
              accept="application/pdf"
            />
            </Button>
            <Button
            component="button"
            onClick={() => handleTestDownload(index)}
            style={{ color: "black" }}
            >
            <FileDownloadOutlinedIcon sx={{ mr: 1 }} /> Download
            </Button>
          </div>
          {report.file && (
            <Typography variant="body2" sx={{ color: "green" }}>
              {report.file.name}
            </Typography>
          )}
          <Typography variant="caption" color="textSecondary">
            Only PDFs. Maximum file size 5MB
          </Typography>
          </div>
        ))}
        <Button
          startIcon={<AddCircleOutlineIcon />}
          sx={{ mt: 0, backgroundColor: "transparent", color: "black" }}
          onClick={addNewTestReport}
        >
          Add new report
        </Button>
        {/* Save Changes Button */}
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Button
            sx={{
              backgroundColor: "#565E6D",
              color: "#fff",
              "&:hover": { backgroundColor: "#546e7a" },
              borderRadius: "0px",
            }}
            onClick={handleTestReportSubmit}  
          >
            Save Changes
          </Button>
        </div>
        </AccordionDetails>
      </Accordion>

      {/* Delivery & Return Policy Section */}
      <Accordion sx={{ mt: 2, backgroundColor: "#F3F4F6", boxShadow: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ display: "flex", alignItems: "center", gap: 2, height: 58 }}
        >
          <Typography variant="h7" sx={{ fontWeight: "bold" }}>
            Delivery & Return Policy
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl component="fieldset" sx={{ mt: 0 }}>
            <RadioGroup
              row
              value={(delivery_return_policy && delivery_return_policy.policy_type) || "manual"}
              onChange={(e) => setDeliveryReturnPolicy(prev => ({
              ...(prev || { shipping_details: '', return_policy: '' }),
              policy_type: e.target.value
              }))}
            >
              <FormControlLabel
                value="stored"
                control={<Radio sx={{ 
                  color: "black", 
                  "&.Mui-checked": { color: "black" } 
                }} />} // Added closing /> here
                label="Add stored data"
              />
              <FormControlLabel
                value="manual"
                control={<Radio sx={{ 
                  color: "black", 
                  "&.Mui-checked": { color: "black" } 
                }} />}
                label="Manual input"
              />
            </RadioGroup>
          </FormControl>

          <div style={{ display: "flex", gap: "20px", marginTop: "10px" }}>
            <div style={{ flex: 1 }}>
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Add specific shipping details
              </Typography>
              <TextField
                placeholder="Write shipping details"
                multiline
                fullWidth
                rows={4}
                variant="outlined"
                value={delivery_return_policy.shipping_details || ""}
                onChange={(e) => setDeliveryReturnPolicy(prev => ({
                  ...prev,
                  shipping_details: e.target.value
                }))}
                sx={{
                  marginTop: "10px",
                  "& .MuiOutlinedInput-root": { borderRadius: "0px", backgroundColor: "white" },
                }}
              />
            </div>

            <div style={{ flex: 1 }}>
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Add specific Return policy
              </Typography>
              <TextField
                placeholder="Write return policy"
                multiline
                fullWidth
                rows={4}
                variant="outlined"
                value={delivery_return_policy.return_policy || ""}
                onChange={(e) => setDeliveryReturnPolicy(prev => ({
                  ...prev,
                  return_policy: e.target.value
                }))}
                sx={{
                  marginTop: "10px",
                  "& .MuiOutlinedInput-root": { borderRadius: "0px", backgroundColor: "white" },
                }}
              />
            </div>
          </div>

          {/* Save Changes Button */}
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Button
              sx={{
                backgroundColor: "#565E6D",
                color: "#fff",
                "&:hover": { backgroundColor: "#546e7a" },
                borderRadius: "0px",
              }}
              onClick={handleDeliveryPolicySubmit}
            >
              Save Changes
            </Button>
          </div>

          {/* Display Saved Policies */}
          {delivery_return_policy.shipping_details && delivery_return_policy.return_policy && (
            <div style={{ marginTop: "20px" }}>
              {/* <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Policy Type: {delivery_return_policy.policy_type === 'stored' ? 'Stored Data' : 'Manual Input'}
              </Typography>  */}
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Shipping Details:
                  </Typography>
                  <Typography variant="body1">
                    {delivery_return_policy.shipping_details}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Return Policy:
                  </Typography>
                  <Typography variant="body1">
                    {delivery_return_policy.return_policy}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          )}
        </AccordionDetails>
      </Accordion>

      {/* Delivery Details Section */}
      <Accordion sx={{ mt: 2, backgroundColor: "#F3F4F6", boxShadow: 0 }}>
        <AccordionSummary
          expandIcon={
          <div onClick={toggleCheck}>
            <ExpandMoreIcon />
            {isChecked ? (
            <RadioButtonCheckedIcon sx={{ ml: 2 }} />
            ) : (
            <RadioButtonUncheckedIcon sx={{ ml: 2 }} />
            )}
          </div>
          }
          sx={{ display: "flex", alignItems: "center", gap: 2, height: 58 }}
        >
          <Typography
          variant="h7"
          sx={{ display: "flex", alignItems: "center", gap: 2, fontWeight: "bold" }}
          >
          Delivery Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* Two-Column Layout */}
          <div style={{ display: "flex", gap: "40px", padding: "10px", paddingTop: "0px" }}>
            {/* Left Section - Inputs */}
            <div style={{ flex: 1 }}>
              {/* Excluded Territories Input */}
              <Typography variant="body1" fontWeight="bold" sx={{ mb: 2 }}>
                Excluded territories for shipping
              </Typography>
              <Typography variant="body2" fontWeight="bold" sx={{ mb: 1 }}>
                Excluded Territories
              </Typography>

              {territoryInputs.map((input) => (
                <div key={input.id} style={{ marginBottom: '10px' }}>
                  <TextField
                    variant="outlined"
                    value={input.value}
                    onChange={(e) => handleTerritoryInputChange(input.id, e.target.value)}
                    placeholder="Enter state, district or region"
                    sx={{ 
                      width: "350px",
                      '& .MuiOutlinedInput-root': {
                        height: "36px",
                        borderRadius: '0px',
                        backgroundColor: 'white',
                      }
                    }}
                  />
                  {input.pincodeRange && (
                    <Typography variant="caption" sx={{ 
                      display: 'block',
                      mt: 1,
                      color: '#666',
                      fontSize: '0.8rem'
                    }}>
                      Pincode range: {input.pincodeRange}
                    </Typography>
                  )}
                </div>
              ))}

              <Button
                startIcon={<AddCircleOutlineIcon />}
                onClick={handleAddTerritories}
                sx={{ 
                  mb: 1,
                  backgroundColor: 'transparent',
                  color: 'black',
                }}
              >
                Add more
              </Button>

              {/* Include Pincodes */}
              <Typography variant="body2" fontWeight="bold" sx={{ mb: 1 }}>
                Include specific pincodes for delivery
              </Typography>
              <TextField
                variant="outlined"
                sx={{
                  width: "350px",
                  mb: 1,
                  "& .MuiOutlinedInput-root": {
                    height: "36px",
                    borderRadius: "0px",
                    backgroundColor: "white",
                  },
                }}
                value={inputValues.include}
                onChange={(e) => handleDeliveryInputChange(e, "include")}
                placeholder="Enter pincode to include"
                inputProps={{ 
                  maxLength: 6,
                  pattern: "\\d*",
                  inputMode: "numeric"
                }}
              />
              <Button
                startIcon={<AddCircleOutlineIcon />}
                sx={{ ml: 1, mb: 1, backgroundColor: "transparent", color: "black" }}
                onClick={() => handleAddTag("include")}
              >
                Add more
              </Button>

              {/* Exclude Pincodes */}
              <Typography variant="body2" fontWeight="bold" sx={{ mb: 1 }}>
                Exclude specific pincodes for delivery
              </Typography>
              <TextField
                variant="outlined"
                sx={{
                  width: "350px",
                  mb: 1,
                  "& .MuiOutlinedInput-root": {
                    height: "36px",
                    borderRadius: "0px",
                    backgroundColor: "white",
                  },
                }}
                value={inputValues.exclude}
                onChange={(e) => handleDeliveryInputChange(e, "exclude")}
                placeholder="Enter pincode to exclude"
                inputProps={{ 
                  maxLength: 6,
                  pattern: "\\d*",
                  inputMode: "numeric"
                }}
              />
              <Button
                startIcon={<AddCircleOutlineIcon />}
                sx={{ ml: 1, mb: 0, backgroundColor: "transparent", color: "black" }}
                onClick={() => handleAddTag("exclude")}
              >
                Add more
              </Button>
            </div>

            {/* Right Section - Tags */}
            <div style={{ width: "400px" }}>
              {/* Excluded Territories Tags */}
              <Typography variant="body2" fontWeight="bold" sx={{ mb: 2, mt: 4.5 }}>
                Excluded territories for shipping
              </Typography>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", marginBottom: "20px", height: "100px" }}>
                {excludedTerritories.map((territory, index) => (
                  <Chip
                    key={index}
                    label={territory}
                    onDelete={() => setExcludedTerritories(prev => prev.filter((_, i) => i !== index))}
                    sx={{ 
                      m: 0.5,
                      backgroundColor: '#565E6D',
                      color: 'white',
                      '& .MuiChip-deleteIcon': { color: 'white' }
                    }}
                  />
                ))}
              </div>

              {/* Included Pincodes Tags */}
              <Typography variant="body2" fontWeight="bold" sx={{ mb: 2, mt: 12 }}>
                Included regions for shipping
              </Typography>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", marginBottom: "20px" }}>
                {includePincodes.map((item, index) => (
                  <Chip
                    key={index}
                    label={item}
                    onDelete={() => handleRemoveTag("include", index)}
                    deleteIcon={<CancelIcon sx={{ color: "white" }} />}
                    sx={{ backgroundColor: "#565E6D", color: "white", "& .MuiChip-deleteIcon": { color: "white" } }}
                  />
                ))}
              </div>

              {/* Excluded Pincodes Tags */}
              <Typography variant="body2" fontWeight="bold" sx={{ mb: 2, mt: 5.5 }}>
                Excluded regions for shipping
              </Typography>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {excludePincodes.map((item, index) => (
                  <Chip
                    key={index}
                    label={item}
                    onDelete={() => handleRemoveTag("exclude", index)}
                    deleteIcon={<CancelIcon sx={{ color: "white" }} />}
                    sx={{ backgroundColor: "#565E6D", mb: 2, color: "white", "& .MuiChip-deleteIcon": { color: "white" } }}
                  />
                ))}
              </div>
            </div>
          </div>

          {/* Save Changes Button */}
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Button
              sx={{
                backgroundColor: "#565E6D",
                color: "#fff",
                "&:hover": { backgroundColor: "#546e7a" },
                borderRadius: "0px",
              }}
              onClick={handleDeliveryDetailsSubmit}
            >
              Save Changes
            </Button>
          </div>
        </AccordionDetails>
      </Accordion>

      {/* Meta Tags & Description Section */}
      <Accordion sx={{ mt: 2, backgroundColor: "#F3F4F6", boxShadow: 0 }}>
        <AccordionSummary
          expandIcon={
            <div onClick={toggleCheck}>
              <ExpandMoreIcon />
              {isChecked ? (
                <RadioButtonCheckedIcon sx={{ ml: 2 }} />
              ) : (
                <RadioButtonUncheckedIcon sx={{ ml: 2 }} />
              )}
            </div>
          }
          sx={{ display: "flex", alignItems: "center", gap: 2, height: 58 }}
        >
          <Typography
            variant="h7"
            sx={{ display: "flex", alignItems: "center", gap: 2, fontWeight: "bold" }}
          >
            Meta Tags & Description
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ marginTop: "5px", padding: "10px", paddingTop: "0px" }}>
            {/* AI Generated Title Tags */}
            <Typography variant="body2" fontWeight="bold" sx={{ mb: 1 }}>
              AI generated title tags
            </Typography>
            <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "10px" }}>
              <TextField
                variant="outlined"
                placeholder="Enter AI-generated title tag"
                multiline
                minRows={2}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    height: "65px",
                    borderRadius: "0px",
                    backgroundColor: "white",
                  },
                }}
                value={titleTag}
                onChange={(e) => setTitleTag(e.target.value)}
                disabled={!isEditingTitle}
              />
              <IconButton onClick={() => setIsEditingTitle((prev) => !prev)}>
                <EditOutlinedIcon />
              </IconButton>
            </div>

            {/* Meta Descriptions */}
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "5px" }}>
              <Typography variant="body2" fontWeight="bold">
                Meta Descriptions
              </Typography>
              <IconButton onClick={() => setIsEditingDescription((prev) => !prev)}>
                <EditOutlinedIcon />
              </IconButton>
            </div>
            <TextField
              variant="outlined"
              placeholder="Enter meta description"
              multiline
              minRows={4}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "0px",
                  backgroundColor: "white",
                },
              }}
              value={metaDescription}
              onChange={(e) => setMetaDescription(e.target.value)}
              disabled={!isEditingDescription}
            />

            {/* Save Changes Button */}
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                sx={{
                  backgroundColor: "#565E6D",
                  color: "#fff",
                  "&:hover": { backgroundColor: "#546e7a" },
                  borderRadius: "0px",
                }}
                onClick={handleMetaTagsSubmit}
              >
                Save Changes
              </Button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Button
        fullWidth
        sx={{
          mt: 5,
          padding: 1,
          borderRadius: "0px",
          backgroundColor: "#DEE1E6",
          color: "black",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#b1b4b6",
          },
        }}
      >
        Save properties as custom preset
      </Button>

      {savedTitleTag && savedMetaDescription && (
        <div style={{ marginTop: "20px" }}>
          <Typography variant="h7" sx={{ fontWeight: "bold" }}>
            Saved Meta Tags:
          </Typography>
          <Box sx={{ mt: 2, p: 2, backgroundColor: 'white', border: '1px solid #ddd' }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Title Tag:
            </Typography>
            <Typography variant="body1" sx={{ mt: 1, whiteSpace: 'pre-wrap' }}>
              {savedTitleTag}
            </Typography>
            
            <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 'bold' }}>
              Meta Description:
            </Typography>
            <Typography variant="body1" sx={{ mt: 1, whiteSpace: 'pre-wrap' }}>
              {savedMetaDescription}
            </Typography>
          </Box>
        </div>
      )}
    </Box>
  );
}
