import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getData } from "../../ServerRequest";
import { Typography, Box, Container, TextField, Button, Select, MenuItem, Grid } from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontFamily: 'Helvetica'
  },
  header: {
    marginBottom: 20,
    textAlign: 'center'
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#2a4365',
    marginBottom: 10
  },
  subtitle: {
    fontSize: 12,
    color: '#718096'
  },
  divider: {
    height: 2,
    backgroundColor: '#2b6cb0',
    marginVertical: 15
  },
  section: {
    marginBottom: 15
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#2a4365',
    marginBottom: 8
  },
  filterGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 15
  },
  filterItem: {
    width: '33%',
    marginBottom: 8
  },
  filterLabel: {
    fontSize: 10,
    color: '#4a5568',
    fontWeight: 'bold'
  },
  filterValue: {
    fontSize: 10,
    color: '#2b6cb0'
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: '#e2e8f0'
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#e2e8f0'
  },
  tableHeader: {
    backgroundColor: '#2b6cb0',
    color: 'white',
    fontWeight: 'bold'
  },
  tableCell: {
    padding: 8,
    fontSize: 10,
    flex: 1,
    textAlign: 'center'
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 40,
    right: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 8,
    color: '#718096'
  }
});

const FabricPdfDocument = ({ formData }) => (
  <Document>
    <Page style={styles.page}>
      {/* Header */}
      <View style={styles.header}>
        <Text style={styles.title}>Fabric Catalog Report</Text>
        <Text style={styles.subtitle}>
          Generated on: {new Date().toLocaleDateString()}
        </Text>
      </View>
      
      {/* Divider */}
      <View style={styles.divider} />
      
      {/* Selected Filters */}
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Selected Filters</Text>
        <View style={styles.filterGrid}>
          {Object.entries(formData)
            .filter(([_, value]) => value)
            .map(([key, value], index) => (
              <View key={index} style={styles.filterItem}>
                <Text style={styles.filterLabel}>
                  {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}:
                </Text>
                <Text style={styles.filterValue}>{value.toString()}</Text>
              </View>
            ))}
        </View>
      </View>
      
      {/* Divider */}
      <View style={styles.divider} />
      
      {/* Fabric Table */}
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Fabric List</Text>
        <View style={styles.table}>
          {/* Table Header */}
          <View style={[styles.tableRow, styles.tableHeader]}>
            <Text style={styles.tableCell}>ID</Text>
            <Text style={styles.tableCell}>Name</Text>
            <Text style={styles.tableCell}>Category</Text>
            <Text style={styles.tableCell}>Quantity</Text>
            <Text style={styles.tableCell}>Price</Text>
          </View>
          
          {/* Table Rows */}
          {[
            ['1', 'Cotton Fabric', 'Natural', '100', '$10.50'],
            ['2', 'Polyester Blend', 'Synthetic', '75', '$8.25'],
            ['3', 'Silk Twill', 'Luxury', '50', '$25.00'],
            ['4', 'Linen Canvas', 'Natural', '60', '$12.75'],
            ['5', 'Wool Suiting', 'Natural', '40', '$30.00']
          ].map((row, index) => (
            <View key={index} style={styles.tableRow}>
              {row.map((cell, cellIndex) => (
                <Text key={cellIndex} style={styles.tableCell}>
                  {cell}
                </Text>
              ))}
            </View>
          ))}
        </View>
      </View>
      
      {/* Footer */}
      <View style={styles.footer}>
        <Text>Confidential - Fabric Catalog System</Text>
        <Text render={({ pageNumber, totalPages }) => (
          `Page ${pageNumber} of ${totalPages}`
        )} fixed />
      </View>
    </Page>
  </Document>
);

export default function DownloadPdf() {
  const [formData, setFormData] = useState({
    category: '',
    subCategory: '',
    mill: '',
    size: '',
    width: '',
    style: '',
    type: '',
    pattern: '',
    shade: '',
    color: '',
    anyAll: '',
    removeAdvertisement: '',
    imagesToShow: '',
    showEmptyImages: '',
    minQuantity: '',
    maxQuantity: '',
    tags: ''
  });

  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [mills, setMills] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [widths, setWidths] = useState([]);
  const [styles, setStyles] = useState([]);
  const [types, setTypes] = useState([]);
  const [patterns, setPatterns] = useState([]);
  const [shades, setShades] = useState([]);
  const [colors, setColors] = useState([]);
  const [anyAllOptions, setAnyAllOptions] = useState(['Any', 'All']);
  const [removeAdOptions, setRemoveAdOptions] = useState(['Yes', 'No']);
  const [imagesToShowOptions, setImagesToShowOptions] = useState(['All', 'First', 'Last']);
  const [showEmptyImagesOptions, setShowEmptyImagesOptions] = useState(['Yes', 'No']);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        let categoryRes = await getData('/api/product/get-fabric-category');
        console.log(categoryRes);
        let category_data = categoryRes['data']['fabric_categories']
        if (category_data) {
          setCategories(category_data);
        }

        let resData = await getData('/api/product/get-fabric-tags');
        console.log(resData);
        let tags_data = resData['data']['fabric_tags']
        if (tags_data) {
          setTags(tags_data);
        }

        setMills([{ id: 1, name: 'Mill A' }, { id: 2, name: 'Mill B' }]);
        setStyles([{ id: 1, name: 'Style 1' }, { id: 2, name: 'Style 2' }]);
        setAnyAllOptions(['Any', 'All']);
        setRemoveAdOptions(['Yes', 'No']);
        setImagesToShowOptions(['All', 'First', 'Last']);
        setShowEmptyImagesOptions(['Yes', 'No']);

      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const getTagsForSection = (sectionName) => {
    return tags[sectionName] || [];
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <Box sx={{ width: '95%', marginLeft: 3, boxShadow: 0, border: '2px solid #F4F3F6' }}>            
      <Box sx={{ padding: 2, ml: 2}}>
        <Typography variant="body1" sx={{ fontFamily: "Inter", fontWeight: 700, fontSize: 16 }}>PDF Filter</Typography>                    
      </Box>    
      <Box sx={{ backgroundColor: '#ECEFF1', p: 4 }}>  
        <Grid container spacing={2}>                        
          {/* Category Dropdown */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>Category</Typography>
            <Select 
              fullWidth 
              variant="outlined" 
              sx={{ height: '35px', borderRadius: 0, backgroundColor: '#fff' }}
              name="category"
              value={formData.category}
              onChange={handleChange}
              disabled={loading}
            >
              <MenuItem value="">Select</MenuItem>
              {categories.map(category => (
                <MenuItem key={category.id} value={category.category}>
                  {category.category}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          
          {/* Sub Category Dropdown */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>Sub Category</Typography>
            <Select 
              fullWidth 
              variant="outlined" 
              sx={{ height: '35px', borderRadius: 0, backgroundColor: '#fff' }}
              name="subCategory"
              value={formData.subCategory}
              onChange={handleChange}
              disabled={loading || !formData.category}
            >
              <MenuItem value="">Select</MenuItem>
              {getTagsForSection(formData.category).map(tag => (
                <MenuItem key={tag.id} value={tag.product_tag}>
                  {tag.product_tag}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {/* Mill Dropdown */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>Mill</Typography>
            <Select 
              fullWidth 
              variant="outlined" 
              sx={{ height: '35px', borderRadius: 0, backgroundColor: '#fff' }}
              name="mill"
              value={formData.mill}
              onChange={handleChange}
            >
              <MenuItem value="">Select</MenuItem>
              {mills.map(mill => (
                <MenuItem key={mill.id} value={mill.name}>{mill.name}</MenuItem>
              ))}
            </Select>
          </Grid>

          {/* Size Dropdown */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>Size</Typography>
            <Select 
              fullWidth 
              variant="outlined" 
              sx={{ height: '35px', borderRadius: 0, backgroundColor: '#fff' }}
              name="size"
              value={formData.size}
              onChange={handleChange}
              disabled={loading}
            >
              <MenuItem value="">Select</MenuItem>
              {getTagsForSection("Pattern size").map(tag => (
                <MenuItem key={tag.id} value={tag.product_tag}>
                  {tag.product_tag}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {/* Width Dropdown */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>Width</Typography>
            <Select 
              fullWidth 
              variant="outlined" 
              sx={{ height: '35px', borderRadius: 0, backgroundColor: '#fff' }}
              name="width"
              value={formData.width}
              onChange={handleChange}
              disabled={loading}
            >
              <MenuItem value="">Select</MenuItem>
              {getTagsForSection("Width").map(tag => (
                <MenuItem key={tag.id} value={tag.product_tag}>
                  {tag.product_tag}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {/* Style Dropdown */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>Style</Typography>
            <Select 
              fullWidth 
              variant="outlined" 
              sx={{ height: '35px', borderRadius: 0, backgroundColor: '#fff' }}
              name="style"
              value={formData.style}
              onChange={handleChange}
              disabled={loading}
            >
              <MenuItem value="">Select</MenuItem>
              {getTagsForSection("Style").map(tag => (
                <MenuItem key={tag.id} value={tag.product_tag}>
                  {tag.product_tag}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {/* Type Dropdown */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>Type</Typography>
            <Select 
              fullWidth 
              variant="outlined" 
              sx={{ height: '35px', borderRadius: 0, backgroundColor: '#fff' }}
              name="type"
              value={formData.type}
              onChange={handleChange}
              disabled={loading}
            >
              <MenuItem value="">Select</MenuItem>
              {getTagsForSection("Fabric composition").map(tag => (
                <MenuItem key={tag.id} value={tag.product_tag}>
                  {tag.product_tag}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {/* Pattern Dropdown */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>Pattern</Typography>
            <Select 
              fullWidth 
              variant="outlined" 
              sx={{ height: '35px', borderRadius: 0, backgroundColor: '#fff' }}
              name="pattern"
              value={formData.pattern}
              onChange={handleChange}
              disabled={loading}
            >
              <MenuItem value="">Select</MenuItem>
              {getTagsForSection("Pattern").map(tag => (
                <MenuItem key={tag.id} value={tag.product_tag}>
                  {tag.product_tag}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {/* Shade Dropdown */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>Shade</Typography>
            <Select 
              fullWidth 
              variant="outlined" 
              sx={{ height: '35px', borderRadius: 0, backgroundColor: '#fff' }}
              name="shade"
              value={formData.shade}
              onChange={handleChange}
              disabled={loading}
            >
              <MenuItem value="">Select</MenuItem>
              {getTagsForSection("Foreground color").map(tag => (
                <MenuItem key={tag.id} value={tag.product_tag}>
                  {tag.product_tag}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {/* Color Dropdown */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>Color</Typography>
            <Select 
              fullWidth 
              variant="outlined" 
              sx={{ height: '35px', borderRadius: 0, backgroundColor: '#fff' }}
              name="color"
              value={formData.color}
              onChange={handleChange}
              disabled={loading}
            >
              <MenuItem value="">Select</MenuItem>
              {getTagsForSection("Base color").map(tag => (
                <MenuItem key={tag.id} value={tag.product_tag}>
                  {tag.product_tag}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          
          {/* Any / All */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>Any / All</Typography>
            <Select 
              fullWidth 
              variant="outlined" 
              sx={{ height: '35px', borderRadius: 0, backgroundColor: '#fff' }}
              name="anyAll"
              value={formData.anyAll}
              onChange={handleChange}
            >
              <MenuItem value="">Select</MenuItem>
              {anyAllOptions.map(option => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </Grid>

          {/* Remove Advertisement */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>Remove Advertisement</Typography>
            <Select 
              fullWidth 
              variant="outlined" 
              sx={{ height: '35px', borderRadius: 0, backgroundColor: '#fff' }}
              name="removeAdvertisement"
              value={formData.removeAdvertisement}
              onChange={handleChange}
            >
              <MenuItem value="">Select</MenuItem>
              {removeAdOptions.map(option => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </Grid>

          {/* Images to Show */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>Images to Show</Typography>
            <Select 
              fullWidth 
              variant="outlined" 
              sx={{ height: '35px', borderRadius: 0, backgroundColor: '#fff' }}
              name="imagesToShow"
              value={formData.imagesToShow}
              onChange={handleChange}
            >
              <MenuItem value="">Select</MenuItem>
              {imagesToShowOptions.map(option => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </Grid>

          {/* Show Empty Images */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>Show Empty Images</Typography>
            <Select 
              fullWidth 
              variant="outlined" 
              sx={{ height: '35px', borderRadius: 0, backgroundColor: '#fff' }}
              name="showEmptyImages"
              value={formData.showEmptyImages}
              onChange={handleChange}
            >
              <MenuItem value="">Select</MenuItem>
              {showEmptyImagesOptions.map(option => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </Grid>

          {/* Min Quantity */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>Min Quantity</Typography>
            <TextField 
              fullWidth 
              variant="outlined" 
              sx={{ "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px", backgroundColor: '#fff' } }}
              name="minQuantity"
              value={formData.minQuantity}
              onChange={handleChange}
            />
          </Grid>

          {/* Max Quantity */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>Max Quantity</Typography>
            <TextField 
              fullWidth 
              variant="outlined" 
              sx={{ "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px", backgroundColor: '#fff' } }}
              name="maxQuantity"
              value={formData.maxQuantity}
              onChange={handleChange}
            />
          </Grid>

          {/* Tags */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>Tags</Typography>
            <TextField 
              fullWidth 
              variant="outlined" 
              sx={{ "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px", backgroundColor: '#fff' } }}
              name="tags"
              value={formData.tags}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        
        <Grid item xs={12}>
          <PDFDownloadLink
            document={<FabricPdfDocument formData={formData} />}
            fileName={`fabric_catalog_${new Date().toISOString().slice(0,10)}.pdf`}
          >
            {({ loading }) => (
              <Button 
                sx={{ mt: 4, backgroundColor: '#fff', border: '2px solid #BCC1CA', color: '#000', borderRadius: 0 }} 
                variant="outlined"
                disabled={loading}
              >
                Generate PDF
              </Button>
            )}
          </PDFDownloadLink>
        </Grid>                  
      </Box>           
    </Box>
  );
};
   