import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Select, MenuItem, TextField, Autocomplete } from '@mui/material';
import { getData, postData, putData } from "../../../ServerRequest"; 
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export default function ManualNestedCollection({ setManualNestedCollection , mainCollectionId }) { 
    const searchParams = new URLSearchParams(window.location.search);
    const collectionId = searchParams.get('collection_id');
    const [loading, setLoading] = useState(true);
    const [tagsData, setTagsData] = useState([]);
    const [familyData, setFamilyData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);    
    const [minQuantity, setMinQuantity] = useState("");
    const [filteredTagCategories, setFilteredTagCategories] = useState([]);
    const [filteredProductTags, setFilteredProductTags] = useState([]);  
    // Nested Collection
    const [nestedProperties, setNestedProperties] = useState([{ id: 1, nestedTagFamily: "", nestedTagCategory: "", nestedProductTag: "", nestedMinQuantity: "" }]);
    const [nestedCollectionName, setNestedCollectionName] = useState("");
    const [nestedPromotionalName, setNestedPromotionalName] = useState("");
    const [nestedMarketingKeywords, setNestedMarketingKeywords] = useState("");
    const [collectionsData, setCollectionsData] = useState([]); 
    const [selectedCollection, setSelectedCollection] = useState(""); 
    const [isExistingCollection, setIsExistingCollection] = useState(false); 
    

    const handleNestedCollectionSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
    
        // Step 1: Validate that the Main Collection ID exists
        if (!mainCollectionId) {
            alert('Main Collection must be created before adding a Nested Collection.');
            setLoading(false);
            return;
        }
    
        // Step 2: Validate if the Main Collection ID is new
        const isNewCollection = collectionsData.some(
            (collection) => collection.collection_id === mainCollectionId && collection.is_new
        );
    
        if (!isNewCollection) {
            alert('The Main Collection ID is not valid or is not newly created. Please create a new Main Collection.');
            setLoading(false);
            return;
        }
    
        try {
            // Step 3: Create the Manual Nested Collection
            const manualNestedCollectionReqData = {
                'nested_collection': nestedProperties
                    .map(property => {
                        const tag = tagsData.find(tag => tag.id === property.nestedProductTag);
                        return tag ? { id: tag.id, product_tag: tag.product_tag } : null;
                    })
                    .filter(tag => tag !== null), // Remove null entries
                'collection_name': nestedCollectionName ? nestedCollectionName : "",
                'promotional_name': nestedPromotionalName ? nestedPromotionalName : "",
                'marketing_keyword': nestedMarketingKeywords ? nestedMarketingKeywords : "",
                'parent_collection_id': mainCollectionId, // Link to the main collection
                'is_nested': true, // This is a nested collection
            };
    
            console.log("Manual Nested Collection Request Data:", manualNestedCollectionReqData);
    
            const manualNestedCollectionRes = await postData('/api/product/product-collection', manualNestedCollectionReqData);
            if (manualNestedCollectionRes['data']['code'] !== '200') {
                alert('Error creating Manual Nested Collection. Please try again.');
                return;
            }
    
            const createdManualNestedCollectionId = manualNestedCollectionRes['data']['collection_id'];
            console.log("Created Manual Nested Collection ID:", createdManualNestedCollectionId);
    
            // Step 4: Update the Manual Collection with the Nested Collection ID
            const updateMainCollectionReqData = {
                'collection_id': mainCollectionId, // ID of the manual collection to update
                'nested_collection': createdManualNestedCollectionId, // Add the nested collection ID
            };
    
            console.log("Update Main Collection Request Data:", updateMainCollectionReqData);
    
            const updateMainCollectionRes = await putData('/api/product/product-collection', updateMainCollectionReqData);
            if (updateMainCollectionRes['code'] !== 200) {
                alert('Error updating Main Collection with Nested Collection. Please try again.');
                return;
            }
    
            console.log("Main Collection updated successfully:", updateMainCollectionRes);
    
            // Notify the user of success
            alert('Manual Nested Collection created and added to the Main Collection successfully.');
    
            // Step 5: Reset the form fields after successful submission
            setNestedCollectionName('');
            setNestedPromotionalName('');
            setNestedMarketingKeywords('');
            setNestedProperties([{ id: 1, nestedTagFamily: "", nestedTagCategory: "", nestedProductTag: "", nestedMinQuantity: "" }]);
            setManualNestedCollection([]);
        } catch (error) {
            console.error('Error in collection creation flow:', error);
            alert('An error occurred while saving the collections. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        async function fetchData() {
            try {

                let collectionRes = await getData('/api/product/product-collection');
                console.log(collectionRes);
                let collection_data = collectionRes['data']['collections'];
                if (collection_data) {
                    console.log(collection_data);
                    setCollectionsData(collection_data);
                } else {
                    console.error("Error fetching collections data:", collectionRes.message);
                }

                let masterRes = await getData('/api/product/acc_tag_master');
                console.log(masterRes);
                let master_data = masterRes['data']['tag_masters']
                if (master_data) {
                    console.log(master_data);
                    setTagsData(master_data);
                } else {
                    console.error("Error fetching tag master data:", masterRes.message);
                }

                let familyRes = await getData('/api/product/acc_tag_family');
                console.log(familyRes);
                let family_data = familyRes['data']['tag_families']
                if (family_data) {
                    console.log(family_data);
                    setFamilyData(family_data);
                } else {
                    console.error("Error fetching tags family data:", familyRes.message);
                }

                let categoryRes = await getData('/api/product/acc_tag_category');
                console.log(categoryRes);
                let category_data = categoryRes['data']['tag_categories']
                if (category_data) {
                    console.log(category_data);
                    setCategoryData(category_data);
                } else {
                    console.error("Error fetching tags category data:", categoryRes.message);
                }

            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        } 
            
        fetchData();

    }, [collectionId]); 

    useEffect(() => {
        nestedProperties.forEach((property, index) => {
            // Filter Tag Categories based on selected Tag Family
            const filteredCategories = categoryData.filter(category => 
                !property.nestedTagFamily || category.family === property.nestedTagFamily
            );
            setFilteredTagCategories(filteredCategories);
    
            // Filter Product Tags based on selected Tag Family, Tag Category, and Min Quantity
            const filteredTags = tagsData.filter(({ family, category, quantityId }) =>
                (!property.nestedTagFamily || family === property.nestedTagFamily) &&
                (!property.nestedTagCategory || category === property.nestedTagCategory) &&
                (!property.nestedMinQuantity || quantityId >= property.nestedMinQuantity)
            );
            setFilteredProductTags(filteredTags);
    
            // Debugging logs
            //console.log(`Property ${index + 1} - Selected Tag Family:`, property.nestedTagFamily);
            //console.log(`Property ${index + 1} - Filtered Categories:`, filteredCategories);
            //console.log(`Property ${index + 1} - Selected Tag Category:`, property.nestedTagCategory);
            //console.log(`Property ${index + 1} - Min Quantity:`, property.nestedMinQuantity);
            //console.log(`Property ${index + 1} - Filtered Product Tags:`, filteredTags);
        });
    }, [tagsData, categoryData, nestedProperties]);  

    const handleAddNestedProperty = () => {
        setNestedProperties([...nestedProperties, { id: nestedProperties.length + 1, nestedTagFamily: "", nestedTagCategory: "", nestedProductTag: "", nestedMinQuantity: "" }]);
    };   

    const handleNestedPropertyChange = (id, field, value) => {
        setNestedProperties((prev) =>
            prev.map((property) =>
                property.id === id ? { ...property, [field]: value } : property
            )
        );

        if (field === "nestedProductTag") {
            setManualNestedCollection((prev) => {
                if (!Array.isArray(prev)) return []; // Ensure prev is an array
                const updatedCollection = prev.filter(
                    (tag) => !nestedProperties.some((property) => property.id === id && property.nestedProductTag === tag.id)
                );
                const selectedTag = tagsData.find((tag) => tag.id === value);
                if (selectedTag) {
                    updatedCollection.push({ id: selectedTag.id, product_tag: selectedTag.product_tag });
                }
                return updatedCollection;
            });
        }        
    };

    const handleCollectionChange = (event, newValue) => {
        setSelectedCollection(newValue);
        setIsExistingCollection(!!newValue);
    
        if (newValue) {
            // Populate data based on the selected collection
            const selectedCollectionDetails = collectionsData.find(
                (collection) => collection.collection === newValue.collection
            );
    
            if (selectedCollectionDetails) {
                setNestedCollectionName(selectedCollectionDetails.name || "");
                setNestedPromotionalName(selectedCollectionDetails.promoName || "");
                setNestedMarketingKeywords(selectedCollectionDetails.marketingKeywords || "");
            }
        } else {
            // Clear data
            setNestedCollectionName("");
            setNestedPromotionalName("");
            setNestedMarketingKeywords("");
        }
    };

    return (
        <Box sx={{ width: '100%' }}>            
            {/* Create Manual Nested Tags collection */}
            <Box sx={{ mt: 2 }}>                 
                        
                            <Box sx={{ backgroundColor: "#DEE1E6", padding: 4, marginBottom: 4 }}>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>
                                        Select collection
                                    </Typography>
                                    <Autocomplete
                                        options={collectionsData}
                                        getOptionLabel={(option) => option.collection || ""}
                                        renderInput={(params) => 
                                            <TextField {...params} placeholder="Select a collection" />}
                                        sx={{ width: 499, "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px", backgroundColor: "#fff" } }}
                                        value={selectedCollection}
                                        onChange={handleCollectionChange}
                                    />
                                    
                                    <Typography variant="body1" sx={{ fontFamily: "Inter", fontWeight: "700", fontSize: 16, mt: 2, mb: 2 }}>
                                        Start defining the new collection
                                    </Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: 0 }}>
                                        <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>
                                            Min quantity/ tag (in Mtr)
                                        </Typography>
                                        <TextField
                                            sx={{ width: 191, "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px", backgroundColor: "#fff" } }}
                                            value={minQuantity}
                                            onChange={(e) => setMinQuantity(e.target.value)}
                                            disabled={isExistingCollection}
                                        />             
                
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "column", mt: 2, gap: 1, borderBottom: "1px solid #BCC1CA" }}>
                                        <Typography variant="body1" sx={{ fontFamily: "Inter", fontWeight: "700", fontSize: 16 }}>
                                            Start defining tags by input
                                         </Typography>
                                        {nestedProperties.map((property, index) => (
                                            <Box key={property.id}>
                                                <Box sx={{ display: "flex", flexDirection: "row", gap: 6, mb: 2, mt: 1, alignItems: "flex-end" }}>
                                                    <Box>
                                                        <Typography sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>
                                                            Tag Family
                                                        </Typography>
                                                        <Select
                                                            value={property.nestedTagFamily}
                                                            onChange={(e) => handleNestedPropertyChange(property.id, "nestedTagFamily", e.target.value)}
                                                            sx={{ width: 191, height: "35px", borderRadius: "0px", backgroundColor: '#fff' }}
                                                            variant="outlined"
                                                            disabled={isExistingCollection}
                                                        >
                                                            {familyData.map((family) => (
                                                                <MenuItem key={family.id} value={family.id}>
                                                                    {family.tag_family}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </Box>
                                                    <Box>
                                                        <Typography sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>
                                                            Tag Category
                                                        </Typography>
                                                        <Select
                                                            value={property.nestedTagCategory}
                                                            onChange={(e) => handleNestedPropertyChange(property.id, "nestedTagCategory", e.target.value)}
                                                            sx={{ width: 191, height: "35px", borderRadius: "0px", backgroundColor: '#fff' }}
                                                            variant="outlined"
                                                            disabled={isExistingCollection}
                                                        >
                                                            {categoryData
                                                                .filter((category) => !property.nestedTagFamily || category.tag_family === property.nestedTagFamily)
                                                                .map((category) => (
                                                                    <MenuItem key={category.id} value={category.id}>
                                                                        {category.category}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </Box>
                                                    <Box>
                                                        <Typography sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>
                                                            Product Tag
                                                        </Typography>
                                                        <Select
                                                            value={property.nestedProductTag}
                                                            onChange={(e) => handleNestedPropertyChange(property.id, "nestedProductTag", e.target.value)}
                                                            sx={{ width: 191, height: "35px", borderRadius: "0px", backgroundColor: '#fff' }}
                                                            variant="outlined"
                                                            disabled={isExistingCollection}
                                                        >
                                                            {tagsData
                                                                .filter((tag) => !property.nestedTagCategory || tag.tag_category === property.nestedTagCategory)
                                                                .map((tag) => (
                                                                    <MenuItem key={tag.id} value={tag.id}>
                                                                        {tag.product_tag}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        ))}
                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 6, mb: 4, mt: 2 }}>
                                            <Button
                                                startIcon={<AddCircleOutlineIcon />}
                                                onClick={handleAddNestedProperty}
                                                sx={{
                                                    backgroundColor: "white",
                                                    color: "black",
                                                    textTransform: "none",
                                                    border: "1px solid #BCC1CA",
                                                    borderRadius: "0px",
                                                    height: "35px",
                                                    width: 191,
                                                    boxShadow: 0,
                                                }}
                                            >
                                                Add another property
                                            </Button>
                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: { xs: 2, md: 16 }, mb: 5 }}>
                                            <Box sx={{ flex: 1 }}>
                                                <Typography sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}> 
                                                    Name of the collection 
                                                </Typography>
                                                <TextField 
                                                    sx={{ width: "100%", "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px", backgroundColor: '#fff' } }} 
                                                    variant="outlined" 
                                                    value={nestedCollectionName}
                                                    onChange={(e) => setNestedCollectionName(e.target.value)}
                                                    disabled={isExistingCollection}
                                                />
                                            </Box>
                                            <Box sx={{ flex: 1 }}>
                                                <Typography sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}> 
                                                    Create Promotional Name 
                                                </Typography>
                                                <TextField 
                                                    sx={{ width: "100%", "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px", backgroundColor: '#fff' } }} 
                                                    variant="outlined" 
                                                    value={nestedPromotionalName}
                                                    onChange={(e) => setNestedPromotionalName(e.target.value)}
                                                    disabled={isExistingCollection}
                                                />
                                            </Box>
                                            <Box sx={{ flex: 1 }}>
                                                <Typography sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}> 
                                                    Add marketing keywords 
                                                </Typography>
                                                <TextField 
                                                    sx={{ width: "100%", "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px", backgroundColor: '#fff' } }} 
                                                    variant="outlined" helperText="Summer, Essentials, light, breezy..."
                                                    value={nestedMarketingKeywords}
                                                    onChange={(e) => setNestedMarketingKeywords(e.target.value)} 
                                                    disabled={isExistingCollection}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <div style={{ textAlign: "start", marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
                                        <Button
                                            sx={{
                                                width: "307px",
                                                height: "44px",
                                                backgroundColor: "#565E6D",
                                                color: "#fff",
                                                "&:hover": { backgroundColor: "#546e7a" },
                                                borderRadius: "0px",
                                            }}
                                            onClick={handleNestedCollectionSubmit}
                                            disabled={!mainCollectionId || loading}
                                        >
                                            {loading ? "Saving..." : "Save"}
                                        </Button>
                                        <Button
                                            sx={{
                                                width: "265px",
                                                height: "26px",
                                                backgroundColor: "#DEE1E6",
                                                color: "#000",
                                                borderRadius: "0px",
                                                fontWeight: 700,
                                                fontFamily: "Inter",
                                                fontSize: 14,
                                                borderBottom: '1px solid #000'
                                            }}
                                            onClick 
                                        >
                                             New nested collection
                                        </Button>
                                    </div>
                                </Box>
                            </Box>                                            
            </Box>
        </Box>
    );  
}
