import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import MUIDataTable from 'mui-datatables';
import { Box, Divider, Grid, Typography, TextField, IconButton, TableCell, Select, MenuItem, Link, Tabs, Tab,  } from '@mui/material';
import { getData, postData } from "../../../ServerRequest";
import SearchIcon from '@mui/icons-material/Search';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import BatchCollection from './BatchCollection';   

export default function ProductCollection (){  
    const searchParams = new URLSearchParams(window.location.search);
    const collectionId = searchParams.get('collection_id'); 
    const [collectionData, setCollectionsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedTab, setSelectedTab] = useState(0);
    const [collectionOption, setCollectionOption] = useState();
    const [liveCollection, setLiveCollectionData] = useState([]);
    const [archivedCollection, setArchivedCollectionData] = useState([]);
    const [deletedCollection, setDeletedCollectionData] = useState([]);
    const [columns, setColumns] = useState();
    const [counts, setCounts] = useState({
            liveCollection: 0,
            archivedCollection: 0,
            deletedCollection: 0,
        });
    const [selectedRows, setSelectedRows] = useState([]);  
    const [selectedCollections, setSelectedCollections] = useState([]);
    const [tagsData, setTagsData] = useState([]);
    const [selectedTag, setSelectedTag] = useState("");

    const navigate = useNavigate(); 

    const handleTableAction = (row, actionType) => {
        if (actionType === 'archive') {
            setLiveCollectionData((prev) => prev.filter((item) => item.id !== row.id));
            setArchivedCollectionData((prev) => [row, ...prev]);
            setCounts((prevCounts) => ({
                ...prevCounts,
                liveCollection: prevCounts.liveCollection - 1,
                archivedCollection: prevCounts.archivedCollection + 1,
            }));
        } else if (actionType === 'retrieve') {
            setArchivedCollectionData((prev) => prev.filter((item) => item.id !== row.id));
            setLiveCollectionData((prev) => [row, ...prev]);
            setCounts((prevCounts) => ({
                ...prevCounts,
                archivedCollection: prevCounts.archivedCollection - 1,
                liveCollection: prevCounts.liveCollection + 1,
            }));
        } else if (actionType === 'delete') {
            setLiveCollectionData((prev) => prev.filter((item) => item.id !== row.id));
            setDeletedCollectionData((prev) => [row, ...prev]);
            setCounts((prevCounts) => ({
                ...prevCounts,
                liveCollection: prevCounts.liveCollection - 1,
                deletedCollection: prevCounts.deletedCollection + 1,
            }));
        }
    };
    
    const handleTableSubmit = async (updatedRow, actionType) => {        
        setLoading(true);

        const collectionData = {
            'id': updatedRow.id ? updatedRow.id : '',
            'collection_status': updatedRow.collection_status ? updatedRow.collection_status : '',
        };

        try {
            console.log('Submitting data:', collectionData);
            const res = await postData('/api/product/product-collection', collectionData);
            console.log('Response:', res);

            if (res['data']['code'] === '200') {                
                alert('Table updated successfully');
                handleTableAction(updatedRow, actionType);
                //setCollectionsData((prev) => prev.filter((item) => item.id !== updatedRow.id));
                //window.location.href = '/admin/products/collectionMaster/collection';
            } else {
                alert('Table update failed');
                console.error('Failed to submit table data:', res.statusText);
            }
        } catch (error) {
            console.error('Error submitting table data:', error);
        } finally {    
            setLoading(false);
        }
    };    

    useEffect(() => {
        async function fetchData() {
            setLoading(true);

            let resData = await getData('/api/product/product-collection');
            console.log(resData);
            
            let collections_data = resData['data']['collection_data']
            let live_collections = resData['data']['live_collections']
            let archived_collections = resData['data']['archived_collections']
            let deleted_collections = resData['data']['deleted_collections']
            
            if (collections_data) {
                console.log(collections_data);
                if (collections_data) {
                    setCollectionsData(collections_data);
                    setLiveCollectionData(live_collections);
                    setArchivedCollectionData(archived_collections);
                    setDeletedCollectionData(deleted_collections);
                    setCounts({
                        liveCollection: resData['data']['live_collection_count'],
                        archivedCollection: resData['data']['archived_collection_count'],
                        deletedCollection: resData['data']['deleted_collection_count'],
                    });
                }
                
            } else {
                console.error("Error fetching collections data:", resData.message);
            }

            let masterRes = await getData('/api/product/acc_tag_master');
            console.log(masterRes);
            let master_data = masterRes['data']['tag_masters']
            
            if (master_data.length) {
                setTagsData(master_data);
                
                // Map tag_id to product_tag
                const tagMap = master_data.reduce((acc, tag) => {
                    acc[tag.tag_id] = tag.product_tag;
                    return acc;
                }, {});

                // Map product_tags for all collections
                const mapTags = (collections) =>
                    collections.map((collection) => ({
                        ...collection,
                        product_tags: collection.tag_ids?.map((id) => tagMap[id]) || [], // Convert tag IDs to names
                    }));

                    setCollectionsData(mapTags(collections_data));
                    setLiveCollectionData(mapTags(live_collections));
                    setArchivedCollectionData(mapTags(archived_collections));
                    setDeletedCollectionData(mapTags(deleted_collections));
        
                    setCounts({
                        liveCollection: resData['data']['live_collection_count'],
                        archivedCollection: resData['data']['archived_collection_count'],
                        deletedCollection: resData['data']['deleted_collection_count'],
                    });    
            
            } else {
                console.error("Error fetching tag master data:", masterRes.message);
            }

            setLoading(false);
        }

        fetchData();
        setCollectionOption(selectedTab === 0 ? activeOptions : selectedTab === 1 ? archiveOptions : deleteOptions);

    }, []);   

    const liveColumns = [

        {
            name: 'collection',
            label: 'Collection Name',
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center', fontWeight: 700 }
                }),
            }
        },

        {
            name: 'promotional_name',            
            label: 'Promotional name',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },

        {
            name: 'tag_count',
            label: 'No of items',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },

        {
            name: 'tag',
            label: 'Tags',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
                customBodyRender: (value, tableMeta) => {
                    if (Array.isArray(value)) {
                        return (
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', justifyContent: 'center' }}>
                                {value.map((tag) => (
                                    <div
                                        key={tag.id}
                                        style={{
                                            padding: '5px 10px',
                                            backgroundColor: '#F3F4F6',
                                            borderRadius: '15px',
                                            fontSize: '12px',
                                            color: '#323842',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {tag}
                                    </div>
                                ))}
                            </div>
                        );
                    }
                    return value;
                },
            },
        },

        {
            name: 'collection_status',
            label: 'Status',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },

        {
            name: "edit",
            label: "Edit",
            options: {
                sort: false,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta) => (
                    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '15px' }}>
                        <Link style={{ color: "black", fontWeight: "bold", textDecoration: 'none' }} href={'/admin/products/collectionMaster/CollectionItems?collection_id=' + value}>
                            <IconButton sx="center"
                                onClick={() => {
                                    const collectionId = tableMeta.rowData[1];
                                    navigate(`/admin/products/collectionMaster/CollectionItems`);
                                }}>
                                <EditOutlinedIcon style={{ color: 'black', width: '20px', height: '20px' }} />
                            </IconButton>
                        </Link>
                    </div>
                ),
            }
        },

        {
            name: "archive",
            label: "Archive",
            options: {
                sort: false,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta) => (
                    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '15px' }}>
                        <IconButton
                            style={{ color: 'black' }}
                            sx="center"
                            onClick={async () => {
                                const rowIndex = tableMeta.rowIndex; // Get the index of the row to archive
                                const rowToArchive = liveCollection[rowIndex]; // Get the row data from liveTags
                                const updatedRow = { ...rowToArchive, collection_status: 'archived' };
                                await handleTableSubmit(updatedRow, 'archive');
                            }}
                        >
                            <ArchiveOutlinedIcon style={{ color: 'black', width: '20px', height: '20px' }} />
                        </IconButton>
                    </div>
                ),    
            }
        },

        {
            name: "delete",
            label: "Delete",
            options: {
                sort: false,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta) => (
                    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '15px' }}>
                        <IconButton
                            style={{ color: 'black' }}
                            sx="center"
                            onClick={async () => {
                                const rowIndex = tableMeta.rowIndex; // Get the index of the row to delete
                                const rowToDelete = liveCollection[rowIndex]; // Get the row data from liveTags     
                                const updatedRow = { ...rowToDelete, collection_status: 'deleted' };
                                await handleTableSubmit(updatedRow, 'delete');
                            }}
                        >
                            <DeleteOutlinedIcon style={{ color: 'black', width: '20px', height: '20px' }} />
                        </IconButton>
                    </div>
                ),                
            }
        },

    ];

    const archivedColumns = [
        
        {
            name: 'collection',
            label: 'Collection Name',
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center', fontWeight: 700 }
                }),
            }
        },

        {
            name: 'promotional_name',
            label: 'Promotional Name',
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },

        {
            name: 'tag_count',
            label: 'No of items',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },

        {
            name: 'tag',
            label: 'Tags',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
                customBodyRender: (value, tableMeta) => {
                    if (Array.isArray(value)) {
                        return (
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', justifyContent: 'center' }}>
                                {value.map((tag) => (
                                    <div
                                        key={tag}
                                        style={{
                                            padding: '5px 10px',
                                            backgroundColor: '#F3F4F6',
                                            borderRadius: '15px',
                                            fontSize: '12px',
                                            color: '#323842',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {tag}
                                    </div>
                                ))}
                            </div>
                        );
                    }
                    return value;
                },
            },
        },

        {
            name: 'collection_status',
            label: 'Status',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },

        {
            name: "assign_tag",
            label: "Retrieve",
            options: {
                sort: false,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta) => (
                    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '15px' }}>
                        <IconButton
                            style={{ color: 'black' }}
                            sx="center"
                            onClick={async () => {
                                const rowIndex = tableMeta.rowIndex; // Get the index of the row to retrieve
                                const rowToRetrieve = archivedCollection[rowIndex]; // Get the row data from archivedTags
                                const updatedRow = { ...rowToRetrieve, collection_status: 'live' };
                                await handleTableSubmit(updatedRow, 'retrieve');
                            }}
                        >
                            <UnarchiveOutlinedIcon style={{ color: 'black', width: '20px', height: '20px' }} />
                        </IconButton>
                    </div>
                ),                
            }
        },

    ];

    const deletedColumns = [
        
        {
            name: 'collection',
            label: 'Collection Name',
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center', fontWeight: 700 }
                }),
            }
        },

        {
            name: 'promotional_name',
            label: 'Promotional Name',
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },

        {
            name: 'tag_count',
            label: 'No of items',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },

        {
            name: 'tag',
            label: 'Tags',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
                customBodyRender: (value, tableMeta) => {
                    if (Array.isArray(value)) {
                        return (
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', justifyContent: 'center' }}>
                                {value.map((tag) => (
                                    <div
                                        key={tag}
                                        style={{
                                            padding: '5px 10px',
                                            backgroundColor: '#F3F4F6',
                                            borderRadius: '15px',
                                            fontSize: '12px',
                                            color: '#323842',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {tag}
                                    </div>
                                ))}
                            </div>
                        );
                    }
                    return value;
                },
            },
        },

        {
            name: 'collection_status',
            label: 'Status',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },

    ];


    const options = {
        elevation: 0,
        filterType: 'checkbox',
        responsive: 'standard',
        tableBodyHeight: 'calc(100vh - 64px - 48px)',
        tableBodyMaxHeight: 'calc(100vh - 64px - 48px)',
        textLabels: {
            body: {
                noMatch: loading ? "Loading..." : "Sorry, no matching records found"
            }
        },
        selectableRows: "multiple",
    };
    
    const activeOptions = {
        elevation: 0,
        filterType: 'checkbox',
        responsive: 'standard',
        tableBodyHeight: 'calc(100vh - 64px - 48px)',
        tableBodyMaxHeight: 'calc(100vh - 64px - 48px)',
        print: false,        // Disable print
        download: false,     // Disable download
        columns: false,      // Disable column visibility
        viewColumns: false,  // Disable view columns
        filter: false,
        search: false,
        textLabels: {
            body: {
                noMatch: loading ? "Loading..." : "Sorry, no matching records found"
            }
        },
        selectableRows: "multiple",
        
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
            if (action === 'rowsSelect') {
                const selectedIndexes = state.selectedRows.data.map(row => row.dataIndex);
                const selectedCollections = selectedIndexes.map(index => liveCollection[index]?.product_tag || '');
                setSelectedRows(selectedCollections);
                console.log("Row selection changed:", selectedCollections);
            }
        }
       
    };

    const archiveOptions = {
        elevation: 0,
        filterType: 'checkbox',
        responsive: 'standard',
        tableBodyHeight: 'calc(100vh - 64px - 48px)',
        tableBodyMaxHeight: 'calc(100vh - 64px - 48px)',
        textLabels: {
            body: {
                noMatch: loading ? "Loading..." : "Sorry, no matching records found"
            }
        },
        selectableRows: "multiple",
        
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
            if (action === 'rowsSelect') {
                const selectedIndexes = state.selectedRows.data.map(row => row.dataIndex);
                console.log(selectedIndexes);
                console.log(archivedCollection);
                
                
                const selectedCollections = selectedIndexes.map(index => archivedCollection[index]?.product_tag || '');
                setSelectedRows(selectedCollections);
                console.log("Row selection changed:", selectedCollections);
            }
        }
        
    };

    const deleteOptions = {
        elevation: 0,
        filterType: 'checkbox',
        responsive: 'standard',
        tableBodyHeight: 'calc(100vh - 64px - 48px)',
        tableBodyMaxHeight: 'calc(100vh - 64px - 48px)',
        textLabels: {
            body: {
                noMatch: loading ? "Loading..." : "Sorry, no matching records found"
            }
        },
        selectableRows: "multiple",
        
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
            if (action === 'rowsSelect') {
                const selectedIndexes = state.selectedRows.data.map(row => row.dataIndex);
                const selectedCollections = selectedIndexes.map(index => deletedCollection[index]?.product_tag || '');
                setSelectedRows(selectedCollections);
                console.log("Row selection changed:", selectedCollections);
            }
        }
       
    };

    useEffect(() => {
        // Update collectionData and columns based on the selected tab
        if (selectedTab === 0) {
            setCollectionsData(liveCollection);
            setColumns(liveColumns);
        } else if (selectedTab === 1) {
            setCollectionsData(archivedCollection);
            setColumns(archivedColumns);
        } else {
            setCollectionsData(deletedCollection);
            setColumns(deletedColumns);
        }
    }, [selectedTab, liveCollection, archivedCollection, deletedCollection]);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        setSearchQuery(""); 
    };

    // Filtered data based on searchQuery and selectedTag
    const filteredData = useMemo(() => {
        if (!Array.isArray(collectionData)) return [];
        let data = collectionData;

        if (selectedTag) {
            data = data.filter(row => row.product_tags?.includes(selectedTag));
        }

        if (searchQuery) {
            data = data.filter(row =>
                Object.values(row || {}).some(value =>
                    typeof value === "object"
                        ? Object.values(value || {}).some(subValue =>
                            subValue?.toString().toLowerCase().includes(searchQuery.toLowerCase())
                        )
                        : value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
                )
            );
        }

        return data;
    }, [collectionData, searchQuery, selectedTag]);
    
    //Batch delete
    const handleDeleteRows = (collectionsToDelete) => {
        setSelectedRows((prevRows) =>
            prevRows.filter((row) => !collectionsToDelete.includes(row.id))
        );
        setSelectedCollections((prevCollections) =>
            prevCollections.filter((collection) => !collectionsToDelete.includes(collection.id))
        );
    };

    return (
        <Box sx={{ width: '95%', paddingLeft: 3, mb: 5 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%' }}>
                    {/* View collection Section */}
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 0, mb: 2 }}>
                        <Typography variant="body1" sx={{ font: "Inter", fontWeight: 700, fontSize: 16 }}>View collection list</Typography>                        
                    </Box>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', mt: 4, mb: 2 }}>                                    
                        {/* Tabs Section */}
                        <Box sx={{ height: '36px' }}>
                            <Tabs
                                value={selectedTab}
                                onChange={handleTabChange}
                                variant="fullWidth"
                                sx={{
                                    minHeight: '35px',
                                    border: '1px solid #F3F4F6',
                                    borderRadius: '0px',
                                    '& .MuiTabs-flexContainer': {
                                        height: '35px',
                                    },
                                    '& .MuiTab-root': {
                                        minHeight: '35px',
                                        width: '144px',
                                        borderRight: '1px solid #F3F4F6',                                        
                                        textTransform: 'none',
                                        color: '#333',  
                                    },
                                    '& .MuiTab-root:last-of-type': {
                                        borderRight: 'none',
                                    },
                                    '& .Mui-selected': {
                                        color: '#000 !important',                                        
                                    },
                                    '& .MuiTabs-indicator': {
                                        height: '3px !important',
                                        backgroundColor: '#9095A0 !important', 
                                    },
                                }}
                            >
                                <Tab label={`Live Tags (${counts.liveCollection})`} />
                                <Tab label={`Archived (${counts.archivedCollection})`} />
                                <Tab label={`Deleted (${counts.deletedCollection})`} />
                            </Tabs>
                        </Box>
                    </Box>
                    
                    <Divider sx={{ mb: 3 }} />
                    
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, ml: 'auto', mb: 4 }}> 
                        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'start' }}>
                            {/* Search Box */}
                            <TextField
                                sx={{ mr: 20, "& .MuiOutlinedInput-root": { height: "35px", width: "304px", borderRadius: "0px" }, }} 
                                size="small" 
                                variant="outlined" 
                                placeholder="Search by item name, tag, product code" 
                                value={searchQuery} 
                                onChange={(e) => setSearchQuery(e.target.value)} 
                                InputProps={{
                                    startAdornment: (
                                        <IconButton position="start" sx={{ ml : "-10px", mr: 1, color: "black" }}>
                                            <SearchIcon />
                                        </IconButton>
                                    )
                                }}
                            />
                        </Box>                         
                        {/* <Select 
                            sx={{ width: "167px", height: "35px", borderRadius: "0px" }}
                            size="small" 
                            variant="outlined"       
                            value={selectedTag}
                            onChange={(e) => setSelectedTag(e.target.value)} // Update selectedTag state                           
                        > 
                            {tagsData.map(tag => (
                                <MenuItem key={tag.tag_id} value={tag.product_tag}>
                                    {tag.product_tag}
                                </MenuItem>
                            ))}
                        </Select>                       
                        <Select
                            sx={{ width: "167px", height: "35px", borderRadius: "0px" }}
                            size="small" 
                            variant="outlined" 
                        />    */}                 
                    </Box>

                    {/* Data Table */}
                    <Box sx={{ border: '1px solid #F4F3F6', '& div[class*="MUIDataTableToolbar"]': { display: 'none' } }}>
                        <MUIDataTable
                            title={''}
                            data={filteredData}
                            columns={columns?columns:liveColumns}
                            options={{...collectionOption, elevation : 0}}
                        />
                    </Box>

                    {/* Batch Action Collapsible */}
                    <BatchCollection 
                        selectedRows={selectedRows} 
                        selectedCollections={selectedCollections}
                        onDeleteRows={handleDeleteRows}
                    />
                </Box>
            </Grid>
        </Box>
    );
};
