import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, TextField, Autocomplete} from '@mui/material';
import { getData, postData, putData } from "../../../ServerRequest"; 
import Checkbox from '@mui/material/Checkbox';

export default function SearchCollection({ 
    setSearchNestedCollection,
    setSearchNestedCollectionTagIds,  
    handleShowCollectionSection, 
    mainCollectionId,
}) { 
    const searchParams = new URLSearchParams(window.location.search);
    const collectionId = searchParams.get('collection_id');
    const [loading, setLoading] = useState(true);  
    const [tagsData, setTagsData] = useState([]);  
    const [nestedQuantity, setNestedQuantity] = useState("");  
    const [nestedTags, setNestedTags] = useState("");
    const [nestedSelectedTags, setNestedSelectedTags] = useState([]);
    
    const [collectionName, setCollectionName] = useState('');
    const [promotionalName, setPromotionalName] = useState('');
    const [marketingKeywords, setMarketingKeywords] = useState('');

    const [collectionsData, setCollectionsData] = useState([]);
    const [selectedCollection, setSelectedCollection] = useState("");
    const [isExistingCollection, setIsExistingCollection] = useState(false);


    const handleNestedCollectionSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
    
        // Step 1: Validate that the Main Collection ID exists
        if (!mainCollectionId) {
            alert('Main Collection must be created before adding a Nested Collection.');
            setLoading(false);
            return;
        }
    
        try {
            const searchNestedCollectionReqData = {
                'nested_collection': nestedSelectedTags.map(tag => ({ 
                    id: tag.id, 
                    name: tag.product_tag.trim() 
                })), // Safely access product_tag
                'collection_name': collectionName ? collectionName : "",
                'promotional_name': promotionalName ? promotionalName : "",
                'marketing_keyword': marketingKeywords ? marketingKeywords : "",
                'parent_collection_id': mainCollectionId, // Link to the main search collection
                'is_nested': true, // This is a nested collection
            };
    
            console.log("Search Nested Collection Request Data:", searchNestedCollectionReqData);
    
            const searchNestedCollectionRes = await postData('/api/product/product-collection', searchNestedCollectionReqData);
            if (searchNestedCollectionRes['data']['code'] !== '200') {
                alert('Error creating Search Nested Collection. Please try again.');
                return;
            }
    
            const createdSearchNestedCollectionId = searchNestedCollectionRes['data']['collection_id'];
            console.log("Created Search Nested Collection ID:", createdSearchNestedCollectionId);
    
            // Log the full response for debugging
            console.log("Search Nested Collection Response Data:", searchNestedCollectionRes['data']);
    
            // Step 4: Update the Search Collection with the Nested Collection ID
            const updateMainSearchCollectionReqData = {
                'collection_id': mainCollectionId, // ID of the search collection to update
                'nested_collection': createdSearchNestedCollectionId, // Add the nested collection ID
            };
    
            console.log("Update Main Search Collection Request Data:", updateMainSearchCollectionReqData);
    
            const updateMainSearchCollectionRes = await putData('/api/product/product-collection', updateMainSearchCollectionReqData);
            if (updateMainSearchCollectionRes['code'] !== 200) {
                alert('Error updating Main Search Collection with Nested Collection. Please try again.');
                return;
            }
    
            console.log("Main Search Collection updated successfully:", updateMainSearchCollectionRes);
    
            // Notify the user of success
            alert('Search Nested Collection created and added to the Main Search Collection successfully.');
    
            // Step 5: Reset the form fields after successful submission
            setCollectionName('');
            setPromotionalName('');
            setMarketingKeywords('');
            setNestedQuantity('');
            setNestedTags('');
            setNestedSelectedTags([]);
        } catch (error) {
            console.error('Error in collection creation:', error);
            alert('An error occurred while saving the collections. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        async function fetchData() {
            try {
                let collectionRes = await getData('/api/product/product-collection');
                console.log(collectionRes);
                let collection_data = collectionRes['data']['collection_data'];
                if (collection_data) {
                    console.log(collection_data);
                    setCollectionsData(collection_data);
                } else {
                    console.error("Error fetching collections data:", collectionRes.message);
                }

                let masterRes = await getData('/api/product/acc_tag_master');
                console.log(masterRes);
                let master_data = masterRes['data']['tag_masters'];
                if (master_data) {
                     // Map the data to ensure it contains both id and product_tag
                     const formattedData = master_data.map(tag => ({
                        id: tag.id,
                        product_tag: tag.product_tag
                    }));
                    setTagsData(formattedData); // Set the formatted data  
                } else {
                    console.error("Error fetching tags data:", masterRes.message);
                }

            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [collectionId]);

    const handleCollectionChange = async (event) => {
        const selectedValue = event.target.value;
        setSelectedCollection(selectedValue);
        if (selectedValue) {
            setIsExistingCollection(true);
            try {
                let collectionDetailsRes = await getData(`/api/product/product-collection/${selectedValue}`);
                console.log(collectionDetailsRes);
                let collectionDetails = collectionDetailsRes['data']['collection'];
                if (collectionDetails) {
                    setNestedQuantity(collectionDetails.quantity);
                    setNestedTags(collectionDetails.tags);
                    setCollectionName(collectionDetails.name);
                    setPromotionalName(collectionDetails.promotionalName);
                    setMarketingKeywords(collectionDetails.marketingKeywords);
                } else {
                    console.error("Error fetching collection details:", collectionDetailsRes.message);
                }
            } catch (error) {
                console.error("Error fetching collection details:", error);
            }
        } else {
            setIsExistingCollection(false);
            setNestedQuantity("");
            setNestedTags("");
            setCollectionName("");
            setPromotionalName("");
            setMarketingKeywords("");
        }
    };

    const handleTagsChange = (event, newValue) => {
        // Filter out invalid tags
        const validTags = newValue.filter(tag => tag && tag.id && tag.product_tag);
        console.log("Valid Tags:", validTags);

        // Update the selected tags state
        setNestedSelectedTags(validTags);
    
        // Pass the selected tags to the parent component
        setSearchNestedCollection(validTags); // Pass valid tags directly for display
    };

    return (
        <Box sx={{ width: '100%' }}>            
            {/* Create Search Nested tags collection */}
            <Box sx={{ mt: 2 }}>                                     
                            <Box sx={{ backgroundColor: "#DEE1E6", padding: 4 }}>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>
                                        Select collection
                                    </Typography>
                                    <Autocomplete
                                        options={collectionsData}
                                        getOptionLabel={(option) => option.collection || ""}
                                        renderInput={(params) => 
                                            <TextField {...params} 
                                                placeholder="Select a collection" 
                                            />}
                                        sx={{ width: 499, "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px", backgroundColor: "#fff" } }}
                                        value={selectedCollection}
                                        onChange={(event, newValue) => handleCollectionChange({ target: { value: newValue } })}
                                    />
                                    <Typography variant="body1" sx={{ fontFamily: "Inter", fontWeight: "700", fontSize: 16, mt: 2, mb: 2 }}>
                                        Start defining the new collection
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>
                                        Min quantity/ tag (in Mtr)
                                    </Typography>
                                    <TextField
                                        sx={{ width: 191, "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px", backgroundColor: "#fff" } }}
                                        value={nestedQuantity}
                                        onChange={(e) => setNestedQuantity(e.target.value)}    
                                        disabled={isExistingCollection}
                                    />
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                    <Typography variant="body1" sx={{ fontFamily: "Inter", fontWeight: "700", fontSize: 16, mt: 2, mb: 1  }}>
                                        Start defining tags by search
                                    </Typography>
                                    <Autocomplete
                                        multiple
                                        options={tagsData}  
                                        getOptionLabel={(option) => option.product_tag || ""}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props} style={{ fontSize: '0.875rem', padding: '2px 6px', border: '1px solid #DEE1E6' }}> {/* Ensure border */}
                                                <Checkbox
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.product_tag}
                                            </li>
                                        )}
                                        renderTags={(value) => 
                                            value.map((option) => option.product_tag).join(', ')
                                        }
                                        renderInput={(params) => 
                                            <TextField {...params}                                                 
                                                helperText="Separate input with (,) to get input"                        
                                            />}
                                        sx={{ width: 499, "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px", backgroundColor: "#fff", padding: "5px", overflow: "hidden" }}}
                                        inputValue={nestedTags}
                                        onInputChange={(event, newValue) => setNestedTags(newValue)}
                                        onChange={(event, newValue) => {                                            
                                            handleTagsChange(event, newValue);
                                            setSearchNestedCollection(newValue);
                                            handleShowCollectionSection(true);
                                        }}
                                        isOptionEqualToValue={(option, value) => 
                                            option.id === value.id && option.product_tag === value.product_tag
                                        }
                                        filterSelectedOptions
                                        filterOptions={(options, state) => {
                                            const selectedOptions = state.inputValue ? [] : nestedSelectedTags;
                                            return [...selectedOptions, ...options.filter(option => !nestedSelectedTags.includes(option))];
                                        }}
                                        disabled={isExistingCollection}
                                    />                                   

                                    <Box sx={{ display: "flex", flexDirection: "row", gap: 5, mt: 2, mb: 2 }}>
                                        <Box>
                                            <Typography sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}> 
                                                Name of the collection 
                                            </Typography>
                                            <TextField 
                                                sx={{ width: 295, "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px", backgroundColor: "#fff" } }} 
                                                variant="outlined" 
                                                value={collectionName}
                                                onChange={(e) => setCollectionName(e.target.value)}
                                                disabled={isExistingCollection}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}> 
                                                Create Promotional Name 
                                            </Typography>
                                            <TextField 
                                                sx={{ width: 295, "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px", backgroundColor: "#fff" } }} 
                                                variant="outlined" 
                                                value={promotionalName}
                                                onChange={(e) => setPromotionalName(e.target.value)}
                                                disabled={isExistingCollection}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}> 
                                                Add marketing keywords 
                                            </Typography>
                                            <TextField 
                                                sx={{ width: 295, "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px", backgroundColor: "#fff" } }} 
                                                variant="outlined" helperText="Summer, Essentials, light, breezy..."
                                                value={marketingKeywords}
                                                onChange={(e) => setMarketingKeywords(e.target.value)} 
                                                disabled={isExistingCollection}
                                            />
                                        </Box>
                                    </Box>
                                    <div style={{ textAlign: "start", marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
                                        {/* Save Button */}
                                        {!isExistingCollection && (
                                            <Button
                                                sx={{
                                                    width: "167px",
                                                    height: "44px",
                                                    backgroundColor: "#565E6D",
                                                    color: "#fff",
                                                    "&:hover": { backgroundColor: "#546e7a" },
                                                    borderRadius: "0px",
                                                }}
                                                onClick={handleNestedCollectionSubmit}
                                                disabled={!mainCollectionId}
                                            >
                                                Save
                                            </Button>
                                        )}

                                        {/* Create nested collection Button */}                                
                                        <Button
                                            sx={{
                                                width: "265px",
                                                height: "26px",
                                                backgroundColor: "#DEE1E6",
                                                color: "#000",
                                                borderRadius: "0px",
                                                fontWeight: 700,
                                                fontFamily: "Inter",
                                                fontSize: 14,
                                                borderBottom: '1px solid #000'
                                            }}
                                            
                                        >
                                            New nested collection
                                        </Button>
                                    </div>
                                </Box>                                
                            </Box>               
            </Box>
        </Box>
    );  
}
