import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography, Button, Select, MenuItem, TextField } from '@mui/material';
import { getData } from "../../../ServerRequest"; 
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ManualNestedCollection from './ManualNestedCollection'; 

export default function ManualCollection({ 
        setManualCollection, 
        setSearchCollectionTagIds, 
        setManualNestedCollection, 
        setShowDefineCollection, 
        handleShowCollectionSection,
        isCollectionSaved 
    }) { 
    const searchParams = new URLSearchParams(window.location.search);
    const collectionId = searchParams.get('collection_id');
    const [loading, setLoading] = useState(true);
    const [tagsData, setTagsData] = useState([]);
    const [familyData, setFamilyData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);    
    const [minQuantity, setMinQuantity] = useState("");
    const [filteredTagCategories, setFilteredTagCategories] = useState([]);
    const [filteredProductTags, setFilteredProductTags] = useState([]);  
    const [manualProperties, setManualProperties] = useState([{ id: 1, manualTagFamily: "", manualTagCategory: "", manualProductTag: "", manualMinQuantity: "" }]);   
    const [showNestedCollection, setShowNestedCollection] = useState(false);     
    
    useEffect(() => {
        async function fetchData() {
            try {

                let masterRes = await getData('/api/product/acc_tag_master');
                console.log(masterRes);
                let master_data = masterRes['data']['tag_masters']
                if (master_data) {
                    console.log(master_data);
                    setTagsData(master_data);
                } else {
                    console.error("Error fetching tag master data:", masterRes.message);
                }

                let familyRes = await getData('/api/product/acc_tag_family');
                console.log(familyRes);
                let family_data = familyRes['data']['tag_families']
                if (family_data) {
                    console.log(family_data);
                    setFamilyData(family_data);
                } else {
                    console.error("Error fetching tags family data:", familyRes.message);
                }

                let categoryRes = await getData('/api/product/acc_tag_category');
                console.log(categoryRes);
                let category_data = categoryRes['data']['tag_categories']
                if (category_data) {
                    console.log(category_data);
                    setCategoryData(category_data);
                } else {
                    console.error("Error fetching tags category data:", categoryRes.message);
                }

            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        } 
            
        fetchData();

    }, [collectionId]); 

    useEffect(() => {
        manualProperties.forEach((property, index) => {
            // Filter Tag Categories based on selected Tag Family
            const filteredCategories = categoryData.filter(category => 
                !property.manualTagFamily || category.family === property.manualTagFamily
            );
            setFilteredTagCategories(filteredCategories);
    
            // Filter Product Tags based on selected Tag Family, Tag Category, and Min Quantity
            const filteredTags = tagsData.filter(({ family, category, quantityId }) =>
                (!property.manualTagFamily || family === property.manualTagFamily) &&
                (!property.manualTagCategory || category === property.manualTagCategory) &&
                (!property.manualMinQuantity || quantityId >= property.manualMinQuantity)
            );
            setFilteredProductTags(filteredTags);
    
            // Debugging logs
            //console.log(`Property ${index + 1} - Selected Tag Family:`, property.manualTagFamily);
            //console.log(`Property ${index + 1} - Filtered Categories:`, filteredCategories);
            //console.log(`Property ${index + 1} - Selected Tag Category:`, property.manualTagCategory);
            //console.log(`Property ${index + 1} - Min Quantity:`, property.manualMinQuantity);
            //console.log(`Property ${index + 1} - Filtered Product Tags:`, filteredTags);
        });
    }, [tagsData, categoryData, manualProperties]);   
    
    const handleAddManualProperty = () => {
        setManualProperties([...manualProperties, { id: manualProperties.length + 1, manualTagFamily: "", manualTagCategory: "", manualProductTag: "", manualMinQuantity: "" }]);
    };

    const handleCreateCollection = () => {
        const combinedTags = manualProperties.map((property) => {
            const filteredProductTag = tagsData.find(({ id }) => id === property.manualProductTag);
            return filteredProductTag ? { id: filteredProductTag.id, product_tag: filteredProductTag.product_tag } : null;
        }).filter(tag => tag !== null); // Filter out null values

        const combinedTagIds = combinedTags.map(tag => tag.id);

        console.log("Final Combined Tags Before Filtering:", combinedTags);

        // Extract only valid product_tag values and filter out invalid ones
        const validTags = combinedTags
            .map(tag => tag.product_tag?.trim())
            .filter(tag => tag && tag !== "");
        const validTagIds = combinedTagIds
            .filter(id => id && id !== "");

        console.log("Filtered Valid Tags:", validTags);
        setSearchCollectionTagIds(validTagIds);
        setManualCollection(combinedTags); // Set the combined tags directly
        setShowDefineCollection(true);
    };        

    const handleManualPropertyChange = (id, field, value) => {
        // Update the manualProperties state
        setManualProperties((prev) =>
            prev.map((property) =>
                property.id === id ? { ...property, [field]: value } : property
            )
        );
    
        // If the field is manualProductTag, update the manualCollection
        if (field === "manualProductTag") {
            setManualCollection((prev) => {
                // Find the selected tag in tagsData
                const selectedTag = tagsData.find((tag) => tag.id === value);
    
                if (!selectedTag) {
                    return prev; // If no tag is found, return the previous state
                }
    
                // Remove any existing entry for the current property
                const updatedCollection = prev.filter(
                    (tag) => !manualProperties.some((property) => property.id === id && property.manualProductTag === tag.id)
                );
    
                // Add the new selected tag
                updatedCollection.push({ id: selectedTag.id, product_tag: selectedTag.product_tag.trim() });
    
                return updatedCollection;
            });
        }
    };

    const togglenestedTags = () => {
        setShowNestedCollection(prev => !prev); // Toggle nested collection visibility
    }; 

    return (
        <Box sx={{ width: '100%' }}>            
            {/* Create Manual Tags collection */}
            <Box sx={{ mt: 2 }}>                 
                {/* Manual Tags Dropdown */}
                <Accordion sx={{ mt: 1, backgroundColor: "white", boxShadow: 0, borderTop: '1px solid #BCC1CA', borderBottom: '1px solid #BCC1CA' }}>
                    <AccordionSummary 
                        variant="h7" 
                        sx={{ display: "flex", alignItems: "center", gap: 2, fontFamily: "Inter", fontWeight: 700, fontSize: 16, height: 53 }}
                        expandIcon={
                            <div>
                            <ExpandMoreIcon />
                            </div>
                        }
                    >
                        <RadioButtonUncheckedIcon sx={{ mr: 2 }} /> Create collection by manually defining tags 
                    </AccordionSummary>
                    <AccordionDetails sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 7, pt: 1 }}>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 0 }}>
                            <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>
                                Min quantity/ tag (in Mtr)
                            </Typography>
                            <TextField
                                sx={{ width: 191, "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px", backgroundColor: "#fff" } }}
                                value={minQuantity}
                                onChange={(e) => setMinQuantity(e.target.value)}
                             />             
    
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, borderBottom: "1px solid #BCC1CA" }}>
                            <Typography variant="body1" sx={{ fontFamily: "Inter", fontWeight: "700", fontSize: 16 }}>
                                Select main collection tags
                            </Typography>

                            {manualProperties.map((property, index) => (
                                <Box key={property.id}>
                                    <Box sx={{ display: "flex", flexDirection: "row", gap: 6, mb: 2, mt: 1, alignItems: "flex-end" }}>
                                        <Box>
                                            <Typography sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>
                                                Tag Family
                                            </Typography>
                                            <Select 
                                                value={property.manualTagFamily} 
                                                onChange={(e) => handleManualPropertyChange(property.id, "manualTagFamily", e.target.value)}
                                                sx={{ width: 191, height: "35px", borderRadius: "0px", backgroundColor: '#fff' }}
                                                variant="outlined"
                                            >
                                                {familyData.map((family) => (
                                                    <MenuItem key={family.id} value={family.id}>{family.tag_family}</MenuItem>
                                                ))}
                                            </Select>
                                        </Box>
                                        <Box>
                                            <Typography sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>
                                                Tag Category
                                            </Typography>
                                            <Select 
                                                value={property.manualTagCategory} 
                                                onChange={(e) => handleManualPropertyChange(property.id, "manualTagCategory", e.target.value)}
                                                sx={{ width: 191, height: "35px", borderRadius: "0px", backgroundColor: '#fff' }} 
                                                variant="outlined"
                                            >
                                                {categoryData
                                                    .filter((category) => !property.manualTagFamily || category.tag_family === property.manualTagFamily)
                                                    .map((category) => (
                                                        <MenuItem key={category.id} value={category.id}>{category.category}</MenuItem>
                                                    ))}
                                            </Select>
                                        </Box>
                                        <Box>
                                            <Typography sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>
                                                Product Tag
                                            </Typography>
                                            <Select 
                                                value={property.manualProductTag} 
                                                onChange={(e) => handleManualPropertyChange(property.id, "manualProductTag", e.target.value)}
                                                sx={{ width: 191, height: "35px", borderRadius: "0px", backgroundColor: '#fff' }} 
                                                variant="outlined"
                                            >
                                                {tagsData
                                                    .filter((tag) => !property.manualTagCategory || tag.tag_category === property.manualTagCategory)
                                                    .map((tag) => (
                                                        <MenuItem key={tag.id} value={tag.id}>{tag.product_tag}</MenuItem>
                                                    ))}
                                            </Select>
                                        </Box>
                                    </Box>
                                </Box>
                            ))}

                            {/* Add another property button outside the loop */}
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 6, mb: 4, mt: 2 }}>
                                <Button
                                    startIcon={<AddCircleOutlineIcon />}
                                    onClick={handleAddManualProperty}
                                    sx={{
                                        backgroundColor: "white",
                                        color: "black",
                                        textTransform: "none",
                                        border: "1px solid #BCC1CA",
                                        borderRadius: "0px",
                                        height: "35px",
                                        width: 191,
                                        boxShadow: 0
                                    }}
                                >
                                    Add another property
                                </Button>
                            </Box>
                        </Box>
                        {/* Create collection Button */}
                        <div style={{ textAlign: "start", marginTop: "15px" }}>
                            <Button
                                sx={{
                                    width: "307px",
                                    height: "44px",
                                    backgroundColor: "#565E6D",
                                    color: "#fff",
                                    "&:hover": { backgroundColor: "#546e7a" },
                                    borderRadius: "0px",
                                }}
                                onClick={handleCreateCollection}
                            >
                                Create collection
                            </Button>    
                        </div>
                        {/* Manual Nested Tags collection */}
                        {isCollectionSaved && (
                            <Button
                                sx={{
                                    width: "265px",
                                    height: "26px",
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    borderRadius: "0px",
                                    fontWeight: 700,
                                    fontFamily: "Inter",
                                    fontSize: 14,
                                    borderBottom: '1px solid #000'
                                }}
                                onClick={togglenestedTags} // Toggle nested collection visibility
                            >
                                Create a nested collection
                            </Button>
                        )}     

                        {/* Conditionally render ManualNestedCollection */}
                        {showNestedCollection && (
                            <ManualNestedCollection
                                setManualCollection={(tags) => setManualCollection(tags.map(tag => ({ id: tag.id, product_tag: tag.product_tag })))}
                                setManualNestedCollection={(nestedCollection) => {
                                    setManualNestedCollection(nestedCollection); // Update local state
                                    handleShowCollectionSection(); // Notify parent to show the collection section
                                }}
                            />
                        )}     
                                 
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    );  
}
