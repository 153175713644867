import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography, IconButton, TextField } from '@mui/material';
import { getData, postData } from "../../ServerRequest";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

const buttonStyle = { background: '#f8f9fa', border: '3px solid #b0bec5', color: 'black', fontSize: '0.850rem', '&:hover': { background: '#eceff1' } };
const sectionStyle = { backgroundColor: 'rgba(255, 255, 255, 0.6)', marginBottom: 2, boxShadow: '0px 4px 8px rgba(0, 0, 1, 0.1)' };

export default function LandingHeader() {  
  const [header, setHeader] = useState([]);
  const [loading, setLoading] = useState();
  const [editIndex, setEditIndex] = useState({});

  const handleEditClick = (section) => {
    // Handle edit logic for each section
    console.log(`Edit clicked for ${section}`);
  };

  useEffect(() => {
    async function fetchHeaderData() {
        let resData = await getData('/api/theme/headers');
        console.log(resData);
        let header_data = resData['data']['headers'];
        if (header_data) {
            console.log(header_data);
            if (header_data) {
                setHeader(header_data);
            }
        } else {
            console.error("Error fetching header data:", resData.message);
        }
        setLoading(false);
    }

    fetchHeaderData();
  }, []);

  return (
    <Box sx={{ width: '95%', paddingLeft: 3 }}>
      {/* Header Content Section */}
      <Accordion sx={sectionStyle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <IconButton color="" component="icon">
                    <GridViewIcon />
                </IconButton>
                <Typography variant="h7">Header Content</Typography>
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ backgroundColor: '#eceff1', padding: 4, borderRadius: 1 }}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>Content</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
              <TextField fullWidth label="Language Selection" sx={{ marginRight: 2, backgroundColor: 'white' }}  />
              <TextField fullWidth label="Logo" sx={{ marginRight: 0, backgroundColor: 'white' }}  />
              <IconButton sx={{ ml: 1, marginBottom: 1, color: 'grey' }} onClick={() => handleEditClick('Header Content')} aria-label="edit-content">
                <EditOutlinedIcon />
              </IconButton>
            </Box> 
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
              <TextField fullWidth label="Promotional Text" sx={{ marginRight: 2, backgroundColor: 'white' }}  />
              <TextField fullWidth label="Promotional Link" sx={{ marginRight: 0, backgroundColor: 'white' }}  />
              <IconButton sx={{ ml: 1, marginBottom: 1, color: 'grey' }} onClick={() => handleEditClick('Header Content')} aria-label="edit-content">
                <EditOutlinedIcon />
              </IconButton>
            </Box> 
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
              <TextField fullWidth label="Tracking Text" sx={{ marginRight: 2, backgroundColor: 'white' }}  />
              <TextField fullWidth label="Tracking Link" sx={{ marginRight: 0, backgroundColor: 'white' }}  />
              <IconButton sx={{ ml: 1, marginBottom: 1, color: 'grey' }} onClick={() => handleEditClick('Header Content')} aria-label="edit-content">
                <EditOutlinedIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
              <TextField fullWidth label="Callback Text" sx={{ marginRight: 2, backgroundColor: 'white' }}  />
              <TextField fullWidth label="Callback Link" sx={{ marginRight: 0, backgroundColor: 'white' }}  />
              <IconButton sx={{ ml: 1, marginBottom: 1, color: 'grey' }} onClick={() => handleEditClick('Header Content')} aria-label="edit-content">
                <EditOutlinedIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
              <TextField fullWidth label="Cart Link" sx={{ marginRight: 2, backgroundColor: 'white' }}  />
              <TextField fullWidth label="Wishlist Link" sx={{ marginRight: 0, backgroundColor: 'white' }}  />
              <IconButton sx={{ ml: 1, marginBottom: 1, color: 'grey' }} onClick={() => handleEditClick('Header Content')} aria-label="edit-content">
                <EditOutlinedIcon />
              </IconButton>
            </Box>
            <IconButton variant="contained" sx={{ ...buttonStyle, marginRight: 3, marginTop: 2 }}  aria-label="save-changes">
                <SaveOutlinedIcon /> 
            </IconButton>              
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Menu Items Section */}
      <Accordion sx={sectionStyle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <IconButton color="" component="icon">
                    <GridViewIcon />
                </IconButton>
                <Typography variant="h7">Menu Items</Typography>
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ backgroundColor: '#eceff1', padding: 4, borderRadius: 1 }}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>App Menu</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
              <TextField fullWidth label="Name" sx={{ marginRight: 2, backgroundColor: 'white' }}  />
              <TextField fullWidth label="Link to" sx={{ marginRight: 0, backgroundColor: 'white' }}  />
              <IconButton sx={{ ml: 1, marginBottom: 1, color: 'grey' }} onClick={() => handleEditClick('Header Content')} aria-label="edit-content">
                <EditOutlinedIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
              <TextField fullWidth label="Name" sx={{ marginRight: 2, backgroundColor: 'white' }}  />
              <TextField fullWidth label="Link to" sx={{ marginRight: 0, backgroundColor: 'white' }}  />
              <IconButton sx={{ ml: 1, marginBottom: 1, color: 'grey' }} onClick={() => handleEditClick('Header Content')} aria-label="edit-content">
                <EditOutlinedIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
              <TextField fullWidth label="Name" sx={{ marginRight: 2, backgroundColor: 'white' }}  />
              <TextField fullWidth label="Link to" sx={{ marginRight: 0, backgroundColor: 'white' }}  />
              <IconButton sx={{ ml: 1, marginBottom: 1, color: 'grey' }} onClick={() => handleEditClick('Header Content')} aria-label="edit-content">
                <EditOutlinedIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
              <TextField fullWidth label="Name" sx={{ marginRight: 2, backgroundColor: 'white' }}  />
              <TextField fullWidth label="Link to" sx={{ marginRight: 0, backgroundColor: 'white' }}  />
              <IconButton sx={{ ml: 1, marginBottom: 1, color: 'grey' }} onClick={() => handleEditClick('Header Content')} aria-label="edit-content">
                <EditOutlinedIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
              <TextField fullWidth label="Name" sx={{ marginRight: 2, backgroundColor: 'white' }}  />
              <TextField fullWidth label="Link to" sx={{ marginRight: 0, backgroundColor: 'white' }}  />
              <IconButton sx={{ ml: 1, marginBottom: 1, color: 'grey' }} onClick={() => handleEditClick('Header Content')} aria-label="edit-content">
                <EditOutlinedIcon />
              </IconButton>
            </Box>
            <IconButton variant="contained" sx={{ ...buttonStyle, marginRight: 3, marginTop: 2 }} aria-label="save-changes">
              <SaveOutlinedIcon /> 
            </IconButton>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
