import React, { useState, useEffect, useMemo } from 'react';
import MUIDataTable from "mui-datatables";
import { Box, Grid, Button, IconButton, Typography, TextField, TableCell, Tab, Tabs, Select, MenuItem } from '@mui/material';
import { getData, postData } from "../../../ServerRequest";
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import LandscapeIcon from '@mui/icons-material/Landscape';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import CreateTag from './CreateTag';
import CreateBatch from './CreateBatch';

export default function Tags() {
    const searchParams = new URLSearchParams(window.location.search);
    const tagId = searchParams.get('tag_id');
    const [tagsData, setTagsData] = useState([]);
    const [tagsOption, setTagsOption] = useState();
    const [liveTags, setLiveTagsData] = useState([]);
    const [archivedTags, setArchivedTagsData] = useState([]);
    const [deletedTags, setDeletedTagsData] = useState([]);
    const [familyData, setFamilyData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedTab, setSelectedTab] = useState(0);
    const [counts, setCounts] = useState({
        liveTags: 0,
        archivedTags: 0,
        deletedTags: 0,
    });
    const [selectedRows, setSelectedRows] = useState([]);    

    const navigate = useNavigate();

    const handleTableSubmit = async (updatedRow, actionType = 'save') => {        
        setLoading(true);

        const tagsData = {
            'id': updatedRow.id ? updatedRow.id : '',
            'product_tag': updatedRow.product_tag ? updatedRow.product_tag : '',
            'tag_family': updatedRow.tag_family ? updatedRow.tag_family : '',
            'tag_category': updatedRow.category ? updatedRow.category : '',
            'action': actionType, 
        };

        try {
            console.log(tagsData);
            const res = await postData('/api/product/acc_tag_master', tagsData);
            console.log(res);
            if (res['data']['code'] === '200') {
                setLoading(false);
                alert('Table updated successfully');
                window.location.href = '/admin/products/tagMaster/tags';
            } else {
                alert('Table update failed');
                console.error('Failed to submit table data:', res.statusText);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error submitting table data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        async function fetchData() {
            try {

                let familyRes = await getData('/api/product/acc_tag_family');
                console.log(familyRes);
                let family_data = familyRes['data']['tag_families']
                if (family_data) {
                    console.log(family_data);
                    setFamilyData(family_data);
                } else {
                    console.error("Error fetching tags family data:", familyRes.message);
                }

                let categoryRes = await getData('/api/product/acc_tag_category');
                console.log(categoryRes);
                let category_data = categoryRes['data']['tag_categories']
                if (category_data) {
                    console.log(category_data);
                    setCategoryData(category_data);
                } else {
                    console.error("Error fetching tags category data:", categoryRes.message);
                }

                let masterRes = await getData('/api/product/acc_tag_master');
                console.log(masterRes);
                let master_data = masterRes['data']['tag_masters']
                let live_tags = masterRes['data']['live_tags']
                let archived_tags = masterRes['data']['archived_tags']
                let deleted_tags = masterRes['data']['deleted_tags']
                if (master_data) {
                    console.log(master_data);
                    setTagsData(live_tags);
                    setLiveTagsData(live_tags);
                    setArchivedTagsData(archived_tags);
                    setDeletedTagsData(deleted_tags);
                    setCounts({
                        liveTags: masterRes['data']['live_tags_count'],
                        archivedTags: masterRes['data']['archived_tags_count'],
                        deletedTags: masterRes['data']['deleted_tags_count'],
                    });
                } else {
                    console.error("Error fetching tag master data:", masterRes.message);
                }

            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
        setTagsOption(selectedTab === 0 ? activeOptions : selectedTab === 1 ? archiveOptions : deleteOptions);

    }, []);

    const EditableProductTagCell = ({ value, onChange }) => {
        const [isEditing, setIsEditing] = useState(false);
        const [tagName, setTagName] = useState(value);

        useEffect(() => {
            setTagName(value); // Update the local state when the value prop changes
        }, [value]);

        const handleInputChange = (event) => {
            setTagName(event.target.value);
        };

        const handleCellSubmit = () => {
            onChange(tagName);
            setIsEditing(false);
        };

        return (
            <TableCell style={{ textAlign: "center", cursor: "pointer" }} onClick={() => setIsEditing(true)}>
                {isEditing ? (
                    <TextField
                        value={tagName}
                        onChange={handleInputChange}
                        onBlur={handleCellSubmit} // Submit when clicking outside
                        onKeyDown={(e) => e.key === "Enter" && handleCellSubmit()} // Submit on Enter key
                        size="small"
                        variant="outlined"
                        autoFocus
                        sx={{ 
                            minWidth: "140px",
                            "& .MuiOutlinedInput-root": { 
                                height: "35px",
                                borderRadius: "0px" 
                            }, 
                        }}
                    />
                ) : (
                    tagName || "Click to Edit"
                )}
            </TableCell>
        );
    };

    const EditableTagCategoryCell = ({ value, onChange, categoryOptions }) => {
        const [isEditing, setIsEditing] = useState(false);
        const [selectedValue, setSelectedValue] = useState(value);

        useEffect(() => {
            setSelectedValue(value); // Update local state when the value prop changes
        }, [value]);

        const handleSelectChange = (event) => {
            setSelectedValue(event.target.value);
            onChange(event.target.value);
            setIsEditing(false);
        };

        const displayText = categoryOptions.find(category => category.id === selectedValue)?.category || "N/A";

        return (
            <TableCell onClick={() => setIsEditing(true)} style={{ cursor: "pointer" }}>
                {isEditing ? (
                    <Select
                        value={selectedValue || ""}
                        onChange={handleSelectChange}
                        size="small"
                        displayEmpty
                        sx={{ minWidth: "140px", height: "35px", borderRadius: "0px" }}
                        autoFocus
                    >
                        {categoryOptions.map((category) => (
                            <MenuItem key={category.id} value={category.id}>
                                {category.category}
                            </MenuItem>
                        ))}
                    </Select>
                ) : (
                    displayText
                )}
            </TableCell>
        );
    };

    const EditableTagFamilyCell = ({ value, onChange, familyOptions }) => {
        const [isEditing, setIsEditing] = useState(false);
        const [selectedValue, setSelectedValue] = useState(value);

        useEffect(() => {
            setSelectedValue(value); // Update local state when the value prop changes
        }, [value]);

        const handleSelectChange = (event) => {
            setSelectedValue(event.target.value);
            onChange(event.target.value);
            setIsEditing(false);
        };

        const displayText = familyOptions.find(family => family.id === selectedValue)?.tag_family || "N/A";

        return (
            <TableCell onClick={() => setIsEditing(true)} style={{ cursor: "pointer" }}>
                {isEditing ? (
                    <Select
                        value={selectedValue || ""}
                        onChange={handleSelectChange}
                        size="small"
                        displayEmpty
                        sx={{ minWidth: "140px", height: "35px", borderRadius: "0px" }}
                        autoFocus
                    >
                        {familyOptions.map((family) => (
                            <MenuItem key={family.id} value={family.id}>
                                {family.tag_family}
                            </MenuItem>
                        ))}
                    </Select>
                ) : (
                    displayText
                )}
            </TableCell>
        );
    };

    const liveColumns = [
        {
            name: 'image_url',
            label: 'Image',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div style={{
                        width: 80,
                        height: 53,
                        backgroundColor: '#F3F4F6',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '0px',
                        border: "1px solid #F3F4F6",
                        overflow: 'hidden',
                        margin: 'auto',
                        textAlign: 'center',
                    }}>
                        {value ? (
                            <img
                                src={value}
                                alt="img"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                        ) : (
                            <LandscapeIcon style={{ color: '#BCC1CA', fontSize: 30 }} />
                        )}
                    </div>
                ),
            }
        },

        {
            name: "product_tag",
            label: "Product Tag",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <EditableProductTagCell
                        value={value}
                        onChange={(newValue) => {
                            handleCellChange(tableMeta.rowIndex, "product_tag", newValue);
                        }}
                    />
                ),
                customHeadRender: (columnMeta) => (
                    <TableCell
                        key={columnMeta.index}
                        style={{ fontWeight: "bold", backgroundColor: "#FAFAFB", textAlign: "center" }}
                    >
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: "center" }
                }),
            }
        },

        {
            name: "tag_category",
            label: "Tag category",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <EditableTagCategoryCell
                        value={value}
                        onChange={(newValue) => {
                            handleCellChange(tableMeta.rowIndex, "tag_category", newValue);
                        }}
                        categoryOptions={categoryData || []} // Pass category data
                    />
                ),
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },

        {
            name: "tag_family",
            label: "Tag family",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <EditableTagFamilyCell
                        value={value}
                        onChange={(newValue) => {
                            handleCellChange(tableMeta.rowIndex, "tag_family", newValue);
                        }}
                        familyOptions={familyData || []} // Pass family data
                    />
                ),
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },

        {
            name: "assign_tag",
            label: "Assigned products",
            options: {
                sort: false,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },

        {
            name: "edit_tag",
            label: "Edit",
            options: {
                sort: false,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ font: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta) => (
                    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '15px' }}>
                        <IconButton sx="center"
                            onClick={() => {
                                const productId = tableMeta.rowData[1];
                                navigate(`/admin/products/productDetail/productDetail?product_id=${productId}`);
                            }}>
                            <EditOutlinedIcon style={{ color: 'black', width: '20px', height: '20px' }} />
                        </IconButton>
                    </div>
                ),
            }
        },

        {
            name: "archive_tag",
            label: "Archive",
            options: {
                sort: false,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ font: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta) => (
                    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '15px' }}>
                        <IconButton
                            style={{ color: 'black' }}
                            sx="center"
                            onClick={() => {
                                const rowIndex = tableMeta.rowIndex; // Get the index of the row to archive
                                const rowToArchive = liveTags[rowIndex]; // Get the row data from liveTags
        
                                // Update liveTags and archivedTags state
                                const updatedLiveTags = liveTags.filter((_, index) => index !== rowIndex);
                                const updatedArchivedTags = [rowToArchive, ...archivedTags]; // Prepend the row
        
                                setLiveTagsData(updatedLiveTags);
                                setArchivedTagsData(updatedArchivedTags);
        
                                // Update the counts
                                setCounts((prevCounts) => ({
                                    ...prevCounts,
                                    liveTags: updatedLiveTags.length,
                                    archivedTags: updatedArchivedTags.length,
                                }));
        
                                // Update tagsData if the current tab is liveTags
                                if (selectedTab === 0) {
                                    setTagsData(updatedLiveTags);
                                }
                                handleTableSubmit(rowToArchive, 'archive');
                            }}
                        >
                            <ArchiveOutlinedIcon style={{ color: 'black', width: '20px', height: '20px' }} />
                        </IconButton>
                    </div>
                ),
            }
        },

        {
            name: "delete_tag",
            label: "Delete",
            options: {
                sort: false,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ font: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta) => (
                    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '15px' }}>
                        <IconButton
                            style={{ color: 'black' }}
                            sx="center"
                            onClick={() => {
                                const rowIndex = tableMeta.rowIndex; // Get the index of the row to delete
                                const rowToDelete = liveTags[rowIndex]; // Get the row data from liveTags
        
                                // Update liveTags and deletedTags state
                                const updatedLiveTags = liveTags.filter((_, index) => index !== rowIndex);
                                const updatedDeletedTags = [rowToDelete, ...deletedTags]; // Prepend the row
        
                                setLiveTagsData(updatedLiveTags);
                                setDeletedTagsData(updatedDeletedTags);
        
                                // Update the counts
                                setCounts((prevCounts) => ({
                                    ...prevCounts,
                                    liveTags: updatedLiveTags.length,
                                    deletedTags: updatedDeletedTags.length,
                                }));
        
                                // Update tagsData if the current tab is liveTags
                                if (selectedTab === 0) {
                                    setTagsData(updatedLiveTags);
                                }
                                // Save changes to the backend
                                handleTableSubmit(rowToDelete, 'delete');
                            }}
                        >
                            <DeleteOutlinedIcon style={{ color: 'black', width: '20px', height: '20px' }} />
                        </IconButton>
                    </div>
                ),
            }
        },

    ];

    const archivedColumns = [

        {
            name: 'image_url',
            label: 'Image',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div style={{
                        width: 80,
                        height: 53,
                        backgroundColor: '#F3F4F6',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '0px',
                        border: "1px solid #F3F4F6",
                        overflow: 'hidden',
                        margin: 'auto',
                        textAlign: 'center',
                    }}>
                        {value ? (
                            <img
                                src={value}
                                alt="img"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                        ) : (
                            <LandscapeIcon style={{ color: '#BCC1CA', fontSize: 30 }} />
                        )}
                    </div>
                ),
            }
        },

        {
            name: "product_tag",
            label: "Product Tag",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <EditableProductTagCell
                        value={value}
                        onChange={(newValue) => {
                            handleCellChange(tableMeta.rowIndex, "product_tag", newValue);
                        }}
                    />
                ),
                customHeadRender: (columnMeta) => (
                    <TableCell
                        key={columnMeta.index}
                        style={{ fontWeight: "bold", backgroundColor: "#FAFAFB", textAlign: "center" }}
                    >
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: "center" }
                }),
            }
        },

        {
            name: "tag_category",
            label: "Tag category",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <EditableTagCategoryCell
                        value={value}
                        onChange={(newValue) => {
                            handleCellChange(tableMeta.rowIndex, "tag_category", newValue);
                        }}
                        categoryOptions={categoryData || []} // Pass category data
                    />
                ),
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },

        {
            name: "tag_family",
            label: "Tag family",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <EditableTagFamilyCell
                        value={value}
                        onChange={(newValue) => {
                            handleCellChange(tableMeta.rowIndex, "tag_family", newValue);
                        }}
                        familyOptions={familyData || []} // Pass family data
                    />
                ),
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },

        {
            name: "assign_tag",
            label: "Retrieve",
            options: {
                sort: false,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ font: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta) => (
                    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '15px' }}>
                        <IconButton
                            style={{ color: 'black' }}
                            sx="center"
                            onClick={() => {
                                const rowIndex = tableMeta.rowIndex; // Get the index of the row to retrieve
                                const rowToRetrieve = archivedTags[rowIndex]; // Get the row data from archivedTags
        
                                // Update archivedTags and liveTags state
                                const updatedArchivedTags = archivedTags.filter((_, index) => index !== rowIndex);
                                const updatedLiveTags = [rowToRetrieve, ...liveTags]; // Prepend the row
        
                                setArchivedTagsData(updatedArchivedTags);
                                setLiveTagsData(updatedLiveTags);
        
                                // Update the counts
                                setCounts((prevCounts) => ({
                                    ...prevCounts,
                                    archivedTags: updatedArchivedTags.length,
                                    liveTags: updatedLiveTags.length,
                                }));
        
                                // Update tagsData if the current tab is archivedTags
                                if (selectedTab === 1) {
                                    setTagsData(updatedArchivedTags);
                                }

                                handleTableSubmit(rowToRetrieve, 'retrieve');
                            }}
                        >
                            <UnarchiveOutlinedIcon style={{ color: 'black', width: '20px', height: '20px' }} />
                        </IconButton>
                    </div>
                ),
            }
        },

    ];

    const deletedColumns = [

        {
            name: 'image_url',
            label: 'Image',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div style={{
                        width: 80,
                        height: 53,
                        backgroundColor: '#F3F4F6',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '0px',
                        border: "1px solid #F3F4F6",
                        overflow: 'hidden',
                        margin: 'auto',
                        textAlign: 'center',
                    }}>
                        {value ? (
                            <img
                                src={value}
                                alt="img"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                        ) : (
                            <LandscapeIcon style={{ color: '#BCC1CA', fontSize: 30 }} />
                        )}
                    </div>
                ),
            }
        },

        {
            name: "product_tag",
            label: "Product Tag",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <EditableProductTagCell
                        value={value}
                        onChange={(newValue) => {
                            handleCellChange(tableMeta.rowIndex, "product_tag", newValue);
                        }}
                    />
                ),
                customHeadRender: (columnMeta) => (
                    <TableCell
                        key={columnMeta.index}
                        style={{ fontWeight: "bold", backgroundColor: "#FAFAFB", textAlign: "center" }}
                    >
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: "center" }
                }),
            }
        },

        {
            name: "tag_category",
            label: "Tag category",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <EditableTagCategoryCell
                        value={value}
                        onChange={(newValue) => {
                            handleCellChange(tableMeta.rowIndex, "tag_category", newValue);
                        }}
                        categoryOptions={categoryData || []} // Pass category data
                    />
                ),
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },

        {
            name: "tag_family",
            label: "Tag family",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <EditableTagFamilyCell
                        value={value}
                        onChange={(newValue) => {
                            handleCellChange(tableMeta.rowIndex, "tag_family", newValue);
                        }}
                        familyOptions={familyData || []} // Pass family data
                    />
                ),
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },

        {
            name: "assign_tag",
            label: "Delete date",
            options: {
                sort: false,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },

    ];

    const columns = selectedTab === 0 ? liveColumns : selectedTab === 1 ? archivedColumns : deletedColumns;

    const activeOptions = {
        elevation: 0,
        filterType: 'checkbox',
        responsive: 'standard',
        tableBodyHeight: 'calc(100vh - 64px - 48px)',
        tableBodyMaxHeight: 'calc(100vh - 64px - 48px)',
        print: false,        // Disable print
        download: false,     // Disable download
        columns: false,      // Disable column visibility
        viewColumns: false,  // Disable view columns
        filter: false,
        search: false,
        textLabels: {
            body: {
                noMatch: loading ? "Loading..." : "Sorry, no matching records found"
            }
        },
        selectableRows: "multiple",
        
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
            if (action === 'rowsSelect') {
                const selectedIndexes = state.selectedRows.data.map(row => row.dataIndex);
                const selectedTags = selectedIndexes.map(index => liveTags[index]?.product_tag || '');
                setSelectedRows(selectedTags);
                console.log("Row selection changed:", selectedTags);
            }
        }
       
    };

    const archiveOptions = {
        elevation: 0,
        filterType: 'checkbox',
        responsive: 'standard',
        tableBodyHeight: 'calc(100vh - 64px - 48px)',
        tableBodyMaxHeight: 'calc(100vh - 64px - 48px)',
        textLabels: {
            body: {
                noMatch: loading ? "Loading..." : "Sorry, no matching records found"
            }
        },
        selectableRows: "multiple",
        
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
            if (action === 'rowsSelect') {
                const selectedIndexes = state.selectedRows.data.map(row => row.dataIndex);
                console.log(selectedIndexes);
                console.log(archivedTags);
                
                
                const selectedTags = selectedIndexes.map(index => archivedTags[index]?.product_tag || '');
                setSelectedRows(selectedTags);
                console.log("Row selection changed:", selectedTags);
            }
        }
        
    };

    const deleteOptions = {
        elevation: 0,
        filterType: 'checkbox',
        responsive: 'standard',
        tableBodyHeight: 'calc(100vh - 64px - 48px)',
        tableBodyMaxHeight: 'calc(100vh - 64px - 48px)',
        textLabels: {
            body: {
                noMatch: loading ? "Loading..." : "Sorry, no matching records found"
            }
        },
        selectableRows: "multiple",
        
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
            if (action === 'rowsSelect') {
                const selectedIndexes = state.selectedRows.data.map(row => row.dataIndex);
                const selectedTags = selectedIndexes.map(index => deletedTags[index]?.product_tag || '');
                setSelectedRows(selectedTags);
                console.log("Row selection changed:", selectedTags);
            }
        }
       
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    
        // Update tagsData based on the selected tab
        if (newValue === 0) {
            setTagsData([...liveTags]); // Use a copy of liveTags
            setTagsOption(activeOptions); // Set options for live tags
        } else if (newValue === 1) {
            setTagsData([...archivedTags]); // Use a copy of archivedTags
            setTagsOption(archiveOptions); // Set options for archived tags
        } else if (newValue === 2) {
            setTagsData([...deletedTags]); // Use a copy of deletedTags
            setTagsOption(deleteOptions); // Set options for deleted tags
        }
    };

    const handleCellChange = (rowIndex, columnName, newValue) => {
        setTagsData((prevTagsData) => {
            const updatedTags = [...prevTagsData];
            updatedTags[rowIndex][columnName] = newValue; // Update the specific cell
            return updatedTags;
        });
    
        // Update liveTags, archivedTags, or deletedTags if needed
        if (selectedTab === 0) {
            setLiveTagsData((prevLiveTags) => {
                const updatedLiveTags = [...prevLiveTags];
                updatedLiveTags[rowIndex][columnName] = newValue;
                return updatedLiveTags;
            });
        } else if (selectedTab === 1) {
            setArchivedTagsData((prevArchivedTags) => {
                const updatedArchivedTags = [...prevArchivedTags];
                updatedArchivedTags[rowIndex][columnName] = newValue;
                return updatedArchivedTags;
            });
        } else if (selectedTab === 2) {
            setDeletedTagsData((prevDeletedTags) => {
                const updatedDeletedTags = [...prevDeletedTags];
                updatedDeletedTags[rowIndex][columnName] = newValue;
                return updatedDeletedTags;
            });
        }

        const updatedRow = {
            ...tagsData[rowIndex],
            [columnName]: newValue,
        };
    
        // Call handleTableSubmit with the updated row data
        handleTableSubmit(updatedRow);
    };

    // Filtered data based on searchQuery
    const filteredData = useMemo(() => {
        if (!Array.isArray(tagsData)) return [];
        if (!searchQuery) return tagsData;
    
        return tagsData
            .filter(row => row) // Exclude null or undefined rows
            .filter(row =>
                Object.values(row || {}).some(value =>
                    typeof value === "object"
                        ? Object.values(value || {}).some(subValue =>
                            subValue?.toString().toLowerCase().includes(searchQuery.toLowerCase())
                        )
                            : value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
                )
            );
    }, [tagsData, searchQuery]);    

    /* const handleToggleRow = (tag) => {
        const updatedRows = props.selectedRows.includes(tag)
            ? props.selectedRows.filter(row => row !== tag) // Remove if unchecked
            : [...props.selectedRows, tag]; // Add if checked
        props.setSelectedRows(updatedRows); // Update the state
    }; */
    
    return (
        <Box sx={{ width: '95%', paddingLeft: 3, mb: 5 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>                
                <Box sx={{ width: '100%', height: '100%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 0, mb: 2 }}>
                        <Typography variant="body1" sx={{ fontFamily: "Inter", fontWeight: 700, fontSize: 16 }}>Tag Master</Typography>
                    </Box>
                    
                    {/* Create Tag Section */}
                    <CreateTag />

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', mt: 4, mb: 2, gap: 2 }}>
                        {/* Tabs Section */}
                        <Box sx={{ height: '36px' }}>
                            <Tabs
                                value={selectedTab}
                                onChange={handleTabChange}
                                variant="fullWidth"
                                sx={{
                                    minHeight: '35px',
                                    border: '1px solid #F3F4F6',
                                    borderRadius: '0px',
                                    '& .MuiTabs-flexContainer': {
                                        height: '35px',
                                    },
                                    '& .MuiTab-root': {
                                        minHeight: '35px',
                                        width: { xs: '120px', sm: '144px' },
                                        borderRight: '1px solid #F3F4F6',
                                        textTransform: 'none',
                                        color: '#333',
                                    },
                                    '& .MuiTab-root:last-of-type': {
                                        borderRight: 'none',
                                    },
                                    '& .Mui-selected': {
                                        color: '#000 !important',
                                    },
                                    '& .MuiTabs-indicator': {
                                        height: '3px !important',
                                        backgroundColor: '#9095A0 !important',
                                    },
                                }}
                            >
                                <Tab label={`Live Tags (${counts.liveTags})`} />
                                <Tab label={`Archived (${counts.archivedTags})`} />
                                <Tab label={`Deleted (${counts.deletedTags})`} />
                            </Tabs>
                        </Box>
                        {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, ml: 'auto' }}>
                            <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 12 }}>
                                View 
                            </Typography>
                        </Box> */}
                    </Box>

                    <Box sx={{ flex: 1, display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', padding: 2, mb: 2, gap: 4, borderTop: "1px solid #BCC1CA" }}>
                        {/* Search Box */}
                        <TextField
                            sx={{
                                width: { xs: "100%", sm: "304px" },
                                "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px" }
                            }}
                            size="small"
                            variant="outlined"
                            placeholder="Search by tag, category, family"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <IconButton position="start" sx={{ ml: "-10px", mr: 1, color: "black" }}>
                                        <SearchIcon />
                                    </IconButton>
                                )
                            }}
                        />
                        {/* Filters */}
                        {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap' }}>
                            
                            <Select
                                size="small"
                                sx={{ width: { xs: "100%", sm: "180px" }, height: "35px", borderRadius: "0px" }}
                                value={sortByFamily}
                                onChange={(e) => setSortByFamily(e.target.value)}
                            >
                                <MenuItem value="Tag1">Sort by Family</MenuItem>
                                <MenuItem value="Tag2">Tag2</MenuItem>
                                <MenuItem value="Tag3">Tag3</MenuItem>
                            </Select>
                            
                            <Select
                                size="small"
                                sx={{ width: { xs: "100%", sm: "180px" }, height: "35px", borderRadius: "0px" }}
                            >
                                <MenuItem value="Category1">Filter by Family</MenuItem>
                                <MenuItem value="Category2">Category2</MenuItem>
                                <MenuItem value="Category3">Category3</MenuItem>
                            </Select>
                            
                            <Select
                                size="small"
                                sx={{ width: { xs: "100%", sm: "180px" }, height: "35px", borderRadius: "0px" }}
                            >
                                <MenuItem value="Family1">Family</MenuItem>
                                <MenuItem value="Family2">Family2</MenuItem>
                                <MenuItem value="Family3">Family3</MenuItem>
                            </Select>
                        </Box> */}
                    </Box>

                    {/* Data Table */}
                    <Box sx={{ border: '2px solid #F4F3F6', '& div[class*="MUIDataTableToolbar"]': { display: 'none' } }}>
                        <MUIDataTable
                            title={''}
                            data={loading ? [] : filteredData}
                            columns={columns}
                            options={tagsOption}
                        />
                    </Box>
                    
                    {/* Batch Action Collapsible */}
                    <CreateBatch selectedRows={selectedRows} />

                </Box>
            </Grid>
        </Box>
    );
};


