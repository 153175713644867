import { React, useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import { getData, postData } from "../../ServerRequest";
import MUIDataTable from "mui-datatables";
import { Grid, Container, Typography, Button, Box, TableCell } from '@mui/material';
import { Add, ProductionQuantityLimits } from '@mui/icons-material'; 

export default function Wishlist() {
    const [wishlistsData, setWishlistsData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchWishlistsData() {
            let resData = await getData('/api/buyer/wishlist/');
            console.log(resData);
            let wishlists_data = resData['data']['wishlist']
            if (wishlists_data) {
                console.log(wishlists_data);
                if (wishlists_data) {
                    setWishlistsData(wishlists_data);
                }
            } else {
                console.error("Error fetching wishlists data:", resData.message);
            }
            setLoading(false);
        }

        fetchWishlistsData();
    }, []); 

    const formatDateTime = (utcDateTime) => {
        const date = new Date(utcDateTime);

        // Convert to IST (UTC+5:30)
        const istDate = new Date(date.getTime() + 5.5 * 60 * 60 * 1000);

        // Format date and time
        const datePart = istDate.toLocaleDateString("en-IN", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
        const timePart = istDate.toLocaleTimeString("en-IN", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        });

        return { date: datePart, time: timePart };
    };

    const columns = [
    {
        name: "id",
        label: "Wishlist ID",
        options: {
            filter: true,
            sort: true,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#FAFAFB' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
    {
        name: "product",
        label: "Product ID",
        options: {
            filter: true,
            sort: false,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#FAFAFB' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
    {
        name: "buyer",
        label: "Buyer ID",
        options: {
            filter: true,
            sort: false,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#FAFAFB' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
    {
        name: "phone_number",
        label: "Customer Phone No",
        options: {
            filter: true,
            sort: false,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#FAFAFB' }}>
                    {columnMeta.label}
                </TableCell>
            ),
            customBodyRender: (value) => {
                return <span>{value}</span>;
            }
        }
    },
    {
        name: "added_at",
        label: "Created Date",
        options: {
            filter: true,
            sort: false,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#FAFAFB' }}>
                    {columnMeta.label}
                </TableCell>
            ),
            customBodyRender: (value) => {
                if (!value) return "";
                const { date, time } = formatDateTime(value);
                return (
                    <span>
                        <strong>Date :</strong> {date} <br />
                        <strong>Time :</strong> {time}
                    </span>
                );        
            },
        },
    },
];

    const options = {
        elevation: 0,
        filterType: 'checkbox',
        responsive: 'standard',
        tableBodyHeight: 'calc(100vh - 64px - 48px)', 
        tableBodyMaxHeight: 'calc(100vh - 64px - 48px)',
        print: false,        // Disable print
        download: false,     // Disable download
        columns: false,      // Disable column visibility
        viewColumns: false,  // Disable view columns
        filter: false,
        search: false,
    };

    return (
        <Box sx={{ width: '95%', paddingLeft: 3, mb: 5 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%' }}>
                    <Box sx={{ border: '2px solid #F4F3F6' }}>
                        <MUIDataTable
                            title={<Typography variant="body1" sx={{ fontFamily: "Inter", fontWeight: 700, fontSize: 16 }}>
                                       Wishlist 
                                   </Typography>}
                            data={loading ? [] : wishlistsData}
                            columns={columns}
                            options={options}
                        />
                    </Box>
                </Box>
            </Grid>
        </Box>
    );
};
