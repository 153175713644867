import React, { useState, useEffect } from 'react';
import {
    Box, Grid, Button, IconButton, Typography, TextField, Select,
    MenuItem, Accordion, AccordionSummary, AccordionDetails, FormGroup, FormControlLabel, Checkbox
} from '@mui/material';
import { getData, postData } from "../../../ServerRequest";
import { DataGrid } from '@mui/x-data-grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import LandscapeIcon from '@mui/icons-material/Landscape';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export default function CreateBatch(props) {
    const searchParams = new URLSearchParams(window.location.search);
    const productId = searchParams.get('product_id');
    const [tagsData, setTagsData] = useState([]);
    const [liveTags, setLiveTagsData] = useState([]);
    const [archivedTags, setArchivedTagsData] = useState([]);
    const [deletedTags, setDeletedTagsData] = useState([]);
    const [familyData, setFamilyData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [counts, setCounts] = useState({
        liveTags: 0,
        archivedTags: 0,
        deletedTags: 0,
    });
    const [selectedBatchCategory, setSelectedBatchCategory] = useState("");
    const [isCreatingBatchCategory, setIsCreatingBatchCategory] = useState(false);
    const [newBatchCategory, setNewBatchCategory] = useState("");
    const [selectedBatchFamily, setSelectedBatchFamily] = useState("");
    const [isCreatingBatchFamily, setIsCreatingBatchFamily] = useState(false);
    const [newBatchFamily, setNewBatchFamily] = useState("");
    const [batchVisibility, setBatchVisibility] = useState({});
    const [batchImageUrl, setBatchImageUrl] = useState(null);
    const [batchAction, setBatchAction] = useState('');
    const [batchTagName, setBatchTagName] = useState('');
    const [batchImage, setBatchImage] = useState('');
    const [batchCreatedBy, setBatchCreatedBy] = useState(null);
    const [batchUpdatedBy, setBatchUpdatedBy] = useState(null);
    const [batchStatus, setBatchStatus] = useState([]);

    const handleBatchSubmit = async () => {
        if (!batchAction) {
            alert("Please select a batch action.");
            return;
        }
    
        if (props.selectedRows.length === 0) {
            alert("No rows selected for batch action.");
            return;
        }
    
        setLoading(true);
    
        const batchPayload = {
            'selected_rows': props.selectedRows ? props.selectedRows : '',
            'batch_action': batchAction ? batchAction : '',
            'batch_tag_name': batchTagName ? batchTagName : '',
            'batch_category': selectedBatchCategory ? selectedBatchCategory : '',
            'batch_family': selectedBatchFamily ? selectedBatchFamily : '',
            'batch_image_url': batchImageUrl ? batchImageUrl : '',
            'batch_created_by': batchCreatedBy ? batchCreatedBy : '',
            'batch_updated_by': batchUpdatedBy ? batchUpdatedBy : '',
            'batch_status': batchStatus ? batchStatus : 1,
        };
    
        try {
            const response = await postData('/api/product/acc_tag_master', batchPayload);
            if (response.data.code === '200') {
                alert("Batch action applied successfully.");
                // Refresh data after successful batch action
                const updatedData = await getData('/api/product/tags');
                setTagsData(updatedData.data.tags);
                props.onBatchActionComplete(); // Notify parent component if needed
            } else {
                alert("Failed to apply batch action.");
            }
        } catch (error) {
            console.error("Error applying batch action:", error);
            alert("An error occurred while applying the batch action.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        async function fetchData() {
            try {
                let resData = await getData('/api/product/tags');
                console.log(resData);
                let tags_data = resData['data']['tags']
                if (tags_data) {
                    console.log(tags_data);
                    setTagsData(tags_data);
                } else {
                    console.error("Error fetching tags data:", resData.message);
                }

                let familyRes = await getData('/api/product/acc_tag_family');
                console.log(familyRes);
                let family_data = familyRes['data']['tag_families']
                if (family_data) {
                    console.log(family_data);
                    setFamilyData(family_data);
                } else {
                    console.error("Error fetching tags family data:", familyRes.message);
                }

                let categoryRes = await getData('/api/product/acc_tag_category');
                console.log(categoryRes);
                let category_data = categoryRes['data']['tag_categories']
                if (category_data) {
                    console.log(category_data);
                    setCategoryData(category_data);
                } else {
                    console.error("Error fetching tags category data:", categoryRes.message);
                }

                let masterRes = await getData('/api/product/acc_tag_master');
                console.log(categoryRes);
                let master_data = masterRes['data']['tag_masters']
                let live_tags = masterRes['data']['live_tags']
                let archived_tags = masterRes['data']['archived_tags']
                let deleted_tags = masterRes['data']['deleted_tags']
                if (master_data) {
                    console.log(master_data);
                    setTagsData(live_tags);
                    setLiveTagsData(live_tags);
                    setArchivedTagsData(archived_tags);
                    setDeletedTagsData(deleted_tags);
                    setCounts({
                        liveTags: masterRes['data']['live_tags_count'],
                        archivedTags: masterRes['data']['archived_tags_count'],
                        deletedTags: masterRes['data']['deleted_tags_count'],
                    });
                } else {
                    console.error("Error fetching tag master data:", masterRes.message);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        }

        fetchData();

    }, []);

    const mergeTags = (selectedRows, batchCollectionName, batchCategory, batchFamily) => {
        // Validate inputs
        if (!batchCollectionName || !batchCategory || !batchFamily) {
            alert("Please provide a name, promotional name, and marketing keywords for the merged collection.");
            return null;
        }
    
        // Extract tags from selectedRows
        const rowTags = selectedRows.flatMap((row) => row.tags || []); // Extract tags from selectedRows
    
        // Combine all tags
        const combinedTags = [...rowTags];
    
        // Remove duplicates
        const uniqueTags = [...new Set(combinedTags)];
    
        // Create the merged collection object
        const mergedTags = {
            'name': batchCollectionName, // New collection name
            'batchCategory': batchCategory, // New promotional name
            'batchFamily': batchFamily, // New marketing keywords
            'tags': uniqueTags, // Merged tags
        };
    
        console.log("Merged Collection:", mergedTags);
        return mergedTags;
    };

    const handleBatchActionChange = (event) => {
        setBatchAction(event.target.value);
    };

    const handleBatchTagNameChange = (event) => {
        setBatchTagName(event.target.value);
    };

    const handleCreateNewBatchCategory = () => {
        setIsCreatingBatchCategory(true);
    };

    const handleCreateNewBatchFamily = () => {
        setIsCreatingBatchFamily(true);
    };

    const handleBatchCategoryChange = (event) => {
        setSelectedBatchCategory(event.target.value);
    };


    const handleBatchFamilyChange = (event) => {
        setNewBatchFamily(event.target.value);
    };

    const handleBatchCategorySubmit = (e) => {
        if (e.key === "Enter" && newBatchCategory.trim() !== "") {
            const newBatchCategoryObj = {
                id: categoryData.length + 1, // Simple ID assignment
                category: newBatchCategory.trim(),
            };

            setCategoryData([...categoryData, newBatchCategoryObj]); // Add new category to list
            setSelectedBatchCategory(newBatchCategoryObj.id); // Select the new category
            setIsCreatingBatchCategory(false); // Close input field
        } else if (e.key === "Escape") {
            setIsCreatingBatchCategory(false); // Cancel category creation on Escape key
        }
    };

    const handleBatchFamilySubmit = (e) => {
        if ((e.type === "keydown" && e.key === "Enter") || e.type === "click") {
            if (newBatchFamily.trim() !== "") {
                const newBatchFamilyObj = {
                    id: familyData.length + 1,
                    tag_family: newBatchFamily.trim(),
                };

                setFamilyData([...familyData, newBatchFamilyObj]); // Add new family
                setSelectedBatchFamily(newBatchFamilyObj.id); // Select new family
                setIsCreatingBatchFamily(false); // Hide input field
                setNewBatchFamily(""); // Reset input
            }
        }
    };

    const handleBatchImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const batchImg = new Image();
            const batchImageUrl = URL.createObjectURL(file);
            batchImg.src = batchImageUrl;
            batchImg.onload = () => {
                if (batchImg.width === 120 && batchImg.height === 60) {
                    setBatchImage((prevState) => ({
                        ...prevState,
                        batch_image: file, // Store the file object
                        batchImageUrl: batchImageUrl // Store the object URL for preview
                    }));
                    console.log("Batch image uploaded successfully:", file);
                    // You can use tagImageUrl for preview if needed
                } else {
                    alert('Image must be exactly 120px wide and 60px tall.');
                }
                URL.revokeObjectURL(batchImageUrl); // Free up memory
            };
            batchImg.onerror = () => {
                alert('Failed to load image. Please try again.');
                URL.revokeObjectURL(batchImageUrl); // Free up memory
            };
        }
    };

    const toggleBatchVisibility = (tag) => {
        setBatchVisibility((prev) => ({ ...prev, [tag]: !prev[tag] }));
    };

    return (
        <Box sx={{ width: '100%', mt: 2, mb: 5 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%' }}>

                    <Accordion selectedRows={props.selectedRows} sx={{ backgroundColor: '#F8F9FA', mt: 2, borderRadius: 0, boxShadow: 0 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ p: { xs: 2, sm: 4 }, pt: 0, pb: 0, gap: { xs: 1, sm: 2 }, height: { xs: 48, sm: 58 }, alignItems: 'center', justifyContent: 'space-between', display: "flex" }}>
                            <Typography variant="body1" sx={{ fontFamily: "Inter", fontWeight: "700", fontSize: 16 }}>Create batch actions of tags</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 4, pt: 0, alignItems: 'center', justifyContent: 'space-between' }}>
                            <Grid container spacing={2} sx={{ mb: 1 }}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Typography variant="body1" sx={{ color: "#171A1F", fontFamily: "Inter", fontWeight: 700, fontSize: 14, mt: 1 }}>
                                        Tag selected = {props.selectedRows.length}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormGroup row sx={{ display: 'flex-row' }}>
                                        {props.selectedRows.map(tag => (
                                            <Box key={tag} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                                <Checkbox
                                                    checked={true} // Initially checked since it's a selected row
                                                    onChange={() => props.onToggleRow(tag)} // Call a handler to toggle selection
                                                    sx={{ padding: 0, marginRight: 1 }}
                                                />
                                                <Typography key={tag} variant="body2" sx={{ fontFamily: "Inter", fontWeight: 700, fontSize: 14, mt: 1 }}>
                                                    {tag} &nbsp;&nbsp;
                                                </Typography>
                                            </Box>    
                                        ))}
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                {/* Batch Action */}
                                <Grid item xs={12} sm={6} md={3}>
                                    <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}> Batch Action </Typography>
                                    <Select size="small" value={batchAction} onChange={handleBatchActionChange} sx={{ width: "193px", height: "35px", borderRadius: "0px", backgroundColor: "#fff" }}>
                                        <MenuItem value="Merge">Merge</MenuItem>
                                        <MenuItem value="Delete">Delete</MenuItem>
                                        <MenuItem value="Archived">Archived</MenuItem>
                                        <MenuItem value="Remove assigned">Remove assigned</MenuItem>
                                    </Select>
                                </Grid>

                                {/* Define Name*/}
                                <Grid item xs={12} sm={6} md={3}>
                                    <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}> Define name </Typography>
                                    <TextField size="small"
                                        placeholder="Create a new name"
                                        sx={{ width: "193px", "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px", backgroundColor: '#fff' } }}
                                        value={batchTagName} onChange={handleBatchTagNameChange}
                                    />
                                </Grid>

                                {/* Select Category */}
                                <Grid item xs={12} sm={6} md={3}>
                                    <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}> Select Category </Typography>
                                    {isCreatingBatchCategory ? (
                                        <TextField
                                            size="small"
                                            sx={{ minWidth: "193px", fontSize: 14, "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px", backgroundColor: '#fff' } }}
                                            value={newBatchCategory}
                                            onChange={handleBatchCategoryChange}
                                            onKeyDown={handleBatchCategorySubmit}
                                            autoFocus
                                            placeholder="Enter new category"
                                        />
                                    ) : (
                                        <Select size="small" sx={{ minWidth: "193px", height: "35px", borderRadius: "0px", backgroundColor: '#fff' }} value={selectedBatchCategory} onChange={(event) => setSelectedBatchCategory(event.target.value)}>
                                            <MenuItem onClick={handleCreateNewBatchCategory} value="" sx={{ border: "1px solid #bcc1ca", fontSize: 14 }}>
                                                <AddCircleOutlineIcon sx={{ fontSize: 15, mr: 1 }} />
                                                Create a new Category
                                            </MenuItem>
                                            {categoryData.map((category, index) => (
                                                <MenuItem key={category.id} value={category.id} sx={{ fontSize: 14 }}>
                                                    {category.category}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                </Grid>

                                {/* Select Family */}
                                <Grid item xs={12} sm={6} md={3}>
                                    <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}> Select Family </Typography>
                                    {isCreatingBatchFamily ? (
                                        <TextField
                                            size="small"
                                            sx={{ minWidth: "193px", fontSize: 14, "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px", backgroundColor: '#fff' } }}
                                            value={newBatchFamily}
                                            onChange={handleBatchFamilyChange}
                                            onKeyDown={handleBatchFamilySubmit}
                                            autoFocus
                                            placeholder="Enter new family"
                                        />
                                    ) : (
                                        <Select size="small" sx={{ minWidth: "193px", height: "35px", borderRadius: "0px", backgroundColor: '#fff' }} value={selectedBatchFamily} onChange={(event) => setSelectedBatchFamily(event.target.value)}>
                                            <MenuItem onClick={handleCreateNewBatchFamily} value="" sx={{ border: "1px solid #bcc1ca", fontSize: 14 }}>
                                                <AddCircleOutlineIcon sx={{ fontSize: 15, mr: 1 }} />
                                                Create a new Family
                                            </MenuItem>
                                            {familyData.map((family, index) => (
                                                <MenuItem key={family.id} value={family.id} sx={{ fontSize: 14 }}>
                                                    {family.tag_family}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                </Grid>

                                {/* Upload Section */}
                                <Grid item xs={12} sm={6} md={3}>
                                    <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}> Add Image  </Typography>
                                    <Box sx={{ display: 'flex' }}>
                                        <FormGroup column >
                                            {props.selectedRows.map((tag) => (
                                                <div key={`image-${tag}`} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <FormControlLabel
                                                        control={<Checkbox sx={{ transform: 'scale(0.8)' }} />}
                                                        label={tag}
                                                    />
                                                    <IconButton onClick={() => toggleBatchVisibility(tag)}>
                                                        <VisibilityOutlinedIcon
                                                            style={{
                                                                color: [tag] ? 'black' : 'gray',
                                                                width: '20px',
                                                                height: '20px',
                                                            }}
                                                        />
                                                    </IconButton>
                                                </div>
                                            ))}
                                        </FormGroup>
                                    </Box>
                                    <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14, mb: 1 }}> Upload New image </Typography>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        id="upload-button"
                                        onChange={handleBatchImageUpload}
                                    />
                                    <Box
                                        sx={{
                                            mt: 0.5,
                                            width: 120,
                                            height: 60,
                                            border: '1px solid #FAFAFB',
                                            backgroundColor: "#F3F4F6",
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {batchImageUrl ? <img src={batchImageUrl} alt="Uploaded" style={{ width: '100%', height: '100%' }} /> : <LandscapeIcon sx={{ color: "#BCC1CA" }} />}
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 1, width: 120 }}>
                                        <label style={{ fontSize: "13px", color: 'black' }}>
                                            Upload Image (120x60px)
                                        </label>
                                        <IconButton component="label" size="small" sx={{ color: '#000' }}>
                                            <FileUploadOutlinedIcon />
                                            <input type="file" accept="image/*" hidden onChange={handleBatchImageUpload} />
                                        </IconButton>
                                    </Box>
                                </Grid>
                            </Grid>

                            {/* Save Button */}
                            <Box>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        mt: 2,
                                        backgroundColor: "#565E6D",
                                        color: "#fff",
                                        "&:hover": { backgroundColor: "#546e7a" },
                                        borderRadius: "0px",
                                    }}
                                    onClick={handleBatchSubmit} // Updated to call handleBatchSubmit
                                >
                                    Save
                                </Button>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Grid>
        </Box>
    );
};


