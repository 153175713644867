import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, IconButton, TextField, Container, Divider, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { getData, postData } from "../../ServerRequest";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { styled } from '@mui/material/styles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));
function createData(code, reqQty, reqSwtch) {
    return { code, reqQty, reqSwtch };
}

// const rows = [
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];

export default function EnquiryDetail() {
    const searchParams = new URLSearchParams(window.location.search);
    const bookingId = searchParams.get('booking_id');
    const [bookingData, setBookingData] = useState([]);
    const [buyerData, setBuyerData] = useState([]);
    const [bookingProductData, setBookingProductData] = useState([]);
    const [loading, setLoading] = useState(true);

    const filterBookingProductData = (bookingData) => {
        let bookingTableData = [];
        let codes = bookingData.ps_book_codes.split(',');
        let reqOty = bookingData.ps_book_qty.split(',');
        let availQty = bookingData.ps_book_avail_qty.split(',');
        if (bookingData.ps_book_codes) {
            bookingTableData = codes.map((code, index) => {
                return createData(code, reqOty[index], availQty[index]);
            }
            )
        }
        return bookingTableData;
    };

    useEffect(() => {
        async function getBookingData() {
            let resData = await getData('/api/buyer/booking?booking_id=' + bookingId);
            
            let booking_data = resData['data']['bookings'][0];
            if (booking_data) {
                let bookingData = await filterBookingProductData(booking_data);
                
                if (bookingData) {
                    setBookingProductData(bookingData);
                }
            }
            let resBuyerData = await getData('/api/buyer?buyer_id=' + booking_data.ps_book_buyerid);
            
            let buyerDataRes = resBuyerData['data']['buyers'];
            
            
            if (buyerDataRes) {
                setBuyerData(buyerDataRes);
                
                
            }
            setBookingData(booking_data);
            setLoading(false);
        }
        getBookingData();
    }, []);

    return (
        <Box sx={{ width: '90%', paddingLeft: 3, marginLeft: '80px' }}>
            <Box sx={{ display: 'flex', bgcolor: '#eceff1', justifyContent: 'space-between', alignItems: 'center', boxShadow: 3, p: 1 }}>
                {loading ? (
                    <p>Loading....</p>
                ) : (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6" sx={{ ml: 2 }}>Enquiry ID : {bookingData.ps_book_id}</Typography>
                            </Grid>
                            <Divider sx={{ mt: 2 }} />
                            <Grid item xs={4}>
                                <Typography variant="h8" sx={{ ml: 2 }}>Buyer ID :{buyerData.ps_buyer}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h8" sx={{ ml: 2 }}>Buyer Name :{buyerData.ps_buyer_name}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h8" sx={{ ml: 2 }}>Buyer Email :{buyerData.ps_buyer_email}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h8" sx={{ ml: 2 }}>Buyer Phone :{buyerData.ps_buyer_phone}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h8" sx={{ ml: 2 }}>Buyer City :{buyerData.ps_buyer_city}</Typography>
                            </Grid>
                            <Divider sx={{ mt: 2 }} />
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 550 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Product ID</TableCell>
                                                <TableCell align="center">Requested Qty</TableCell>
                                                <TableCell align="center">Requested Swatch</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {bookingProductData.map((row) => (
                                                <TableRow
                                                    key={row.code}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="center" component="th" scope="row">
                                                        {row.code}
                                                    </TableCell>
                                                    <TableCell align="center">{row.reqQty}</TableCell>
                                                    <TableCell align="center">{row.reqSwtch}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>                        
                    </>
                )}
            </Box>
        </Box>
    );
}
