import React, { useState } from 'react';
import ReactDOM from "react-dom";
import MUIDataTable from "mui-datatables";
import { Grid, Button, IconButton, Card, CardContent, Typography, Paper, Modal, TextField } from '@mui/material';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';
import { DataGrid } from '@mui/x-data-grid';
import CardComponent from '../../components/card/CardComponent';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import UpdateIcon from '@mui/icons-material/Update';

const columns = [
    {
        name: "productName",
        label: "Product Name",
        options: {
            filter: true,
            sort: true,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
    {
        name: "category",
        label: "Category",
        options: {
            filter: true,
            sort: false,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
    {
        name: "lastUpdated",
        label: "Last Updated",
        options: {
            filter: true,
            sort: true,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
    {
        name: "status",
        label: "Status",
        options: {
            filter: true,
            sort: false,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
            customBodyRender: (value) => {
                const color = value === "Active" ? "green" : "red";
                return <span style={{ color }}>{value}</span>;
            }
        }
    },
];

const data = [
    { productName: "Product A", category: "Electronics", lastUpdated: "2024-08-01", status: "Active" },
    { productName: "Product B", category: "Apparel", lastUpdated: "2024-07-20", status: "Inactive" },
    { productName: "Product C", category: "Home Goods", lastUpdated: "2024-06-15", status: "Active" },
    { productName: "Product D", category: "Sports", lastUpdated: "2024-08-10", status: "Active" },
];

function ProductLastUpdated() {
    const [modalOpen, setModalOpen] = useState(false); 
    const [categoryName, setCategoryName] = useState(''); 

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleCategoryNameChange = (event) => {
        setCategoryName(event.target.value);
    };

    const handleFormSubmit = () => {
        console.log("Category Name:", categoryName);
        setModalOpen(false); 
    };

    const options = {
        filterType: 'checkbox',
        customToolbar: () => {
            return (
                <IconButton onClick={() => setModalOpen(true)} sx={{ background: '#f8f9fa', border: '3px solid #b0bec5', color: 'black', fontSize: '0.850rem', '&:hover': { background: '#eceff1' }, mb:0 }}>
                    <UpdateIcon /> 
                </IconButton>
            );
        }
    };

    return (
        <Box sx={{ width: '90%', paddingLeft: 3, marginLeft: '80px' }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%' }}>
                    <MUIDataTable
                        title={"Product Last Updated"}
                        data={data}
                        columns={columns}
                        options={{
                            ...options,
                            responsive: 'standard',
                            tableBodyHeight: 'calc(100vh - 64px - 48px)', 
                            tableBodyMaxHeight: 'calc(100vh - 64px - 48px)', 
                        }}
                    />
                </Box>
            </Grid>
            {/* Modal for updating all products */}
            <Modal open={modalOpen} onClose={handleModalClose} aria-labelledby="create-tag-category" aria-describedby="create-a-new-tag-category">
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 6, borderRadius: 2 }}>
                    <Typography id="create-tag-category" variant="h6" component="h2" sx={{ mb: 3 }}>
                        Update Product
                    </Typography>
                    <TextField label="update date" fullWidth variant="outlined" value={categoryName} onChange={handleCategoryNameChange} sx={{ mb: 3 }} />
                    <Button variant="contained" sx={{ backgroundColor: '#417690', '&:hover': { backgroundColor: '#5A8EA8' } }} style={{ color: '#fff' }} onClick={handleFormSubmit}>
                        Submit
                    </Button>
                </Box>
            </Modal>
        </Box> 
    );
}

export default ProductLastUpdated;
