import React, { useState, useEffect } from 'react';
import {
    Box, Grid, Button, IconButton, Typography, TextField, Select,
    MenuItem, Accordion, AccordionSummary, AccordionDetails, FormGroup, FormControlLabel, Checkbox
} from '@mui/material';
import { Autocomplete } from '@mui/material';
import { postData, getData } from '../../ServerRequest';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import * as XLSX from 'xlsx';

const UploadBulkImage = () => {
    const [productCode, setProductCode] = useState('');
    const [images, setImages] = useState({});
    const [excelName, setExcelName] = useState('productExcel');
    const [productCodeList, setProductCodeList] = useState([]);


    const [productDetails, setProductList] = useState({
        productName: '',
        description: '',
        price: ''
    });
    const [productsData, setProductsData] = useState([]);
    const [productStatus, setProductStatus] = useState('added');
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedTab, setSelectedTab] = useState(0);

    const [pagination, setPagination] = useState({ current_page: 1, per_page: 10, total: 0 });
    const [loading, setLoading] = useState(false);
    const [productCount, setProductCount] = useState(0);
    const [counts, setCounts] = useState({
        addedProductCount: 0,
        liveProductCount: 0,
        archivedProductCount: 0,
        deletedProductCount: 0,
    });



    useEffect(() => {
    }, []);

    const readExcelFile = (file) => {
        console.log(file);
        
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                // const productIds = jsonData.map((row) => row.product_id);

                // Assuming the product IDs are in the first column of the first sheet
                const productIds = jsonData.map((row) => row[Object.keys(row)[0]]); // Adjust this line based on your Excel structure
                setProductCodeList(productIds);
                resolve(productIds);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsArrayBuffer(file);
        });
    };


    const handleProductCodeChange = (event, newValue) => {
        setProductCode(newValue ? newValue.code : '');
    };

    const handleImageChange = (e) => {
        const { name, files } = e.target;
        setImages((prevImages) => ({
            ...prevImages,
            [name]: files[0],
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        console.log(productCodeList);
        
        const reqData = {
            product_ids: productCodeList
        };
        try {
            const response = await postData('/api/product/bulk-upload-fabric-image', reqData);
            if (response.data.code === '200') {

                alert("Images uploaded successfully");
                setLoading(false);
                window.location.reload();
            }
            else {
                alert("Error uploading images");
                setLoading(false);
                window.location.reload();
            }
        } catch (error) {
            alert("Error uploading images");
            setLoading(false);
            window.location.reload();
            console.error('Error uploading images:', error);
        }
    };

    return (
        <Box sx={{ width: '95%', paddingLeft: 3, mb: 5 }}>
            {loading ? <Typography>Loading...</Typography> : <>
                <Typography variant="h4" sx={{ marginBottom: 2 }}>Import Excel</Typography>
                <form onSubmit={handleSubmit}>
                    <div>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    mt: 1,
                                    width: 120,
                                }}
                            >
                                <input
                                    type="file"
                                    id={excelName}
                                    name={excelName}
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (file) {
                                            readExcelFile(file)
                                                .then(() => alert("Excel file processed successfully"))
                                                .catch((error) => alert("Error processing Excel file: " + error.message));
                                        }
                                    }}
                                />
                            </Box>
                        </Grid>
                        <br />
                        <br />
                    </div>
                    <Button type="submit" variant="contained" color="primary">
                        Submit
                    </Button>
                </form>
            </>}
        </Box>
    );
};

export default UploadBulkImage;