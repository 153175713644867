import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import MUIDataTable from 'mui-datatables';
import { Box, Container, Grid, Button, Typography, TextField, IconButton, TableCell, Pagination, Select, Tabs, Tab,  } from '@mui/material';
import { getData, postData } from "../../../ServerRequest";
import SearchIcon from '@mui/icons-material/Search';
import LandscapeIcon from '@mui/icons-material/Landscape';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';

export default function CollectionItems (){  
    const searchParams = new URLSearchParams(window.location.search);
    const collectionId = searchParams.get('collection_data_id'); 
    const [collectionsData, setCollectionsData] = useState([]);
    const [collectionsOption, setCollectionsOption] = useState();
    const [liveCollections, setLiveCollectionsData] = useState([]);
    const [archivedCollections, setArchivedCollectionsData] = useState([]);
    const [deletedCollections, setDeletedCollectionsData] = useState([]);
    
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedTab, setSelectedTab] = useState(0);

    const [tagsData, setTagsData] = useState([]);
    const [liveTags, setLiveTagsData] = useState([]);
    const [archivedTags, setArchivedTagsData] = useState([]);
    const [deletedTags, setDeletedTagsData] = useState([]);
    const [counts, setCounts] = useState({
            liveTags: 0,
            archivedTags: 0,
            deletedTags: 0,
        });
    const [selectedRows, setSelectedRows] = useState([]);    

    const navigate = useNavigate();    

    useEffect(() => {
        async function fetchData() {
            let resData = await getData('/api/product/product-collection-data', { collection_data_id: collectionId });
            console.log(resData);
            let collections_data = resData['data']['collection_data_data']
            let live_collections = resData['data']['live_collections']
            let archived_collections = resData['data']['archived_collections']
            let deleted_collections = resData['data']['deleted_collections']
            if (collections_data) {
                console.log(collections_data);
                if (collections_data) {
                    setCollectionsData(collections_data);
                    setLiveCollectionsData(live_collections);
                    setArchivedCollectionsData(archived_collections);
                    setDeletedCollectionsData(deleted_collections);
                    setCounts({
                        liveCollections: resData['data']['live_collections_count'],
                        archivedCollections: resData['data']['archived_collections_count'],
                        deletedCollections: resData['data']['deleted_collections_count'],
                    });
                }
            } else {
                console.error("Error fetching collections data:", resData.message);
            }

            let masterRes = await getData('/api/product/acc_tag_master');
            console.log(masterRes);
            let master_data = masterRes['data']['tag_masters']
            
            if (master_data.length) {
                setTagsData(master_data);
            } else {
                console.error("Error fetching tag master data:", masterRes.message);
            }

            // Map tag_id to product_tag name
            const tagMap = master_data.reduce((acc, tag) => {
                acc[tag.tag_id] = tag.product_tag;
                return acc;
            }, {});

            // Add a new `product_tags` field to collections_data
            const updatedCollections = collections_data.map(collection => ({
                ...collection,
                product_tags: collection.tag_ids?.map(id => tagMap[id]) || [], // Convert tag IDs to names
            }));

            setCollectionsData(updatedCollections);
            setLoading(false);
        }

        fetchData();
        setCollectionsOption(selectedTab === 0 ? activeOptions : selectedTab === 1 ? archiveOptions : deleteOptions);

    }, []);     

    const handleArchiveCollection = (rowIndex) => {
        const rowToArchive = liveCollections[rowIndex];
        const updatedLiveCollections = liveCollections.filter((_, index) => index !== rowIndex);
        const updatedArchivedCollections = [rowToArchive, ...archivedCollections];

        setLiveCollectionsData(updatedLiveCollections);
        setArchivedCollectionsData(updatedArchivedCollections);

        setCounts((prevCounts) => ({
            ...prevCounts,
            liveCollections: updatedLiveCollections.length,
            archivedCollections: updatedArchivedCollections.length,
        }));

        if (selectedTab === 0) {
            setCollectionsData(updatedLiveCollections);
        }
    };

    const handleDeleteCollection = (rowIndex) => {
        const rowToDelete = liveCollections[rowIndex];
        const updatedLiveCollections = liveCollections.filter((_, index) => index !== rowIndex);
        const updatedDeletedCollections = [rowToDelete, ...deletedCollections];

        setLiveCollectionsData(updatedLiveCollections);
        setDeletedCollectionsData(updatedDeletedCollections);

        setCounts((prevCounts) => ({
            ...prevCounts,
            liveCollections: updatedLiveCollections.length,
            deletedCollections: updatedDeletedCollections.length,
        }));

        if (selectedTab === 0) {
            setCollectionsData(updatedLiveCollections);
        }
    };

    const handleRetrieveCollection = (rowIndex) => {
        const rowToRetrieve = archivedCollections[rowIndex];
        const updatedArchivedCollections = archivedCollections.filter((_, index) => index !== rowIndex);
        const updatedLiveCollections = [rowToRetrieve, ...liveCollections];

        setArchivedCollectionsData(updatedArchivedCollections);
        setLiveCollectionsData(updatedLiveCollections);

        setCounts((prevCounts) => ({
            ...prevCounts,
            archivedCollections: updatedArchivedCollections.length,
            liveCollections: updatedLiveCollections.length,
        }));

        if (selectedTab === 1) {
            setCollectionsData(updatedArchivedCollections);
        }
    };

    const liveColumns = [
        {
            name: 'image_url',
            label: 'Image',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div style={{
                        width: 80,
                        height: 53,
                        backgroundColor: '#F3F4F6',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '0px',
                        border: "1px solid #F3F4F6",
                        overflow: 'hidden',
                        textAlign: 'center',
                        margin: 'auto', 
                    }}>
                        {value ? (
                            <img
                                src={value}
                                alt="img"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                        ) : (
                            <LandscapeIcon style={{ color: '#BCC1CA', fontSize: 30 }} />
                        )}
                    </div>
                ),
            }
        },        

        {
            name: 'collection',
            label: 'Product Code',
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center', fontWeight: 700 }
                }),
            }
        },

        {
            name: 'promotional_name',
            label: 'Product name',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },

        {
            name: 'collection.length',
            label: 'Stock in Mtr',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },

        {
            name: 'product_tag',
            label: 'Tags',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
                customBodyRender: (value) => {
                    if (Array.isArray(value) && value.length > 0) {
                        return (
                            <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap', justifyContent: 'center' }}>
                                {value.map((tag, index) => (
                                    <span key={index} style={{
                                        backgroundColor: '#E0F7FA',
                                        padding: '4px 8px',
                                        borderRadius: '4px',
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        whiteSpace: 'nowrap'
                                    }}>
                                        {tag.product_tag}
                                    </span>
                                ))}
                            </div>
                        );
                    }
                    return 'N/A';
                },                
            }
        },        

        {
            name: "edit",
            label: "Action",
            options: {
                sort: false,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta) => (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <IconButton
                            onClick={() => {
                                const collectionId = tableMeta.rowData[1];
                                navigate(`/admin/products/collectionMaster/CollectionDetail?collection_id=${collectionId}`);
                            }}
                        >
                            <EditOutlinedIcon style={{ color: 'black', width: '20px', height: '20px' }} />
                        </IconButton>
                        <IconButton
                            onClick={() => handleArchiveCollection(tableMeta.rowIndex)}
                        >
                            <ArchiveOutlinedIcon style={{ color: 'black', width: '20px', height: '20px' }} />
                        </IconButton>
                        <IconButton
                            onClick={() => handleDeleteCollection(tableMeta.rowIndex)}
                        >
                            <DeleteOutlinedIcon style={{ color: 'black', width: '20px', height: '20px' }} />
                        </IconButton>
                    </div>
                ),
            }
        },
    ];

    const archivedColumns = [
        {
            name: 'image_url',
            label: 'Image',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div style={{
                        width: 80,
                        height: 53,
                        backgroundColor: '#F3F4F6',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '0px',
                        border: "1px solid #F3F4F6",
                        overflow: 'hidden',
                        textAlign: 'center',
                        margin: 'auto', 
                    }}>
                        {value ? (
                            <img
                                src={value}
                                alt="img"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                        ) : (
                            <LandscapeIcon style={{ color: '#BCC1CA', fontSize: 30 }} />
                        )}
                    </div>
                ),
            }
        },        
        
        {
            name: 'collection',
            label: 'Product Code',
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center', fontWeight: 700 }
                }),
            }
        },

        {
            name: 'promotional_name',
            label: 'Promotional Name',
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },

        {
            name: 'stock',
            label: 'No of items',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },

        {
            name: 'product_tag',
            label: 'Tags',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
                customBodyRender: (value) => {
                    if (Array.isArray(value) && value.length > 0) {
                        return (
                            <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap', justifyContent: 'center' }}>
                                {value.map((tag, index) => (
                                    <span key={index} style={{
                                        backgroundColor: '#E0F7FA',
                                        padding: '4px 8px',
                                        borderRadius: '4px',
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        whiteSpace: 'nowrap'
                                    }}>
                                        {tag.product_tag}
                                    </span>
                                ))}
                            </div>
                        );
                    }
                    return 'N/A';
                }, 
            }
        },

        {
            name: "assign_tag",
            label: "Retrieve",
            options: {
                sort: false,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta) => (
                    <IconButton
                        onClick={() => handleRetrieveCollection(tableMeta.rowIndex)}
                    >
                        <UnarchiveOutlinedIcon style={{ color: 'black', width: '20px', height: '20px' }} />
                    </IconButton>
                ),                
            }
        },

    ];

    const deletedColumns = [
        {
            name: 'image_url',
            label: 'Image',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div style={{
                        width: 80,
                        height: 53,
                        backgroundColor: '#F3F4F6',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '0px',
                        border: "1px solid #F3F4F6",
                        overflow: 'hidden',
                        textAlign: 'center',
                        margin: 'auto', 
                    }}>
                        {value ? (
                            <img
                                src={value}
                                alt="img"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                        ) : (
                            <LandscapeIcon style={{ color: '#BCC1CA', fontSize: 30 }} />
                        )}
                    </div>
                ),
            }
        },
        
        {
            name: 'collection',
            label: 'Product Code',
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center', fontWeight: 700 }
                }),
            }
        },

        {
            name: 'promotional_name',
            label: 'Promotional Name',
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },

        {
            name: 'stock',
            label: 'No of items',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },

        {
            name: 'product_tag',
            label: 'Tags',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
                customBodyRender: (value) => {
                    if (Array.isArray(value) && value.length > 0) {
                        return (
                            <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap', justifyContent: 'center' }}>
                                {value.map((tag, index) => (
                                    <span key={index} style={{
                                        backgroundColor: '#E0F7FA',
                                        padding: '4px 8px',
                                        borderRadius: '4px',
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        whiteSpace: 'nowrap'
                                    }}>
                                        {tag.product_tag}
                                    </span>
                                ))}
                            </div>
                        );
                    }
                    return 'N/A';
                }, 
            }
        },
        {
            name: 'stock',
            label: 'Deleted date',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontFamily: 'inter', fontWeight: 600, backgroundColor: '#FAFAFB', textAlign: 'center' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                setCellProps: () => ({
                    style: { textAlign: 'center' }
                }),
            }
        },

    ];

    const columns = selectedTab === 0 ? liveColumns : selectedTab === 1 ? archivedColumns : deletedColumns;

    const options = {
        elevation: 0,
        filterType: 'checkbox',
        responsive: 'standard',
        tableBodyHeight: 'calc(100vh - 64px - 48px)',
        tableBodyMaxHeight: 'calc(100vh - 64px - 48px)',
        textLabels: {
            body: {
                noMatch: loading ? "Loading..." : "Sorry, no matching records found"
            }
        },
        selectableRows: "multiple",
    };
    
    const activeOptions = {
        elevation: 0,
        filterType: 'checkbox',
        responsive: 'standard',
        tableBodyHeight: 'calc(100vh - 64px - 48px)',
        tableBodyMaxHeight: 'calc(100vh - 64px - 48px)',
        print: false,        // Disable print
        download: false,     // Disable download
        columns: false,      // Disable column visibility
        viewColumns: false,  // Disable view columns
        filter: false,
        search: false,
        textLabels: {
            body: {
                noMatch: loading ? "Loading..." : "Sorry, no matching records found"
            }
        },
        selectableRows: "multiple",
        
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
            if (action === 'rowsSelect') {
                const selectedIndexes = state.selectedRows.data.map(row => row.dataIndex);
                const selectedCollections = selectedIndexes.map(index => liveCollections[index]?.collection || '');
                setSelectedRows(selectedCollections);
                console.log("Row selection changed:", selectedCollections);
            }
        }
       
    };

    const archiveOptions = {
        elevation: 0,
        filterType: 'checkbox',
        responsive: 'standard',
        tableBodyHeight: 'calc(100vh - 64px - 48px)',
        tableBodyMaxHeight: 'calc(100vh - 64px - 48px)',
        textLabels: {
            body: {
                noMatch: loading ? "Loading..." : "Sorry, no matching records found"
            }
        },
        selectableRows: "multiple",
        
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
            if (action === 'rowsSelect') {
                const selectedIndexes = state.selectedRows.data.map(row => row.dataIndex);
                console.log(selectedIndexes);
                console.log(archivedTags);
                
                
                const selectedCollections = selectedIndexes.map(index => archivedCollections[index]?.collection || '');
                setSelectedRows(selectedCollections);
                console.log("Row selection changed:", selectedCollections);
            }
        }
        
    };

    const deleteOptions = {
        elevation: 0,
        filterType: 'checkbox',
        responsive: 'standard',
        tableBodyHeight: 'calc(100vh - 64px - 48px)',
        tableBodyMaxHeight: 'calc(100vh - 64px - 48px)',
        textLabels: {
            body: {
                noMatch: loading ? "Loading..." : "Sorry, no matching records found"
            }
        },
        selectableRows: "multiple",
        
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
            if (action === 'rowsSelect') {
                const selectedIndexes = state.selectedRows.data.map(row => row.dataIndex);
                const selectedCollections = selectedIndexes.map(index => deletedCollections[index]?.collection || '');
                setSelectedRows(selectedCollections);
                console.log("Row selection changed:", selectedCollections);
            }
        }
       
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    
        // Update collectionsData based on the selected tab
        if (newValue === 0) {
            setCollectionsData([...liveCollections]); // Use a copy of liveCollections
            setCollectionsOption(activeOptions); // Set options for live collections
        } else if (newValue === 1) {
            setCollectionsData([...archivedCollections]); // Use a copy of archivedCollections
            setCollectionsOption(archiveOptions); // Set options for archived collections
        } else if (newValue === 2) {
            setCollectionsData([...deletedCollections]); // Use a copy of deletedCollections
            setCollectionsOption(deleteOptions); // Set options for deleted collections
        }
    };

    // Filtered data based on searchQuery
    const filteredData = useMemo(() => {
        if (!Array.isArray(collectionsData)) return [];
        if (!searchQuery) return collectionsData;
    
        return collectionsData
            .filter(row => row) // Exclude null or undefined rows
            .filter(row =>
                Object.values(row || {}).some(value =>
                    typeof value === "object"
                        ? Object.values(value || {}).some(subValue =>
                              subValue?.toString().toLowerCase().includes(searchQuery.toLowerCase())
                          )
                        : value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
                )
            );
    }, [collectionsData, searchQuery]);      

    return (
        <Box sx={{ width: '95%', paddingLeft: 3, mb: 5 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%' }}>
                    {/* View collection Section */}
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 0, mb: 2 }}>
                        <Typography variant="body1" sx={{ font: "Inter", fontWeight: 700, fontSize: 16 }}>View Items in the collection </Typography>                        
                        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                            {/* Search Box */}
                            <TextField
                                sx={{ mr: 20, "& .MuiOutlinedInput-root": { height: "31px", width: "304px", borderRadius: "0px" }, }} 
                                size="small" 
                                variant="outlined" 
                                placeholder="Search by item name, tag, product code" 
                                value={searchQuery} 
                                onChange={(e) => setSearchQuery(e.target.value)} 
                                InputProps={{
                                    startAdornment: (
                                        <IconButton position="start" sx={{ ml : "-10px", mr: 1, color: "black" }}>
                                            <SearchIcon />
                                        </IconButton>
                                    )
                                }}
                            />
                        </Box> 
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', mt: 2, mb: 2, fontFamily: "Inter", fontWeight: 700, fontSize: 24 }}>
                        AW25-Summer collection 
                    </Box>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', mt: 4, mb: 2 }}>                                    
                        {/* Tabs Section */}
                        <Box sx={{ height: '36px' }}>
                            <Tabs
                                value={selectedTab}
                                onChange={handleTabChange}
                                variant="fullWidth"
                                sx={{
                                    minHeight: '35px',
                                    border: '1px solid #F3F4F6',
                                    borderRadius: '0px',
                                    '& .MuiTabs-flexContainer': {
                                        height: '35px',
                                    },
                                    '& .MuiTab-root': {
                                        minHeight: '35px',
                                        width: '144px',
                                        borderRight: '1px solid #F3F4F6',                                        
                                        textTransform: 'none',
                                        color: '#333',  
                                    },
                                    '& .MuiTab-root:last-of-type': {
                                        borderRight: 'none',
                                    },
                                    '& .Mui-selected': {
                                        color: '#000 !important',                                        
                                    },
                                    '& .MuiTabs-indicator': {
                                        height: '3px !important',
                                        backgroundColor: '#9095A0 !important', 
                                    },
                                }}
                            >
                                <Tab label={`Live Tags (${counts.liveCollections})`} />
                                <Tab label={`Archived (${counts.archivedCollections})`} />
                                <Tab label={`Deleted (${counts.deletedCollections})`} />
                            </Tabs>
                        </Box>
                        {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, ml: 'auto', mb: 0 }}>                         
                            <Select 
                                sx={{ width: "167px", height: "35px", borderRadius: "0px" }}
                                size="small" 
                                variant="outlined"                                 
                            />                        
                            <Select
                                sx={{ width: "167px", height: "35px", borderRadius: "0px" }}
                                size="small" 
                                variant="outlined" 
                            />                    
                        </Box> */}
                    </Box>

                    {/* Data Table */}
                    <Box sx={{ border: '1px solid #F4F3F6', '& div[class*="MUIDataTableToolbar"]': { display: 'none' } }}>
                        <MUIDataTable
                            title={''}
                            data={loading ? [] : filteredData}
                            columns={columns}
                            options={collectionsOption}
                        />
                    </Box>  
                                        
                </Box>
            </Grid>
        </Box>
    );
};
