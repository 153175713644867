import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography, Button, IconButton, TextField } from '@mui/material';
import { getData, postData } from "../../ServerRequest";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GridViewIcon from '@mui/icons-material/GridView';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

const buttonStyle = { background: '#f8f9fa', border: '3px solid #b0bec5', color: 'black', fontSize: '0.850rem', '&:hover': { background: '#eceff1' } };
const sectionStyle = { backgroundColor: 'rgba(255, 255, 255, 0.6)', marginBottom: 2, boxShadow: '0px 4px 8px rgba(0, 0, 1, 0.1)' };

export default function LandingFooter() {  
  const [footerColumns, setFooterColumns] = useState([]);
  const [footers, setFooters] = useState([]);
  const [loading, setLoading] = useState();
  const [editIndex, setEditIndex] = useState({});

  const handleFooterSubmit = async (index) => {
    let footer = footers[index];
    let footerReqData  = {
        'id': footer.id ? footer.id : '',
        'column_title': footer.column_title ? footer.column_title : '',
        'links': footer.links.map(link => ({
          label: link.label ? link.label : '',  
          url: link.url ? link.url : '',      
          position: link.position ? link.position : ''
        }))
    };        

    let footerResData = await postData('/api/theme/footers', footerReqData);
    if (footerResData['data']['code'] !== 200) {
        alert('Footer data saved successfully');
        setLoading(false);
        return true;
    }
    else {
        alert('Error saving footer data');
        setLoading(false);
    }
    return false;
  };

  useEffect(() => {
    async function fetchFooterData() {
        let resData = await getData('/api/theme/footers');
        console.log(resData);
        let footer_data = resData['data']['footers'];
        if (footer_data) {
            console.log(footer_data);
            if (footer_data) {
                setFooterColumns(footer_data);
            }
        } else {
            console.error("Error fetching footer data:", resData.message);
        }
        setLoading(false);
    }

    fetchFooterData();
  }, []);

  const handleTitleChange = (e, columnId) => {
    setFooterColumns((prevColumns) =>
      prevColumns.map((column) =>
        column.id === columnId ? { ...column, column_title: e.target.value } : column
      )
    );
  };

  const handleLinkChange = (columnId, index, key, value) => {
    setFooterColumns((prevColumns) =>
      prevColumns.map((column) =>
        column.id === columnId
          ? {
              ...column,
              links: column.links.map((link, i) =>
                i === index ? { ...link, [key]: value } : link
              ),
            }
          : column
      )
    );
  };

  const handleAddLink = (columnId) => {
    setFooterColumns((prevColumns) =>
      prevColumns.map((column) =>
        column.id === columnId
          ? { ...column, links: [...column.links, { label: '', url: '' }] }
          : column
      )
    );
  };

  const handleDeleteLink = (columnId, index) => {
    setFooterColumns((prevColumns) =>
      prevColumns.map((column) =>
        column.id === columnId
          ? { ...column, links: column.links.filter((_, i) => i !== index) }
          : column
      )
    );
  };

  const handleTitleEditClick = (columnId) => {
    setEditIndex((prevState) => ({
      ...prevState,
      [columnId]: {
        ...prevState[columnId],
        title: prevState[columnId]?.title ? false : true, 
      },
    }));
  };
  
  const handleLinkEditClick = (columnId, index) => {
    setEditIndex((prevState) => ({
      ...prevState,
      [columnId]: prevState[columnId] === index ? -1 : index,
    }));
  }; 

  const handleAddAccordion = () => {
    const newId = (footerColumns.length + 1).toString();
    setFooterColumns((prevColumns) => [
      ...prevColumns,
      { id: newId, column_title: `New Column ${newId}`, links: [{ label: '', url: '' }] },
    ]);
  };

  return (
    <Box sx={{ width: '95%', paddingLeft: 3 }}>
      {footerColumns.map((column, colIndex) => (
        <Accordion key={column.id} sx={sectionStyle}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>      
            <Typography sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <IconButton component="icon">
                    <GridViewIcon />
                </IconButton>      
                <Typography variant="h7">{`Column ${colIndex + 1}`}</Typography>                
            </Typography>  
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ backgroundColor: '#eceff1', padding: 4, borderRadius: 1 }}>
              <Typography variant="h6" sx={{ marginBottom: 2 }}>Column Title</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                <TextField fullWidth sx={{ marginBottom: 2, backgroundColor: 'white' }} value={column.column_title} onChange={(e) => handleTitleChange(e, column.id)} disabled={!editIndex[column.id]?.title} />
                <IconButton sx={{ marginBottom: 2, color: 'grey' }} onClick={() => handleTitleEditClick(column.id)} aria-label="edit-title">
                  <EditOutlinedIcon />
                </IconButton>
              </Box>
              <Typography variant="h6" sx={{ marginBottom: 2 }}>Links</Typography>
              {column.links.map((link, index) => (
                <Box key={index} sx={{ display: 'flex', marginBottom: 2, alignItems: 'center' }}>
                  <TextField fullWidth label="Label" sx={{ marginRight: 2, backgroundColor: 'white' }} value={link.label} onChange={(e) => handleLinkChange(column.id, index, 'label', e.target.value)} disabled={editIndex[column.id] !== index} />
                  <TextField fullWidth label="URL" sx={{ marginRight: 2, backgroundColor: 'white' }} value={link.url} onChange={(e) => handleLinkChange(column.id, index, 'url', e.target.value)} disabled={editIndex[column.id] !== index} />
                  <TextField label="Position" sx={{ marginRight: 2, backgroundColor: 'white' }} value={link.position} onChange={(e) => handleLinkChange(column.id, index, 'position', e.target.value)} disabled={editIndex[column.id] !== index} />
                  <IconButton sx={{ color: 'grey' }} onClick={() => handleLinkEditClick(column.id, index)} aria-label="edit">
                    <EditOutlinedIcon />
                  </IconButton>
                  <IconButton  sx={{ color: 'red' }} onClick={() => handleDeleteLink(column.id, index)} aria-label="delete">
                    <DeleteOutlinedIcon />
                  </IconButton>
                </Box>
              ))}
              <IconButton variant="contained" sx={{ ...buttonStyle, marginRight: 3, marginTop: 2 }} onClick={() => handleAddLink(column.id)} aria-label="add-link" >
                <AddIcon />
              </IconButton>
              <IconButton variant="contained" sx={{ ...buttonStyle, marginRight: 3, marginTop: 2 }} onClick={() => handleFooterSubmit(colIndex)} aria-label="save-changes">
                <SaveOutlinedIcon /> 
              </IconButton>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
      {/* Button to add a new accordion section */}
      <IconButton variant="contained" sx={{ ...buttonStyle, marginTop: 3 }} onClick={handleAddAccordion} aria-label="add">
        <AddIcon />
      </IconButton>
    </Box>
  );
}
