import React, { useState } from 'react';
import { Box, Container, Grid, Card, CardContent, CardMedia, Typography, Dialog, DialogContent, IconButton, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const productTypes = [
    { name: 'Checks', image: '/images/checks.jpg', products: 120 },
    { name: 'Denim', image: '/images/denim.jpg', products: 80 },
    { name: 'Plain', image: '/images/plain.jpg', products: 150 },
    { name: 'Print', image: '/images/print.jpg', products: 200 },
    { name: 'Stripes', image: '/images/stripes.jpg', products: 90 },
];

function ProductCatalogue() {  
    
    const navigate = useNavigate();

    const handleCardClick = (productType) => {
        navigate('/product-list/:product-type');
    };

    return (
        <Box sx={{ width: '90%', marginLeft: '80px', border: '2px solid #F4F3F6' }}>
            <Box sx={{ padding: 2, ml: 2 }}>
                <Typography variant="body1" sx={{ fontFamily: "Inter", fontWeight: 700, fontSize: 16 }}>
                    Product Catalogue
                </Typography>
            </Box>
            <Box container spacing={2} sx={{ backgroundColor: '#ECEFF1', p: 4 }}>
                {productTypes.map((product, index) => (
                    <Box item xs={12} sm={6} md={4} key={index}>
                        <Card sx={{ height: 300, mb: 3, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', fontFamily: '"Roboto Condensed", sans-serif', cursor: 'pointer' }} onClick={() => handleCardClick(product.name)}>
                            <CardMedia 
                                component="img"
                                alt={product.name}
                                height="300"
                                image={product.image}
                                sx={{ height: '235px', width: '100%', objectFit: 'cover' }}
                            />
                            <CardContent sx={{ padding: 1, height: 100, fontSize: '0.75rem', fontFamily: '"Roboto Condensed", sans-serif' }}>
                                <Typography variant="h6" component="div" sx={{ fontSize: '1rem', fontFamily: '"Roboto Condensed", sans-serif' }}>
                                    {product.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem', fontFamily: '"Roboto Condensed", sans-serif' }}>
                                    Products: {product.products}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

export default ProductCatalogue;
