import React, { useEffect, useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography, Button, Checkbox, Slider, FormControlLabel, RadioGroup, FormControl, Grid, Radio, Chip, Dialog, DialogTitle, DialogContent, TextField, DialogActions
} from "@mui/material";
import { getData, postData } from "../../../ServerRequest";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import LandscapeIcon from '@mui/icons-material/Landscape';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CancelIcon from "@mui/icons-material/Cancel";

export default function DetailDropdowns() {
  const searchParams = new URLSearchParams(window.location.search);
  const productId = searchParams.get("product_id");
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState("");
  const [open, setOpen] = useState(false);
  const [editingImageUrl, setEditingImageUrl] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [images, setImages] = useState([]);
  const [tagIds, setTagIds] = useState([]);

  const updateTagIds = (newTagId) => {
    setTagIds((prevTagIds) => {
      if (prevTagIds.includes(newTagId)) {
        return prevTagIds.filter((id) => id !== newTagId);
      } else {
        return [...prevTagIds, newTagId];
      }
    });
  };
  const [formData, setFormData] = useState({
    ps_product_ID: "",
  });

  const [savedTags, setSavedTags] = useState([]);

  const handleSubmit = async (tagIds) => {
    // event.preventDefault();
    setLoading(true);

    let tagReqData = {
      product_id: productId ? productId : "",
      tag_ids: tagIds,
    };

    try {
      console.log(tagReqData);
      const res = await postData("/api/product/save-fabric-tags", tagReqData);
      console.log(res);
      if (res["data"]["code"] === "200") {
        const selectedTags = fabricTags[selectedSection]
          ?.filter((tag) => tagIds.includes(tag.id))
          ?.map((tag) => tag.product_tag);

        setSavedTags(selectedTags);
        //localStorage.setItem(`savedTags_${productId}_${selectedSection}`, JSON.stringify(selectedTags));
        console.log("Saved tags:", selectedTags); 
        setLoading(false);
        alert("Tag updated successfully");
        //window.location.href = '/admin/product/tags/';
      } else {
        alert("Tag update failed");
        console.error("Failed to submit form:", res.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setLoading(false);
    }
  };

  const handleFabricCompositionSubmit = async (tagIds, blendPercentages) => {
    setLoading(true);
  
    let tagReqData = {
      product_id: productId ? productId : "",
      tag_ids: tagIds,
      blend_percentages: blendPercentages, 
    };
  
    try {
      console.log(tagReqData);
      const res = await postData("/api/product/save-fabric-tags", tagReqData);
      console.log(res);
  
      if (res["data"]["code"] === "200") {
        const selectedTags = fabricTags[selectedSection]
          ?.filter((tag) => tagIds.includes(tag.id))
          ?.map((tag) => ({
            name: tag.product_tag,
            percentage: blendPercentages[tag.product_tag] || 0,
          }));
  
        setSavedTags(selectedTags);
        /* localStorage.setItem(
          `savedTags_${productId}_${selectedSection}`,
          JSON.stringify(selectedTags)
        ); */
  
        console.log("Saved tags:", selectedTags);
        setLoading(false);
        alert("Tag updated successfully");
      } else {
        console.error("Failed to submit form:", res.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setLoading(false);
    }
  };

  const handleSubmitBaseColor = async (tagIds) => {
    setLoading(true);

    const tagReqData = {
      product_id: productId ? productId : "",
      tag_ids: tagIds,
    };

    try {
      const res = await postData("/api/product/save-fabric-tags", tagReqData);
      console.log(res);

      if (res.data.code === "200") {
        const baseColorTags = fabricTags["Base color"]
          ?.filter((tag) => baseColorState.baseColor[tag.product_tag])
          ?.map((tag) => tag.product_tag);

        const foregroundColorTags = fabricTags["Foreground color"]
          ?.filter((tag) => baseColorState.foregroundColor[tag.product_tag])
          ?.map((tag) => tag.product_tag);

        const backgroundColorTags = fabricTags["Background color"]
          ?.filter((tag) => baseColorState.backgroundColor[tag.product_tag])
          ?.map((tag) => tag.product_tag);

        const allTags = [
          ...baseColorTags,
          ...foregroundColorTags,
          ...backgroundColorTags,
        ];

        setSavedTags(allTags);
        // localStorage.setItem(`savedTags_${productId}_${selectedSection}`, JSON.stringify(allTags));
        console.log("Saved tags:", allTags); 
        setLoading(false);
        alert("Tag updated successfully");
      } else {
        alert("Tag update failed");
        console.error("Failed to submit form:", res.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setLoading(false);
    }
  };

  const [fabricCategories, setFabricCategories] = useState([]);
  const [fabricTags, setFabricTags] = useState({});

  useEffect(() => {
    async function fetchformData() {
      let resCategoryData = await getData("/api/product/get-fabric-category");
      let resTagData = await getData("/api/product/get-fabric-tags");
      console.log(resCategoryData);
      console.log(resTagData);
      setFabricCategories(resCategoryData.data.fabric_categories);
      setFabricTags(resTagData.data.fabric_tags);
      console.log(resTagData.data.fabric_tags);
      setLoading(false);
    }
    fetchformData();
  }, []);

  const [selected, setSelected] = useState({});
  const [selectedSection, setSelectedSection] = useState(null);

  const [fabricComposition, setFabricComposition] = useState([]);
  const [selectedComposition, setSelectedComposition] = useState([]);
  const [blendPercentage, setBlendPercentage] = useState([]);

  const [baseColor, setBaseColor] = useState([]);
  const [foregroundColor, setForegroundColor] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState([]);
  const [selectedBaseColor, setSelectedBaseColor] = useState(null);
  const [selectedForegroundColor, setSelectedForegroundColor] = useState(null);
  const [selectedBackgroundColor, setSelectedBackgroundColor] = useState(null);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (!selectedSection || !fabricTags) return;
  
    // Get saved tags from localStorage
    const savedTagsFromStorage = localStorage.getItem(
      `savedTags_${productId}_${selectedSection}`
    );
    const savedTags = savedTagsFromStorage ? JSON.parse(savedTagsFromStorage) : [];

    const sectionStateMap = {
      // Checkbox sections
      "Blends": "blends",
      "Print style": "printStyle",
      "Pattern": "pattern",
      "Character": "character",
      "Weave": "weave",
      "Structure": "structure",
      "Yarn property": "yarnProperty",
      "Fabric Finish": "fabricFinish",
      "Style": "style",
      
      // Radio button sections
      "Fabric Category": "fabricCategory",
      "Pattern size": "patternSize",
      "Season": "season",
      "Category": "category",
      "Denim": "denim",
      "Sustainability": "sustainability",
      "Dye technique": "dyeTechnique",
      "Dye used": "dyeUsed"
    };
  
    if (selectedSection === "Fabric composition") {
      const initialComposition = {};
      const savedPercentages = JSON.parse(localStorage.getItem(
        `savedBlendPercentages_${productId}_${selectedSection}`
      )) || [];
  
      savedPercentages.forEach(({ name }) => {
        initialComposition[name] = true;
      });
  
      setStyleState(prev => ({
        ...prev,
        fabricComposition: initialComposition
      }));
  
    } else if (selectedSection === "Base color") {
      const initialColors = { baseColor: {}, foregroundColor: {}, backgroundColor: {} };
      
      savedTags.forEach(tag => {
        if (fabricTags["Base color"]?.some(t => t.product_tag === tag)) {
          initialColors.baseColor[tag] = true;
        }
        if (fabricTags["Foreground color"]?.some(t => t.product_tag === tag)) {
          initialColors.foregroundColor[tag] = true;
        }
        if (fabricTags["Background color"]?.some(t => t.product_tag === tag)) {
          initialColors.backgroundColor[tag] = true;
        }
      });
  
      setBaseColorState(initialColors);
  
    } else if (sectionStateMap[selectedSection]) {
      const stateKey = sectionStateMap[selectedSection];
      
      if (["fabricCategory", "patternSize", "season", "category", 
           "denim", "sustainability", "dyeTechnique", "dyeUsed"].includes(stateKey)) {
        const selectedValue = savedTags.length > 0 ? savedTags[0] : null;
        // eslint-disable-next-line default-case
        switch (stateKey) {
          case "fabricCategory": setFabricCategory(selectedValue); break;
          case "patternSize": setPatternSize(selectedValue); break;
          case "season": setSeason(selectedValue); break;
          case "category": setCategory(selectedValue); break;
          case "denim": setDenim(selectedValue); break;
          case "sustainability": setSustainability(selectedValue); break;
          case "dyeTechnique": setDyeTechnique(selectedValue); break;
          case "dyeUsed": setDyeUsed(selectedValue); break;
        }
      } else {
        // Checkbox sections
        const initialSelections = {};
        savedTags.forEach(tag => {
          initialSelections[tag] = true;
        });
  
        setStyleState(prev => ({
          ...prev,
          [stateKey]: initialSelections
        }));
      }
    }
  }, [selectedSection, productId, fabricTags]);

  const handleBaseColorSelect = (color) => {
    setSelectedBaseColor(color);
    setSelectedForegroundColor(null);
    setSelectedBackgroundColor(null);
  };

  const [patternSize, setPatternSize] = useState(null);
  const [fabricCategory, setFabricCategory] = useState(null);
  const [season, setSeason] = useState(null);
  const [category, setCategory] = useState(null);
  const [denim, setDenim] = useState(null);
  const [sustainability, setSustainability] = useState(null);
  const [dyeTechnique, setDyeTechnique] = useState(null);
  const [dyeUsed, setDyeUsed] = useState(null);

  const [baseColorState, setBaseColorState] = useState({
    baseColor: {},
    foregroundColor: {},
    backgroundColor: {},
  });

  const [styleState, setStyleState] = useState({
    fabricComposition: {},
    foregroundColor: {},
    blends: {},
    printStyle: {},
    pattern: {},
    character: {},
    weave: {},
    structure: {},
    yarnProperty: {},
    fabricFinish: {},
    style: {},
  });

  const handleToggle = (label) => {
    setSelectedSection((prev) => (prev === label ? null : label));
    setSavedTags([]);
  };

  const handleCompCheckboxChange = (fabric) => {
    setSelectedComposition((prev) =>
      prev.includes(fabric)
        ? prev.filter((item) => item !== fabric)
        : [...prev, fabric]
    );
  };

  const handleSliderChange = (type, newValue) => {
    setBlendPercentage((prev) => ({ ...prev, [type]: newValue }));
  };

  const handleCheckboxChange = (section, tag) => {
    setStyleState((prev) => ({
      ...prev,
      [section]: { ...prev[section], [tag]: !prev[section][tag] },
    }));
  };

  const handleColorChange = (colorType, tag) => {
    setBaseColorState((prev) => ({
      ...prev,
      [colorType]: { ...prev[colorType], [tag]: !prev[colorType][tag] },
    }));
  };

  const handleOpen = (imgSrc) => {
    setSelectedImage(imgSrc);
    setOpen(true);
  };

  const [isChecked, setIsChecked] = useState(false);

  const toggleCheck = () => {
    setIsChecked((prev) => !prev);
  };

  const [isStocked, setIsStocked] = useState(false);

  const handleStockToggle = () => {
    setIsStocked((prevState) => !prevState);
  };

const [newTagDialogOpen, setNewTagDialogOpen] = useState(false);
const [newTagData, setNewTagData] = useState({
  product_tag: "",
});

const [tagImageUrl, setTagImageUrl] = useState('');
const [imageFile, setImageFile] = useState(null);

const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

const handleCreateNewTag = async () => {
  try {
    const currentCategory = fabricCategories.find(
      cat => cat.category === selectedSection
    );

    if (!currentCategory) {
      alert("Could not determine category");
      return;
    }

    const payload = {
      product_tag: newTagData.product_tag,
      tag_family: currentCategory.tag_family,
      category: currentCategory.id,
      created_by: "admin", 
      updated_by: "admin", 
      status: 1,
      tag_image_url: "", 
      is_assigned: false  
    };

    if (imageFile) {
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onload = () => {
        payload.tag_image_url = reader.result;
      };
      await new Promise(resolve => (reader.onloadend = resolve));
    }

    const res = await postData("/api/product/acc_tag_master", payload);

    if (res.data.code === "200") {
      const [categories, tags] = await Promise.all([
        getData("/api/product/get-fabric-category"),
        getData("/api/product/get-fabric-tags")
      ]);
      
      setFabricCategories(categories.data.fabric_categories);
      setFabricTags(tags.data.fabric_tags);

      setNewTagData({
        product_tag: "",
        image_url: "",
        status: true,
        is_assigned: false
      });
      setTagImageUrl('');
      setImageFile(null);
      setNewTagDialogOpen(false);
      alert("Tag created successfully");
    } else {
      alert("Tag creation failed");
    }
  } catch (error) {
    console.error("Error creating tag:", error);
    alert(`Error: ${error.response?.data?.message || "Server error"}`);
  }
};

  return (
    <Box sx={{ padding: 0, maxWidth: "1200px", margin: "0 auto" }}>
      {/* Dropdowns */}
      <Accordion
        sx={{
          mt: 2,
          backgroundColor: "#F3F4F6",
          boxShadow: 0,
          borderRadius: 0,
        }}
      >
        <AccordionSummary
          expandIcon={
            <div onClick={""}>
              <ExpandMoreIcon />
              {isChecked ? (
                <RadioButtonCheckedIcon sx={{ ml: 2 }} />
              ) : (
                <RadioButtonUncheckedIcon sx={{ ml: 2 }} />
              )}
            </div>
          }
          sx={{ display: "flex", alignItems: "center", gap: 2, height: 58 }}
        >
          <Typography
            variant="h7"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              fontWeight: "bold",
            }}
          >
            Add Fabric Tags
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              display: "grid",
              // gridTemplateColumns: "repeat(5, 1fr)",
              gap: "20px",
              padding: 20,
              paddingTop: 6,
            }}
          >
            {/* Fabric Details */}
            <div>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", mb: 1, whiteSpace: "nowrap" }}
              >
                1 Fabric details
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  overflowX: "auto",
                }}
              >
                {fabricCategories
                  .filter((category) => category.tag_family === 2)
                  .map((category) => (
                    <Button
                      key={category.id}
                      onClick={() => handleToggle(category.category)}
                      sx={{
                        borderRadius: "20px",
                        fontSize: "11px",
                        padding: "4px 10px",
                        minWidth: "70px",
                        whiteSpace: "nowrap",
                        display: "flex",
                        border: "1px solid #565E6D",
                        backgroundColor:
                          selectedSection === category.category
                            ? "#565E6D"
                            : "#fff",
                        color:
                          selectedSection === category.category
                            ? "#fff"
                            : "#565E6D",
                        "&:hover": {
                          backgroundColor: "#546e7a",
                          color: "#fff",
                        },
                      }}
                    >
                      {category.category}
                    </Button>
                  ))}
              </div>
            </div>

            {/* Design Related */}
            <div>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", mb: 1, whiteSpace: "nowrap" }}
              >
                2 Design related
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  overflowX: "auto",
                }}
              >
                {fabricCategories
                  .filter(
                    (category) =>
                      category.tag_family === 3 && 
                      category.category !== "Foreground color" && 
                      category.category !== "Background color" 
                  )
                  .map((category) => (
                    <Button
                      key={category.id}
                      onClick={() => handleToggle(category.category)}
                      sx={{
                        borderRadius: "20px",
                        fontSize: "11px",
                        padding: "4px 10px",
                        minWidth: "70px",
                        whiteSpace: "nowrap",
                        display: "flex",
                        border: "1px solid #565E6D",
                        backgroundColor:
                          selectedSection === category.category
                            ? "#565E6D"
                            : "#fff",
                        color:
                          selectedSection === category.category
                            ? "#fff"
                            : "#565E6D",
                        "&:hover": {
                          backgroundColor: "#546e7a",
                          color: "#fff",
                        },
                      }}
                    >
                      {category.category}
                    </Button>
                  ))}
              </div>
            </div>

            {/* Look & Feel */}
            <div>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", mb: 1, whiteSpace: "nowrap" }}
              >
                3 Look & Feel
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  overflowX: "auto",
                }}
              >
                {fabricCategories
                  .filter((category) => category.tag_family === 4)
                  .map((category) => (
                    <Button
                      key={category.id}
                      onClick={() => handleToggle(category.category)}
                      sx={{
                        borderRadius: "20px",
                        fontSize: "11px",
                        padding: "4px 10px",
                        minWidth: "70px",
                        whiteSpace: "nowrap",
                        display: "flex",
                        border: "1px solid #565E6D",
                        backgroundColor:
                          selectedSection === category.category
                            ? "#565E6D"
                            : "#fff",
                        color:
                          selectedSection === category.category
                            ? "#fff"
                            : "#565E6D",
                        "&:hover": {
                          backgroundColor: "#546e7a",
                          color: "#fff",
                        },
                      }}
                    >
                      {category.category}
                    </Button>
                  ))}
              </div>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(5, 1fr)",
              gap: "50px",
              padding: 20,
              paddingTop: 0,
            }}
          >
            {/* Product Related */}
            <div>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", mb: 1, whiteSpace: "nowrap" }}
              >
                4 Season & Category
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  overflowX: "auto",
                }}
              >
                {fabricCategories
                  .filter((category) => category.tag_family === 6)
                  .map((category) => (
                    <Button
                      key={category.id}
                      onClick={() => handleToggle(category.category)}
                      sx={{
                        borderRadius: "20px",
                        fontSize: "11px",
                        padding: "4px 10px",
                        minWidth: "70px",
                        whiteSpace: "nowrap",
                        display: "flex",
                        border: "1px solid #565E6D",
                        backgroundColor:
                          selectedSection === category.category
                            ? "#565E6D"
                            : "#fff",
                        color:
                          selectedSection === category.category
                            ? "#fff"
                            : "#565E6D",
                        "&:hover": {
                          backgroundColor: "#546e7a",
                          color: "#fff",
                        },
                      }}
                    >
                      {category.category}
                    </Button>
                  ))}
              </div>
            </div>

            {/* Technical */}
            <div>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", mb: 1, whiteSpace: "nowrap" }}
              >
                5 Processing
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  overflowX: "auto",
                }}
              >
                {fabricCategories
                  .filter((category) => category.tag_family === 7)
                  .map((category) => (
                    <Button
                      key={category.id}
                      onClick={() => handleToggle(category.category)}
                      sx={{
                        borderRadius: "20px",
                        fontSize: "11px",
                        padding: "4px 10px",
                        minWidth: "70px",
                        whiteSpace: "nowrap",
                        display: "flex",
                        border: "1px solid #565E6D",
                        backgroundColor:
                          selectedSection === category.category
                            ? "#565E6D"
                            : "#fff",
                        color:
                          selectedSection === category.category
                            ? "#fff"
                            : "#565E6D",
                        "&:hover": {
                          backgroundColor: "#546e7a",
                          color: "#fff",
                        },
                      }}
                    >
                      {category.category}
                    </Button>
                  ))}
              </div>
            </div>
          </div>
          <Grid
            container
            sx={{
              backgroundColor: "#F3F4F6",
              padding: 2,
              borderRadius: "0px",
              maxWidth: "1210px",
            }}
          >
            {/* Image Placeholder */}
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "240px",
                  border: "2px solid #ccc",
                  borderRadius: "0px",
                  backgroundColor: "white",
                  cursor: "pointer",
                }}
                style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }}
                onClick={() => handleOpen(formData.ps_product_productimg)}
              //value={formData.ps_product_productimg}
              >
                <img
                  src={formData.ps_product_productimg}
                  alt="Product"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              </Box>
            </Grid>

            {/* Input Fields */}
            <Grid item xs={12} sm={8}>
              <Grid container spacing={2} sx={{ ml: 1 }}>
                {/* Header Inputs */}
                <Grid item xs={12} sx={{ display: "flex", gap: 2, mt: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Predefined information{" "}
                    </Typography>
                  </Box>
                </Grid>

                {/* Left Column */}
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <label htmlFor="count" style={{ width: "120px" }}>
                      Count
                    </label>
                    <input
                      id="count"
                      type="text"
                      style={{
                        flex: 1,
                        padding: "6px",
                        border: "1px solid #f1f4f6",
                        borderRadius: "0px",
                        backgroundColor: "#F3F4F6",
                      }}
                      disabled
                    //value={formData.ps_product_count}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <label htmlFor="construction" style={{ width: "120px" }}>
                      Construction
                    </label>
                    <input
                      id="construction"
                      type="text"
                      style={{
                        flex: 1,
                        padding: "6px",
                        border: "1px solid #f1f4f6",
                        borderRadius: "0px",
                        backgroundColor: "#F3F4F6",
                      }}
                      disabled
                    //value={formData.ps_product_construction}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <label htmlFor="gsm" style={{ width: "120px" }}>
                      GSM
                    </label>
                    <input
                      id="gsm"
                      type="text"
                      style={{
                        flex: 1,
                        padding: "6px",
                        border: "1px solid #f1f4f6",
                        borderRadius: "0px",
                        backgroundColor: "#F3F4F6",
                      }}
                      disabled
                    />
                  </Box>
                </Grid>

                {/* Right Column */}
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <label htmlFor="width" style={{ width: "120px" }}>
                      Width
                    </label>
                    <input
                      id="width"
                      type="text"
                      style={{
                        flex: 1,
                        padding: "6px",
                        border: "1px solid #f1f4f6",
                        borderRadius: "0px",
                        backgroundColor: "#F3F4F6",
                      }}
                      disabled
                    //value={formData.ps_product_mill}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <label htmlFor="fabricPattern" style={{ width: "120px" }}>
                      Fabric pattern
                    </label>
                    <input
                      id="fabricPattern"
                      type="text"
                      style={{
                        flex: 1,
                        padding: "6px",
                        border: "1px solid #f1f4f6",
                        borderRadius: "0px",
                        backgroundColor: "#F3F4F6",
                      }}
                      disabled
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <label htmlFor="fabricType" style={{ width: "120px" }}>
                      Fabric type
                    </label>
                    <input
                      id="fabricType"
                      type="text"
                      style={{
                        flex: 1,
                        padding: "6px",
                        border: "1px solid #f1f4f6",
                        borderRadius: "0px",
                        backgroundColor: "#F3F4F6",
                      }}
                      disabled
                    //value={formData.ps_product_pattern}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Fabric Composition Section */}
          {selectedSection === "Fabric composition" && (
            <div
              style={{
                marginTop: "20px",
                padding: "10px",
                backgroundColor: "#F3F4F6",
                borderRadius: "0px",
                borderTop: "2px solid #ccc",
              }}
            >
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Fabric Composition
              </Typography>
              <Box sx={{ 
                display: "flex", 
                flexWrap: "wrap", 
                gap: "8px", 
                mb: 4,
                mt: 3,
                "& .MuiFormControlLabel-root": {
                  mr: 2,
                  ml: 0
                }
              }}>
                {fabricTags["Fabric composition"]?.map((tag) => (
                  <FormControlLabel
                    key={tag.id}
                    control={
                      <Checkbox
                        checked={styleState.fabricComposition[tag.product_tag] || false}
                        onChange={() => handleCheckboxChange("fabricComposition", tag.product_tag)}
                        sx={{ 
                          color: "#565E6D",
                          "&.Mui-checked": { color: "#565E6D" },
                          padding: "4px"
                        }}
                      />
                    }
                    label={tag.product_tag.charAt(0).toUpperCase() + tag.product_tag.slice(1)}
                  />
                ))}
              </Box>

              {/* Blend Percentage Section */}
              {Object.keys(styleState.fabricComposition).some(key => styleState.fabricComposition[key]) && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body1" sx={{ 
                    // fontWeight: "bold", 
                    mb: 3,
                    fontSize: "1rem"
                  }}>
                    Define blend percentage
                  </Typography>

                  {/* Vertical slider layout */}
                  <Box sx={{ 
                    display: "flex",
                    flexDirection: "column",
                    gap: 3,
                    width: "100%"
                  }}>
                    {fabricTags["Fabric composition"]?.map((tag) => (
                      styleState.fabricComposition[tag.product_tag] && (
                        <Box key={tag.id} sx={{ 
                          display: "flex", 
                          alignItems: "center",
                          gap: 2,
                          width: "100%"
                        }}>
                          <Typography variant="body2" sx={{ 
                            minWidth: "100px",
                            fontSize: "1rem"
                          }}>
                            {tag.product_tag}
                          </Typography>
                          <Slider
                            value={blendPercentage[tag.product_tag] || 0}
                            onChange={(e, newValue) => handleSliderChange(tag.product_tag, newValue)}
                            min={0}
                            max={100}
                            size="small"
                            sx={{
                              width: "250px", 
                              color: "#565E6D",
                              "& .MuiSlider-rail": {
                                height: 4
                              },
                              "& .MuiSlider-track": {
                                height: 4
                              },
                              "& .MuiSlider-thumb": {
                                width: 14,
                                height: 14,
                                backgroundColor: "#fff",
                                border: "2px solid #565E6D"
                              }
                            }}
                          />
                          <Box
                            sx={{
                              width: "60px",
                              height: "30px",
                              border: "1px solid #ddd",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "1rem",
                              backgroundColor: "#fff"
                            }}
                          >
                            {blendPercentage[tag.product_tag] || 0}%
                          </Box>
                        </Box>
                      )
                    ))}
                  </Box>
                </Box>
              )}

              {/* Create New Tag Button */}
              <Button
                startIcon={<AddCircleOutlineIcon />}
                sx={{
                  mt: 4.5,
                  width: 200,
                  height: 28,
                  border: "1px solid #ccc",
                  borderRadius: "0px",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#b1b4b6" },
                }}
                onClick={() => setNewTagDialogOpen(true)}
              >
                Create New Tag
              </Button>

              {/* Save Button */}
              <Box sx={{ mt: 4, textAlign: "center" }}>
                <Button
                  onClick={() => {
                    const selectedTagIds = fabricTags["Fabric composition"]
                      ?.filter((tag) => styleState.fabricComposition[tag.product_tag])
                      ?.map((tag) => tag.id);

                    const selectedBlendPercentages = fabricTags["Fabric composition"]
                      ?.filter((tag) => styleState.fabricComposition[tag.product_tag])
                      ?.reduce((acc, tag) => {
                        acc[tag.product_tag] = blendPercentage[tag.product_tag] || 0;
                        return acc;
                      }, {});

                    handleFabricCompositionSubmit(selectedTagIds, selectedBlendPercentages);

                    const savedBlendPercentages = fabricTags["Fabric composition"]
                      ?.map((tag) => ({
                        name: tag.product_tag,
                        percentage: blendPercentage[tag.product_tag] || 0,
                      }))
                      ?.filter((tag) => tag.percentage > 0);
                    localStorage.setItem(
                      `savedBlendPercentages_${productId}_${selectedSection}`,
                      JSON.stringify(savedBlendPercentages)
                    );
                    setSavedTags(savedBlendPercentages);
                  }}
                  sx={{
                    backgroundColor: "#565E6D",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#546e7a" },
                    borderRadius: "0px",
                    px: 4,
                    py: 1,
                  }}
                >
                  Save Changes
                </Button>
              </Box>

              {/* Saved Tags Display */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="body1" sx={{ fontWeight: "bold", mb: 2 }}>
                  Saved Tag(s)
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  {(() => {
                    const savedData = JSON.parse(localStorage.getItem(
                      `savedBlendPercentages_${productId}_${selectedSection}`)) || [];
                      return savedData.length > 0 ? ( savedData.map((item, index) => (
                      <Chip
                        key={index}
                        label={`${item.name} ${item.percentage}%`}
                        sx={{
                          backgroundColor: "#565E6D",
                          color: "#fff",
                          borderRadius: "4px",
                        }}
                      />
                    ))
                  ) : (
                    <Typography variant="body1">No saved tags found.</Typography>
                  );
                  })()}
                </Box>
              </Box>
            </div>
          )}

          {/* Fabric Category Section*/}
          {selectedSection === "Fabric Category" && (
            <div
              style={{
                marginTop: "20px",
                padding: "10px",
                backgroundColor: "#F3F4F6",
                borderRadius: "0px",
                borderTop: "2px solid #ccc",
              }}
            >
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Fabric Category
              </Typography>
              <FormControl
                component="fieldset"
                sx={{
                  mt: 2,
                  padding: "10px 0",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <RadioGroup
                  row
                  value={fabricCategory}
                  onChange={(e) => setFabricCategory(e.target.value)}
                >
                  {fabricTags["Fabric Category"]?.map((tag) => (
                    <FormControlLabel
                      key={tag.id}
                      value={tag.product_tag}
                      control={
                        <Radio
                          sx={{
                            color: "black",
                            "&.Mui-checked": { color: "black" },
                          }}
                        />
                      }
                      label={
                        tag.product_tag.charAt(0).toUpperCase() +
                        tag.product_tag.slice(1)
                      }
                    />
                  ))}
                </RadioGroup>

                {/* Create New Tag Button */}
                <Button
                  startIcon={<AddCircleOutlineIcon />}
                  sx={{
                    mt: 4.5,
                    width: 200,
                    height: 28,
                    border: "1px solid #ccc",
                    borderRadius: "0px",
                    backgroundColor: "white",
                    color: "black",
                    fontWeight: "bold",
                    "&:hover": { backgroundColor: "#b1b4b6" },
                  }}
                  onClick={() => setNewTagDialogOpen(true)}
                >
                  Create New Tag
                </Button>
              </FormControl>

              {/* Save Changes Button */}
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Button
                  onClick={() => {
                    const selectedTag = fabricTags["Fabric Category"]?.find(
                      (tag) => tag.product_tag === fabricCategory
                    );
                    handleSubmit(selectedTag ? [selectedTag.id] : []);
                    if (selectedTag) {
                      const updatedTags = [selectedTag.product_tag]; 
                      localStorage.setItem(`savedTags_${productId}_${selectedSection}`, JSON.stringify(updatedTags));
                      setLoading((prev) => !prev);
                    }
                  }}
                  sx={{
                    backgroundColor: "#565E6D",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#546e7a" },
                    borderRadius: "0px",
                  }}
                >
                  Save Changes
                </Button>
              </div>

              {/* Display Saved Tags */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  Saved Tag(s)
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                {(() => {
                    const savedTagsFromStorage = localStorage.getItem(`savedTags_${productId}_${selectedSection}`);
                    const savedTags = savedTagsFromStorage ? JSON.parse(savedTagsFromStorage) : [];

                    return savedTags.length > 0 ? (
                      savedTags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          sx={{
                            backgroundColor: "#565E6D",
                            color: "#fff",
                            borderRadius: "4px",
                          }}
                        />
                      ))
                    ) : (
                      <Typography variant="body1">No saved tags found.</Typography>
                    );
                  })()}
                </div>
              </Box>
            </div>
          )}

          {/* Blends Section */}
          {selectedSection === "Blends" && (
            <div
              style={{
                marginTop: "20px",
                padding: "10px",
                backgroundColor: "#F3F4F6",
                borderRadius: "0px",
                borderTop: "2px solid #ccc",
              }}
            >
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Blends
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                {fabricTags["Blends"]?.map((tag) => (
                  <div key={tag.id} style={{ textAlign: "start" }}>
                    <div
                      style={{
                        width: "122px",
                        height: "68px",
                        backgroundColor: "white",
                        borderRadius: "0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        border: styleState.blends[tag.product_tag]
                          ? "2px solid #565E6D"
                          : "2px solid transparent",
                      }}
                      onClick={() =>
                        handleCheckboxChange("blends", tag.product_tag)
                      }
                    >
                      <Typography>
                        {tag.product_tag.charAt(0).toUpperCase() +
                          tag.product_tag.slice(1)}
                      </Typography>
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={styleState.blends[tag.product_tag] || false}
                          onChange={() =>
                            handleCheckboxChange("blends", tag.product_tag)
                          }
                          sx={{
                            color: "#565E6D",
                            "&.Mui-checked": { color: "#565E6D" },
                            "& .MuiSvgIcon-root": { fontSize: 22 },
                          }}
                        />
                      }
                      label={
                        tag.product_tag.charAt(0).toUpperCase() +
                        tag.product_tag.slice(1)
                      }
                    />
                  </div>
                ))}
              </div>

              {/* Create New Tag Button */}
              <Button
                startIcon={<AddCircleOutlineIcon />}
                sx={{
                  mt: 4.5,
                  width: 200,
                  height: 28,
                  border: "1px solid #ccc",
                  borderRadius: "0px",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#b1b4b6" },
                }}
                onClick={() => setNewTagDialogOpen(true)}
              >
                Create New Tag
              </Button>

              {/* Save Changes Button */}
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Button
                  onClick={async () => {
                    const selectedTags = fabricTags["Blends"]
                      ?.filter((tag) => styleState.blends[tag.product_tag])
                      ?.map((tag) => tag.product_tag) || [];

                    const selectedIds = fabricTags["Blends"]
                      ?.filter((tag) => styleState.blends[tag.product_tag])
                      ?.map((tag) => tag.id) || [];

                    await handleSubmit(selectedIds);
                  
                    localStorage.setItem(
                      `savedTags_${productId}_${selectedSection}`,
                      JSON.stringify(selectedTags)
                    );
                  }}
                  sx={{
                    backgroundColor: "#565E6D",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#546e7a" },
                    borderRadius: "0px",
                  }}
                >
                  Save Changes
                </Button>
              </div>

              {/* Display Saved Tags */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  Saved Tag(s)
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                  {(() => {
                    const savedTagsFromStorage = localStorage.getItem(
                      `savedTags_${productId}_${selectedSection}`
                    );
                    const savedTags = savedTagsFromStorage
                      ? JSON.parse(savedTagsFromStorage)
                      : [];

                    return savedTags.length > 0 ? (
                      savedTags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          sx={{
                            backgroundColor: "#565E6D",
                            color: "#fff",
                            borderRadius: "4px",
                          }}
                        />
                      ))
                    ) : (
                      <Typography variant="body1">No saved tags found.</Typography>
                    );
                  })()}
                </div>
              </Box>
            </div>
          )}

          {/* Base color Section */}
          {selectedSection === "Base color" && (
            <div
              style={{
                marginTop: "20px",
                padding: "30px",
                backgroundColor: "#F3F4F6",
                borderRadius: "0px",
                borderTop: "2px solid #ccc",
              }}
            >
              {/* Base Color Section */}
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Base colour
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                {fabricTags["Base color"]?.map((tag) => (
                  <div key={tag.id} style={{ textAlign: "start" }}>
                    <div
                      style={{
                        width: "60px",
                        height: "60px",
                        backgroundColor: tag.product_tag,
                        borderRadius: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        border: baseColorState.baseColor[tag.product_tag]
                          ? "2px solid #565E6D"
                          : "2px solid transparent",
                      }}
                    ></div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={baseColorState.baseColor[tag.product_tag] || false}
                          onChange={() => {
                            handleColorChange("baseColor", tag.product_tag);
                            setBaseColorState((prev) => ({
                              ...prev,
                              foregroundColor: { ...prev.foregroundColor, [tag.product_tag]: true },
                              backgroundColor: { ...prev.backgroundColor, [tag.product_tag]: true },
                            }));
                          }}
                          sx={{
                            color: "#565E6D",
                            "&.Mui-checked": { color: "#565E6D" },
                            "& .MuiSvgIcon-root": { fontSize: 20 },
                          }}
                        />
                      }
                      label={
                        tag.product_tag.charAt(0).toUpperCase() +
                        tag.product_tag.slice(1)
                      }
                    />
                  </div>
                ))}
              </div>
              {/* Create New Tag Button */}
              <Button
                startIcon={<AddCircleOutlineIcon />}
                sx={{
                  mt: 2,
                  width: 200,
                  height: 28,
                  border: "1px solid #ccc",
                  borderRadius: "0px",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#b1b4b6" },
                }}
                onClick={() => setNewTagDialogOpen(true)}
              >
                Create New Tag
              </Button>

              {/* Show Foreground Color Section only if any Base Color is checked */}
              {Object.values(baseColorState.baseColor).some((checked) => checked) && (
                <Box sx={{ mt: 4 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Object.values(baseColorState.foregroundColor).some((checked) => checked)}
                        onChange={() => {}}
                        sx={{
                          color: "#565E6D",
                          "&.Mui-checked": { color: "#565E6D" },
                          "& .MuiSvgIcon-root": { fontSize: 20 },
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "Inter, sans-serif",
                          fontWeight: 700,
                          fontSize: 16,
                        }}
                      >
                        Select foreground colour
                      </Typography>
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "start",
                      gap: "20px",
                      marginTop: "20px",
                    }}
                  >
                    {fabricTags["Foreground color"]?.map((tag) => (
                      <div key={tag.id} style={{ textAlign: "start" }}>
                        <div
                          style={{
                            width: "60px",
                            height: "60px",
                            backgroundColor: tag.product_tag,
                            borderRadius: "100px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            border: baseColorState.foregroundColor[tag.product_tag]
                              ? "2px solid #565E6D"
                              : "2px solid transparent",
                          }}
                        ></div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={baseColorState.foregroundColor[tag.product_tag] || false}
                              onChange={() =>
                                handleColorChange("foregroundColor", tag.product_tag)
                              }
                              sx={{
                                color: "#565E6D",
                                "&.Mui-checked": { color: "#565E6D" },
                                "& .MuiSvgIcon-root": { fontSize: 20 },
                              }}
                            />
                          }
                          label={
                            tag.product_tag.charAt(0).toUpperCase() +
                            tag.product_tag.slice(1)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  {/* Create New Tag Button */}
                  <Button
                    startIcon={<AddCircleOutlineIcon />}
                    sx={{
                      mt: 4.5,
                      width: 200,
                      height: 28,
                      border: "1px solid #ccc",
                      borderRadius: "0px",
                      backgroundColor: "white",
                      color: "black",
                      fontWeight: "bold",
                      "&:hover": { backgroundColor: "#b1b4b6" },
                    }}
                    onClick={() => setNewTagDialogOpen(true)}
                  >
                    Create New Tag
                  </Button>
                </Box>
              )}

              {/* Show Background Color Section only if any Base Color is checked */}
              {Object.values(baseColorState.baseColor).some((checked) => checked) && (
                <Box sx={{ mt: 4 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Object.values(baseColorState.backgroundColor).some((checked) => checked)}
                        onChange={() => {}}
                        sx={{
                          color: "#565E6D",
                          "&.Mui-checked": { color: "#565E6D" },
                          "& .MuiSvgIcon-root": { fontSize: 20 },
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "Inter, sans-serif",
                          fontWeight: 700,
                          fontSize: 16,
                        }}
                      >
                        Select background colour
                      </Typography>
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "start",
                      gap: "20px",
                      marginTop: "20px",
                    }}
                  >
                    {fabricTags["Background color"]?.map((tag) => (
                      <div key={tag.id} style={{ textAlign: "start" }}>
                        <div
                          style={{
                            width: "60px",
                            height: "60px",
                            backgroundColor: tag.product_tag,
                            borderRadius: "100px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            border: baseColorState.backgroundColor[tag.product_tag]
                              ? "2px solid #565E6D"
                              : "2px solid transparent",
                          }}
                        ></div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={baseColorState.backgroundColor[tag.product_tag] || false}
                              onChange={() =>
                                handleColorChange("backgroundColor", tag.product_tag)
                              }
                              sx={{
                                color: "#565E6D",
                                "&.Mui-checked": { color: "#565E6D" },
                                "& .MuiSvgIcon-root": { fontSize: 20 },
                              }}
                            />
                          }
                          label={
                            tag.product_tag.charAt(0).toUpperCase() +
                            tag.product_tag.slice(1)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  {/* Create New Tag Button */}
                  <Button
                    startIcon={<AddCircleOutlineIcon />}
                    sx={{
                      mt: 4.5,
                      width: 200,
                      height: 28,
                      border: "1px solid #ccc",
                      borderRadius: "0px",
                      backgroundColor: "white",
                      color: "black",
                      fontWeight: "bold",
                      "&:hover": { backgroundColor: "#b1b4b6" },
                    }}
                    onClick={() => setNewTagDialogOpen(true)}
                  >
                    Create New Tag
                  </Button>
                </Box>
              )}

              {/* Save Changes Button */}
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Button
                  onClick={() => {
                    const baseColorIds =
                      fabricTags["Base color"]
                        ?.filter((tag) => baseColorState.baseColor[tag.product_tag])
                        ?.map((tag) => tag.id) || [];

                    const foregroundColorIds =
                      fabricTags["Foreground color"]
                        ?.filter((tag) => baseColorState.foregroundColor[tag.product_tag])
                        ?.map((tag) => tag.id) || [];

                    const backgroundColorIds =
                      fabricTags["Background color"]
                        ?.filter((tag) => baseColorState.backgroundColor[tag.product_tag])
                        ?.map((tag) => tag.id) || [];

                    const selectedIds = [
                      ...baseColorIds,
                      ...foregroundColorIds,
                      ...backgroundColorIds,
                    ];
                    handleSubmitBaseColor(selectedIds);

                    const baseColorTags = fabricTags["Base color"]
                      ?.filter((tag) => baseColorState.baseColor[tag.product_tag])
                      ?.map((tag) => tag.product_tag) || [];

                    const foregroundColorTags = fabricTags["Foreground color"]
                      ?.filter((tag) => baseColorState.foregroundColor[tag.product_tag])
                      ?.map((tag) => tag.product_tag) || [];

                    const backgroundColorTags = fabricTags["Background color"]
                      ?.filter((tag) => baseColorState.backgroundColor[tag.product_tag])
                      ?.map((tag) => tag.product_tag) || [];

                    const selectedTags = [
                      ...baseColorTags,
                      ...foregroundColorTags,
                      ...backgroundColorTags,
                    ];
                    localStorage.setItem(`savedTags_${productId}_${selectedSection}`, JSON.stringify(selectedTags));
                    setLoading((prev) => !prev);
                  }}
                  sx={{
                    backgroundColor: "#565E6D",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#546e7a" },
                    borderRadius: "0px",
                  }}
                >
                  Save Changes
                </Button>
              </div>

              {/* Display Saved Tags */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  Saved Tag(s)
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                  {(() => {
                    const savedTagsFromStorage = localStorage.getItem(`savedTags_${productId}_${selectedSection}`);
                    const savedTags = savedTagsFromStorage ? JSON.parse(savedTagsFromStorage) : [];

                    return savedTags.length > 0 ? (
                      savedTags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          sx={{
                            backgroundColor: "#565E6D",
                            color: "#fff",
                            borderRadius: "4px",
                          }}
                        />
                      ))
                    ) : (
                      <Typography variant="body1">No saved tags found.</Typography>
                    );
                  })()}
                </div>
              </Box>
            </div>
          )}

          {/* Pattern Size Section */}
          {selectedSection === "Pattern size" && (
            <div
              style={{
                marginTop: "20px",
                padding: "10px",
                backgroundColor: "#F3F4F6",
                borderRadius: "0px",
                borderTop: "2px solid #ccc",
              }}
            >
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Pattern Size
              </Typography>
              <FormControl
                component="fieldset"
                sx={{
                  mt: 2,
                  padding: "10px 0",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <RadioGroup
                  row
                  value={patternSize}
                  onChange={(e) => setPatternSize(e.target.value)}
                >
                  {fabricTags["Pattern size"]?.map((tag) => (
                    <FormControlLabel
                      key={tag.id}
                      value={tag.product_tag}
                      control={
                        <Radio
                          sx={{
                            color: "black",
                            "&.Mui-checked": { color: "black" },
                          }}
                        />
                      }
                      label={
                        tag.product_tag.charAt(0).toUpperCase() +
                        tag.product_tag.slice(1)
                      }
                    />
                  ))}
                </RadioGroup>

                {/* Create New Tag Button */}
                <Button
                  startIcon={<AddCircleOutlineIcon />}
                  sx={{
                    mt: 4.5,
                    width: 200,
                    height: 28,
                    border: "1px solid #ccc",
                    borderRadius: "0px",
                    backgroundColor: "white",
                    color: "black",
                    fontWeight: "bold",
                    "&:hover": { backgroundColor: "#b1b4b6" },
                  }}
                  onClick={() => setNewTagDialogOpen(true)}
                >
                  Create New Tag
                </Button>
              </FormControl>

              {/* Save Changes Button */}
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Button
                  onClick={() => {
                    const selectedTag = fabricTags["Pattern size"]?.find(
                      (tag) => tag.product_tag === patternSize
                    );
                    handleSubmit(selectedTag ? [selectedTag.id] : []);
                    if (selectedTag) {
                      const updatedTags = [selectedTag.product_tag]; 
                      localStorage.setItem(`savedTags_${productId}_${selectedSection}`, JSON.stringify(updatedTags));
                      setLoading((prev) => !prev);
                    }
                  }}
                  sx={{
                    backgroundColor: "#565E6D",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#546e7a" },
                    borderRadius: "0px",
                  }}
                >
                  Save Changes
                </Button>
              </div>

              {/* Display Saved Tags */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  Saved Tag(s)
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                {(() => {
                    const savedTagsFromStorage = localStorage.getItem(`savedTags_${productId}_${selectedSection}`);
                    const savedTags = savedTagsFromStorage ? JSON.parse(savedTagsFromStorage) : [];

                    return savedTags.length > 0 ? (
                      savedTags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          sx={{
                            backgroundColor: "#565E6D",
                            color: "#fff",
                            borderRadius: "4px",
                          }}
                        />
                      ))
                    ) : (
                      <Typography variant="body1">No saved tags found.</Typography>
                    );
                  })()}
                </div>
              </Box>
            </div>
          )}

          {/* Print Style Section */}
          {selectedSection === "Print style" && (
            <div
              style={{
                marginTop: "20px",
                padding: "10px",
                backgroundColor: "#F3F4F6",
                borderRadius: "0px",
                borderTop: "2px solid #ccc",
              }}
            >
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Print Style
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                {fabricTags["Print style"]?.map((tag) => (
                  <div key={tag.id} style={{ textAlign: "start" }}>
                    <div
                      style={{
                        width: "122px",
                        height: "68px",
                        backgroundColor: "white",
                        borderRadius: "0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        border: styleState.printStyle[tag.product_tag]
                          ? "2px solid #565E6D"
                          : "2px solid transparent",
                      }}
                      onClick={() =>
                        handleCheckboxChange("printStyle", tag.product_tag)
                      }
                    >
                      <Typography>
                        {tag.product_tag.charAt(0).toUpperCase() +
                          tag.product_tag.slice(1)}
                      </Typography>
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={styleState.printStyle[tag.product_tag] || false}
                          onChange={() =>
                            handleCheckboxChange("printStyle", tag.product_tag)
                          }
                          sx={{
                            color: "#565E6D",
                            "&.Mui-checked": { color: "#565E6D" },
                            "& .MuiSvgIcon-root": { fontSize: 22 },
                          }}
                        />
                      }
                      label={
                        tag.product_tag.charAt(0).toUpperCase() +
                        tag.product_tag.slice(1)
                      }
                    />
                  </div>
                ))}
              </div>

              {/* Create New Tag Button */}
              <Button
                startIcon={<AddCircleOutlineIcon />}
                sx={{
                  mt: 4.5,
                  width: 200,
                  height: 28,
                  border: "1px solid #ccc",
                  borderRadius: "0px",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#b1b4b6" },
                }}
                onClick={() => setNewTagDialogOpen(true)}
              >
                Create New Tag
              </Button>

              {/* Save Changes Button */}
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Button
                  onClick={() => {
                    const selectedTags =
                    fabricTags["Print style"]
                    ?.filter((tag) => styleState.printStyle[tag.product_tag])
                    ?.map((tag) => tag.product_tag) || [];
                    localStorage.setItem(`savedTags_${productId}_${selectedSection}`, JSON.stringify(selectedTags));

                    const selectedIds = fabricTags["Print style"]
                      ?.filter((tag) => styleState.printStyle[tag.product_tag])
                      ?.map((tag) => tag.id) || [];
                      handleSubmit(selectedIds);
                    setLoading((prev) => !prev);
                  }}
                  sx={{
                    backgroundColor: "#565E6D",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#546e7a" },
                    borderRadius: "0px",
                  }}
                >
                  Save Changes
                </Button>
              </div>

              {/* Display Saved Tags */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  Saved Tag(s)
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                {(() => {
                    const savedTagsFromStorage = localStorage.getItem(`savedTags_${productId}_${selectedSection}`);
                    const savedTags = savedTagsFromStorage ? JSON.parse(savedTagsFromStorage) : [];

                    return savedTags.length > 0 ? (
                      savedTags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          sx={{
                            backgroundColor: "#565E6D",
                            color: "#fff",
                            borderRadius: "4px",
                          }}
                        />
                      ))
                    ) : (
                      <Typography variant="body1">No saved tags found.</Typography>
                    );
                  })()}
                </div>
              </Box>
            </div>
          )}

          {/* Pattern Section */}
          {selectedSection === "Pattern" && (
            <div
              style={{
                marginTop: "20px",
                padding: "10px",
                backgroundColor: "#F3F4F6",
                borderRadius: "0px",
                borderTop: "2px solid #ccc",
              }}
            >
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Pattern
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                {fabricTags["Pattern"]?.map((tag) => (
                  <div key={tag.id} style={{ textAlign: "start" }}>
                    <div
                      style={{
                        width: "122px",
                        height: "68px",
                        backgroundColor: "white",
                        borderRadius: "0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        border: styleState.pattern[tag.product_tag]
                          ? "2px solid #565E6D"
                          : "2px solid transparent",
                      }}
                      onClick={() =>
                        handleCheckboxChange("pattern", tag.product_tag)
                      }
                    >
                      <Typography>
                        {tag.product_tag.charAt(0).toUpperCase() +
                          tag.product_tag.slice(1)}
                      </Typography>
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={styleState.pattern[tag.product_tag] || false}
                          onChange={() =>
                            handleCheckboxChange("pattern", tag.product_tag)
                          }
                          sx={{
                            color: "#565E6D",
                            "&.Mui-checked": { color: "#565E6D" },
                            "& .MuiSvgIcon-root": { fontSize: 22 },
                          }}
                        />
                      }
                      label={
                        tag.product_tag.charAt(0).toUpperCase() +
                        tag.product_tag.slice(1)
                      }
                    />
                  </div>
                ))}
              </div>

              {/* Create New Tag Button */}
              <Button
                startIcon={<AddCircleOutlineIcon />}
                sx={{
                  mt: 4.5,
                  width: 200,
                  height: 28,
                  border: "1px solid #ccc",
                  borderRadius: "0px",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#b1b4b6" },
                }}
                onClick={() => setNewTagDialogOpen(true)}
              >
                Create New Tag
              </Button>

              {/* Save Changes Button */}
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Button
                  onClick={() => {
                    const selectedTags =
                    fabricTags["Pattern"]
                    ?.filter((tag) => styleState.pattern[tag.product_tag])
                    ?.map((tag) => tag.product_tag) || [];
                    localStorage.setItem(`savedTags_${productId}_${selectedSection}`, JSON.stringify(selectedTags));

                    const selectedIds = fabricTags["Pattern"]
                      ?.filter((tag) => styleState.pattern[tag.product_tag])
                      ?.map((tag) => tag.id) || [];
                      handleSubmit(selectedIds);
                    setLoading((prev) => !prev);
                  }}
                  sx={{
                    backgroundColor: "#565E6D",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#546e7a" },
                    borderRadius: "0px",
                  }}
                >
                  Save Changes
                </Button>
              </div>

              {/* Display Saved Tags */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  Saved Tag(s)
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                {(() => {
                    const savedTagsFromStorage = localStorage.getItem(`savedTags_${productId}_${selectedSection}`);
                    const savedTags = savedTagsFromStorage ? JSON.parse(savedTagsFromStorage) : [];

                    return savedTags.length > 0 ? (
                      savedTags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          sx={{
                            backgroundColor: "#565E6D",
                            color: "#fff",
                            borderRadius: "4px",
                          }}
                        />
                      ))
                    ) : (
                      <Typography variant="body1">No saved tags found.</Typography>
                    );
                  })()}
                </div>
              </Box>
            </div>
          )}

          {/* Character Section */}
          {selectedSection === "Character" && (
            <div
              style={{
                marginTop: "20px",
                padding: "10px",
                backgroundColor: "#F3F4F6",
                borderRadius: "0px",
                borderTop: "2px solid #ccc",
              }}
            >
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Character
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                {fabricTags["Character"]?.map((tag) => (
                  <div key={tag.id} style={{ textAlign: "start" }}>
                    <div
                      style={{
                        width: "122px",
                        height: "68px",
                        backgroundColor: "white",
                        borderRadius: "0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        border: styleState.character[tag.product_tag]
                          ? "2px solid #565E6D"
                          : "2px solid transparent",
                      }}
                      onClick={() =>
                        handleCheckboxChange("character", tag.product_tag)
                      }
                    >
                      <Typography>
                        {tag.product_tag.charAt(0).toUpperCase() +
                          tag.product_tag.slice(1)}
                      </Typography>
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={styleState.character[tag.product_tag] || false}
                          onChange={() =>
                            handleCheckboxChange("character", tag.product_tag)
                          }
                          sx={{
                            color: "#565E6D",
                            "&.Mui-checked": { color: "#565E6D" },
                            "& .MuiSvgIcon-root": { fontSize: 22 },
                          }}
                        />
                      }
                      label={
                        tag.product_tag.charAt(0).toUpperCase() +
                        tag.product_tag.slice(1)
                      }
                    />
                  </div>
                ))}
              </div>

              {/* Create New Tag Button */}
              <Button
                startIcon={<AddCircleOutlineIcon />}
                sx={{
                  mt: 4.5,
                  width: 200,
                  height: 28,
                  border: "1px solid #ccc",
                  borderRadius: "0px",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#b1b4b6" },
                }}
                onClick={() => setNewTagDialogOpen(true)}
              >
                Create New Tag
              </Button>

              {/* Save Changes Button */}
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Button
                  onClick={() => {
                    const selectedTags =
                    fabricTags["Character"]
                    ?.filter((tag) => styleState.character[tag.product_tag])
                    ?.map((tag) => tag.product_tag) || [];
                    localStorage.setItem(`savedTags_${productId}_${selectedSection}`, JSON.stringify(selectedTags));

                    const selectedIds = fabricTags["Character"]
                      ?.filter((tag) => styleState.character[tag.product_tag])
                      ?.map((tag) => tag.id) || [];
                      handleSubmit(selectedIds);
                    setLoading((prev) => !prev);
                  }}
                  sx={{
                    backgroundColor: "#565E6D",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#546e7a" },
                    borderRadius: "0px",
                  }}
                >
                  Save Changes
                </Button>
              </div>

              {/* Display Saved Tags */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  Saved Tag(s)
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                {(() => {
                    const savedTagsFromStorage = localStorage.getItem(`savedTags_${productId}_${selectedSection}`);
                    const savedTags = savedTagsFromStorage ? JSON.parse(savedTagsFromStorage) : [];

                    return savedTags.length > 0 ? (
                      savedTags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          sx={{
                            backgroundColor: "#565E6D",
                            color: "#fff",
                            borderRadius: "4px",
                          }}
                        />
                      ))
                    ) : (
                      <Typography variant="body1">No saved tags found.</Typography>
                    );
                  })()}
                </div>
              </Box>
            </div>
          )}

          {/* Weave Section */}
          {selectedSection === "Weave" && (
            <div
              style={{
                marginTop: "20px",
                padding: "10px",
                backgroundColor: "#F3F4F6",
                borderRadius: "0px",
                borderTop: "2px solid #ccc",
              }}
            >
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Weave
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                {fabricTags["Weave"]?.map((tag) => (
                  <div key={tag.id} style={{ textAlign: "start" }}>
                    <div
                      style={{
                        width: "122px",
                        height: "68px",
                        backgroundColor: "white",
                        borderRadius: "0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        border: styleState.weave[tag.product_tag]
                          ? "2px solid #565E6D"
                          : "2px solid transparent",
                      }}
                      onClick={() =>
                        handleCheckboxChange("weave", tag.product_tag)
                      }
                    >
                      <Typography>
                        {tag.product_tag.charAt(0).toUpperCase() +
                          tag.product_tag.slice(1)}
                      </Typography>
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={styleState.weave[tag.product_tag] || false}
                          onChange={() =>
                            handleCheckboxChange("weave", tag.product_tag)
                          }
                          sx={{
                            color: "#565E6D",
                            "&.Mui-checked": { color: "#565E6D" },
                            "& .MuiSvgIcon-root": { fontSize: 22 },
                          }}
                        />
                      }
                      label={
                        tag.product_tag.charAt(0).toUpperCase() +
                        tag.product_tag.slice(1)
                      }
                    />
                  </div>
                ))}
              </div>

              {/* Create New Tag Button */}
              <Button
                startIcon={<AddCircleOutlineIcon />}
                sx={{
                  mt: 4.5,
                  width: 200,
                  height: 28,
                  border: "1px solid #ccc",
                  borderRadius: "0px",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#b1b4b6" },
                }}
                onClick={() => setNewTagDialogOpen(true)}
              >
                Create New Tag
              </Button>

              {/* Save Changes Button */}
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Button
                  onClick={() => {
                    const selectedTags =
                    fabricTags["Weave"]
                    ?.filter((tag) => styleState.weave[tag.product_tag])
                    ?.map((tag) => tag.product_tag) || [];
                    localStorage.setItem(`savedTags_${productId}_${selectedSection}`, JSON.stringify(selectedTags));

                    const selectedIds = fabricTags["Weave"]
                      ?.filter((tag) => styleState.weave[tag.product_tag])
                      ?.map((tag) => tag.id) || [];
                      handleSubmit(selectedIds);
                    setLoading((prev) => !prev);
                  }}
                  sx={{
                    backgroundColor: "#565E6D",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#546e7a" },
                    borderRadius: "0px",
                  }}
                >
                  Save Changes
                </Button>
              </div>

              {/* Display Saved Tags */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  Saved Tag(s)
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                {(() => {
                    const savedTagsFromStorage = localStorage.getItem(`savedTags_${productId}_${selectedSection}`);
                    const savedTags = savedTagsFromStorage ? JSON.parse(savedTagsFromStorage) : [];

                    return savedTags.length > 0 ? (
                      savedTags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          sx={{
                            backgroundColor: "#565E6D",
                            color: "#fff",
                            borderRadius: "4px",
                          }}
                        />
                      ))
                    ) : (
                      <Typography variant="body1">No saved tags found.</Typography>
                    );
                  })()}
                </div>
              </Box>
            </div>
          )}

          {/* Structure Section */}
          {selectedSection === "Structure" && (
            <div
              style={{
                marginTop: "20px",
                padding: "10px",
                backgroundColor: "#F3F4F6",
                borderRadius: "0px",
                borderTop: "2px solid #ccc",
              }}
            >
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Structure
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                {fabricTags["Structure"]?.map((tag) => (
                  <div key={tag.id} style={{ textAlign: "start" }}>
                    <div
                      style={{
                        width: "122px",
                        height: "68px",
                        backgroundColor: "white",
                        borderRadius: "0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        border: styleState.structure[tag.product_tag]
                          ? "2px solid #565E6D"
                          : "2px solid transparent",
                      }}
                      onClick={() =>
                        handleCheckboxChange("structure", tag.product_tag)
                      }
                    >
                      <Typography>
                        {tag.product_tag.charAt(0).toUpperCase() +
                          tag.product_tag.slice(1)}
                      </Typography>
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={styleState.structure[tag.product_tag] || false}
                          onChange={() =>
                            handleCheckboxChange("structure", tag.product_tag)
                          }
                          sx={{
                            color: "#565E6D",
                            "&.Mui-checked": { color: "#565E6D" },
                            "& .MuiSvgIcon-root": { fontSize: 22 },
                          }}
                        />
                      }
                      label={
                        tag.product_tag.charAt(0).toUpperCase() +
                        tag.product_tag.slice(1)
                      }
                    />
                  </div>
                ))}
              </div>

              {/* Create New Tag Button */}
              <Button
                startIcon={<AddCircleOutlineIcon />}
                sx={{
                  mt: 4.5,
                  width: 200,
                  height: 28,
                  border: "1px solid #ccc",
                  borderRadius: "0px",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#b1b4b6" },
                }}
                onClick={() => setNewTagDialogOpen(true)}
              >
                Create New Tag
              </Button>

              {/* Save Changes Button */}
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Button
                  onClick={() => {
                    const selectedTags =
                    fabricTags["Structure"]
                    ?.filter((tag) => styleState.structure[tag.product_tag])
                    ?.map((tag) => tag.product_tag) || [];
                    localStorage.setItem(`savedTags_${productId}_${selectedSection}`, JSON.stringify(selectedTags));

                    const selectedIds = fabricTags["Structure"]
                      ?.filter((tag) => styleState.structure[tag.product_tag])
                      ?.map((tag) => tag.id) || [];
                      handleSubmit(selectedIds);
                    setLoading((prev) => !prev);
                  }}
                  sx={{
                    backgroundColor: "#565E6D",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#546e7a" },
                    borderRadius: "0px",
                  }}
                >
                  Save Changes
                </Button>
              </div>

              {/* Display Saved Tags */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  Saved Tag(s)
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                {(() => {
                    const savedTagsFromStorage = localStorage.getItem(`savedTags_${productId}_${selectedSection}`);
                    const savedTags = savedTagsFromStorage ? JSON.parse(savedTagsFromStorage) : [];

                    return savedTags.length > 0 ? (
                      savedTags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          sx={{
                            backgroundColor: "#565E6D",
                            color: "#fff",
                            borderRadius: "4px",
                          }}
                        />
                      ))
                    ) : (
                      <Typography variant="body1">No saved tags found.</Typography>
                    );
                  })()}
                </div>
              </Box>
            </div>
          )}

          {/* Yarn property Section */}
          {selectedSection === "Yarn property" && (
            <div
              style={{
                marginTop: "20px",
                padding: "10px",
                backgroundColor: "#F3F4F6",
                borderRadius: "0px",
                borderTop: "2px solid #ccc",
              }}
            >
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Yarn Property
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                {fabricTags["Yarn property"]?.map((tag) => (
                  <div key={tag.id} style={{ textAlign: "start" }}>
                    <div
                      style={{
                        width: "122px",
                        height: "68px",
                        backgroundColor: "white",
                        borderRadius: "0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        border: styleState.yarnProperty[tag.product_tag]
                          ? "2px solid #565E6D"
                          : "2px solid transparent",
                      }}
                      onClick={() =>
                        handleCheckboxChange("yarnProperty", tag.product_tag)
                      }
                    >
                      <Typography>
                        {tag.product_tag.charAt(0).toUpperCase() +
                          tag.product_tag.slice(1)}
                      </Typography>
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={styleState.yarnProperty[tag.product_tag] || false}
                          onChange={() =>
                            handleCheckboxChange(
                              "yarnProperty",
                              tag.product_tag
                            )
                          }
                          sx={{
                            color: "#565E6D",
                            "&.Mui-checked": { color: "#565E6D" },
                            "& .MuiSvgIcon-root": { fontSize: 22 },
                          }}
                        />
                      }
                      label={
                        tag.product_tag.charAt(0).toUpperCase() +
                        tag.product_tag.slice(1)
                      }
                    />
                  </div>
                ))}
              </div>

              {/* Create New Tag Button */}
              <Button
                startIcon={<AddCircleOutlineIcon />}
                sx={{
                  mt: 4.5,
                  width: 200,
                  height: 28,
                  border: "1px solid #ccc",
                  borderRadius: "0px",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#b1b4b6" },
                }}
                onClick={() => setNewTagDialogOpen(true)}
              >
                Create New Tag
              </Button>

              {/* Save Changes Button */}
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Button
                  onClick={() => {
                    const selectedTags =
                    fabricTags["Yarn property"]
                    ?.filter((tag) => styleState.yarnProperty[tag.product_tag])
                    ?.map((tag) => tag.product_tag) || [];
                    localStorage.setItem(`savedTags_${productId}_${selectedSection}`, JSON.stringify(selectedTags));

                    const selectedIds = fabricTags["Yarn property"]
                      ?.filter((tag) => styleState.yarnProperty[tag.product_tag])
                      ?.map((tag) => tag.id) || [];
                      handleSubmit(selectedIds);
                    setLoading((prev) => !prev);
                  }}
                  sx={{
                    backgroundColor: "#565E6D",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#546e7a" },
                    borderRadius: "0px",
                  }}
                >
                  Save Changes
                </Button>
              </div>

              {/* Display Saved Tags */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  Saved Tag(s)
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                {(() => {
                    const savedTagsFromStorage = localStorage.getItem(`savedTags_${productId}_${selectedSection}`);
                    const savedTags = savedTagsFromStorage ? JSON.parse(savedTagsFromStorage) : [];

                    return savedTags.length > 0 ? (
                      savedTags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          sx={{
                            backgroundColor: "#565E6D",
                            color: "#fff",
                            borderRadius: "4px",
                          }}
                        />
                      ))
                    ) : (
                      <Typography variant="body1">No saved tags found.</Typography>
                    );
                  })()}
                </div>
              </Box>
            </div>
          )}

          {/* Fabric Finish Section */}
          {selectedSection === "Fabric Finish" && (
            <div
              style={{
                marginTop: "20px",
                padding: "10px",
                backgroundColor: "#F3F4F6",
                borderRadius: "0px",
                borderTop: "2px solid #ccc",
              }}
            >
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Fabric Finish
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                {fabricTags["Fabric Finish"]?.map((tag) => (
                  <div key={tag.id} style={{ textAlign: "start" }}>
                    <div
                      style={{
                        width: "122px",
                        height: "68px",
                        backgroundColor: "white",
                        borderRadius: "0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        border: styleState.fabricFinish[tag.product_tag]
                          ? "2px solid #565E6D"
                          : "2px solid transparent",
                      }}
                      onClick={() =>
                        handleCheckboxChange("fabricFinish", tag.product_tag)
                      }
                    >
                      <Typography>
                        {tag.product_tag.charAt(0).toUpperCase() +
                          tag.product_tag.slice(1)}
                      </Typography>
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={styleState.fabricFinish[tag.product_tag] || false}
                          onChange={() =>
                            handleCheckboxChange(
                              "fabricFinish",
                              tag.product_tag
                            )
                          }
                          sx={{
                            color: "#565E6D",
                            "&.Mui-checked": { color: "#565E6D" },
                            "& .MuiSvgIcon-root": { fontSize: 22 },
                          }}
                        />
                      }
                      label={
                        tag.product_tag.charAt(0).toUpperCase() +
                        tag.product_tag.slice(1)
                      }
                    />
                  </div>
                ))}
              </div>

              {/* Create New Tag Button */}
              <Button
                startIcon={<AddCircleOutlineIcon />}
                sx={{
                  mt: 4.5,
                  width: 200,
                  height: 28,
                  border: "1px solid #ccc",
                  borderRadius: "0px",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#b1b4b6" },
                }}
                onClick={() => setNewTagDialogOpen(true)}
              >
                Create New Tag
              </Button>

              {/* Save Changes Button */}
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Button
                  onClick={() => {
                    const selectedTags =
                    fabricTags["Fabric Finish"]
                    ?.filter((tag) => styleState.fabricFinish[tag.product_tag])
                    ?.map((tag) => tag.product_tag) || [];
                    localStorage.setItem(`savedTags_${productId}_${selectedSection}`, JSON.stringify(selectedTags));

                    const selectedIds = fabricTags["Fabric Finish"]
                      ?.filter((tag) => styleState.fabricFinish[tag.product_tag])
                      ?.map((tag) => tag.id) || [];
                      handleSubmit(selectedIds);
                    setLoading((prev) => !prev);
                  }}
                  sx={{
                    backgroundColor: "#565E6D",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#546e7a" },
                    borderRadius: "0px",
                  }}
                >
                  Save Changes
                </Button>
              </div>

              {/* Display Saved Tags */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  Saved Tag(s)
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                {(() => {
                    const savedTagsFromStorage = localStorage.getItem(`savedTags_${productId}_${selectedSection}`);
                    const savedTags = savedTagsFromStorage ? JSON.parse(savedTagsFromStorage) : [];

                    return savedTags.length > 0 ? (
                      savedTags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          sx={{
                            backgroundColor: "#565E6D",
                            color: "#fff",
                            borderRadius: "4px",
                          }}
                        />
                      ))
                    ) : (
                      <Typography variant="body1">No saved tags found.</Typography>
                    );
                  })()}
                </div>
              </Box>
            </div>
          )}

          {/* Season Section */}
          {selectedSection === "Season" && (
            <div
              style={{
                marginTop: "20px",
                padding: "10px",
                backgroundColor: "#F3F4F6",
                borderRadius: "0px",
                borderTop: "2px solid #ccc",
              }}
            >
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Season
              </Typography>
              <FormControl
                component="fieldset"
                sx={{
                  mt: 2,
                  padding: "10px 0",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <RadioGroup
                  row
                  value={season}
                  onChange={(e) => setSeason(e.target.value)}
                >
                  {fabricTags["Season"]?.map((tag) => (
                    <FormControlLabel
                      key={tag.id}
                      value={tag.product_tag}
                      control={
                        <Radio
                          sx={{
                            color: "black",
                            "&.Mui-checked": { color: "black" },
                          }}
                        />
                      }
                      label={
                        tag.product_tag.charAt(0).toUpperCase() +
                        tag.product_tag.slice(1)
                      }
                    />
                  ))}
                </RadioGroup>

                {/* Create New Tag Button */}
                <Button
                  startIcon={<AddCircleOutlineIcon />}
                  sx={{
                    mt: 4.5,
                    width: 200,
                    height: 28,
                    border: "1px solid #ccc",
                    borderRadius: "0px",
                    backgroundColor: "white",
                    color: "black",
                    fontWeight: "bold",
                    "&:hover": { backgroundColor: "#b1b4b6" },
                  }}
                  onClick={() => setNewTagDialogOpen(true)} 
                >
                  Create New Tag
                </Button>
              </FormControl>

              {/* Save Changes Button */}
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Button
                  onClick={() => {
                    const selectedTag = fabricTags["Season"]?.find(
                      (tag) => tag.product_tag === season
                    );
                    handleSubmit(selectedTag ? [selectedTag.id] : []);
                    if (selectedTag) {
                      const updatedTags = [selectedTag.product_tag]; 
                      localStorage.setItem(`savedTags_${productId}_${selectedSection}`, JSON.stringify(updatedTags));
                      setLoading((prev) => !prev);
                    }
                  }}
                  sx={{
                    backgroundColor: "#565E6D",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#546e7a" },
                    borderRadius: "0px",
                  }}
                >
                  Save Changes
                </Button>
              </div>

              {/* Display Saved Tags */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  Saved Tag(s)
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                {(() => {
                    const savedTagsFromStorage = localStorage.getItem(`savedTags_${productId}_${selectedSection}`);
                    const savedTags = savedTagsFromStorage ? JSON.parse(savedTagsFromStorage) : [];

                    return savedTags.length > 0 ? (
                      savedTags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          sx={{
                            backgroundColor: "#565E6D",
                            color: "#fff",
                            borderRadius: "4px",
                          }}
                        />
                      ))
                    ) : (
                      <Typography variant="body1">No saved tags found.</Typography>
                    );
                  })()}
                </div>
              </Box>
            </div>
          )}

          {/* Category Section */}
          {selectedSection === "Category" && (
            <div
              style={{
                marginTop: "20px",
                padding: "10px",
                backgroundColor: "#F3F4F6",
                borderRadius: "0px",
                borderTop: "2px solid #ccc",
              }}
            >
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Category
              </Typography>
              <FormControl
                component="fieldset"
                sx={{
                  mt: 2,
                  padding: "10px 0",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <RadioGroup
                  row
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  {fabricTags["Category"]?.map((tag) => (
                    <FormControlLabel
                      key={tag.id}
                      value={tag.product_tag}
                      control={
                        <Radio
                          sx={{
                            color: "black",
                            "&.Mui-checked": { color: "black" },
                          }}
                        />
                      }
                      label={
                        tag.product_tag.charAt(0).toUpperCase() +
                        tag.product_tag.slice(1)
                      }
                    />
                  ))}
                </RadioGroup>

                {/* Create New Tag Button */}
                <Button
                  startIcon={<AddCircleOutlineIcon />}
                  sx={{
                    mt: 4.5,
                    width: 200,
                    height: 28,
                    border: "1px solid #ccc",
                    borderRadius: "0px",
                    backgroundColor: "white",
                    color: "black",
                    fontWeight: "bold",
                    "&:hover": { backgroundColor: "#b1b4b6" },
                  }}
                  onClick={() => setNewTagDialogOpen(true)}
                >
                  Create New Tag
                </Button>
              </FormControl>

              {/* Save Changes Button */}
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Button
                  onClick={() => {
                    const selectedTag = fabricTags["Category"]?.find(
                      (tag) => tag.product_tag === category
                    );
                    handleSubmit(selectedTag ? [selectedTag.id] : []);
                    if (selectedTag) {
                      const updatedTags = [selectedTag.product_tag]; 
                      localStorage.setItem(`savedTags_${productId}_${selectedSection}`, JSON.stringify(updatedTags));
                      setLoading((prev) => !prev);
                    }
                  }}
                  sx={{
                    backgroundColor: "#565E6D",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#546e7a" },
                    borderRadius: "0px",
                  }}
                >
                  Save Changes
                </Button>
              </div>

              {/* Display Saved Tags */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  Saved Tag(s)
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                {(() => {
                     const savedTagsFromStorage = localStorage.getItem(`savedTags_${productId}_${selectedSection}`);
                     const savedTags = savedTagsFromStorage ? JSON.parse(savedTagsFromStorage) : [];

                    return savedTags.length > 0 ? (
                      savedTags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          sx={{
                            backgroundColor: "#565E6D",
                            color: "#fff",
                            borderRadius: "4px",
                          }}
                        />
                      ))
                    ) : (
                      <Typography variant="body1">No saved tags found.</Typography>
                    );
                  })()}
                </div>
              </Box>
            </div>
          )}

          {/* Style Section */}
          {selectedSection === "Style" && (
            <div
              style={{
                marginTop: "20px",
                padding: "10px",
                backgroundColor: "#F3F4F6",
                borderRadius: "0px",
                borderTop: "2px solid #ccc",
              }}
            >
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Style
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                {fabricTags["Style"]?.map((tag) => (
                  <div key={tag.id} style={{ textAlign: "start" }}>
                    <div
                      style={{
                        width: "122px",
                        height: "68px",
                        backgroundColor: "white",
                        borderRadius: "0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        border: styleState.style[tag.product_tag]
                          ? "2px solid #565E6D"
                          : "2px solid transparent",
                      }}
                      onClick={() =>
                        handleCheckboxChange("style", tag.product_tag)
                      }
                    >
                      <Typography>
                        {tag.product_tag.charAt(0).toUpperCase() +
                          tag.product_tag.slice(1)}
                      </Typography>
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={styleState.style[tag.product_tag] || false}
                          onChange={() =>
                            handleCheckboxChange("style", tag.product_tag)
                          }
                          sx={{
                            color: "#565E6D",
                            "&.Mui-checked": { color: "#565E6D" },
                            "& .MuiSvgIcon-root": { fontSize: 22 },
                          }}
                        />
                      }
                      label={
                        tag.product_tag.charAt(0).toUpperCase() +
                        tag.product_tag.slice(1)
                      }
                    />
                  </div>
                ))}
              </div>

              {/* Create New Tag Button */}
              <Button
                startIcon={<AddCircleOutlineIcon />}
                sx={{
                  mt: 4.5,
                  width: 200,
                  height: 28,
                  border: "1px solid #ccc",
                  borderRadius: "0px",
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  "&:hover": { backgroundColor: "#b1b4b6" },
                }}
                onClick={() => setNewTagDialogOpen(true)}
              >
                Create New Tag
              </Button>

              {/* Save Changes Button */}
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Button
                  onClick={() => {
                    const selectedTags =
                    fabricTags["Style"]
                    ?.filter((tag) => styleState.style[tag.product_tag])
                    ?.map((tag) => tag.product_tag) || [];
                    localStorage.setItem(`savedTags_${productId}_${selectedSection}`, JSON.stringify(selectedTags));

                    const selectedIds = fabricTags["Style"]
                      ?.filter((tag) => styleState.style[tag.product_tag])
                      ?.map((tag) => tag.id) || [];
                      handleSubmit(selectedIds);
                    setLoading((prev) => !prev);
                  }}
                  sx={{
                    backgroundColor: "#565E6D",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#546e7a" },
                    borderRadius: "0px",
                  }}
                >
                  Save Changes
                </Button>
              </div>

              {/* Display Saved Tags */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  Saved Tag(s)
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                {(() => {
                    const savedTagsFromStorage = localStorage.getItem(`savedTags_${productId}_${selectedSection}`);
                    const savedTags = savedTagsFromStorage ? JSON.parse(savedTagsFromStorage) : [];

                    return savedTags.length > 0 ? (
                      savedTags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          sx={{
                            backgroundColor: "#565E6D",
                            color: "#fff",
                            borderRadius: "4px",
                          }}
                        />
                      ))
                    ) : (
                      <Typography variant="body1">No saved tags found.</Typography>
                    );
                  })()}
                </div>
              </Box>
            </div>
          )}

          {/* Denim Section */}
          {selectedSection === "Denim" && (
            <div
              style={{
                marginTop: "20px",
                padding: "10px",
                backgroundColor: "#F3F4F6",
                borderRadius: "0px",
                borderTop: "2px solid #ccc",
              }}
            >
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Denim
              </Typography>
              <FormControl
                component="fieldset"
                sx={{
                  mt: 2,
                  padding: "10px 0",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <RadioGroup
                  row
                  value={denim}
                  onChange={(e) => setDenim(e.target.value)}
                >
                  {fabricTags["Denim"]?.map((tag) => (
                    <FormControlLabel
                      key={tag.id}
                      value={tag.product_tag}
                      control={
                        <Radio
                          sx={{
                            color: "black",
                            "&.Mui-checked": { color: "black" },
                          }}
                        />
                      }
                      label={
                        tag.product_tag.charAt(0).toUpperCase() +
                        tag.product_tag.slice(1)
                      }
                    />
                  ))}
                </RadioGroup>

                {/* Create New Tag Button */}
                <Button
                  startIcon={<AddCircleOutlineIcon />}
                  sx={{
                    mt: 4.5,
                    width: 200,
                    height: 28,
                    border: "1px solid #ccc",
                    borderRadius: "0px",
                    backgroundColor: "white",
                    color: "black",
                    fontWeight: "bold",
                    "&:hover": { backgroundColor: "#b1b4b6" },
                  }}
                  onClick={() => setNewTagDialogOpen(true)}
                >
                  Create New Tag
                </Button>
              </FormControl>

              {/* Save Changes Button */}
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Button
                  onClick={() => {
                    const selectedTag = fabricTags["Denim"]?.find(
                      (tag) => tag.product_tag === denim
                    );
                    handleSubmit(selectedTag ? [selectedTag.id] : []);
                    if (selectedTag) {
                      const updatedTags = [selectedTag.product_tag]; 
                      localStorage.setItem(`savedTags_${productId}_${selectedSection}`, JSON.stringify(updatedTags));
                      setLoading((prev) => !prev);
                    }
                  }}
                  sx={{
                    backgroundColor: "#565E6D",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#546e7a" },
                    borderRadius: "0px",
                  }}
                >
                  Save Changes
                </Button>
              </div>

              {/* Display Saved Tags */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  Saved Tag(s)
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                {(() => {
                    const savedTagsFromStorage = localStorage.getItem(`savedTags_${productId}_${selectedSection}`);
                    const savedTags = savedTagsFromStorage ? JSON.parse(savedTagsFromStorage) : [];

                    return savedTags.length > 0 ? (
                      savedTags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          sx={{
                            backgroundColor: "#565E6D",
                            color: "#fff",
                            borderRadius: "4px",
                          }}
                        />
                      ))
                    ) : (
                      <Typography variant="body1">No saved tags found.</Typography>
                    );
                  })()}
                </div>
              </Box>
            </div>
          )}

          {/* Sustainability Section */}
          {selectedSection === "Sustainability" && (
            <div
              style={{
                marginTop: "20px",
                padding: "10px",
                backgroundColor: "#F3F4F6",
                borderRadius: "0px",
                borderTop: "2px solid #ccc",
              }}
            >
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Sustainability
              </Typography>
              <FormControl
                component="fieldset"
                sx={{
                  mt: 2,
                  padding: "10px 0",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <RadioGroup
                  row
                  value={sustainability}
                  onChange={(e) => setSustainability(e.target.value)}
                >
                  {fabricTags["Sustainability"]?.map((tag) => (
                    <FormControlLabel
                      key={tag.id}
                      value={tag.product_tag}
                      control={
                        <Radio
                          sx={{
                            color: "black",
                            "&.Mui-checked": { color: "black" },
                          }}
                        />
                      }
                      label={
                        tag.product_tag.charAt(0).toUpperCase() +
                        tag.product_tag.slice(1)
                      }
                    />
                  ))}
                </RadioGroup>

                {/* Create New Tag Button */}
                <Button
                  startIcon={<AddCircleOutlineIcon />}
                  sx={{
                    mt: 4.5,
                    width: 200,
                    height: 28,
                    border: "1px solid #ccc",
                    borderRadius: "0px",
                    backgroundColor: "white",
                    color: "black",
                    fontWeight: "bold",
                    "&:hover": { backgroundColor: "#b1b4b6" },
                  }}
                  onClick={() => setNewTagDialogOpen(true)}
                >
                  Create New Tag
                </Button>
              </FormControl>

              {/* Save Changes Button */}
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Button
                  onClick={() => {
                    const selectedTag = fabricTags["Sustainability"]?.find(
                      (tag) => tag.product_tag === sustainability
                    );
                    handleSubmit(selectedTag ? [selectedTag.id] : []);
                    if (selectedTag) {
                      const updatedTags = [selectedTag.product_tag]; 
                      localStorage.setItem(`savedTags_${productId}_${selectedSection}`, JSON.stringify(updatedTags));
                      setLoading((prev) => !prev);
                    }
                  }}
                  sx={{
                    backgroundColor: "#565E6D",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#546e7a" },
                    borderRadius: "0px",
                  }}
                >
                  Save Changes
                </Button>
              </div>

              {/* Display Saved Tags */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  Saved Tag(s)
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                {(() => {
                    const savedTagsFromStorage = localStorage.getItem(`savedTags_${productId}_${selectedSection}`);
                    const savedTags = savedTagsFromStorage ? JSON.parse(savedTagsFromStorage) : [];

                    return savedTags.length > 0 ? (
                      savedTags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          sx={{
                            backgroundColor: "#565E6D",
                            color: "#fff",
                            borderRadius: "4px",
                          }}
                        />
                      ))
                    ) : (
                      <Typography variant="body1">No saved tags found.</Typography>
                    );
                  })()}
                </div>
              </Box>
            </div>
          )}

          {/* Dye technique Section */}
          {selectedSection === "Dye technique" && (
            <div
              style={{
                marginTop: "20px",
                padding: "10px",
                backgroundColor: "#F3F4F6",
                borderRadius: "0px",
                borderTop: "2px solid #ccc",
              }}
            >
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Dye technique
              </Typography>
              <FormControl
                component="fieldset"
                sx={{
                  mt: 2,
                  padding: "10px 0",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <RadioGroup
                  row
                  value={dyeTechnique}
                  onChange={(e) => setDyeTechnique(e.target.value)}
                >
                  {fabricTags["Dye technique"]?.map((tag) => (
                    <FormControlLabel
                      key={tag.id}
                      value={tag.product_tag}
                      control={
                        <Radio
                          sx={{
                            color: "black",
                            "&.Mui-checked": { color: "black" },
                          }}
                        />
                      }
                      label={
                        tag.product_tag.charAt(0).toUpperCase() +
                        tag.product_tag.slice(1)
                      }
                    />
                  ))}
                </RadioGroup>

                {/* Create New Tag Button */}
                <Button
                  startIcon={<AddCircleOutlineIcon />}
                  sx={{
                    mt: 4.5,
                    width: 200,
                    height: 28,
                    border: "1px solid #ccc",
                    borderRadius: "0px",
                    backgroundColor: "white",
                    color: "black",
                    fontWeight: "bold",
                    "&:hover": { backgroundColor: "#b1b4b6" },
                  }}
                  onClick={() => setNewTagDialogOpen(true)}
                >
                  Create New Tag
                </Button>
              </FormControl>

              {/* Save Changes Button */}
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Button
                  onClick={() => {
                    const selectedTag = fabricTags["Dye technique"]?.find(
                      (tag) => tag.product_tag === dyeTechnique
                    );
                    handleSubmit(selectedTag ? [selectedTag.id] : []);
                    if (selectedTag) {
                      const updatedTags = [selectedTag.product_tag]; 
                      localStorage.setItem(`savedTags_${productId}_${selectedSection}`, JSON.stringify(updatedTags));
                      setLoading((prev) => !prev);
                    }
                  }}
                  sx={{
                    backgroundColor: "#565E6D",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#546e7a" },
                    borderRadius: "0px",
                  }}
                >
                  Save Changes
                </Button>
              </div>

              {/* Display Saved Tags */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  Saved Tag(s)
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                {(() => {
                    const savedTagsFromStorage = localStorage.getItem(`savedTags_${productId}_${selectedSection}`);
                    const savedTags = savedTagsFromStorage ? JSON.parse(savedTagsFromStorage) : [];

                    return savedTags.length > 0 ? (
                      savedTags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          sx={{
                            backgroundColor: "#565E6D",
                            color: "#fff",
                            borderRadius: "4px",
                          }}
                        />
                      ))
                    ) : (
                      <Typography variant="body1">No saved tags found.</Typography>
                    );
                  })()}
                </div>
              </Box>
            </div>
          )}

          {/* Dye used Section */}
          {selectedSection === "Dye used" && (
            <div
              style={{
                marginTop: "20px",
                padding: "10px",
                backgroundColor: "#F3F4F6",
                borderRadius: "0px",
                borderTop: "2px solid #ccc",
              }}
            >
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                Dye used
              </Typography>
              <FormControl
                component="fieldset"
                sx={{
                  mt: 2,
                  padding: "10px 0",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <RadioGroup
                  row
                  value={dyeUsed}
                  onChange={(e) => setDyeUsed(e.target.value)}
                >
                  {fabricTags["Dye used"]?.map((tag) => (
                    <FormControlLabel
                      key={tag.id}
                      value={tag.product_tag}
                      control={
                        <Radio
                          sx={{
                            color: "black",
                            "&.Mui-checked": { color: "black" },
                          }}
                        />
                      }
                      label={
                        tag.product_tag.charAt(0).toUpperCase() +
                        tag.product_tag.slice(1)
                      }
                    />
                  ))}
                </RadioGroup>

                {/* Create New Tag Button */}
                <Button
                  startIcon={<AddCircleOutlineIcon />}
                  sx={{
                    mt: 4.5,
                    width: 200,
                    height: 28,
                    border: "1px solid #ccc",
                    borderRadius: "0px",
                    backgroundColor: "white",
                    color: "black",
                    fontWeight: "bold",
                    "&:hover": { backgroundColor: "#b1b4b6" },
                  }}
                  onClick={() => setNewTagDialogOpen(true)}
                >
                  Create New Tag
                </Button>
              </FormControl>

              {/* Save Changes Button */}
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Button
                  onClick={() => {
                    const selectedTag = fabricTags["Dye used"]?.find(
                      (tag) => tag.product_tag === dyeUsed
                    );
                    handleSubmit(selectedTag ? [selectedTag.id] : []);
                    if (selectedTag) {
                      const updatedTags = [selectedTag.product_tag]; 
                      localStorage.setItem(`savedTags_${productId}_${selectedSection}`, JSON.stringify(updatedTags));
                      setLoading((prev) => !prev);
                    }
                  }}
                  sx={{
                    backgroundColor: "#565E6D",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#546e7a" },
                    borderRadius: "0px",
                  }}
                >
                  Save Changes
                </Button>
              </div>

              {/* Display Saved Tags */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  Saved Tag(s)
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                  {(() => {
                    const savedTagsFromStorage = localStorage.getItem(`savedTags_${productId}_${selectedSection}`);
                    const savedTags = savedTagsFromStorage ? JSON.parse(savedTagsFromStorage) : [];

                    return savedTags.length > 0 ? (
                      savedTags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          sx={{
                            backgroundColor: "#565E6D",
                            color: "#fff",
                            borderRadius: "4px",
                          }}
                        />
                      ))
                    ) : (
                      <Typography variant="body1">No saved tags found.</Typography>
                    );
                  })()}
                </div>
              </Box>
            </div>
          )}
        </AccordionDetails>
      </Accordion>

      <Dialog 
        open={newTagDialogOpen} 
        onClose={() => setNewTagDialogOpen(false)} 
        fullWidth 
        maxWidth="sm"
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '0px',
            backgroundColor: '#F3F4F6',
          }
        }}
      >
        <DialogTitle 
          sx={{ 
            backgroundColor: '#565E6D', 
            color: '#fff', 
            fontWeight: 'bold',
            fontSize: '1rem',
            padding: '16px 24px',
          }}
        >
          Create New Tag for {selectedSection}
        </DialogTitle>
        
        <DialogContent sx={{ padding: '24px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} mt={2}>
              <TextField
                fullWidth
                label="Tag Name"
                value={newTagData.product_tag}
                onChange={(e) => setNewTagData(prev => ({ ...prev, product_tag: e.target.value }))}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '0px',
                    backgroundColor: '#fff',
                    '& fieldset': {
                      borderColor: '#BCC1CA',
                    },
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <input
                accept="image/*"
                type="file"
                style={{ display: 'none' }}
                id="tag-image-upload"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    const img = new Image();
                    const url = URL.createObjectURL(file);
                    
                    img.onload = () => {
                      if (img.width === 120 && img.height === 60) {
                        setTagImageUrl(url);
                        setImageFile(file);
                      } else {
                        alert('Image must be exactly 120px x 60px');
                        URL.revokeObjectURL(url);
                      }
                    };
                    
                    img.src = url;
                  }
                }}
              />
              
              <Box 
                sx={{ 
                  mt: 2,
                  width: 120,
                  height: 60,
                  border: "1px solid #BCC1CA",
                  backgroundColor: "#FAFAFB",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  '&:hover': {
                    borderColor: '#565E6D',
                  }
                }}
                component="label"
                htmlFor="tag-image-upload"
              >
                {tagImageUrl ? (
                  <img
                    src={tagImageUrl}
                    alt="Preview"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                ) : (
                  <LandscapeIcon sx={{ color: "#BCC1CA" }} />
                )}
              </Box>
              
              <Box sx={{ 
                display: 'flex',
                alignItems: 'center',
                mt: 1,
                gap: 1
              }}>
                <label htmlFor="tag-image-upload" style={{ cursor: 'pointer' }}>
                  <Button
                    variant="outlined"
                    component="span"
                    startIcon={<FileUploadOutlinedIcon sx={{ color: '#565E6D' }} />}
                    size="small"
                    sx={{
                      borderColor: '#BCC1CA',
                      color: '#565E6D',
                      '&:hover': {
                        borderColor: '#565E6D',
                        backgroundColor: '#F3F4F6'
                      }
                    }}
                  >
                    Upload Image
                  </Button>
                </label>
                <Typography variant="caption" sx={{ color: '#565E6D' }}>
                  120x60px required
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ padding: '16px 24px', borderTop: '1px solid #E5E7EB' }}>
          <Button 
            onClick={() => setNewTagDialogOpen(false)}
            sx={{
              color: '#565E6D',
              border: '1px solid #BCC1CA',
              borderRadius: '0px',
              padding: '6px 16px',
              '&:hover': {
                backgroundColor: '#E5E7EB',
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleCreateNewTag}
            disabled={!newTagData.product_tag}
            sx={{
              backgroundColor: '#565E6D',
              color: '#fff',
              borderRadius: '0px',
              padding: '6px 20px',
              '&:hover': {
                backgroundColor: '#424956',
              },
              '&:disabled': {
                backgroundColor: '#E5E7EB',
                color: '#9CA3AF'
              }
            }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
