import React from 'react';
import { Box } from '@mui/material';
import LandingPageBanner from './LandingPageBanner';
import LandingPageHeaderIcons from './LandingPageHeaderIcons';
import LandingPageIcons from './LandingPageIcons';
import LandingPageFabric from './LandingPageFabric';
import LandingPagePromotionalBanner from './LandingPagePromotionalBanner';
import LandingPageEnquiry from './LandingPageEnquiry';
import LandingPageFeatured from './LandingPageFeatured';
import LandingPageMoodBoard from './LandingPageMoodBoard';

export default function LandingPage() {
    return (
        <Box sx={{ width: '95%', paddingLeft: 3 }}>
            {/* Hero Banner Section */}
            <LandingPageBanner />
            {/* Section 1: Header Icons */}
            <LandingPageHeaderIcons/>
            {/* Section 2: Icon Tiles */}
            <LandingPageIcons />
            {/* Section 3: Special Fabrics */}
            <LandingPageFabric />
            {/* Section 4: Promotion Banner */}
            <LandingPagePromotionalBanner />
            {/* Section 5: Form */}
            <LandingPageEnquiry />
            {/* Section 6: Featured */}
            <LandingPageFeatured />
            {/* Section 7: Moodboard */}
            <LandingPageMoodBoard />
        </Box>
    );
}


