import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import { Box, Grid, Button, IconButton, Typography, TextField, Select, MenuItem } from '@mui/material';
import { getData, postData } from "../../../ServerRequest";
import { DataGrid } from '@mui/x-data-grid';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import LandscapeIcon from '@mui/icons-material/Landscape';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export default function CreateTag() {
    const searchParams = new URLSearchParams(window.location.search);
    const productId = searchParams.get('product_id');
    const [selectedRow, setSelectedRow] = useState(null);
    const [tagsData, setTagsData] = useState([]);
    const [liveTags, setLiveTagsData] = useState([]);
    const [archivedTags, setArchivedTagsData] = useState([]);
    const [deletedTags, setDeletedTagsData] = useState([]);
    const [familyData, setFamilyData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [selectedFamily, setSelectedFamily] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [loading, setLoading] = useState(true);
    const [counts, setCounts] = useState({
        liveTags: 0,
        archivedTags: 0,
        deletedTags: 0,
    });
    const [productTag, setProductTag] = useState('');
    const [createdBy, setCreatedBy] = useState('');
    const [updatedBy, setUpdatedBy] = useState('');
    const [tagImageUrl, settagImageUrl] = useState('');
    const [status, setStatus] = useState('');    

    const handleTagSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        const tagsData = {
            'id': productId ? productId : '',
            'product_tag': productTag ? productTag : '',
            'tag_family': selectedFamily ? selectedFamily : '',
            'category': selectedCategory ? selectedCategory : '',
            'created_by': createdBy ? createdBy : '',
            'updated_by': updatedBy ? updatedBy : '',
            'tag_image_url': tagImageUrl ? tagImageUrl : '',
            'status': status ? status : 1,
        };

        const convertToBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        };
    
        if (tagsData.tag_image && tagsData.tag_image instanceof Blob) {
            tagsData.tag_image = await convertToBase64(tagsData.tag_image);
        }

        try {
            console.log(tagsData);
            const res = await postData('/api/product/acc_tag_master', tagsData);
            console.log(res);
            if (res['data']['code'] === '200') {
                setLoading(false);
                alert('Tag updated successfully');
                window.location.href = '/admin/products/tagMaster/tags';
            } else {
                alert('Tag update failed');
                console.error('Failed to submit form:', res.statusText);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setLoading(false);
        }
    };    

    useEffect(() => {
        async function fetchData() {
            try {
                let resData = await getData('/api/product/tags');
                console.log(resData);
                let tags_data = resData['data']['tags']
                if (tags_data) {
                    console.log(tags_data);
                    setTagsData(tags_data);
                } else {
                    console.error("Error fetching tags data:", resData.message);
                }

                let familyRes = await getData('/api/product/acc_tag_family');
                console.log(familyRes);
                let family_data = familyRes['data']['tag_families']
                if (family_data) {
                    console.log(family_data);
                    setFamilyData(family_data);
                } else {
                    console.error("Error fetching tags family data:", familyRes.message);
                }

                let categoryRes = await getData('/api/product/acc_tag_category');
                console.log(categoryRes);
                let category_data = categoryRes['data']['tag_categories']
                if (category_data) {
                    console.log(category_data);
                    setCategoryData(category_data);
                } else {
                    console.error("Error fetching tags category data:", categoryRes.message);
                }

                let masterRes = await getData('/api/product/acc_tag_master');
                console.log(categoryRes);
                let master_data = masterRes['data']['tag_masters']
                let live_tags = masterRes['data']['live_tags']
                let archived_tags = masterRes['data']['archived_tags']
                let deleted_tags = masterRes['data']['deleted_tags']
                if (master_data) {
                    console.log(master_data);
                    setTagsData(live_tags);
                    setLiveTagsData(live_tags);
                    setArchivedTagsData(archived_tags);
                    setDeletedTagsData(deleted_tags);
                    setCounts({
                        liveTags: masterRes['data']['live_tags_count'],
                        archivedTags: masterRes['data']['archived_tags_count'],
                        deletedTags: masterRes['data']['deleted_tags_count'],
                    });
                } else {
                    console.error("Error fetching tag master data:", masterRes.message);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        }

        fetchData();

    }, []);  

    const [newCategory, setNewCategory] = useState('');
    const [isCreatingCategory, setIsCreatingCategory] = useState(false);
    const [newFamily, setNewFamily] = useState('');
    const [isCreatingFamily, setIsCreatingFamily] = useState(false);
    const [tagImage, setTagImage] = useState('');

    const handleCreateNewCategory = () => {
        setIsCreatingCategory(true);
        setNewCategory("");
    };
    
    const handleCreateNewFamily = () => {
        setIsCreatingFamily(true);
        setNewFamily("");
    };

    const handleCategorySubmit = (e) => {
        if (e.key === "Enter" && newCategory.trim() !== "") {
            const newCategoryObj = {
            id: categoryData.length + 1, // Simple ID assignment
            category: newCategory.trim(),
            };
    
            setCategoryData([...categoryData, newCategoryObj]); // Add new category to list
            setSelectedCategory(newCategoryObj.id); // Select the new category
            setIsCreatingCategory(false); // Close input field
        } else if (e.key === "Escape") {
            setIsCreatingCategory(false); // Cancel category creation on Escape key
        }
    };

    const handleFamilySubmit = (e) => {
        if ((e.type === "keydown" && e.key === "Enter") || e.type === "click") {
            if (newFamily.trim() !== "") {
                const newFamilyObj = {
                    id: familyData.length + 1,
                    tag_family: newFamily.trim(),
                };

                setFamilyData([...familyData, newFamilyObj]); // Add new family
                setSelectedFamily(newFamilyObj.id); // Select new family
                setIsCreatingFamily(false); // Hide input field
                setNewFamily(""); // Reset input
            }
        }
    };

    const handleTagImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const tagImg = new Image();
            const tagImageUrl = URL.createObjectURL(file);
            tagImg.src = tagImageUrl;
            tagImg.onload = () => {
                if (tagImg.width === 120 && tagImg.height === 60) {
                    setTagImage((prevState) => ({
                        ...prevState,
                        tag_image: file,  // Store the file object
                        tagImageUrl: tagImageUrl  // Store the object URL for preview
                    }));
                    console.log("Tag image uploaded successfully:", file);
                    // You can use tagImageUrl for preview if needed
                } else {
                    alert('Image must be exactly 120px wide and 60px tall.');
                }
                URL.revokeObjectURL(tagImageUrl); // Free up memory
            };
            tagImg.onerror = () => {
                alert('Failed to load image. Please try again.');
                URL.revokeObjectURL(tagImageUrl); // Free up memory
            };
        }
    };   
    
    return (
      <Box sx={{ width: "100%", mb: 5 }}>
        <Grid container sx={{ flex: 1, height: "100%" }}>
          <Box sx={{ width: "100%", height: "100%" }}>
            {/* Create A New Tag Section */}
            <Box
              sx={{
                mt: 3,
                mb: 6,
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontFamily: "Inter", fontWeight: "700", fontSize: 16 }}
              >
                Create A New Tag
              </Typography>

              <Grid container spacing={2} sx={{ mt: 1 }}>
                {/* Product Tag */}
                <Grid item xs={12} sm={6} md={3}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#424956",
                      fontFamily: "Inter",
                      fontWeight: 700,
                      fontSize: 14,
                    }}
                  >
                    Product Tag
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        height: "35px",
                        borderRadius: "0px",
                      },
                    }}
                    value={productTag}
                    onChange={(e) => setProductTag(e.target.value)}
                  />
                </Grid>

                {/* Tag Category */}
                <Grid item xs={12} sm={6} md={3}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#424956",
                      fontFamily: "Inter",
                      fontWeight: 700,
                      fontSize: 14,
                    }}
                  >
                    Tag Category
                  </Typography>
                  {isCreatingCategory ? (
                    <TextField
                      fullWidth
                      size="small"
                      sx={{
                        fontSize: 14,
                        "& .MuiOutlinedInput-root": {
                          height: "35px",
                          borderRadius: "0px",
                        },
                      }}
                      value={newCategory}
                      onChange={(e) => setNewCategory(e.target.value)}
                      onKeyDown={handleCategorySubmit}
                      autoFocus
                      placeholder="Enter new category"
                    />
                  ) : (
                    <Select
                      fullWidth
                      size="small"
                      sx={{ height: "35px", borderRadius: "0px" }}
                      value={selectedCategory}
                      onChange={(event) => setSelectedCategory(event.target.value)}
                    >
                      <MenuItem
                        onClick={handleCreateNewCategory}
                        value=""
                        sx={{ border: "1px solid #bcc1ca", fontSize: 14 }}
                      >
                        <AddCircleOutlineIcon sx={{ fontSize: 15, mr: 1 }} />
                        Create a new Category
                      </MenuItem>
                      {categoryData.map((category) => (
                        <MenuItem
                          key={category.id}
                          value={category.id}
                          sx={{ fontSize: 14 }}
                        >
                          {category.category}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Grid>

                {/* Tag Family */}
                <Grid item xs={12} sm={6} md={3}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#424956",
                      fontFamily: "Inter",
                      fontWeight: 700,
                      fontSize: 14,
                    }}
                  >
                    Tag Family
                  </Typography>
                  {isCreatingFamily ? (
                    <TextField
                      fullWidth
                      size="small"
                      sx={{
                        fontSize: 14,
                        "& .MuiOutlinedInput-root": {
                          height: "35px",
                          borderRadius: "0px",
                        },
                      }}
                      value={newFamily}
                      onChange={(e) => setNewFamily(e.target.value)}
                      onKeyDown={handleFamilySubmit}
                      autoFocus
                      placeholder="Enter new family"
                    />
                  ) : (
                    <Select
                      fullWidth
                      size="small"
                      sx={{ height: "35px", borderRadius: "0px" }}
                      value={selectedFamily}
                      onChange={(event) =>
                        setSelectedFamily(event.target.value)
                      }
                    >
                      <MenuItem
                        onClick={handleCreateNewFamily}
                        value=""
                        sx={{ border: "1px solid #bcc1ca", fontSize: 14 }}
                      >
                        <AddCircleOutlineIcon sx={{ fontSize: 15, mr: 1 }} />
                        Create a new Family
                      </MenuItem>
                      {familyData.map((family) => (
                        <MenuItem
                          key={family.id}
                          value={family.id}
                          sx={{ fontSize: 14 }}
                        >
                          {family.tag_family}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Grid>

                {/* Upload Section */}
                <Grid item xs={12} sm={6} md={3}>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    id="upload-button"
                    onChange={handleTagImageUpload}
                  />
                  <Box
                    sx={{
                      mt: 0.5,
                      width: 120,
                      height: 60,
                      border: "1px solid #FAFAFB",
                      backgroundColor: "#FAFAFB",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {tagImageUrl ? (
                      <img
                        src={tagImageUrl}
                        alt="Uploaded"
                        style={{ width: "100%", height: "100%" }}
                      />
                    ) : (
                      <LandscapeIcon sx={{ color: "#BCC1CA" }} />
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: 1,
                      width: 120,
                    }}
                  >
                    <label style={{ fontSize: "13px", color: "black" }}>
                      Upload Image (120x60px)
                    </label>
                    <IconButton
                      component="label"
                      size="small"
                      sx={{ color: "#000" }}
                    >
                      <FileUploadOutlinedIcon />
                      <input
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            settagImageUrl(URL.createObjectURL(file));
                          }
                        }}
                      />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>

              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor: "#565E6D",
                    border: "1px solid #565E6D",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#546e7a" },
                    borderRadius: "0px",
                  }}
                  onClick={handleTagSubmit}
                >
                  Save new tag
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Box>
    );
};


