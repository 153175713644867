import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography, Button, TextField, Autocomplete} from '@mui/material';
import { getData } from "../../../ServerRequest"; 
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Checkbox from '@mui/material/Checkbox';
import SearchNestedCollection from './SearchNestedCollection'; 

export default function SearchCollection({ 
        setSearchCollection, 
        setSearchCollectionTagIds, 
        setSearchNestedCollection, 
        setShowDefineCollection, 
        handleShowCollectionSection,
        mainCollectionId,
        isCollectionSaved    
    }) { 
    const searchParams = new URLSearchParams(window.location.search);
    const collectionId = searchParams.get('collection_id');
    const [loading, setLoading] = useState(true);  
    const [tagsData, setTagsData] = useState([]);
    const [searchQuantity, setSearchQuantity] = useState("");    
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);    
    const [showNestedCollection, setShowNestedCollection] = useState(false); 

    useEffect(() => {
        async function fetchData() {
            try {

                let masterRes = await getData('/api/product/acc_tag_master');
                console.log(masterRes);
                let master_data = masterRes['data']['tag_masters'];
                if (master_data) {
                    // Map the data to ensure it contains both id and product_tag
                    const formattedData = master_data.map(tag => ({
                        id: tag.id,
                        product_tag: tag.product_tag
                    }));
                    setTagsData(formattedData); // Set the formatted data
                } else {
                    console.error("Error fetching tags data:", masterRes.message);
                }

            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
        console.log("mainCollectionId:", mainCollectionId);

    }, [collectionId, mainCollectionId]);
    
    const handleCreateCollection = () => {
        const combinedTags = [
            ...(Array.isArray(selectedTags) ? selectedTags : [])
        ];
        
        console.log("Final Combined Tags Before Filtering:", combinedTags);        
       
        // Extract only product_tag values and filter out invalid ones
        const validTags = combinedTags
            .map(tag => tag?.product_tag?.trim()) // Ensure trimming and avoid undefined values
            .filter(tag => tag && tag !== ""); // Remove empty or falsy values
        
        const validTagIds = combinedTags
            .map(tag => tag?.id) // Ensure trimming and avoid undefined values
            .filter(tag => tag && tag !== ""); // Remove empty or falsy values

        console.log("Filtered Valid Tags:", validTags);
        console.log("Filtered Valid Tag IDs:", validTagIds);
        
        // Pass both id and product_tag to setSearchCollection
        setSearchCollection(combinedTags.map(tag => ({
            id: tag.id,
            product_tag: tag.product_tag
        })));
        setSearchCollectionTagIds(validTagIds);
        setShowDefineCollection(true);
        handleShowCollectionSection();
    };
    
    const togglenestedTags = () => {
        setShowNestedCollection(!showNestedCollection); 
    };

    return (
        <Box sx={{ width: '100%' }}>            
            {/* Create Search Tags collection */}
            <Box sx={{ mt: 2 }}> 
                {/* Search Tags Dropdown */}
                <Accordion sx={{ mt: 2, backgroundColor: "white", boxShadow: 0, borderTop: '1px solid #BCC1CA', borderBottom: '1px solid #BCC1CA' }}>
                    <AccordionSummary
                        variant="h7"
                        sx={{ display: "flex", alignItems: "center", gap: 2, fontFamily: "Inter", fontWeight: 700, fontSize: 16, height: 53 }}
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <RadioButtonUncheckedIcon sx={{ mr: 2 }} /> Create collection by search input
                    </AccordionSummary>
                    <AccordionDetails sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 7, pt: 1, pb: 2 }}>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography variant="body1" sx={{ color: "#424956", fontFamily: "Inter", fontWeight: 700, fontSize: 14 }}>Min quantity/ tag (in Mtr)</Typography>
                            <TextField  
                                sx={{ width: 191, "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px", backgroundColor: "#fff" } }}
                                value={searchQuantity}
                                onChange={(e) => setSearchQuantity(e.target.value)}
                            />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            <Typography variant="body1" sx={{ fontFamily: "Inter", fontWeight: "700", fontSize: 16 }}>
                                Start defining tags by search
                            </Typography>
                            <Autocomplete
                                multiple
                                options={tagsData}  
                                getOptionLabel={(option) => option.product_tag || ""}
                                renderOption={(props, option, { selected }) => (
                                    <li 
                                        {...props} 
                                        style={{ 
                                            fontSize: '0.875rem', 
                                            padding: '2px 6px', 
                                            border: '1px solid #DEE1E6', 
                                            color: '#565E6D', // Match the button color
                                            backgroundColor: selected ? '#f5f5f5' : '#fff', 
                                            cursor: 'pointer'                                         
                                        }}>         
                                        <Checkbox
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.product_tag}
                                    </li>
                                )}
                                renderTags={(value) => value.map((option) => option.product_tag).join(', ')}
                                renderInput={(params) => 
                                    <TextField {...params}                                         
                                        helperText="Separate input with (,) to get input"                                    
                                    />}
                                sx={{ width: 499, "& .MuiOutlinedInput-root": { height: "35px", borderRadius: "0px", padding: "5px", overflow: "hidden" } }}
                                inputValue={tags} 
                                onInputChange={(event, newValue) => setTags(newValue)} 
                                onChange={(event, newValue) => {
                                    setSelectedTags(newValue); // Update selected tags
                                    // Pass objects with id and product_tag to setSearchCollection
                                    setSearchCollection(newValue.map(tag => ({ id: tag.id, product_tag: tag.product_tag })));
                                    handleShowCollectionSection();
                                }}
                                isOptionEqualToValue={(option, value) => option.product_tag === value.product_tag} // Compare by id
                                filterSelectedOptions
                                filterOptions={(options, state) => {
                                    const selectedOptions = state.inputValue ? [] : selectedTags;
                                    return [...selectedOptions, ...options.filter(option => !selectedTags.includes(option))];
                                }}
                            />
                        </Box>
                        
                        {/* Create collection Button */}
                        <div style={{ textAlign: "start", marginTop: "20px" }}>
                            <Button
                                sx={{
                                    width: "307px",
                                    height: "44px",
                                    backgroundColor: "#565E6D",
                                    color: "#fff",
                                    "&:hover": { backgroundColor: "#546e7a" },
                                    borderRadius: "0px",
                                }}
                                onClick={handleCreateCollection}
                            >
                                Create collection
                            </Button>
                        </div>

                        {/* Search Nested Tags collection */}                        
                        {isCollectionSaved && (
                            <Button
                                sx={{
                                    width: "265px",
                                    height: "26px",
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    borderRadius: "0px",
                                    fontWeight: 700,
                                    fontFamily: "Inter",
                                    fontSize: 14,
                                    borderBottom: '1px solid #000'
                                }}
                                onClick={togglenestedTags} // Toggle nested collection
                            >
                                Create a nested collection
                            </Button> 
                        )}        
                            
                        {/* Conditionally render SearchNestedCollection */}
                        {showNestedCollection && (
                            <SearchNestedCollection
                                setSearchCollection={(tags) => setSearchCollection(tags.map(tag => ({ id: tag.id, product_tag: tag.product_tag })))}
                                setSearchNestedCollection={(nestedTags) => setSearchNestedCollection(nestedTags)}
                                setShowDefineCollection={setShowDefineCollection}
                                handleShowCollectionSection={handleShowCollectionSection}
                                mainCollectionId={mainCollectionId}
                            />
                        )}
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    );  
}
